<template>
  <div>
    <div
      v-if="tagsPond.length < 1"
      class="text-center text-h6 text-grey-5 q-mt-lg"
    >
      No pond tags to display
    </div>
    <div
      v-for="(farm, index) in tagsPond"
      :key="farm.farm_id + 'farm-index'"
      :class="index % 2 === 0 ? colorBlueGrey : colorGrey"
    >
      <div
        class="row items-center no-wrap q-py-sm q-px-sm cursor-pointer"
        @click="farm.expand = !farm.expand"
      >
        <div class="ellipsis">
          <q-icon name="home_work" size="xs" color="purple" class="q-mr-sm" />
          {{ farmName(farm.farm_id) }}
        </div>
        <q-space />
        <div>
          <q-badge color="grey-8" class="q-mr-sm">
            {{ farm.numTags }}
          </q-badge>
        </div>
        <q-icon
          v-if="!farm.expand"
          name="expand_more"
          size="md"
          :color="colorOrange"
        />
        <q-icon
          v-if="farm.expand"
          name="expand_less"
          size="md"
          :color="colorOrange"
        />
      </div>

      <div v-if="farm.expand && showOptions" class="col-12">
        <q-separator />
        <div class="row justify-center q-py-xs">
          <q-btn
            outline
            icon="delete"
            color="red-14"
            size="sm"
            padding="1px sm"
            class="q-mr-sm"
            @click="$emit('farmDeleteStart', farm, 'ponds')"
          />
          <q-btn
            outline
            icon="add"
            color="purple"
            size="sm"
            padding="1px sm"
            @click="$emit('farmAddStart', farm, 'ponds')"
          />
        </div>
      </div>

      <div v-if="farm.expand" class="q-pl-md">
        <q-separator />
      </div>
      <div v-if="farm.expand" class="col-12 row q-pl-md">
        <div
          v-for="tag in farm.tags"
          :key="tag.tag_name + 'tag-index'"
          class="col-12 row items-center text-caption q-py-xs"
        >
          <q-icon name="sell" size="xs" :color="colorOrange" class="q-mr-sm" />
          {{ tag.tag_name }}
          <div
            v-for="pond in tag.ponds"
            :key="pond.farm_id + pond.pond_id"
            class="col-12 row items-center text-caption q-py-xs"
          >
            <q-icon
              name="water"
              size="xs"
              color="primary"
              class="q-ml-lg q-mr-sm"
            />
            {{ pondName(pond) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { decoder } from '@/lib/helpers';

export default {
  name: 'PondTags',
  props: {
    tagsPond: {
      type: Array,
      required: true
    },
    showOptions: {
      type: Boolean,
      required: true
    },
    farmList: {
      type: Array,
      required: true
    }
  },
  methods: {
    farmName(farmId) {
      const farm = this.farmList.find((x) => x.id === parseInt(farmId));
      return farm ? decoder(farm.farm_name) : '';
    },
    pondName(tag) {
      const farm = this.farmList.find((x) => x.id === parseInt(tag.farm_id));
      const pond = farm.gps.ponds.find(
        (pond) => tag.pond_id === parseInt(pond.id)
      );

      return pond?.name ?? '---';
    },
    levelColor(tag) {
      return tag.tag_level === 0
        ? 'black'
        : tag.tag_level === 1
        ? 'green-14'
        : tag.tag_level === 2
        ? 'purple'
        : tag.tag_level === 3
        ? 'red-14'
        : tag.tag_level === 4
        ? 'orange-14'
        : tag.tag_level === 10
        ? 'primary'
        : 'grey';
    }
  },
  computed: {
    colorBlueGrey() {
      return this.$q.dark.isActive ? 'bg-blue-grey-10' : 'bg-grey-4';
    },
    colorGrey() {
      return this.$q.dark.isActive ? 'bg-grey-10' : 'bg-grey-5';
    },
    colorOrange() {
      return this.$q.dark.isActive ? 'orange-14' : 'orange-10';
    }
  }
};
</script>

<style scoped></style>
