<template>
  <div class="row full-width justify-center">
    <div class="div-container">
      <q-toolbar class="bg-primary text-white shadow-1 rounded-borders q-py-xs">
        <div class="text-h6 q-pl-sm">Attachments</div>
        <q-space />

        <q-btn round flat icon="more_vert">
          <q-menu anchor="bottom right" self="top right" :offset="[12, 4]">
            <q-item clickable v-close-popup @click="addMode">
              <q-item-section>
                <div class="row items-center">
                  <q-icon name="add" size="24px" class="text-primary q-mr-md" />
                  New Attachment
                </div>
              </q-item-section>
            </q-item>
            <q-separator />
            <div class="q-pa-md">
              <q-toggle
                dense
                v-model="displayCompressed"
                label="Display Compressed Images"
                v-close-popup
              />
            </div>
            <div class="q-pa-md">
              <q-toggle
                dense
                v-model="modes.edit"
                label="Display Edit Options"
                v-close-popup
              />
            </div>
          </q-menu>
        </q-btn>
      </q-toolbar>

      <div v-if="modes.loading" class="q-py-xs">
        <q-linear-progress indeterminate rounded color="primary" size="md" />
      </div>

      <div v-if="modes.uploadProgress" class="q-py-xs">
        <q-linear-progress
          rounded
          :value="uploadProgress"
          color="primary"
          size="md"
        />
      </div>

      <div v-if="modes.add" class="q-pt-lg">
        <div>
          <q-checkbox
            v-model="add.useImageName"
            dense
            label="Use Image Name"
            class="text-primary q-mr-md"
            color="primary"
          />
          <q-checkbox
            v-if="isAdmin"
            v-model="add.adminRestricted"
            dense
            label="Admin Only"
            class="text-red-14"
            color="red-14"
          />
        </div>

        <div class="q-mt-sm">
          <q-file
            v-model="image"
            outlined
            color="primary"
            label-color="primary"
            label="Select Image"
            accept=".jpg, .jpeg, .png, .svg, .pdf, image/*"
            @rejected="onRejected"
          />
          <span v-if="add.selectAttachmentErr" class="text-red-14"
            >Please Select Image!</span
          >
          <q-input
            v-if="!add.useImageName"
            outlined
            v-model="add.name"
            label="Image Name (optional)"
            color="primary"
            label-color="primary"
            class="q-mt-sm"
          />
        </div>

        <div align="right" class="q-mt-sm">
          <q-btn
            label="Cancel"
            icon="cancel"
            color="grey-4"
            text-color="black"
            class="q-mr-sm"
            @click="modes.add = false"
          />
          <q-btn
            label="Upload"
            icon="cloud_upload"
            color="primary"
            @click="uploadImage"
          />
        </div>
      </div>
    </div>

    <div
      v-if="!modes.add && !modes.loading"
      class="row full-width justify-center q-gutter-sm q-mt-xl"
    >
      <div v-if="!images.length && !pdfs.length" class="q-mt-lg">
        <div class="text-primary text-center" style="font-size: 27px">
          No Attachments
        </div>
      </div>

      <div
        v-for="pdf in pdfs"
        :key="pdf.img_url"
        class="full-width row justify-center q-px-sm"
        style="padding-bottom: 60px"
      >
        <div
          v-if="
            pdf.admin_restricted === 0 ||
            (isAdmin && pdf.admin_restricted === 1)
          "
          class="row full-width"
          style="max-width: 700px"
        >
          <div class="row full-width">
            <div class="row items-center full-width q-mb-sm">
              <q-badge
                v-if="pdf.admin_restricted === 0 && isAdmin"
                color="primary"
              >
                <q-icon name="visibility" size="xs" class="q-mr-sm q-ml-xs" />
                Public
              </q-badge>
              <q-badge
                v-if="pdf.admin_restricted === 1 && isAdmin"
                color="primary"
              >
                <q-icon
                  name="visibility_off"
                  size="xs"
                  class="q-mr-sm q-ml-xs"
                />
                Admin Only
              </q-badge>
              <q-space />
              {{ pdf.currentPage }} / {{ pdf.pageCount }}
              <q-btn
                flat
                round
                icon="chevron_left"
                color="primary"
                padding="0px"
                size="md"
                class="q-ml-sm"
                @click="pdfBack(pdf)"
              />
              <q-btn
                flat
                round
                icon="chevron_right"
                color="primary"
                padding="0px"
                size="md"
                @click="pdfNext(pdf)"
              />
              <q-space />
              <q-btn
                outline
                label="New Tab"
                icon="open_in_new"
                color="primary"
                padding="xs sm"
                size="sm"
                @click="openInNewTab(pdf.img_url)"
              />
            </div>
          </div>
          <div
            class="full-width rounded-borders text-h6 text-bold text-primary q-pl-sm"
            style="border: solid #245ad3 1px"
          >
            <div v-if="pdf.img_name.length < 1" class="q-pa-md"></div>
            {{ pdf.img_name }}
          </div>

          <div class="row items-center justify-end full-width q-my-xs"></div>
          <pdf
            :src="pdf.img_url"
            :page="pdf.currentPage"
            style="width: 100%"
            @num-pages="pdf.pageCount = $event"
          />

          <div class="row full-width text-caption">
            <div class="text-primary">
              {{ parseDateTime(pdf.upload_date) }}
            </div>
            <q-space />
            <div class="text-primary">
              {{ userName(pdf.uploaded_by) }}
            </div>
          </div>

          <div v-if="modes.edit" class="row full-width justify-end">
            <q-btn
              unelevated
              label="Edit"
              icon="edit"
              color="grey-4"
              text-color="black"
              class="q-mr-sm"
              padding="xs md"
              @click="editAttachment(pdf)"
            />
            <q-btn
              unelevated
              label="Delete"
              icon="delete"
              color="grey-4"
              text-color="accent"
              @click="confirmDelete(pdf)"
            />
          </div>
        </div>
      </div>

      <div
        v-for="image in images"
        :key="image.img_key"
        class="full-width row justify-center q-px-sm"
        style="padding-bottom: 60px"
      >
        <div
          v-if="
            image.admin_restricted === 0 ||
            (isAdmin && image.admin_restricted === 1)
          "
          class="row full-width"
          style="max-width: 700px"
        >
          <div class="row full-width">
            <div class="row items-end full-width q-mb-sm">
              <q-badge
                v-if="image.admin_restricted === 0 && isAdmin"
                color="primary"
              >
                <q-icon name="visibility" size="xs" class="q-mr-sm q-ml-xs" />
                Public
              </q-badge>
              <q-badge
                v-if="image.admin_restricted === 1 && isAdmin"
                color="primary"
              >
                <q-icon
                  name="visibility_off"
                  size="xs"
                  class="q-mr-sm q-ml-xs"
                />
                Admin Only
              </q-badge>
              <q-space />
              <div>
                <q-btn
                  outline
                  label="New Tab"
                  icon="open_in_new"
                  color="primary"
                  padding="xs sm"
                  size="sm"
                  @click="openInNewTab(image.img_url)"
                />
              </div>
            </div>
          </div>
          <div
            v-if="image.img_name.length > 1"
            class="full-width rounded-borders text-h6 text-bold text-primary q-pl-sm q-mb-sm"
            style="border: solid #245ad3 1px"
          >
            {{ image.img_name }}
          </div>

          <q-img
            :src="displayCompressed ? image.compressed_url : image.img_url"
            :native-context-menu="true"
            spinner-color="primary"
            style="border: solid 1px #eeeeee"
            class="rounded-borders"
          />

          <div class="row full-width text-caption">
            <div class="text-primary">
              {{ parseDateTime(image.upload_date) }}
            </div>
            <q-space />
            <div class="text-primary">
              {{ userName(image.uploaded_by) }}
            </div>
          </div>

          <div v-if="modes.edit" class="row full-width justify-end">
            <q-btn
              unelevated
              label="Edit"
              icon="edit"
              color="grey-4"
              text-color="black"
              class="q-mr-sm"
              padding="xs md"
              @click="editAttachment(image)"
            />
            <q-btn
              unelevated
              label="Delete"
              icon="delete"
              color="grey-4"
              text-color="accent"
              @click="confirmDelete(image)"
            />
          </div>
        </div>
      </div>
    </div>

    <q-dialog v-model="edit.display" persistent>
      <q-card class="q-pa-lg">
        <div class="row items-center q-pb-lg">
          <div
            class="row full-width rounded-borders text-h6 text-bold text-primary"
            style="border: solid #245ad3 1px"
          >
            <div class="q-pl-sm" style="min-height: 34px"></div>
            {{ attachment.img_name }}
          </div>
        </div>

        <div class="row items-center">
          <div class="full-width">
            <q-btn
              unelevated
              :label="
                attachment.compressed_key
                  ? 'Move to top of images'
                  : 'Move to top of Pdfs'
              "
              icon="arrow_upward"
              color="primary"
              class="full-width q-mb-lg"
              @click="moveToTop"
            />
          </div>

          <div class="full-width q-pb-md">
            <div v-if="isAdmin" class="q-pb-xs">
              <q-checkbox
                v-model="edit.adminRestricted"
                dense
                label="Admin Only"
                color="red-14"
              />
            </div>

            <q-input
              outlined
              v-model="edit.attachmentName"
              label="Edit Image Name"
              class="q-mt-sm"
              color="primary"
              label-color="primary"
            />
          </div>
        </div>

        <div class="row justify-end">
          <q-btn
            unelevated
            label="Cancel"
            color="grey-4"
            text-color="black"
            class="q-mr-sm"
            @click="edit.display = false"
          />
          <q-btn
            unelevated
            label="Update"
            color="primary"
            @click="updateAttachment"
          />
        </div>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import axios from 'axios';
import pdf from 'vue-pdf';
import store from '@/store';

export default {
  name: 'ManageAttachement',
  components: {
    pdf
    //ConfirmDialog,
  },
  props: {
    parentObj: {
      type: Object,
      required: true
    },
    parentType: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      add: {
        name: '',
        adminRestricted: false,
        selectAttachmentErr: false,
        useImageName: false
      },
      attachment: {},
      displayCompressed: true,
      edit: {
        display: false,
        adminRestricted: false,
        attachmentName: ''
      },
      modes: {
        add: false,
        edit: false,
        uploadProgress: false,
        loading: false
      },
      image: null,
      images: [],
      pdfs: [],
      uploadProgress: 0
    };
  },
  mounted() {
    this.setAttachments();
    this.checkScreenshot();
  },
  methods: {
    checkScreenshot() {
      if (store.state.screenshot !== null) {
        this.image = store.state.screenshot.file;
        this.uploadImage();
        store.dispatch('setScreenshot', null);
      }
    },
    parseDateTime(dateTime) {
      if (!dateTime) {
        return '- - -';
      }
      return new Date(dateTime * 1000).toLocaleString('en-US');
    },
    addMode() {
      this.image = null;
      this.add.name = '';
      this.add.adminRestricted = false;
      this.add.selectAttachmentErr = false;
      this.modes.edit = false;
      this.modes.add = true;

      if (this.isAdmin) {
        this.add.adminRestricted = true;
      }
    },
    attachmentDispatch(attachObj) {
      if (this.parentType === 'repair-log') {
        store
          .dispatch('attachmentRepairLog', {
            repair_id: attachObj.id,
            log: attachObj
          })
          .then((results) => {
            this.modes.loading = false;
            // this.modes.edit = false;

            results.disableCheck = true;
            this.$finishResult.handleResultsAsync(results).then((response) => {
              if (response === 'retry') {
                this.attachmentDispatch(attachObj);
              }
            });
          });
      } else if (this.parentType === 'farm') {
        store
          .dispatch('setFarmAttachments', {
            farmId: attachObj.farmId,
            value: attachObj
          })
          .then((results) => {
            this.modes.loading = false;
            // this.modes.edit = false;

            // COMMENT: following line necessary to disable green checkbox
            results.disableCheck = true;
            this.$finishResult.handleResultsAsync(results).then((response) => {
              if (response === 'retry') {
                this.attachmentDispatch(attachObj);
              }
            });
          });
      } else {
        store
          .dispatch('attachmentWorkLog', {
            farmId: attachObj.farm_id,
            log: attachObj
          })
          .then((results) => {
            this.modes.loading = false;
            // this.modes.edit = false;

            results.disableCheck = true;
            this.$finishResult.handleResultsAsync(results).then((response) => {
              if (response === 'retry') {
                this.attachmentDispatch(attachObj);
              }
            });
          });
      }
    },
    updateAttachment() {
      this.edit.display = false;
      this.modes.loading = true;

      let attachmentName = this.edit.attachmentName;
      attachmentName = attachmentName.trim();
      attachmentName = attachmentName.replaceAll('.', '');
      attachmentName =
        attachmentName.charAt(0).toUpperCase() + attachmentName.slice(1);
      this.attachment.img_name = attachmentName;
      this.attachment.admin_restricted = this.edit.adminRestricted ? 1 : 0;

      const attachObj = JSON.parse(JSON.stringify(this.parentObj));
      attachObj.deleteAttachment = false;
      attachObj.editAttachment = true;
      attachObj.selectedAttachment = this.attachment;

      this.attachmentDispatch(attachObj);
    },
    confirmDelete(attachment) {
      this.$q
        .dialog({
          title: 'Delete Attachment',
          message: 'Are you sure you want to permanently delete attachment?',
          ok: {
            unelevated: true,
            icon: 'delete',
            color: 'negative',
            label: 'Delete'
          },
          cancel: {
            unelevated: true,
            color: 'grey-4',
            textColor: 'black',
            label: 'Cancel'
          },
          focus: 'none',
          persistent: true
        })
        .onOk(() => {
          this.deleteImage(attachment);
        });
    },
    deleteImage(attachment) {
      this.modes.loading = true;

      const attachObj = JSON.parse(JSON.stringify(this.parentObj));
      attachObj.deleteAttachment = true;
      attachObj.editAttachment = false;
      attachObj.selectedAttachment = attachment;

      this.attachmentDispatch(attachObj);

      this.$q.notify({
        message: 'Attachment Deleted',
        color: 'primary'
      });
    },
    editAttachment(attachment) {
      this.attachment = attachment;
      this.edit.attachmentName = attachment.img_name;
      this.edit.adminRestricted =
        attachment.admin_restricted === 0 ? false : true;
      this.edit.display = true;
    },
    moveToTop() {
      this.edit.display = false;
      this.modes.loading = true;

      this.attachment.sort_date = Math.floor(new Date().getTime() / 1000);

      const attachObj = JSON.parse(JSON.stringify(this.parentObj));
      attachObj.deleteAttachment = false;
      attachObj.editAttachment = true;
      attachObj.selectedAttachment = this.attachment;

      this.attachmentDispatch(attachObj);
    },
    onRejected(err) {
      console.log(err);
    },
    openInNewTab(url) {
      window.open(url);
    },
    pdfBack(pdf) {
      if (pdf.currentPage !== 1) {
        pdf.currentPage--;
      }
    },
    pdfNext(pdf) {
      if (pdf.currentPage !== pdf.pageCount) {
        pdf.currentPage++;
      }
    },
    async setAttachments() {
      const attachments = JSON.parse(
        JSON.stringify(this.parentObj.attachments)
      );
      const images = [];
      const pdfs = [];

      const prevPdfs = JSON.parse(JSON.stringify(this.pdfs));

      for (let i = 0; i < attachments.length; i++) {
        const attachment = attachments[i];
        // if null then it is a pdf
        if (attachment.is_pdf === 1) {
          const prev = prevPdfs.find((x) => x.img_url === attachment.img_url);

          if (prev) {
            attachment.currentPage = prev.currentPage;
            attachment.pageCount = prev.pageCount;
          } else {
            attachment.currentPage = 1;
            attachment.pageCount = 1;
          }

          pdfs.push(attachment);
        } else {
          images.push(attachment);
        }
      }

      pdfs.sort((a, b) => b.sort_date - a.sort_date);
      this.pdfs = pdfs;

      images.sort((a, b) => b.sort_date - a.sort_date);
      this.images = images;
    },
    async uploadImage() {
      if (this.image === null) {
        this.add.selectAttachmentErr = true;
        return;
      }

      this.modes.add = false;

      let attachmentName = this.add.useImageName
        ? this.image.name
        : this.add.name;
      attachmentName = attachmentName.trim();
      attachmentName = attachmentName.replaceAll('.', '');
      attachmentName =
        attachmentName.charAt(0).toUpperCase() + attachmentName.slice(1);

      const newAttachment = {
        name: attachmentName,
        uploadDate: Math.floor(new Date().getTime() / 1000),
        uploadedBy: this.currentUser.user_id,
        adminRestricted: this.add.adminRestricted ? 1 : 0
      };

      const formData = new FormData();
      formData.append('file', this.image);

      const config = {
        onUploadProgress: (progressEvent) => {
          this.uploadProgress = progressEvent.loaded / progressEvent.total;
        }
      };

      this.modes.uploadProgress = true;
      let upload;
      if (this.parentType === 'farm-work' || this.parentType === 'workLog') {
        const workLog = JSON.parse(JSON.stringify(this.parentObj));
        workLog.parentType = this.parentType;
        workLog.newAttachment = newAttachment;

        formData.append('workLog', JSON.stringify(workLog));

        upload = await axios
          .post('/api/attachment_upload/worklog/', formData, config)
          .then((response) => {
            return response;
          })
          .catch((err) => {
            return { data: 'Error uploading file' };
          });
      } else if (this.parentType === 'farm') {
        const attachObj = {
          parentType: this.parentType,
          farmId: this.parentObj.farmId,
          newAttachment
        };
        formData.append('attachObj', JSON.stringify(attachObj));

        upload = await axios
          .post('/api/attachment_upload/farm/', formData, config)
          .then((response) => {
            return response;
          })
          .catch((err) => {
            return { data: 'Error uploading file' };
          });
      } else if (this.parentType === 'repair-log') {
        const attachObj = {
          parentType: this.parentType,
          repair_id: this.parentObj.id,
          newAttachment
        };
        formData.append('attachObj', JSON.stringify(attachObj));

        upload = await axios
          .post('/api/attachment_upload/repairlog/', formData, config)
          .then((response) => {
            return response;
          })
          .catch((err) => {
            return { data: 'Error uploading file' };
          });
      }

      this.modes.uploadProgress = false;

      if (upload.data === 'Upload Was Successful') {
        this.$q.notify({
          message: 'Attachment Uploaded',
          icon: 'photo_library',
          color: 'primary'
        });
      } else if (upload.data === 'Error uploading file') {
        this.$q.dialog({
          title: 'Error',
          message: 'Error uploading file ;)'
        });
      } else if (upload.data === 'No files were uploaded') {
        this.$q.dialog({
          title: 'Error',
          message: 'No files were uploaded'
        });
      }
    },
    userName(id) {
      if (id === null) {
        return '---';
      }
      const user = this.$store.state.userList.find(
        (element) => element.user_id === id
      );
      return user ? user.display_name : '---';
    }
  },
  computed: {
    isAdmin() {
      return this.$store.state.user.aerworx_level === 'super-admin';
    },
    currentUser() {
      return this.$store.state.user;
    }
  },
  watch: {
    parentObj: {
      deep: true,
      handler() {
        this.setAttachments();
      }
    },
    '$store.state.screenshot'() {
      if (store.state.screenshot) {
        this.checkScreenshot();
      }
    }
  }
};
</script>

<style scoped>
.div-container {
  width: 700px;
}

.max-width {
  max-width: 700px;
}

@media all and (max-width: 700px) {
  .div-container {
    width: 96vw;
  }
}
</style>
