import { render, staticRenderFns } from "./BoardLogs.vue?vue&type=template&id=559f7f6d&scoped=true"
import script from "./BoardLogs.vue?vue&type=script&lang=js"
export * from "./BoardLogs.vue?vue&type=script&lang=js"
import style0 from "./BoardLogs.vue?vue&type=style&index=0&id=559f7f6d&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "559f7f6d",
  null
  
)

export default component.exports
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QPageSticky from 'quasar/src/components/page-sticky/QPageSticky.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QForm from 'quasar/src/components/form/QForm.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QPopupProxy from 'quasar/src/components/popup-proxy/QPopupProxy.js';
import QDate from 'quasar/src/components/date/QDate.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QBtn,QTable,QInput,QSpace,QTd,QIcon,QPageSticky,QDialog,QCard,QCardSection,QForm,QToolbar,QToolbarTitle,QSelect,QPopupProxy,QDate,QCardActions,QSeparator});qInstall(component, 'directives', {ClosePopup});
