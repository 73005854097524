<template>
  <div>
    <div v-if="selectedTodo">
      <template>
        <q-input
          outlined
          autogrow
          v-model="newMessage"
          label="Message"
          @input="setEmptyErr"
        >
          <template v-slot:append>
            <q-btn @click="addMsg" round flat icon="send" />
          </template>
        </q-input>
        <div v-if="noGroupErr" class="text-caption text-negative">
          Please select recipients
        </div>
        <div v-if="emptyMsgErr" class="text-caption text-negative">
          Message is empty
        </div>
        <q-option-group
          dense
          size="sm"
          :options="chatGroupOptions"
          type="checkbox"
          v-model="chatGroup"
          class="q-pt-sm"
          @input="setGroupErr"
        />
        <q-btn
          v-if="!fullUserList"
          outline
          label="more"
          color="primary"
          icon="expand_more"
          size="12px"
          padding="1px md"
          class="q-mt-sm"
          @click="(fullUserList = true), setChatGroupOptions()"
        />
        <q-btn
          v-if="fullUserList"
          outline
          color="primary"
          label="less"
          icon="expand_less"
          size="12px"
          padding="1px md"
          class="q-mt-sm"
          @click="(fullUserList = false), setChatGroupOptions()"
        />
      </template>
    </div>

    <div>
      <template v-if="ChatsBuilder.length > 0">
        <div
          class="rounded-borders q-py-xs q-mt-sm q-mb-md"
          style="border: 1px solid #1976d2"
        >
          <div class="col text-center text-primary text-subtitle1">
            <q-icon name="chat" color="orange" size="25px" class="q-mr-sm" />
            Chats
          </div>
        </div>
        <div v-for="(chat, i) in ChatsBuilder" :key="i + chat.chatIdsStr">
          <q-card
            v-if="chat.isPartOfChat || isSuperAdmin"
            flat
            bordered
            :class="isSelected(chat) && 'highlight'"
            class="q-py-sm q-px-md q-mb-md"
          >
            <div
              class="row justify-center text-subtitle1"
              :class="$q.dark.isActive ? 'text-white' : 'text-black'"
            >
              {{ chat.groupStr }}
            </div>
            <q-chat-message
              v-for="(msgObj, j) in chat.messages"
              :key="j + i"
              :name="userName(msgObj.sentBy)"
              :sent="msgObj.isSelf"
              :bg-color="
                msgObj.starred
                  ? 'yellow-4'
                  : msgObj.isSelf
                  ? 'green-3'
                  : 'grey-3'
              "
            >
              <div class="row">
                <div class="row col-12 q-pb-xs">
                  <div style="width: calc(100% - 40px)">
                    {{ msgObj.msg }}
                  </div>
                  <div style="width: 40px">
                    <q-space />
                    <div class="row no-wrap">
                      <q-space />
                      <q-btn
                        v-if="
                          !msgObj.isSelf && msgObj.starred && chat.isPartOfChat
                        "
                        round
                        flat
                        icon="star"
                        color="red-14"
                        size="12px"
                        style="float: right"
                        padding="0px"
                        @click="starMsg(false, msgObj.originalIndex)"
                      />
                      <q-btn
                        v-if="
                          !msgObj.isSelf && !msgObj.starred && chat.isPartOfChat
                        "
                        round
                        flat
                        icon="star"
                        color="grey-5"
                        size="12px"
                        style="float: right"
                        padding="0px"
                        @click="starMsg(true, msgObj.originalIndex)"
                      />
                      <q-btn
                        v-if="msgObj.isSelf"
                        flat
                        icon="clear"
                        color="negative"
                        size="10px"
                        style="float: right"
                        padding="0px"
                        @click="deleteMsg(msgObj.originalIndex)"
                      />
                    </div>
                  </div>
                </div>
                <div class="row col-12 text-caption text-grey-8">
                  {{ parseMsgTime(msgObj.time) }}
                  <q-space />
                  <q-btn
                    v-if="msgObj.isSelf && msgObj.starred && chat.isPartOfChat"
                    round
                    flat
                    icon="star"
                    color="red-14"
                    size="12px"
                    style="float: right"
                    padding="0px"
                    @click="starMsg(false, msgObj.originalIndex)"
                  />
                  <q-btn
                    v-if="msgObj.isSelf && !msgObj.starred && chat.isPartOfChat"
                    round
                    flat
                    icon="star"
                    color="grey-6"
                    size="12px"
                    style="float: right"
                    padding="0px"
                    @click="starMsg(true, msgObj.originalIndex)"
                  />
                  <q-icon
                    v-if="unreadIds.includes(msgObj.id)"
                    name="mark_chat_unread"
                    color="negative"
                    class="q-pl-xs"
                    style="float: right"
                    size="20px"
                  />
                  <q-icon
                    dense
                    name="done_all"
                    size="18px"
                    :color="msgObj.readByAll ? 'blue-9' : 'grey-6'"
                    class="q-pl-sm"
                  />
                </div>
              </div>
            </q-chat-message>
          </q-card>
        </div>
      </template>
    </div>

    <!-- field display -->
    <q-dialog v-model="displayFields">
      <q-card>
        <q-card-section>
          <div class="text-h6">{{ parseTimeStamp(selectedTodo.date) }}</div>
          <div :style="buildBackColor(selectedTodo.color, 0.3)" class="text">
            {{ selectedTodo.work_cat_name }} : {{ selectedTodo.work_item_name }}
          </div>
        </q-card-section>

        <q-card-section class="q-pt-none">
          <div
            v-for="(field, index) in fieldList(selectedTodo.work_item)"
            :key="index"
            class="row"
          >
            <div class="col-8" style="min-width: 250px">
              {{ field.name }}
            </div>
            <div class="col">
              <template v-if="field.type === 'checkbox'">
                <q-icon
                  class="check-style check-color"
                  v-if="fieldDecoder(field, selectedTodo.field_values)"
                  name="check"
                ></q-icon>
                <q-icon v-else color="negative" name="clear"></q-icon>
              </template>
              <template v-else>
                {{ fieldDecoder(field, selectedTodo.field_values) }}
              </template>
            </div>
          </div>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn flat label="OK" color="orange" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import store from '@/store';
import { parseTimeStamp } from '@/lib/date-utils';
import { countUnread, trimName } from '@/lib/helpers';
import { v4 } from 'uuid';
import { colors } from 'quasar';

export default {
  name: 'TodoChats',
  props: {
    selectedTodo: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      chatGroup: [],
      chatGroupOptions: [],
      displayFields: false,
      emptyMsgErr: false,
      fullUserList: false,
      newMessage: '',
      noGroupErr: false,
      showNote: false,
      unreadIds: []
    };
  },
  mounted() {
    this.markMsgRead();
    this.setChatFull();

    setTimeout(() => {
      this.setUnreadArr();
    }, 2 * 60 * 1001);
  },
  methods: {
    addMsg() {
      if (!this.chatGroup.length) {
        this.noGroupErr = true;
        return;
      }
      this.noGroupErr = false;

      if (!this.newMessage.length) {
        this.emptyMsgErr = true;
        return;
      }
      this.emptyMsgErr = false;

      const chatGroup = [];
      chatGroup.push({
        userId: this.$store.state.user.user_id,
        viewed: 1
      });

      for (let i = 0; i < this.chatGroup.length; i++) {
        const userId = this.chatGroup[i];
        chatGroup.push({
          userId,
          viewed: userId === this.$store.state.user.user_id ? 1 : 0
        });
      }
      const todo = JSON.parse(JSON.stringify(this.selectedTodo));
      const sentTo = [];
      todo.deleteMsg = false;
      todo.starMsg = false;
      todo.markRead = false;
      todo.newMsg = {
        id: v4(),
        sentBy: this.$store.state.user.user_id,
        chatGroup,
        comment: this.newMessage,
        time: Date.now(),
        sentTo
      };

      const users = [];
      let adminOnly = true;
      if (this.isSuperAdmin) {
        for (let i = 0; i < chatGroup.length; i++) {
          const id = chatGroup[i].userId;
          const user = this.userList.find((x) => x.value === id);
          if (user.userRole === 'installer') {
            users.push(user.label);
            adminOnly = false;
          }
        }
      }

      if (!adminOnly) {
        this.$q
          .dialog({
            title: 'Confirm',
            message: `Are you sure you want to send to ${users.join(' - ')}?`,
            cancel: true,
            persistent: true
          })
          .onOk(() => {
            this.msgDispatch(todo);
            this.newMessage = '';

            this.$q.notify({
              message: 'Message Sent',
              color: 'primary'
            });
          })
          .onCancel(() => {
            this.$q.notify({
              message: 'Message Not Sent',
              color: 'red-14'
            });
          });
      } else {
        this.msgDispatch(todo);
        this.newMessage = '';

        this.$q.notify({
          message: 'Message Sent',
          color: 'primary'
        });
      }
    },
    addToUnreadList(todo) {
      const addToUnread = this.unreadMsgs.unreadArr.filter((x) => {
        return todo.chats.some((y) => y.id === x);
      });

      const now = +new Date();
      const unreadArr = JSON.parse(localStorage.getItem('unreadChats')) || [];
      addToUnread.forEach((x) => {
        unreadArr.push({ id: x, ts: now });
      });

      localStorage.setItem('unreadChats', JSON.stringify(unreadArr));

      this.setUnreadArr();
    },
    msgDispatch(todo) {
      store.dispatch('chatTodo', { todo }).then((results) => {
        // COMMENT: following line necessary to disable green checkbox for chats.
        results.disableCheck = true;
        this.$finishResult.handleResultsAsync(results).then((response) => {
          if (response === 'retry') {
            this.msgDispatch({ todo });
          }
        });
      });
    },
    buildBackColor(color, opacity) {
      if (color) {
        return 'background-color: ' + colors.changeAlpha(color, opacity || 0.5);
      } else {
        return '';
      }
    },
    deleteMsg(index) {
      this.$q
        .dialog({
          title: 'Delete Message?',
          cancel: true,
          persistent: true
        })
        .onOk(() => {
          const todo = JSON.parse(JSON.stringify(this.selectedTodo));
          todo.addMsg = false;
          todo.starMsg = false;
          todo.markRead = false;
          todo.deleteMsg = {
            id: todo.chats[index].id
          };
          this.msgDispatch(todo);

          this.$q.notify({
            message: 'Message Deleted',
            color: 'primary'
          });
        });
    },
    fieldDecoder(fieldDef, fields) {
      const item = fields[fieldDef.id];
      if (item) {
        switch (fieldDef.type) {
          case 'checkbox':
            return item || false;
          case 'date':
            return this.dateHelper(item);
          case 'number':
          case 'text':
          case 'fields':
            return item;
          default:
            return '!!';
        }
      } else {
        switch (fieldDef.type) {
          case 'checkbox':
            return false;
          default:
            return '--';
        }
      }
    },
    fieldList(itemId) {
      const workItem = this.$store.state.workItems.find(
        (element) => element.id === itemId
      );
      return workItem ? workItem.fields : [];
    },
    isSelected(chat) {
      const chatGroup = this.chatGroup.slice();
      chatGroup.push(this.currUserId);
      return (
        chat.chatIdsStr === JSON.stringify(chatGroup.sort((a, b) => a - b))
      );
    },
    markMsgRead() {
      if (!this.selectedTodo) return;
      const todo = JSON.parse(JSON.stringify(this.selectedTodo));

      this.addToUnreadList(todo);

      const unread = countUnread(todo.chats, this.currUserId);
      if (!unread) {
        return;
      }

      todo.deleteMsg = false;
      todo.starMsg = false;
      todo.addMsg = false;
      todo.markRead = {
        chats: todo.chats,
        currentUser: this.currUserId
      };
      this.msgDispatch(todo);

      const msg = unread > 1 ? ' Messages' : ' Message';

      this.$q.notify({
        message: unread + msg + ' Marked Read',
        color: 'primary'
      });
    },
    parseMsgTime(value) {
      if (value && !isNaN(value)) {
        return new Date(value).toLocaleString('en-US', {
          timeStyle: 'short',
          dateStyle: 'short'
        });
      } else {
        return '--';
      }
    },
    parseTimeStamp(value) {
      return parseTimeStamp(value, 'short');
    },
    setChatGroupOptions() {
      this.chatGroupOptions = this.chatGroupAll.filter((x) => {
        if (this.fullUserList) {
          return x.value !== this.currUserId;
        }

        const notCurrUser = x.value !== this.currUserId;
        const notInstaller = x.userRole !== 'installer';
        const isUser = x.value === this.selectedTodo.user_id;
        return notCurrUser && (notInstaller || isUser);
      });
    },
    setChatFull() {
      this.fullUserList = false;
      this.setChatGroupOptions();
      this.newMessage = '';
      this.emptyMsgErr = false;
      const chats = this.ChatsBuilder;
      this.chatGroup = [];

      if (chats.length && !chats[0].chatIds.includes(this.currUserId)) {
        this.chatGroup = [];
        return;
      }

      // Set checkboxes to equal first chat group
      if (chats.length) {
        this.chatGroup = chats[0].chatIds.filter((x) => {
          // Make sure user has not been deleted
          const isValidUser = this.chatGroupAll.some((y) => y.value === x);
          return isValidUser ? x !== this.currUserId : false;
        });
      }
    },
    setGroupErr() {
      this.noGroupErr = !this.chatGroup.length;
    },
    setEmptyErr() {
      this.emptyMsgErr = !this.newMessage.length;
    },
    setUnreadArr() {
      const unreadArr = JSON.parse(localStorage.getItem('unreadChats')) || [];
      const twoMinAgo = +new Date() - 2 * 60 * 1000;
      const updatedArr = unreadArr.filter((x) => x.ts > twoMinAgo);
      localStorage.setItem('unreadChats', JSON.stringify(updatedArr));
      this.unreadIds = updatedArr.map((x) => x.id);
    },
    starMsg(star, chatIndex) {
      const todo = JSON.parse(JSON.stringify(this.selectedTodo));

      todo.addMsg = false;
      todo.markRead = false;
      todo.deleteMsg = false;
      todo.starMsg = {
        id: todo.chats[chatIndex].id,
        currentUser: this.currUserId,
        star
      };
      this.msgDispatch(todo);

      if (star) {
        this.$q.notify({
          message: 'Message Starred',
          icon: 'star',
          color: 'yellow',
          textColor: 'black'
        });
      } else {
        this.$q.notify({
          message: 'Star Removed',
          icon: 'star',
          color: 'primary'
        });
      }
    },
    userName(id) {
      if (id === null) {
        return '--';
      }
      const user = this.$store.state.userList.find(
        (element) => element.user_id === id
      );
      return user ? user.display_name : '- - - -';
    }
  },
  computed: {
    ChatsBuilder() {
      if (!this.selectedTodo) return [];

      const chats = [];
      const chatIdsSortedArr = [];
      for (let i = 0; i < this.selectedTodo.chats.length; i++) {
        const chat = JSON.parse(JSON.stringify(this.selectedTodo.chats[i]));

        const chatIds = chat.chatGroup
          .map((x) => x.userId)
          .sort((a, b) => a - b);

        const isPartOfChat = chatIds.includes(this.currUserId);

        if (!isPartOfChat && !this.isSuperAdmin) {
          continue;
        }

        let starred = false;
        if (isPartOfChat) {
          const userIndex = chat.chatGroup.findIndex(
            (x) => x.userId === this.currUserId
          );
          if (chat.chatGroup[userIndex].viewed === 2) {
            starred = true;
          }
        }

        const chatIdsStr = JSON.stringify(chatIds);
        const groupStr = chat.chatGroup
          .map((x) => {
            const user = this.userList.find((u) => u.value === x.userId);
            return user ? trimName(user.label) : '~~~';
          })
          .join(' - ');

        const readByAll = !chat.chatGroup.some((x) => !x.viewed);

        const chatIndex = chats.findIndex((x) => x.chatIdsStr === chatIdsStr);

        if (chatIndex < 0) {
          chatIdsSortedArr.push(chatIdsStr);
          chats.push({
            chatIds,
            chatIdsStr,
            isPartOfChat,
            groupStr,
            messages: [
              {
                id: chat.id,
                originalIndex: i,
                sentBy: chat.sentBy,
                readByAll,
                msg: chat.comment,
                time: chat.time,
                isSelf: chat.sentBy === this.currUserId,
                starred
              }
            ]
          });
        } else {
          chats[chatIndex].messages.push({
            id: chat.id,
            originalIndex: i,
            sentBy: chat.sentBy,
            readByAll,
            msg: chat.comment,
            time: chat.time,
            isSelf: chat.sentBy === this.currUserId,
            starred
          });
        }
      }

      for (let i = 0; i < chats.length; i++) {
        chats[i].lastChatTs =
          chats[i].messages[chats[i].messages.length - 1].time;
      }

      chats.sort((a, b) => b.lastChatTs - a.lastChatTs);

      return chats;
    },
    currUserId() {
      return this.$store.state.user.user_id;
    },
    isSuperAdmin() {
      return this.$store.state.user.aerworx_level === 'super-admin';
    },
    chatGroupAll() {
      const userList = JSON.parse(JSON.stringify(this.userList));
      const all = userList
        .filter((x) => x.chatAuth)
        .map((x) => {
          x.label = trimName(x.label);
          return x;
        });
      return all;
    },
    installerIds() {
      return this.chatGroupAll
        .filter((x) => x.userRole === 'installer')
        .map((x) => x.value);
    },
    unreadMsgs() {
      return JSON.parse(JSON.stringify(this.$store.state.unreadTodoMsgs));
    },
    userList() {
      const list = [];
      for (let i = 0; i < this.$store.state.userList.length; i++) {
        list.push({
          label: this.$store.state.userList[i].display_name,
          value: this.$store.state.userList[i].user_id,
          chatAuth: this.$store.state.userList[i].chatAuth,
          userRole: this.$store.state.userList[i].user_role
        });
      }
      list.sort(function (a, b) {
        return a.label.localeCompare(b.label, undefined, {
          numeric: true,
          sensitivity: 'base'
        });
      });
      return list;
    }
  },
  watch: {
    ChatsBuilder() {
      this.markMsgRead();

      setTimeout(() => {
        this.setUnreadArr();
      }, 2 * 60 * 1001);
    },
    unreadMsgs() {
      this.markMsgRead();
    }
  }
};
</script>

<style scoped>
.highlight {
  background: radial-gradient(circle, #35a2ff 0%, #014a88 100%);
}
</style>
