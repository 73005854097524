let screenshot;

function handleKeyDown(event) {
  if (event.ctrlKey && event.key === '4') {
    captureScreen();
  }
}

async function captureScreen() {
  const displayMediaOptions = {
    video: {
      displaySurface: 'window'
    },
    audio: {
      echoCancellation: true,
      noiseSuppression: true,
      sampleRate: 44100,
      suppressLocalAudioPlayback: true
    },
    preferCurrentTab: false,
    surfaceSwitching: 'include',
    selfBrowserSurface: 'include',
    systemAudio: 'exclude',
    monitorTypeSurfaces: 'include'
  };

  let mediaStream = null;
  try {
    mediaStream = await navigator.mediaDevices.getDisplayMedia(
      displayMediaOptions
    );
  } catch (err) {
    console.error(err);
  }

  const videoElement = document.createElement('video');
  videoElement.style.display = 'none';
  videoElement.autoplay = true;

  const canvasElement = document.createElement('canvas');
  canvasElement.style.display = 'none';

  let blobData = null;

  videoElement.srcObject = mediaStream;
  videoElement.onloadedmetadata = async () => {
    videoElement.play();

    // Draw the video frame to canvas
    canvasElement.width = videoElement.videoWidth;
    canvasElement.height = videoElement.videoHeight;
    const context = canvasElement.getContext('2d');
    context.drawImage(
      videoElement,
      0,
      0,
      canvasElement.width,
      canvasElement.height
    );

    canvasElement.toBlob((blob) => {
      blobData = blob;

      mediaStream.getTracks().forEach((track) => track.stop());
      videoElement.style.display = 'none';

      const file = new File([blobData], 'screenshot.png', {
        type: 'image/png'
      });

      screenshot.file = file;

      document.removeEventListener('keydown', handleKeyDown);
    }, 'image/png');
  };
}

function addScreenshotListener(scrnshot) {
  screenshot = scrnshot;
  document.addEventListener('keydown', handleKeyDown);
}

export { addScreenshotListener };
