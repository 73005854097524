<template>
  <!--Reminder dialog-->
  <q-dialog v-model="reminderDialog" persistent>
    <q-card style="width: 450px" :class="$q.screen.xs ? 'q-pa-md' : 'q-pa-lg'">
      <div class="ellipsis-2-lines text-subtitle2">
        <span>
          {{ farmName(remindNowObj.farm_id) }}
        </span>
      </div>
      <q-separator />
      <div class="q-py-xs">
        <span class="text-red-14 q-pr-xs"> Note: </span>
        <span v-if="isTodo">
          {{ remindNowObj.note }}
        </span>
        <span v-if="!isTodo">
          {{ remindNowObj.notes }}
        </span>
      </div>
      <q-separator />
      <div class="q-py-xs">
        <span class="text-red-14 q-pr-xs">Office Note: </span>
        <span v-if="!isTodo">
          {{ remindNowObj.office_notes }}
        </span>
      </div>
      <q-separator />
      <div class="row items-center q-py-sm">
        <div class="q-mr-md">
          <q-btn
            icon="event"
            color="deep-purple-14"
            class="q-mr-xs"
            size="sm"
            padding="xs sm"
          >
            <q-popup-proxy transition-show="scale" transition-hide="scale">
              <q-date
                v-model="reminderDate"
                no-unset
                mask="YYYY-MM-DD h:mm A"
                color="deep-purple-14"
              >
                <div class="row items-center justify-end q-gutter-sm">
                  <q-btn
                    label="Cancel"
                    color="deep-purple-14"
                    flat
                    v-close-popup
                    @click="adjustDateCancel"
                  />
                  <q-btn
                    label="OK"
                    color="deep-purple-14"
                    flat
                    v-close-popup
                    @click="adjustDate"
                  />
                </div>
              </q-date>
            </q-popup-proxy>
          </q-btn>
          <q-btn
            icon="access_time"
            color="deep-purple-14"
            class="q-mx-xs"
            size="sm"
            padding="xs sm"
          >
            <q-popup-proxy transition-show="scale" transition-hide="scale">
              <q-time
                v-model="reminderDate"
                mask="YYYY-MM-DD h:mm A"
                color="deep-purple-14"
              >
                <div class="row items-center justify-end q-gutter-sm">
                  <q-btn
                    label="Cancel"
                    color="deep-purple-14"
                    flat
                    v-close-popup
                    @click="adjustDateCancel"
                  />
                  <q-btn
                    label="OK"
                    color="deep-purple-14"
                    flat
                    v-close-popup
                    @click="adjustDate"
                  />
                </div>
              </q-time>
            </q-popup-proxy>
          </q-btn>
        </div>
        <q-space />
        <span class="q-mr-md">
          {{ reminderDateF(reminderDate) }}
        </span>
        <span>
          {{ reminderTime(reminderDate) }}
        </span>
      </div>
      <q-separator />

      <div class="text-center text-green-14 q-mt-sm">Remind Again In:</div>
      <div class="row q-mt-sm q-mb-md">
        <div class="q-pr-xs" style="width: 20%">
          <q-btn
            outline
            label="1 Hr"
            color="green-14"
            size="sm"
            padding="xs sm"
            class="full-width q-mx-xs"
            @click="reminderAddTime(3600000, 'one hour')"
          />
        </div>
        <div class="q-pr-xs" style="width: 20%">
          <q-btn
            outline
            label="1 Day"
            color="green-14"
            size="sm"
            padding="xs sm"
            class="full-width q-mx-xs"
            @click="reminderAddTime(86400000, 'one day')"
          />
        </div>
        <div class="q-pr-xs" style="width: 20%">
          <q-btn
            outline
            label="1 Wk"
            color="green-14"
            size="sm"
            padding="xs sm"
            class="full-width q-mx-xs"
            @click="reminderAddTime(604800000, 'one week')"
          />
        </div>
        <div class="q-pr-xs" style="width: 20%">
          <q-btn
            outline
            label="1 Mo"
            color="green-14"
            size="sm"
            padding="xs sm"
            class="full-width q-mx-xs"
            @click="reminderAddTime(null, 'one month')"
          />
        </div>
        <div class="q-pr-xs" style="width: 20%">
          <q-btn
            outline
            label="1 Yr"
            color="green-14"
            size="sm"
            padding="xs sm"
            class="full-width q-mx-xs"
            @click="reminderAddTime(null, 'one year')"
          />
        </div>
      </div>
      <q-separator />

      <div class="row q-mt-md">
        <div v-if="!globalSnooze || snoozing" class="col q-pr-xs">
          <q-btn
            label="Snooze All"
            color="green-14"
            size="sm"
            padding="xs xs"
            class="full-width q-mx-xs"
            :loading="snoozing"
            @click="confirmAction('snoozeAll')"
          />
        </div>
        <div class="col q-px-xs">
          <q-btn
            :label="pinLabel"
            color="deep-purple-14"
            size="sm"
            padding="xs sm"
            class="full-width q-mx-xs"
            @click="confirmAction('pinReminder')"
          />
        </div>
        <div class="col q-pl-xs">
          <q-btn
            label="Dismiss"
            color="red-14"
            size="sm"
            padding="xs sm"
            class="full-width q-mx-xs"
            @click="confirmAction('deleteReminder')"
          />
        </div>
      </div>
    </q-card>
  </q-dialog>
</template>

<script>
import store from '@/store';
import { date } from 'quasar';
import { decoder, cloneObj } from '@/lib/helpers';

export default {
  name: 'ReminderDialog',
  props: {
    remindNowObj: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      reminderDialog: true,
      reminderDate: '',
      reminderDateOnMount: '',
      snoozing: false
    };
  },
  mounted() {
    const remindNow = cloneObj(this.remindNowObj);
    const reminder = remindNow.reminders.find(
      (x) => x.reminderRecipient === this.currUserId
    );

    this.reminderDate = date.formatDate(
      reminder.reminderDate,
      'YYYY-MM-DD h:mm A'
    );
    this.reminderDateOnMount = reminder.reminderDate;
  },
  methods: {
    adjustDate() {
      // reminderParent could be a todo or a workLog
      const reminderParent = cloneObj(this.remindNowObj);
      const reminder = reminderParent.reminders.find(
        (x) => x.reminderRecipient === this.currUserId
      );

      const now = +new Date();
      const rDateTS = +date.extractDate(this.reminderDate, 'YYYY-MM-DD h:mm A');

      let message = 'Remind again at updated date/time?';

      // Reminder cannot be set more than two years into future
      if (!this.reminderFutureCheck(rDateTS)) {
        this.reminderFutureErr();
        return;
      }

      if (rDateTS < now) {
        reminder.pinned = true;
        message = 'Reminder date/time has already passed. Pin reminder?';
      } else {
        reminder.pinned = false;
      }

      this.$q
        .dialog({
          ok: {
            flat: true,
            color: 'green-14',
            label: 'Ok'
          },
          cancel: {
            flat: true,
            color: 'green-14'
          },
          title: 'Confirm',
          message,
          persistent: true
        })
        .onOk(() => {
          reminder.reminderDate = +date.extractDate(
            this.reminderDate,
            'YYYY-MM-DD h:mm A'
          );

          if (reminderParent.hasOwnProperty('billed')) {
            this.dispatchWorkLog(reminderParent);
          } else {
            this.dispatchTodo(reminderParent);
          }

          this.$emit('reminderUpdated');

          this.$q.notify({
            message: 'Reminder Updated',
            icon: 'alarm',
            color: 'deep-purple-10'
          });
        })
        .onCancel(() => {
          this.adjustDateCancel();
        });
    },
    adjustDateCancel() {
      this.reminderDate = date.formatDate(
        this.reminderDateOnMount,
        'YYYY-MM-DD h:mm A'
      );
    },
    confirmAction(action) {
      const message =
        action === 'snoozeAll'
          ? 'Snooze All Reminders?'
          : action === 'pinReminder'
          ? 'Pin Reminder To Screen?'
          : 'Permanently Dismiss Reminder?';

      const okActionLabel =
        action === 'snoozeAll'
          ? 'Snooze All'
          : action === 'pinReminder'
          ? 'Pin Reminder'
          : 'Permanently Dismiss';

      this.$q
        .dialog({
          ok: {
            color: action === 'deleteReminder' ? 'red-14' : 'deep-purple-14',
            label: okActionLabel
          },
          cancel: {
            flat: true,
            color: 'deep-purple-13'
          },
          title: 'Confirm',
          message,
          persistent: true,
          focus: 'none'
        })
        .onOk(() => {
          if (action === 'deleteReminder') {
            this.deleteReminder();
          }

          if (action === 'pinReminder') {
            this.pinReminder();
          }

          if (action === 'snoozeAll') {
            this.snoozeAll();
          }
        });
    },
    deleteReminder() {
      // reminderParent could be a todo or a workLog
      const reminderParent = cloneObj(this.remindNowObj);
      const index = reminderParent.reminders.findIndex(
        (x) => x.reminderRecipient === this.currUserId
      );
      reminderParent.reminders.splice(index, 1);

      if (reminderParent.hasOwnProperty('billed')) {
        this.dispatchWorkLog(reminderParent);
      } else {
        this.dispatchTodo(reminderParent);
      }

      this.$emit('reminderUpdated');

      this.$q.notify({
        message: 'Reminder Dismissed',
        icon: 'alarm',
        color: 'red-14'
      });
    },
    dispatchTodo(todo) {
      store.dispatch('updateTodo', { todo }).then((results) => {
        results.disableCheck = true;
        this.$finishResult.handleResultsAsync(results).then((response) => {
          if (response === 'retry') {
            this.dispatchTodo(todo);
          }
        });
      });
    },
    dispatchWorkLog(workLog) {
      store
        .dispatch('updateWorkLog', {
          farmId: workLog.farm_id,
          log: workLog
        })
        .then((results) => {
          results.disableCheck = true;
          this.$finishResult.handleResultsAsync(results).then((response) => {
            if (response === 'retry') {
              this.dispatchWorkLog(workLog);
            }
          });
        });
    },
    farmName(id) {
      if (id === null) {
        return '';
      }
      const farm = this.$store.state.farmList.find(
        (element) => element.id === id
      );
      return farm ? decoder(farm.farm_name) : '';
    },
    reminderAddTime(time, period) {
      // reminderParent could be a todo or a workLog
      const reminderParent = cloneObj(this.remindNowObj);
      const reminder = reminderParent.reminders.find(
        (x) => x.reminderRecipient === this.currUserId
      );

      if (
        period === 'one hour' ||
        period === 'one day' ||
        period === 'one week'
      ) {
        let reminderTS = +new Date();
        reminderTS += time;
        reminder.reminderDate = reminderTS;
      }

      if (period === 'one month') {
        const plusMo = date.addToDate(new Date(), { months: 1 });
        reminder.reminderDate = +new Date(plusMo);
      }

      if (period === 'one year') {
        const plusYr = date.addToDate(new Date(), { years: 1 });
        reminder.reminderDate = +new Date(plusYr);
      }

      // Reminder cannot be set more than two years into future
      if (!this.reminderFutureCheck(reminder.reminderDate)) {
        this.reminderFutureErr();
        return;
      }

      reminder.pinned = false;

      this.$q
        .dialog({
          ok: {
            flat: true,
            color: 'green-14',
            label: 'Ok'
          },
          cancel: {
            flat: true,
            color: 'green-14'
          },
          title: 'Confirm',
          message: `Remind in ${period}?`,
          persistent: true
        })
        .onOk(() => {
          if (reminderParent.hasOwnProperty('billed')) {
            this.dispatchWorkLog(reminderParent);
          } else {
            this.dispatchTodo(reminderParent);
          }

          this.$emit('reminderUpdated');

          this.$q.notify({
            message: 'Reminder Updated',
            icon: 'alarm',
            color: 'deep-purple-10'
          });
        });
    },
    pinReminder() {
      // reminderParent could be a todo or a workLog
      const reminderParent = cloneObj(this.remindNowObj);
      const reminder = reminderParent.reminders.find(
        (x) => x.reminderRecipient === this.currUserId
      );

      // If already pinned
      if (reminder.pinned) {
        this.$emit('reminderUpdated');
        return;
      }

      reminder.pinned = true;

      if (reminderParent.hasOwnProperty('billed')) {
        this.dispatchWorkLog(reminderParent);
      } else {
        this.dispatchTodo(reminderParent);
      }

      this.$emit('reminderUpdated');

      this.$q.notify({
        message: 'Reminder Pinned',
        icon: 'alarm',
        color: 'deep-purple-10'
      });
    },
    reminderDateF(TS) {
      return date.formatDate(TS, 'ddd, MMM D YYYY');
    },
    reminderFutureCheck(TS) {
      const twoYrFuture = +date.addToDate(this.remindNowObj.date * 1000, {
        years: 2
      });
      return TS < twoYrFuture;
    },
    reminderFutureErr() {
      this.$q.dialog({
        title: 'Alert',
        ok: {
          flat: true,
          color: 'deep-purple-14'
        },
        message: 'Cannot set Reminder Date more than two years after ToDo date!'
      });
    },
    reminderTime(TS) {
      return date.formatDate(TS, 'h:mm A');
    },
    snoozeAll() {
      this.snoozing = true;

      setTimeout(() => {
        this.snoozing = false;
      }, 2000);

      localStorage.setItem('snoozeAllTS', +new Date());

      store.dispatch('snoozeAllReminders', true);
    }
  },
  computed: {
    currUserId() {
      return this.$store.state.user.user_id;
    },
    globalSnooze() {
      return this.$store.state.snoozeAllReminders;
    },
    pinLabel() {
      const reminderParent = cloneObj(this.remindNowObj);
      const reminder = reminderParent.reminders.find(
        (x) => x.reminderRecipient === this.currUserId
      );

      return reminder.pinned ? 'Keep Pin' : 'Pin';
    },
    isTodo() {
      return !this.remindNowObj.hasOwnProperty('billed');
    }
  }
};
</script>

<style scoped></style>
