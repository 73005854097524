<template>
  <div class="shadow-1">
    <q-tabs v-model="tab" dense align="right">
      <q-space />
      <q-btn
        v-if="farmBackBtn"
        padding="1px sm"
        icon="arrow_back"
        color="blue-14"
        class="q-mx-md"
        @click="goBackToFarm"
      />
      <q-btn
        v-if="emailBackBtn"
        padding="2px 12px"
        icon="arrow_back"
        color="blue-14"
        class="q-mx-md"
        @click="goBackToEmail"
      />
      <q-btn
        v-if="workOrderBackBtn"
        padding="2px 12px"
        icon="arrow_back"
        color="blue-14"
        class="q-mx-md"
        @click="goBackToWorkOrders"
      />
      <div class="text-subtitle1 q-py-sm">
        {{ decoder(farmName) }}
      </div>
      <q-space />
      <q-route-tab
        v-if="$q.screen.gt.md"
        name="map"
        :to="'/farm/' + farmId"
        label="Map"
        style="max-width: 135px"
      />
      <q-route-tab
        name="workLogs"
        :to="'/farm/' + farmId + '/work'"
        label="Work Logs"
        style="max-width: 135px"
      />
      <q-route-tab
        v-if="$q.screen.gt.md"
        name="emails"
        :to="'/farm/' + farmId + '/emails'"
        label="Emails"
        style="max-width: 135px"
      />
      <q-route-tab
        v-if="$q.screen.gt.md"
        name="todos"
        :to="'/farm/' + farmId + '/todos'"
        label="To-Do"
        style="max-width: 135px"
      />
      <q-route-tab
        v-if="$q.screen.gt.md"
        name="ponds"
        :to="'/farm/' + farmId + '/ponds'"
        label="Ponds"
        style="max-width: 135px"
      />
      <q-route-tab
        v-if="$q.screen.gt.md"
        name="computers"
        :to="'/farm/' + farmId + '/computers'"
        label="Computers"
        style="max-width: 135px"
      />
      <q-route-tab
        v-if="$q.screen.gt.md"
        name="repeaters"
        :to="'/farm/' + farmId + '/repeaters'"
        label="Repeaters"
        style="max-width: 135px"
      />
      <q-route-tab
        v-if="$q.screen.gt.md"
        name="notes"
        :to="'/farm/' + farmId + '/notes'"
        label="Notes"
        style="max-width: 135px"
      />
      <q-route-tab
        v-if="$q.screen.gt.md"
        name="attachments"
        :to="'/farm/' + farmId + '/attachments'"
        label="Attachments"
        style="max-width: 135px"
      />
      <q-btn-dropdown v-if="$q.screen.lt.lg" auto-close stretch flat>
        <template v-slot:label>
          <div>
            <div class="row items-center no-wrap">More...</div>
          </div>
        </template>

        <q-list>
          <q-item clickable @click="goToTab('')">
            <q-item-section>Map</q-item-section>
          </q-item>

          <q-item clickable @click="goToTab('emails')">
            <q-item-section>Emails</q-item-section>
          </q-item>

          <q-item clickable @click="goToTab('todos')">
            <q-item-section>To-Do</q-item-section>
          </q-item>

          <q-item clickable @click="goToTab('ponds')">
            <q-item-section>Ponds</q-item-section>
          </q-item>

          <q-item clickable @click="goToTab('computers')">
            <q-item-section>Computers</q-item-section>
          </q-item>

          <q-item clickable @click="goToTab('repeaters')">
            <q-item-section>Repeaters</q-item-section>
          </q-item>

          <q-item clickable @click="goToTab('notes')">
            <q-item-section>Notes</q-item-section>
          </q-item>
          <q-item clickable @click="goToTab('attachments')">
            <q-item-section>Attachments</q-item-section>
          </q-item>
        </q-list>
      </q-btn-dropdown>
    </q-tabs>
  </div>
</template>

<script>
import { decoder } from '@/lib/helpers';

export default {
  name: 'farm-tabs',
  props: {
    farmId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      emailBackBtn: false,
      farmBackBtn: false,
      workOrderBackBtn: false,
      tab: 'worklogs'
    };
  },
  mounted() {
    this.checkEmailBackBtn();
    this.checkFarmBackBtn();
    this.checkWorkOrderBackBtn();
  },
  methods: {
    checkEmailBackBtn() {
      const scrollObj = this.$store.state.emailScrollTo;
      if (scrollObj.goStatus === 'active') {
        this.emailBackBtn = true;
      }
      scrollObj.goStatus = 'inactive';
      this.$store.dispatch('setEmailScrollTo', scrollObj);
    },
    checkFarmBackBtn() {
      const scrollObj = this.$store.state.farmScrollTo;
      if (scrollObj.goStatus === 'active') {
        this.farmBackBtn = true;
      }
      scrollObj.goStatus = 'inactive';
      this.$store.dispatch('setFarmScrollTo', scrollObj);
    },
    checkWorkOrderBackBtn() {
      const scrollObj = this.$store.state.workOrderScrollTo;
      if (scrollObj.goStatus === 'active') {
        this.workOrderBackBtn = true;
      }
      scrollObj.goStatus = 'inactive';
      this.$store.dispatch('setWorkOrderScrollTo', scrollObj);
    },
    goBackToEmail() {
      const scrollObj = this.$store.state.emailScrollTo;
      scrollObj.goStatus = 'go';
      this.$store.dispatch('setEmailScrollTo', scrollObj);
      this.$router.push('/emails');
    },
    goBackToFarm() {
      const scrollObj = this.$store.state.farmScrollTo;
      scrollObj.goStatus = 'go';
      this.$store.dispatch('setFarmScrollTo', scrollObj);
      this.$router.push('/');
    },
    goBackToWorkOrders() {
      const scrollObj = this.$store.state.workOrderScrollTo;
      scrollObj.goStatus = 'go';
      this.$store.dispatch('setWorkOrderScrollTo', scrollObj);
      this.$router.push('/work-orders/');
    },
    goToTab(tab) {
      this.$router.push(`/farm/${this.farmId}/${tab}`);
    },
    decoder(val) {
      return decoder(val);
    }
  },
  computed: {
    farmName() {
      const farm = this.$store.state.farmList.find(
        (e) => e.id === parseInt(this.farmId)
      );
      return farm ? farm.farm_name : '';
    }
  }
};
</script>
