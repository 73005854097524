import { render, staticRenderFns } from "./farm-emails.vue?vue&type=template&id=6c99c701&scoped=true"
import script from "./farm-emails.vue?vue&type=script&lang=js"
export * from "./farm-emails.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c99c701",
  null
  
)

export default component.exports
import QCard from 'quasar/src/components/card/QCard.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';
import QMenu from 'quasar/src/components/menu/QMenu.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QCard,QBtn,QSeparator,QIcon,QBadge,QMenu,QList,QItem,QSpace,QDialog});qInstall(component, 'directives', {ClosePopup});
