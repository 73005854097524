<template>
  <div style="padding-top: 2px; padding-left: 2px; padding-right: 2px">
    <q-table
      title="Repeaters"
      :data="Repeaters"
      :columns="columns"
      :pagination.sync="pagination"
      row-key="id"
    >
      <template v-slot:body-cell-Gps="props">
        <q-td :props="props">
          <q-icon
            v-if="validateGps(props.row.gps)"
            class="check-color"
            name="done"
            size="sm"
          ></q-icon>
        </q-td>
      </template>
      <template v-slot:body-cell-Config="props">
        <q-td :props="props">
          <template
            v-if="
              props.row.config_name !== '' && props.row.config_name !== null
            "
          >
            <q-btn flat>
              <q-icon
                @click="downloadConfig(props.row)"
                color="secondary"
                name="file_copy"
                size="sm"
              />
            </q-btn>
          </template>
        </q-td>
      </template>
      <template v-slot:body-cell-Edit="props">
        <q-td :props="props">
          <q-btn
            class="btn-table-primary"
            outline
            icon="edit"
            size="md"
            padding="xs"
            @click="editRepeater(props.row)"
          >
          </q-btn>
        </q-td>
      </template>
      <template v-slot:body-cell-Delete="props">
        <q-td :props="props">
          <q-btn
            class="btn-table-negative"
            outline
            icon="delete"
            size="md"
            padding="xs"
            @click="deleteRepeater(props.row)"
          >
          </q-btn>
        </q-td>
      </template>
    </q-table>
    <q-page-sticky position="bottom-right" :offset="[28, 28]">
      <q-btn fab icon="add" color="accent" @click="addRepeater" />
    </q-page-sticky>
    <!-- edit modal -->
    <q-dialog v-model="showEditModal" persistent class="">
      <q-card square bordered class="q-pa-lg card-size">
        <q-card-section class="">
          <q-form @submit="onSubmit" class="q-gutter-sm">
            <q-toolbar class="shadow-2">
              <q-toolbar-title class="row justify-center items-center">
                <q-icon name="repeater" size="sm" class="q-mr-sm" />
                {{ ModalHeader }}
              </q-toolbar-title>
            </q-toolbar>
            <q-input
              filled
              v-model="editCopy.name"
              label="Name"
              maxlength="32"
              outlined
              square
              lazy-rules
              :rules="[(val) => (val && val.length > 0) || 'Required']"
            />
            <q-input
              filled
              v-model="editCopy.radio_channel"
              label="Hopping Channel"
              type="number"
              min="0"
              max="9"
              outlined
              square
              lazy-rules
              :rules="[(val) => (val && val !== '') || 'Required']"
            />
            <q-input
              filled
              v-model="editCopy.vid"
              label="VID"
              outlined
              square
            />
            <q-input
              filled
              v-model="editCopy.gps"
              label="Location"
              maxlength="64"
              outlined
              square
            >
              <q-btn v-if="gpsAvailable" color="primary" @click="fillGps"
                >Use position</q-btn
              >
              <q-btn v-if="!gpsAvailable" disabled color="negative"
                >Not available</q-btn
              >
            </q-input>
            <q-file outlined accept=".pro, .xml" v-model="fileAttach">
              <template v-slot:prepend>
                <q-icon name="attach_file" />
              </template>
            </q-file>
            <q-separator />
            <div align="right">
              <div class="row full-width">
                <div class="col spaced-l">
                  <q-btn
                    outline
                    class="full-width"
                    label="Cancel"
                    color="secondary"
                    @click="cancelEdit"
                  />
                </div>
                <div class="col spaced-r">
                  <q-btn
                    outline
                    class="full-width"
                    label="Submit"
                    type="submit"
                    color="primary"
                  />
                </div>
              </div>
            </div>
          </q-form>
        </q-card-section>
      </q-card>
    </q-dialog>
    <!-- end edit modal -->
  </div>
</template>

<script>
import { decoder, SplitGps } from '@/lib/helpers';
import { copyToClipboard, exportFile } from 'quasar';
import { encode, decode } from 'base64-arraybuffer';
import store from '@/store';

export default {
  name: 'Repeaters',
  props: {
    farmId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      gpsAvailable: !!navigator.geolocation,
      showEditModal: false,
      fileAttach: null,
      editCopy: {
        name: '',
        awId: ''
      },
      pagination: {
        descending: false,
        page: 1,
        rowsPerPage: 0
      },
      columns: [
        {
          sortable: true,
          name: 'Name',
          label: 'Name',
          align: 'left',
          classes: 'table-highlight-column',
          field: (row) => decoder(row.name)
        },
        {
          sortable: true,
          name: 'RF Channel',
          label: 'RF Channel',
          align: 'center',
          field: (row) => row.radio_channel
        },
        {
          sortable: true,
          name: 'VID',
          label: 'VID',
          align: 'center',
          field: (row) => row.vid
        },
        {
          sortable: true,
          name: 'Gps',
          label: 'Gps',
          align: 'center'
        },
        {
          name: 'Config',
          label: 'Config',
          align: 'center'
        },
        {
          name: 'Edit',
          label: '',
          align: 'center',
          classes: 'max-width'
        },
        {
          name: 'Delete',
          label: '',
          align: 'center',
          classes: 'max-width'
        }
      ]
    };
  },
  methods: {
    downloadConfig(repeater) {
      exportFile(repeater.config_name, new Blob([decode(repeater.config)]));
    },
    ToClipboard(text) {
      copyToClipboard(text)
        .then(() => {
          this.$q.notify({
            message: 'License key copied to clipboard',
            color: 'primary'
          });
        })
        .catch(() => {
          this.$q.notify({
            message: 'License key copy error',
            color: 'negative'
          });
        });
    },
    validateGps(value) {
      return SplitGps(value) !== null;
    },
    async onSubmit() {
      let dispatch;
      if (this.editCopy.id === 'new') {
        dispatch = 'createRepeater';
      } else {
        dispatch = 'updateRepeater';
      }
      if (this.fileAttach !== null) {
        this.editCopy.config = encode(await this.fileAttach.arrayBuffer());
        this.editCopy.config_name = this.fileAttach.name;
      }
      store
        .dispatch(dispatch, {
          farmId: this.farmId,
          repeater: this.editCopy
        })
        .then((results) => {
          this.$finishResult.handleResultsAsync(results).then((response) => {
            if (response === 'retry') {
              this.onSubmit();
            } else {
              this.showEditModal = false;
            }
          });
        });
    },
    fillGps() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            this.editCopy.gps =
              position.coords.latitude + ',' + position.coords.longitude;
          },
          () => {
            this.gpsAvailable = false;
          }
        );
      } else {
        this.gpsAvailable = false;
      }
    },
    editRepeater(repeater) {
      this.editCopy = JSON.parse(JSON.stringify(repeater));
      if (repeater.config !== null) {
        this.fileAttach = new File(
          [new Uint8Array(repeater.config.data)],
          repeater.config_name
        );
      }
      this.showEditModal = true;
    },
    deleteRepeater(repeater) {
      this.$deleteResult
        .getResultsAsync('repeater', repeater.name, 'Delete repeater?')
        .then((result) => {
          if (result === 'delete') {
            this.dispatchDelete(repeater);
          }
        });
    },
    dispatchDelete(repeater) {
      store
        .dispatch('deleteRepeater', {
          farmId: this.farmId,
          repeater
        })
        .then((results) => {
          this.$finishResult.handleResultsAsync(results).then((response) => {
            if (response === 'retry') {
              this.dispatchDelete(repeater);
            }
          });
        });
    },
    addRepeater(repeater) {
      this.editCopy = {
        farm_id: this.farmId,
        gps: '',
        id: 'new',
        vid: null,
        name: '',
        config: '',
        config_name: ''
      };
      this.fileAttach = null;
      this.showEditModal = true;
    },
    cancelEdit() {
      this.showEditModal = false;
    }
  },
  computed: {
    Repeaters() {
      return this.$store.state.farm.repeaters;
    },
    ModalHeader() {
      return this.editCopy.id === 'new' ? 'New Repeater' : 'Edit Repeater';
    }
  }
};
</script>

<style scoped>
.spaced-l {
  margin-right: 2px;
}
.spaced-r {
  margin-left: 2px;
}

@media only screen and (min-width: 750px) {
  table {
    width: 80%;
  }

  .card-size {
    min-width: 500px;
  }
}

.max-width {
  max-width: 100px;
}
</style>
