const BoardTypes = [
  'Main White',
  'Main Green',
  'Main Blue',
  'Screen Green',
  'Screen White',
  'Screen Blue',
  'CPU Blue',
  'HB20',
  'RM420',
  'PC',
  'New Label'
];

module.exports = {
  BoardTypes
};
