<template>
  <div>
    <q-layout view="lHh lpr lFf" container :style="`height: ${usableHeight}px`">
      <q-header :class="$q.dark.isActive ? 'bg-dark' : 'bg-white'">
        <div v-if="isSuperAdmin" class="row justify-center q-py-xs">
          <div class="q-pa-xs">
            <q-btn
              :outline="filter !== 'todo'"
              no-caps
              color="primary"
              :text-color="filter === 'todo' ? 'btn-txt' : ''"
              size="md"
              :style="$q.screen.xs ? 'width: 85px' : 'width: 112px'"
              padding="xs none"
              :loading="filterByTodosLoading"
              @click="setFilterByTodos"
            >
              <div
                :style="$q.screen.xs ? 'font-size: 12px' : 'font-size: 14px'"
              >
                ToDos
              </div>
            </q-btn>
          </div>
          <div class="q-pa-xs">
            <q-btn
              :outline="filter !== 'complete'"
              color="primary"
              :text-color="filter === 'complete' ? 'btn-txt' : ''"
              size="md"
              :style="$q.screen.xs ? 'width: 85px' : 'width: 112px'"
              padding="xs none"
              :loading="filterByCompleteLoading"
              @click="setFilterByComplete"
            >
              <div
                :style="$q.screen.xs ? 'font-size: 12px' : 'font-size: 14px'"
              >
                Completed
              </div>
            </q-btn>
          </div>
          <div class="q-pa-xs">
            <q-btn
              :outline="filter !== 'reviewed'"
              color="primary"
              :text-color="filter === 'reviewed' ? 'btn-txt' : ''"
              size="md"
              :style="$q.screen.xs ? 'width: 85px' : 'width: 112px'"
              padding="xs none"
              :loading="filterByReviewedLoading"
              @click="setFilterByReviewed"
            >
              <div
                :style="$q.screen.xs ? 'font-size: 12px' : 'font-size: 14px'"
              >
                Reviewed
              </div>
            </q-btn>
          </div>
          <div class="q-pa-xs">
            <q-btn
              outline
              icon="sort"
              color="primary"
              size="md"
              style="width: 40px"
              padding="xs none"
            >
              <q-menu auto-close anchor="bottom right" self="top right">
                <q-card bordered class="q-pa-md">
                  <div class="q-pb-md">
                    <q-btn
                      :outline="sort !== 'date'"
                      label="Date"
                      color="primary"
                      :icon="
                        sortDateReverse ? 'arrow_upward' : 'arrow_downward'
                      "
                      :text-color="sort === 'date' ? 'btn-txt' : ''"
                      size="md"
                      style="width: 180px"
                      @click="setSortByDate"
                    />
                  </div>
                  <div class="q-pb-md">
                    <q-btn
                      :outline="sort !== 'todo'"
                      :icon="
                        sortTodoReverse ? 'arrow_upward' : 'arrow_downward'
                      "
                      label="ToDos"
                      no-caps
                      color="primary"
                      :text-color="sort === 'todo' ? 'btn-txt' : ''"
                      size="md"
                      style="width: 180px"
                      @click="setSortByTodos"
                    />
                  </div>
                  <div class="q-pb-md">
                    <q-btn
                      :outline="sort !== 'farm'"
                      :icon="
                        sortFarmReverse ? 'arrow_upward' : 'arrow_downward'
                      "
                      label="Farm"
                      color="primary"
                      :text-color="sort === 'farm' ? 'btn-txt' : ''"
                      size="md"
                      style="width: 180px"
                      @click="setSortByFarm"
                    />
                  </div>
                  <div>
                    <q-btn
                      :outline="sort !== 'assigned'"
                      :icon="
                        sortAssignedReverse ? 'arrow_upward' : 'arrow_downward'
                      "
                      label="Assigned"
                      color="primary"
                      :text-color="sort === 'assigned' ? 'btn-txt' : ''"
                      size="md"
                      style="width: 180px"
                      @click="setSortByAssigned"
                    />
                  </div>
                </q-card>
              </q-menu>
            </q-btn>
          </div>
        </div>

        <q-separator class="gt-md" />

        <div
          class="gt-md row items-center full-width q-py-none"
          :class="$q.dark.isActive ? 'text-white' : 'text-black'"
        >
          <div class="col row">
            <div
              class="col-auto text-center cursor-pointer q-pl-xs q-py-xs"
              :class="sort === 'date' && 'bg-accent text-white'"
              style="width: 62px"
              @click="setSortByDate"
            >
              Date
            </div>
            <div
              class="col-auto text-center cursor-pointer q-py-xs"
              :class="sort === 'todo' && 'bg-accent text-white'"
              style="width: 36px"
              @click="setSortByTodos"
            >
              ToDo
            </div>
            <div
              class="col-auto text-center cursor-pointer q-py-xs"
              :class="sort === 'farm' && 'bg-accent text-white'"
              style="width: 128px"
              @click="setSortByFarm"
            >
              Farm
            </div>
            <div class="col-auto text-center q-py-xs" style="width: 148px">
              Category - Item
            </div>
            <div class="col-auto text-center q-py-xs" style="width: 88px">
              Pond
            </div>
            <div class="col-auto q-py-xs" style="width: 34px">Time</div>
            <div class="col-auto q-py-xs" style="width: 96px"></div>
            <div class="col text-center q-py-xs">Office Notes</div>
            <div class="col text-center q-py-xs">Field Notes</div>
            <div class="col-auto text-center q-py-xs" style="width: 96px">
              Billing
            </div>
            <div
              class="col-auto text-center cursor-pointer q-py-xs"
              :class="sort === 'assigned' && 'bg-accent text-white'"
              style="width: 75px"
              @click="setSortByAssigned"
            >
              Assigned
            </div>
            <div class="col-auto" style="width: 44px"></div>
          </div>
        </div>
        <q-separator />
      </q-header>

      <q-resize-observer @resize="onResize" />

      <q-page-container>
        <q-page>
          <q-card unelevated sauare style="min-height: inherit">
            <div v-for="workLog in WorkLogsDisplayed" :key="workLog.id">
              <WorkLogRow
                :workLog="workLog"
                :sort="sort"
                :scrollToId="scrollToId"
                :farmNames="farmNames"
                :userNames="userNames"
              />

              <q-separator v-if="$q.screen.gt.md" class="full-width" />
            </div>

            <div
              v-if="
                this.WorkLogsDisplayed.length > 0 &&
                this.WorkLogs.length > this.WorkLogsDisplayed.length
              "
              class="text-center q-py-xl q-pb-md"
            >
              <q-btn
                rounded
                outline
                label="more"
                icon="expand_more"
                color="primary"
                padding="xs"
                style="width: 200px"
                @click="displayMore"
              />
            </div>
          </q-card>
        </q-page>
      </q-page-container>
    </q-layout>
  </div>
</template>

<script>
import WorkLogRow from '@/components/workLogs/WorkLogRow';

import {
  decoder,
  chatInfo,
  trimName,
  cloneObj,
  getReminderStatus
} from '@/lib/helpers';
import { parseTimeStamp } from '@/lib/date-utils';
import { colors, date } from 'quasar';
import store from '@/store';

export default {
  name: 'WorkOrders',
  components: {
    WorkLogRow
  },
  data() {
    return {
      displayNumWorkLogs: 100,
      filter: 'todo',
      filterByTodosLoading: false,
      filterByCompleteLoading: false,
      filterByReviewedLoading: false,
      scrollToId: 0,
      scrollToIndex: 0,
      selectedWorkLog: {},
      sort: this.isSuperAdmin ? 'date' : 'assigned',
      sortAssignedReverse: false,
      sortDateReverse: false,
      sortFarmReverse: false,
      sortTodoReverse: false,
      usableHeight: 1000
    };
  },
  async mounted() {
    this.scrollTo();
    this.sort = this.isSuperAdmin ? 'date' : 'assigned';
  },
  methods: {
    displayMore() {
      this.displayNumWorkLogs += 100;
    },
    getBackColor(color, opacity) {
      if (color) {
        return 'background-color: ' + colors.changeAlpha(color, opacity || 0.5);
      } else {
        return '';
      }
    },
    onResize() {
      const height =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight;

      this.usableHeight = height - 51;
    },
    scrollTo() {
      const scrollObj = this.$store.state.workOrderScrollTo;
      if (scrollObj.goStatus === 'go') {
        this.scrollToId = scrollObj.workLogId;
        this.sort = scrollObj.sort;

        this.$nextTick(() => {
          const el = document.getElementById(scrollObj.workLogId);
          if (!el) {
            if (this.scrollToIndex > 10) {
              return;
            }
            this.scrollToIndex++;

            setTimeout(() => {
              this.scrollTo();
            }, 200);
            return;
          }

          el.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          });

          scrollObj.workOrderId = '';
          scrollObj.goStatus = 'inactive';
          this.$store.dispatch('setWorkOrderScrollTo', scrollObj);
          setTimeout(() => {
            this.scrollToId = 0;
          }, 2000);
        });
      }
    },
    async setFilterByComplete() {
      this.filterByCompleteLoading = true;
      // allow loading to show
      await new Promise((resolve) => setTimeout(resolve, 100));

      this.displayNumWorkLogs = 100;
      this.filter = 'complete';
      this.sort = 'date';

      await new Promise((resolve) => setTimeout(resolve, 300));
      this.filterByCompleteLoading = false;
    },
    async setFilterByReviewed() {
      this.filterByReviewedLoading = true;
      // allow loading to show
      await new Promise((resolve) => setTimeout(resolve, 100));

      this.displayNumWorkLogs = 100;
      this.filter = 'reviewed';
      this.sort = 'date';

      await new Promise((resolve) => setTimeout(resolve, 300));
      this.filterByReviewedLoading = false;
    },
    async setFilterByTodos() {
      this.filterByTodosLoading = true;
      // allow loading to show
      await new Promise((resolve) => setTimeout(resolve, 100));

      this.displayNumWorkLogs = 100;
      this.filter = 'todo';
      this.sort = 'date';

      await new Promise((resolve) => setTimeout(resolve, 300));
      this.filterByTodosLoading = false;
    },
    setSortByAssigned() {
      this.sortAssignedReverse =
        this.sort === 'assigned' ? !this.sortAssignedReverse : false;
      this.sort = 'assigned';
    },
    setSortByDate() {
      this.sortDateReverse =
        this.sort === 'date' ? !this.sortDateReverse : false;
      this.sort = 'date';
    },
    setSortByFarm() {
      this.sortFarmReverse =
        this.sort === 'farm' ? !this.sortFarmReverse : false;
      this.sort = 'farm';
    },
    setSortByTodos() {
      this.sortTodoReverse =
        this.sort === 'todo' ? !this.sortTodoReverse : false;
      this.sort = 'todo';
    },
    sortAndFilterWorkLogs(workLogs) {
      const filtered =
        this.filter === 'todo'
          ? workLogs.filter((x) => x.todo_flag > 5)
          : this.filter === 'complete'
          ? workLogs.filter((x) => x.todo_flag === 2)
          : workLogs.filter((x) => x.todo_flag === 1);

      return this.sort === 'date'
        ? this.sortByDate(filtered)
        : this.sort === 'todo'
        ? this.sortByTodo(filtered)
        : this.sort === 'farm'
        ? this.sortByFarm(filtered)
        : this.sortByAssigned(filtered);
    },
    sortByAssigned(workLogs) {
      return this.sortAssignedReverse
        ? workLogs.sort((a, b) => {
            if (!a.assigned_to || !b.assigned_to) {
              return !a.assigned_to && !b.assigned_to
                ? 0
                : !a.assigned_to
                ? 1
                : -1;
            }

            return this.userNames[a.assigned_to].localeCompare(
              this.userNames[b.assigned_to]
            );
          })
        : workLogs.sort((a, b) => {
            if (
              a.assigned_to === this.currUserId ||
              b.assigned_to === this.currUserId
            ) {
              return a.assigned_to === this.currUserId ? -1 : 1;
            }

            if (!a.assigned_to || !b.assigned_to) {
              return !a.assigned_to && !b.assigned_to
                ? 0
                : !a.assigned_to
                ? 1
                : -1;
            }
            if (this.userNames && this.userNames[a.assigned_to] && this.userNames[b.assigned_to]) {
              return this.userNames[a.assigned_to].localeCompare(
                this.userNames[b.assigned_to]
              );
            } else {
              return 0;
            }
          });
    },
    sortByDate(workLogs) {
      return this.sortDateReverse
        ? workLogs.sort((a, b) => a.date - b.date)
        : workLogs.sort((a, b) => b.date - a.date);
    },
    sortByFarm(workLogs) {
      return this.sortFarmReverse
        ? workLogs.sort((a, b) =>
            this.farmNames[b.farm_id].localeCompare(this.farmNames[a.farm_id])
          )
        : workLogs.sort((a, b) =>
            this.farmNames[a.farm_id].localeCompare(this.farmNames[b.farm_id])
          );
    },
    sortByTodo(workLogs) {
      return this.sortTodoReverse
        ? workLogs.sort((a, b) => a.todo_flag - b.todo_flag)
        : workLogs.sort((a, b) => b.todo_flag - a.todo_flag);
    }
  },
  computed: {
    currUserId() {
      return this.$store.state.user.user_id;
    },
    farmNames() {
      const farmNames = {};

      for (const farm of this.$store.state.farmList) {
        farmNames[farm.id] = farm.farm_name;
      }

      return farmNames;
    },
    isSuperAdmin() {
      return this.$store.state.user.aerworx_level === 'super-admin';
    },
    sortLabel() {
      return this.sort === 'date'
        ? 'Date'
        : this.sort === 'todo'
        ? 'ToDos'
        : this.sort === 'farm'
        ? 'Farms'
        : 'Assigned';
    },
    WorkLogs() {
      if (
        this.$store.state.workLogs.length === 0 ||
        this.$store.getters.workCategory.length === 0 ||
        this.$store.getters.workItem.length === 0
      ) {
        return [];
      }

      const workLogs = [];
      // const farmTodosMap = new Map();

      for (const workLogRaw of this.$store.state.workLogs) {
        const workLog = cloneObj(workLogRaw);
        // Deleted WorkLogs are empty str
        if (workLog === '') {
          continue;
        }

        if (workLog.todo_flag < 1) {
          continue;
        }

        // Set last comment time for sorting
        let lastCommentTime = 0;
        if (workLog.comments.length) {
          lastCommentTime = workLog.comments[workLog.comments.length - 1].time;
        }
        workLog.lastCommentTime = lastCommentTime;

        const item = this.$store.getters.workItem(workLog.work_item);
        const cat = this.$store.getters.workCategory(workLog.work_cat);
        if (cat && item) {
          workLog.work_cat_name = cat.item;
          workLog.color = cat.def_color;
          workLog.work_item_name = item.name;
          workLog.master_billable = item.billable;
        }

        const chatInfoObj = chatInfo(workLog.chats, this.currUserId);

        workLog.latestMsgTs = chatInfoObj.latestMsgTs;
        workLog.numUnread = chatInfoObj.numUnread;
        workLog.numStarred = chatInfoObj.numStarred;
        workLog.numImportant = chatInfoObj.numUnread + chatInfoObj.numStarred;
        workLog.hasChat =
          chatInfoObj.isPartOfChat ||
          (workLog.chats.length > 0 && this.isSuperAdmin);
        workLog.isUser = workLog.assigned_to === this.currUserId;
        workLog.reminderStatus = getReminderStatus(
          workLog.reminders,
          this.$store.state
        );

        const officeNotesSplit = workLog.office_notes.split('[ Email: ');
        workLog.officeNotes = officeNotesSplit[0];
        workLog.emailMsg =
          officeNotesSplit.length > 1
            ? '[ Email: ' + officeNotesSplit.pop()
            : '';

        workLogs.push(workLog);
      }

      return this.sortAndFilterWorkLogs(workLogs);
    },
    WorkLogsDisplayed() {
      return this.WorkLogs.slice(0, this.displayNumWorkLogs);
    },
    userNames(id) {
      const userNames = {};

      for (const user of this.$store.state.userList) {
        const shortName = user.display_name
          .split(' ')
          .map((x, i) => (i === 0 ? x : x.substring(0, 1)))
          .join(' ');

        userNames[user.user_id] = shortName;
      }

      return userNames;
    }
  },
  watch: {
    '$store.state.user.userAccessSet'() {
      this.sort = this.isSuperAdmin ? 'date' : 'assigned';
    }
  }
};
</script>

<style scoped></style>
