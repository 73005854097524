const { date } = require('quasar');
const { cloneObj } = require('./helpers');

const emailMsgExtractor = (msgs) => {
  const emailBody = [];
  for (const msg of msgs) {
    const msgArr = msg.split(' ');
    const amPmIndex = msgArr.findIndex((x) => x === 'AM' || x === 'PM');
    const pond = amPmIndex > 0 ? msgArr.slice(0, amPmIndex - 2).join(' ') : '';
    const msgStr =
      amPmIndex > 0
        ? msgArr.slice(amPmIndex + 1, msgArr.length).join(' ')
        : msg;

    emailBody.push({ msg: msgStr, pond });
  }

  return emailBody;
};

const emailKeyBuilder = (email) => {
  return (
    email.farm_id +
    email.emailBody
      .map((x) => x.pond + x.msg)
      .join('')
      .split(' ')
      .join('')
      .split('.')
      .join('')
  );
};

const emailKeyBuilderUnique = (email) => {
  return (
    email.farm_id +
    email.date +
    email.emailBody
      .map((x) => x.pond + x.msg)
      .join('')
      .split(' ')
      .join('')
      .split('.')
      .join('')
  );
};

const splitIntoDays = (emailDuplicates) => {
  const days = [];
  for (const email of emailDuplicates) {
    const hasDay = days.find((x) => x.startDay === email.startDay);

    if (hasDay) {
      const hasEmail = hasDay.emailDuplicates.find(
        (x) => x.message === email.message
      );

      if (!hasEmail) {
        hasDay.emailDuplicates.push(cloneObj(email));
        hasDay.numEmailDuplicates += 1;
      }
    } else {
      const emailClone = cloneObj(email);
      emailClone.emailDuplicates = [cloneObj(email)];
      emailClone.numEmailDuplicates = 1;
      days.push(emailClone);
    }
  }
  return days;
};

function getTwoDayDuplicates(duplicateEmails) {
  // check if two timestamps are within 24 hours of each other
  function isWithin24Hours(timestamp1, timestamp2) {
    const secondsIn24Hours = 24 * 60 * 60; // 24 hours in seconds
    return Math.abs(timestamp1 - timestamp2) <= secondsIn24Hours;
  }

  // Filter out objects that don't have any other object within 24 hours
  return duplicateEmails.filter((currentObj, index) => {
    return duplicateEmails.some((otherObj, otherIndex) => {
      return (
        index !== otherIndex &&
        isWithin24Hours(currentObj.startDay, otherObj.startDay)
      );
    });
  });
}

const getTimeDiff = (days) => {
  const min = Math.min(...days.map((x) => x.date));
  const max = Math.max(...days.map((x) => x.date));

  const hourDiff = date.getDateDiff(
    new Date(max * 1000),
    new Date(min * 1000),
    'hours'
  );

  if (hourDiff > 48) {
    for (const day of days) {
      day.multiDays = true;
      day.multiDaysEmails = days;
    }
  }
};

const checkForWorkLog = (email, recentLogs) => {
  const emailMsgKey = email.emailBody
    .map((x) => `${x.pond} - ${x.msg}`)
    .join(' --- ')
    .split(' ')
    .join('');

  const filteredLogs = recentLogs
    .filter((x) => x.farm_id === email.farm_id)
    .filter((x) => x.office_notes.includes('[ Email: '));

  let numWorkLogs = 0;
  let workLogIds = [];
  for (const log of filteredLogs) {
    const officeNoteStr = log.office_notes
      .split('[ Email: ')
      .pop()
      .split('(')[0]
      .trim()
      .split(' ')
      .join('');

    if (officeNoteStr === emailMsgKey) {
      numWorkLogs += 1;

      workLogIds.push(log.id);
    }
  }

  return { numWorkLogs, workLogIds };
};

module.exports = {
  emailMsgExtractor,
  emailKeyBuilder,
  emailKeyBuilderUnique,
  splitIntoDays,
  getTwoDayDuplicates,
  getTimeDiff,
  checkForWorkLog
};
