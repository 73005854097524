/*
 Vuex Network plugin
 */
import connector from '@/services/mqtt/connector';
import UrlPattern from 'url-pattern';

// const repeaterList = new UrlPattern('farm/:farmId/repeaters/:repeaterId/');

// const WorkCategories = new UrlPattern('workCategories/:itemId/');

const DELETED = '';

export default function createNetworkPlugin() {
  return (store) => {
    const handlers = [];

    const handle = function (pattern, fn) {
      handlers.push({
        matcher: new UrlPattern(pattern),
        fn
      });
    };

    const defaultHandler = function (pattern, set, all, del) {
      handlers.push({
        matcher: new UrlPattern(pattern),
        fn: function (packet, params, store) {
          const payload = packet.payload.toString();
          if (payload === DELETED && del) {
            store.commit({
              type: del,
              itemId: parseInt(params.itemId),
              params
            });
          } else {
            const item = JSON.parse(payload);
            if (params.itemId === 'all' && all) {
              store.commit({
                type: all,
                items: item,
                params
              });
            } else {
              store.commit({
                type: set,
                item,
                itemId: parseInt(params.itemId),
                params
              });
            }
          }
        }
      });
    };

    defaultHandler(
      'farmList/:itemId/',
      'SET_FARM_LIST',
      'SET_FARM_LIST_ALL',
      'DEL_FARM_LIST'
    );

    defaultHandler('farmList/:itemId/billing/', 'SET_FARM_BILLING');
    defaultHandler('farmList/:itemId/asm/', 'SET_FARM_ASM');

    defaultHandler('farmList/:itemId/gps/', 'SET_FARM_GPS');

    defaultHandler(
      'serviceItems/:itemId/',
      'SET_SERVICE_LIST',
      'SET_SERVICE_LIST_ALL'
    );

    defaultHandler(
      'serviceItems/:itemId/priority/',
      'SET_SERVICE_LIST_POSITION'
    );

    defaultHandler('settings/', 'SET_SETTINGS');

    defaultHandler('parts/:itemId/', 'SET_PART', 'SET_PART_ALL', 'DEL_PART');

    defaultHandler(
      'partsCategories/:itemId/',
      'SET_PART_CAT',
      'SET_PART_CAT_ALL',
      'DEL_PART_CAT'
    );

    defaultHandler(
      'farm/:farmId/ponds/:itemId/',
      'SET_POND',
      'SET_PONDS_ALL',
      'DEL_POND'
    );

    defaultHandler(
      'farm/:farmId/computers/:itemId/',
      'SET_COMPUTER',
      'SET_COMPUTERS_ALL',
      'DEL_COMPUTER'
    );

    defaultHandler(
      'farm/:farmId/repeaters/:itemId/',
      'SET_REPEATER',
      'SET_REPEATERS_ALL',
      'DEL_REPEATER'
    );

    defaultHandler('workItems/:itemId/', 'SET_WORK', 'SET_WORK_ALL');

    defaultHandler(
      'workCategories/:itemId/',
      'SET_WORK_CAT',
      'SET_WORK_CAT_ALL'
    );

    defaultHandler(
      'farm/:farmId/logs/:itemId/',
      'SET_WORK_LOG',
      'SET_WORK_LOG_ALL',
      'DEL_WORK_LOG'
    );

    defaultHandler(
      'farm/:farmId/notes/:itemId/',
      'SET_NOTE',
      'SET_NOTE_ALL',
      'DEL_NOTE'
    );

    defaultHandler(
      'boards/:itemId/',
      'SET_BOARD',
      'SET_BOARDS_ALL',
      'DEL_BOARD'
    );

    defaultHandler(
      'boardLogs/:itemId/',
      'SET_BOARD_LOG',
      'SET_BOARD_LOGS_ALL',
      'DEL_BOARD_LOG'
    );

    defaultHandler(
      'smsRecords/:itemId/',
      'SET_SMS_RECORD',
      'SET_SMS_RECORDS_ALL'
    );

    defaultHandler(
      'zebraPrinters/:itemId/',
      'SET_ZPL_PRINTER',
      'SET_ZPL_PRINTERS_ALL'
    );

    defaultHandler('todos/:itemId/', 'SET_TODO', 'SET_TODOS_ALL');

    defaultHandler('todos/all/msgs/', 'SET_TODO_MSGS');

    defaultHandler('userList/', 'SET_USER_LIST');

    defaultHandler('userList/:itemId/farms/', 'SET_USER_ACCESS');

    defaultHandler('aerwarePC/all/', 'SET_AERWARE_UNASSIGNED_PC');

    defaultHandler(
      'workLogs/:itemId/',
      'SET_RECENT_WORK',
      'SET_RECENT_WORK_ALL',
      'DEL_RECENT_WORK'
    );

    defaultHandler('workLogs/custom/dates/', 'SET_RECENT_WORK_CUSTOM_ALL');

    defaultHandler('workLogs/all/msgs/', 'SET_RECENT_WORK_MSGS');

    defaultHandler('tags/all/', 'SET_TAGS');

    defaultHandler('movePond/', 'MOVE_POND');

    defaultHandler('emails/all/', 'SET_EMAILS');

    handle('zebraPrinters/:serial/print/', function () {
      /* Empty handler */
    });

    connector.on('connect', function (connected) {
      store.commit({
        type: 'SET_ONLINE',
        OnLine: connected
      });
    });

    connector.on('login', function (userData, apiVersion) {
      store.commit('SET_USER_DATA', userData);
      store.commit('SET_API_VERSION', apiVersion);
    });

    connector.on('publish', function (packet) {
      try {
        let handled = false;
        let params;
        for (const arrayElement of handlers) {
          if ((params = arrayElement.matcher.match(packet.topic))) {
            arrayElement.fn(packet, params, store);
            handled = true;
            break;
          }
        }
        if (!handled) {
          packet.payload = packet.payload.toString();
          console.log('Unhandled packet', packet.topic, packet);
        }
      } catch (error) {
        console.log(packet.topic, packet.payload.toString(), packet);
        console.error(error);
      }
    });
  };
}
