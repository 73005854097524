<template>
  <div>
    <div
      v-if="tagsMaster.length < 1"
      class="text-center text-h6 text-grey-5 q-mt-lg"
    >
      No tags to display
    </div>
    <q-card
      v-for="(tagM, i) in tagsMaster"
      :key="tagM.category"
      class="q-mb-md"
    >
      <div class="bg-grey text-subtitle2 text-black q-pl-md">
        {{ tagM.category }}
      </div>
      <div
        v-for="(tagC, j) in tagM.tags"
        :key="tagC.tag_name"
        :class="j % 2 === 0 ? colorBlueGrey : colorGrey"
        :style="`border-left: 10px solid ${levelColorStyle(tagC)}`"
      >
        <div
          class="row items-center no-wrap q-py-sm q-px-sm cursor-pointer"
          @click="tagC.expand = !tagC.expand"
        >
          <div class="row no-wrap items-center">
            <q-icon
              name="sell"
              size="xs"
              :color="colorOrange"
              class="q-mr-sm"
            />
          </div>
          <span class="row ellipsis-2-lines">
            {{ tagC.tag_name }}
          </span>
          <q-space />
          <div class="row no-wrap">
            <q-icon
              v-if="tagC.admin_restricted"
              name="visibility"
              :color="colorOrange"
              class="q-mr-sm"
              size="xs"
            />
            <q-badge color="grey-8" class="q-mr-sm">
              <span v-if="tagC.tag_level !== 10">
                {{ tagC.farmTags.length }}
              </span>
              <span v-if="tagC.tag_level === 10">
                {{ tagC.pondTags.numPonds }}
              </span>
            </q-badge>
          </div>
          <q-icon
            v-if="!tagC.expand"
            name="expand_more"
            size="md"
            :color="colorOrange"
          />
          <q-icon
            v-if="tagC.expand"
            name="expand_less"
            size="md"
            :color="colorOrange"
          />
        </div>

        <div v-if="tagC.expand && showOptions" class="col-12">
          <q-separator />
          <div class="row q-py-xs">
            <q-space />
            <q-btn
              outline
              icon="delete"
              color="red-14"
              size="sm"
              padding="1px sm"
              class="q-mr-sm"
              @click="$emit('masterTagDelete', i, j)"
            />
            <q-btn
              outline
              icon="edit"
              :color="colorOrange"
              size="sm"
              padding="1px sm"
              class="q-mr-sm"
              @click="$emit('masterTagEdit', i, j)"
            />
            <q-btn
              outline
              icon="add"
              color="purple"
              size="sm"
              padding="1px sm"
              class="q-mr-sm"
              @click="$emit('farmAddStart', tagC, 'tags')"
            />
          </div>
        </div>

        <div v-if="tagC.expand" class="q-pl-md">
          <q-separator />
        </div>
        <div v-if="tagC.expand" class="col-12 row q-pl-md">
          <div v-if="tagC.tag_level !== 10">
            <div
              v-for="tag in tagC.farmTags"
              :key="tag.tag_name + tag.farm_id + tag.pond_id"
              class="col-12 row items-center text-caption q-py-xs"
            >
              <q-icon
                name="home_work"
                size="xs"
                color="purple"
                class="q-mr-sm"
              />
              {{ farmName(tag.farm_id) }}
            </div>
          </div>
          <div v-if="tagC.tag_level === 10">
            <div
              v-for="tag in tagC.pondTags.tags"
              :key="tag.farm_id + 'pond-tag'"
              class="col-12 row items-center text-caption q-py-xs"
            >
              <q-icon
                name="home_work"
                size="xs"
                color="purple"
                class="q-mr-sm"
              />
              {{ farmName(tag.farm_id) }}
              <div
                v-for="pond in tag.ponds"
                :key="pond.farm_id + pond.pond_id"
                class="col-12 row items-center text-caption q-py-xs"
              >
                <q-icon
                  name="water"
                  size="xs"
                  color="primary"
                  class="q-ml-lg q-mr-sm"
                />
                {{ pondName(pond) }}
              </div>
            </div>
          </div>
        </div>
        <div v-if="tagC.expand" class="q-py-sm"></div>
      </div>
    </q-card>
  </div>
</template>

<script>
import { decoder } from '@/lib/helpers';

export default {
  name: 'MainTags',
  props: {
    tagsMaster: {
      type: Array,
      required: true
    },
    showOptions: {
      type: Boolean,
      required: true
    },
    farmList: {
      type: Array,
      required: true
    }
  },
  methods: {
    farmName(farmId) {
      const farm = this.farmList.find((x) => x.id === parseInt(farmId));
      return farm ? decoder(farm.farm_name) : '';
    },
    pondName(tag) {
      const farm = this.farmList.find((x) => x.id === parseInt(tag.farm_id));
      const pond = farm.gps.ponds.find(
        (pond) => tag.pond_id === parseInt(pond.id)
      );

      return pond?.name ?? '---';
    },
    levelColorStyle(tag) {
      return tag.tag_level === 0
        ? '#000000'
        : tag.tag_level === 1
        ? '#00c853'
        : tag.tag_level === 2
        ? '#9c27b0'
        : tag.tag_level === 3
        ? '#d50000'
        : tag.tag_level === 4
        ? '#ff6d00'
        : tag.tag_level === 10
        ? '#1976d2'
        : '#9e9e9e';
    }
  },
  computed: {
    colorBlueGrey() {
      return this.$q.dark.isActive ? 'bg-blue-grey-10' : 'bg-grey-4';
    },
    colorGrey() {
      return this.$q.dark.isActive ? 'bg-grey-10' : 'bg-grey-5';
    },
    colorOrange() {
      return this.$q.dark.isActive ? 'orange-14' : 'orange-10';
    }
  }
};
</script>

<style scoped>
.dot {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  display: inline-block;
}
</style>
