import store from '@/store';
import date from 'quasar/src/utils/date.js';;

function getFarmEval(farm, evalKey, workItems) {
  const canEval = farm.web_eval && farm.pc_eval && farm.win_eval ? true : false;
  const isObj = farm[evalKey] && typeof farm[evalKey] === 'object';
  const hasKeys = isObj && Object.keys(farm[evalKey]).length > 0;
  let evalUpToDate = false;
  let lastEvalDate = null;
  let nextEvalDate = null;
  let evalWLs = [];
  let evalHistoryReversed = [];
  let evalFDays = 0;
  let notifyOnExpiration = false;
  let evalNote = null;

  if (isObj && hasKeys) {
    notifyOnExpiration = farm[evalKey]?.notifyOnExpiration ?? false;

    const lastEval =
      farm[evalKey].evalHistory[farm[evalKey].evalHistory.length - 1];

    evalFDays = farm[evalKey].evalFrequencyDays;
    const evalFDaysMultiplier =
      evalFDays === 61 ? 90 : evalFDays === 62 ? 180 : evalFDays;

    lastEvalDate = lastEval?.date ?? 0;
    const milliDay = 86400000;
    nextEvalDate = lastEvalDate + milliDay * evalFDaysMultiplier;
    evalUpToDate = nextEvalDate > +new Date();

    // If eval frequency is 0, then eval expires in 1 hour
    if (evalFDays === 0) {
      const milliHour = 3600000;
      nextEvalDate = lastEvalDate + milliHour;
      evalUpToDate = nextEvalDate > +new Date();
    }

    lastEvalDate = lastEvalDate
      ? date.formatDate(lastEvalDate, 'MM/DD/YY')
      : null;

    // Check for worklog that matches the eval date
    // 135 is work_item for Web Eval
    // 136 is work_item for PC Eval
    evalWLs = store.state.workLogs
      .filter((x) => x.farm_id === farm.id && workItems.includes(x.work_item))
      .map((y) => {
        return {
          date: date.formatDate(y.date * 1000, 'MM/DD/YY'),
          workLogId: y.id
        };
      });

    for (const evalItem of farm[evalKey].evalHistory) {
      const evalDate = date.formatDate(evalItem.date, 'MM/DD/YY');
      evalItem.workLog = evalWLs.find((x) => x.date === evalDate) ?? null;
    }

    evalHistoryReversed = farm[evalKey].evalHistory.slice().reverse();

    evalNote = lastEval?.note ?? null;
  }

  const evalColor = evalUpToDate
    ? 'green-4'
    : !lastEvalDate || !notifyOnExpiration
    ? 'grey-3'
    : 'red-14';

  return {
    canEval,
    lastEvalDate,
    nextEvalDate,
    evalColor,
    evalUpToDate,
    evalWLs,
    evalHistoryReversed,
    notifyOnExpiration,
    evalNote
  };
}

function getWebFolders(farm) {
  const webAxessUrls = [];
  for (const webfolder of farm.webfolders) {
    const homeUrl = `https://farms.aercon.net/${webfolder}/`;
    const buoyUrl = `https://farms.aercon.net/${webfolder}/BuoyInfo.html`;
    const configUrl = `https://farms.aercon.net/${webfolder}/Configs.html`;
    const urlObj = {
      homeUrl,
      buoyUrl,
      configUrl,
      locationName: webfolder
    };

    webAxessUrls.push(urlObj);
  }

  return webAxessUrls;
}

export { getFarmEval, getWebFolders };
