import { render, staticRenderFns } from "./Chats.vue?vue&type=template&id=a891382a&scoped=true"
import script from "./Chats.vue?vue&type=script&lang=js"
export * from "./Chats.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a891382a",
  null
  
)

export default component.exports
import QBtnDropdown from 'quasar/src/components/btn-dropdown/QBtnDropdown.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QList from 'quasar/src/components/item/QList.js';
import QResizeObserver from 'quasar/src/components/resize-observer/QResizeObserver.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QBtnDropdown,QCard,QBtn,QSeparator,QList,QResizeObserver,QItem,QItemSection,QAvatar,QItemLabel,QBadge,QIcon,QChip,QSpace,QDialog,QCardSection,QCardActions});qInstall(component, 'directives', {ClosePopup});
