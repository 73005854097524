/*
 MQTT <-> network
 */
import status from '@/services/api/status';
import keepalive from '@/services/api/keepalive';
import CookieUtils from '@/lib/cookie-utils';
import { v4 } from 'uuid';
import websocket from 'websocket-stream';
import mqttCon from 'mqtt-connection';
const events = require('events');
const clientEvents = new events.EventEmitter();

const KeepAlivePingTime = 15 * 60 * 1000;
// eslint-disable-next-line no-unused-vars
let serverTimeOffset = 0;
let timeoutTime = 5000;
let lastUserAction = 0;
let httpKeepAliveTimer;
const clientId = 'WEB-' + v4();
const EVENT = {
  TIMEOUT: 'Timeout',
  CLOSED: 'Closed',
  VALID: 'Valid'
};

// Shim for mqtt-connection
if (!global.setImmediate) {
  global.setImmediate = function (func) {
    setTimeout(func, 0);
  };
}

const Connector = function () {
  // Events
  let loggedIn = false;
  let onPublish = null;
  let onConnect = null;
  let onLogin = null;
  // Other local variables
  let currentMessageId = 0;
  const messagesInUse = [];
  let client = null;
  // let closed = true;

  const subscriptions = [];

  const getMqttError = function (err) {
    switch (err) {
      case 0:
        return 'GRANTED QOS 0';
      case 1:
        return 'GRANTED QOS 1';
      case 2:
        return 'GRANTED QOS 2';
      case 17:
        return 'NO_SUBSCRIPTION';
      case 131:
        return 'IMP_SPEC_ERROR';
      case 134:
        return 'BAD_USER_PASS';
      case 135:
        return 'NOT_AUTHORIZED';
      case 136:
        return 'SERVER_UNAVAILABLE';
      case 143:
        return 'TOPIC_INVALID';
      case 144:
        return 'WILL_TOPIC_INVALID';
      case 149:
        return 'PACKET_TOO_LARGE';
      case 153:
        return 'PAYLOAD_INVALID';
      case 155:
        return 'QOS_NOT_SUPPORTED';
      default:
        return `Unknown const (${err})`;
    }
  };

  const getNewMessageId = function () {
    let max = 0xffff;
    while (true) {
      if (++currentMessageId > 0xffff) {
        currentMessageId = 1;
      }
      if (!messagesInUse.find((element) => element === currentMessageId)) {
        messagesInUse.push(currentMessageId);
        return currentMessageId;
      }
      if (max-- === 0) {
        console.log('debug', 'getNewMessageId failure');
        return 0;
      }
    }
  };

  const closeMessageId = function (messageId) {
    const index = messagesInUse.findIndex((element) => element === messageId);
    if (index > -1) {
      messagesInUse.splice(index, 1);
    }
  };

  const openWebSocket = function (jwt, user_id) {
    let closed = false;
    let mqttKeepAliveTimer = null;
    let connTimer = null;
    const url =
      window.location.protocol.replace('http', 'ws') +
      '//' +
      window.location.host +
      '/api/wss/mqtt';
    const ws = websocket(url);
    client = mqttCon(ws, {
      protocolVersion: 5
    });

    const closeConnection = function (reason) {
      if (closed) {
        return;
      }
      closed = true;
      try {
        clientEvents.emit('error', EVENT.CLOSED);
      } catch (error) {
        // No pending errors
      }
      console.log('MQTT closed:' + reason);
      clearTimeout(httpKeepAliveTimer);
      clearTimeout(mqttKeepAliveTimer);
      if (onConnect) {
        onConnect(false);
      }
      setTimeout(checkLogin, 5000);
      timeoutTime = 5000;
      client.destroy();
      client = null;
    };

    const connectionTimeout = function () {
      ws.socket.close();
      closeConnection('connection timeout');
    };

    const mqttKeepAlive = function () {
      client.pingreq(null, function () {
        mqttKeepAliveTimer = setTimeout(mqttKeepAlive, 30000);
      });
    };

    const updateConnTimer = function (timeOut) {
      if (connTimer) {
        clearTimeout(connTimer);
      }
      connTimer = setTimeout(connectionTimeout, timeOut);
    };

    updateConnTimer(5000);

    client.connect({
      clientId: clientId,
      protocolVersion: 5,
      clean: false,
      username: 'blank',
      password: jwt
    });

    client.on('connack', function (packet) {
      if (packet.reasonCode !== 0) {
        closeConnection('Unable to login to mqtt server');
      }
      if (!packet.sessionPresent) {
        if (subscriptions.length > 0) {
          console.log('Re subscribing all');
          const subs = [];
          const messageId = getNewMessageId();
          for (let i = 0; i < subscriptions.length; i++) {
            subscriptions[i].ack = false;
            subscriptions[i].messageId = messageId;
            subs.push({
              topic: subscriptions[i].topic,
              qos: 1
            });
          }
          // eslint-disable-next-line no-unused-vars
          const packet = {
            messageId: messageId,
            subscriptions: subs
          };
          client.subscribe(packet);
        }
      } else {
        // TODO consider validation of subscriptions
        console.log('connack', packet);
        console.log('Already subscribed');
      }
      updateConnTimer(60000);
      mqttKeepAliveTimer = setTimeout(mqttKeepAlive, 10000);
      if (onConnect) {
        onConnect(true);
      }
    });

    client.on('publish', function (packet) {
      // TODO consider duplicate handling
      // TODO Consider ramifications of order
      if (packet.qos > 0) {
        client.puback({
          messageId: packet.messageId
        });
      }
      updateConnTimer(60000);
      if (onPublish) {
        onPublish(packet);
      }
    });

    client.on('suback', function (packet) {
      if (packet.granted.find((element) => element > 1)) {
        for (let i = 0; i < subscriptions.length; i++) {
          if (subscriptions[i].messageId === packet.messageId) {
            if (subscriptions[i].index) {
              console.log(
                `Subscribe failed: ${subscriptions[i].topic} %c` +
                  getMqttError(packet.granted[subscriptions[i].index]),
                'color: #F50'
              );
            } else {
              console.log(
                `Subscribe failed: ${subscriptions[i].topic} %c` +
                  getMqttError(packet.granted[0]),
                'color: #F50'
              );
            }
          }
        }
        clientEvents.emit(`suback:${packet.messageId}`, EVENT.VALID, false);
      } else {
        for (let i = 0; i < subscriptions.length; i++) {
          if (subscriptions[i].messageId === packet.messageId) {
            subscriptions[i].ack = true;
          }
        }
        // console.log('suback', subscriptions, packet);
        clientEvents.emit(`suback:${packet.messageId}`, EVENT.VALID, true);
      }
      closeMessageId(packet.messageId);
    });

    client.on('unsuback', function (packet) {
      // console.log('unSuback', subscriptions, packet);
      clientEvents.emit(`unsuback:${packet.messageId}`, EVENT.VALID, packet);
      closeMessageId(packet.messageId);
    });

    client.on('puback', function (packet) {
      clientEvents.emit(`puback:${packet.messageId}`, EVENT.VALID, packet);
      closeMessageId(packet.messageId);
    });

    client.on('pingresp', function () {
      updateConnTimer(60000);
    });

    client.on('close', function () {
      closeConnection('server closed');
    });

    // eslint-disable-next-line handle-callback-err
    client.on('error', function (error) {
      console.log('MQTT connection error', error);
    });

    doKeepAlive(); // Retrieve initial clock offset, and start timer
  };

  const doKeepAlive = function () {
    if (loggedIn && Date.now() - lastUserAction < KeepAlivePingTime * 3) {
      const t0 = Date.now();
      keepalive
        .doKeepAlive()
        .then((data) => {
          // NTP offset calculation
          const t3 = Date.now();
          serverTimeOffset = (data.t1 - t0 + (data.t2 - t3)) / 2;
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.status &&
            error.response.status === 401
          ) {
            CookieUtils.redirectToAccounts();
          }
        })
        .finally(() => {
          httpKeepAliveTimer = setTimeout(doKeepAlive, KeepAlivePingTime);
        });
    } else {
      httpKeepAliveTimer = setTimeout(doKeepAlive, KeepAlivePingTime);
    }
  };

  const checkLogin = function () {
    status
      .getStatus()
      .then(({ data, version }) => {
        if (onLogin) {
          onLogin(data, version);
        }
        loggedIn = true;
        openWebSocket(data.jwt, data.user_id);
        lastUserAction = Date.now();
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status &&
          error.response.status === 401
        ) {
          CookieUtils.redirectToAccounts();
        } else {
          // Try again after some time
          setTimeout(checkLogin, timeoutTime);
          if (timeoutTime <= 20000) {
            timeoutTime *= 2;
          }
        }
      });
  };

  const onCheckLoginCookie = function () {
    if (loggedIn) {
      // This particular routine closes this app if another app in the Aercon family
      // on the same browser has logged out.
      if (
        document.cookie.indexOf(
          process.env.VUE_APP_ERASABLE_COOKIE_NAME ?? ''
        ) === -1
      ) {
        CookieUtils.redirectToAccounts();
        return; // Prevent Firefox loop
      }
    }
    setTimeout(onCheckLoginCookie, 500);
  };

  const publish = async function (topic, payload) {
    if (client === null) {
      return {
        sent: false,
        reason: 0x88,
        error: 'No network'
      };
    }
    const messageId = getNewMessageId();
    client.publish({
      topic,
      messageId,
      qos: 1,
      payload
    });
    try {
      const [valid, puback] = await events.once(
        clientEvents,
        `puback:${messageId}`
      );
      return {
        sent: valid === EVENT.VALID,
        reasonCode: puback.reasonCode,
        reason: puback.properties.reasonString,
        data: puback.properties.userProperties,
        error:
          puback.properties.userProperties &&
          puback.properties.userProperties.error
            ? puback.properties.userProperties.error
            : ''
      };
    } catch (error) {
      return {
        sent: false,
        reason: error.toString()
      };
    }
  };

  const subscribe = async function (topic) {
    if (client === null) {
      return false; // or throw error
    }
    const messageId = getNewMessageId();
    const subs = [];
    if (Array.isArray(topic)) {
      for (let i = 0; i < topic.length; i++) {
        subscriptions.push({
          ack: false,
          topic: topic[i],
          messageId,
          index: i
        });
        subs.push({
          topic: topic[i],
          qos: 1
        });
      }
    } else {
      subscriptions.push({
        ack: false,
        topic,
        messageId
      });
      subs.push({
        topic: topic,
        qos: 1
      });
    }

    client.subscribe({
      messageId: messageId,
      subscriptions: subs
    });

    try {
      const [valid, result] = await events.once(
        clientEvents,
        `suback:${messageId}`
      );
      // return valid === EVENT.VALID && result === true;
      return {
        subscribed: valid === EVENT.VALID && result === true,
        reason: result
      };
    } catch (error) {
      return {
        subscribed: false,
        reason: error.toString()
      };
    }
  };

  const unSubscribe = async function (topic) {
    if (client === null) {
      return false; // or throw error
    }
    const messageId = getNewMessageId();

    const unSubs = [];
    if (Array.isArray(topic)) {
      for (let i = 0; i < topic.length; i++) {
        const index = subscriptions.findIndex(
          (element) => element.topic === topic[i]
        );
        if (index > -1) {
          subscriptions.splice(index, 1);
        }
        unSubs.push(topic[i]);
      }
    } else {
      const index = subscriptions.findIndex(
        (element) => element.topic === topic
      );
      if (index > -1) {
        subscriptions.splice(index, 1);
      }
      unSubs.push(topic);
    }

    const index = subscriptions.findIndex((element) => element.topic === topic);
    if (index > -1) {
      subscriptions.splice(index, 1);
    }

    client.unsubscribe({
      messageId: messageId,
      unsubscriptions: unSubs,
      qos: 1
    });
    try {
      const [valid, result] = await events.once(
        clientEvents,
        `unsuback:${messageId}`
      );
      // return valid === EVENT.VALID && result === true;
      return {
        subscribed: valid === EVENT.VALID && result === true,
        reason: result
      };
    } catch (error) {
      return {
        subscribed: false,
        reason: error.toString()
      };
    }
  };

  const on = function (event, callback) {
    switch (event) {
      case 'publish':
        onPublish = callback;
        break;
      case 'connect':
        onConnect = callback;
        break;
      case 'login':
        onLogin = callback;
        break;
    }
  };

  onCheckLoginCookie();
  checkLogin();
  console.log('Starting connector for ' + clientId);

  return {
    publish,
    subscribe,
    unSubscribe,
    on
  };
};

export default Connector();

// module.exports = Connector;
