var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"q-pa-md row items-start q-gutter-md"},_vm._l((_vm.Notes),function(note,index){return _c('q-card',{key:index,staticClass:"my-card",attrs:{"flat":"","bordered":""}},[_c('q-card-section',{staticClass:"hdr-offset-tone"},[_vm._v(" "+_vm._s(_vm.dateFormat(note.date))+" "),_c('q-btn',{staticClass:"btn-table-negative float-right",attrs:{"icon":"delete","size":"md","padding":"xs"},on:{"click":function($event){return _vm.deleteNote(note)}}}),_c('q-btn',{staticClass:"btn-table-primary float-right",attrs:{"icon":"edit","size":"md","padding":"xs"},on:{"click":function($event){return _vm.editNote(note)}}})],1),_c('q-separator'),_c('q-card-section',{staticClass:"q-pt-none",domProps:{"innerHTML":_vm._s(note.note)}})],1)}),1),_c('q-page-sticky',{attrs:{"position":"bottom-right","offset":[28, 28]}},[_c('q-btn',{attrs:{"fab":"","icon":"add","color":"accent"},on:{"click":_vm.addNote}})],1),_c('q-dialog',{attrs:{"persistent":""},model:{value:(_vm.showEditModal),callback:function ($$v) {_vm.showEditModal=$$v},expression:"showEditModal"}},[_c('q-card',{staticClass:"q-pa-md card-size",attrs:{"square":"","bordered":""}},[_c('q-card-section',{},[_c('q-form',{staticClass:"q-gutter-sm",on:{"submit":_vm.onSubmit}},[_c('q-toolbar',{staticClass:"shadow-2"},[_c('q-toolbar-title',{staticClass:"row justify-center items-center"},[_c('q-icon',{staticClass:"q-mr-sm",attrs:{"name":"note","size":"sm"}}),_vm._v(" "+_vm._s(_vm.ModalHeader)+" ")],1)],1),_c('q-editor',{attrs:{"min-height":"5rem","fonts":{
              arial: 'Arial',
              arial_black: 'Arial Black',
              comic_sans: 'Comic Sans MS',
              courier_new: 'Courier New',
              impact: 'Impact',
              lucida_grande: 'Lucida Grande',
              times_new_roman: 'Times New Roman',
              verdana: 'Verdana'
            },"toolbar":[
              ['left', 'center', 'right', 'justify'],
              [
                'token',
                'bold',
                'italic',
                'strike',
                'underline',
                'subscript',
                'superscript',
                'link'
              ],
              ['token', 'fullscreen'],
              [
                {
                  label: _vm.$q.lang.editor.formatting,
                  icon: _vm.$q.iconSet.editor.formatting,
                  list: 'no-icons',
                  options: ['p', 'h4', 'h5', 'h6', 'quote', 'code']
                },
                {
                  label: _vm.$q.lang.editor.defaultFont,
                  icon: _vm.$q.iconSet.editor.font,
                  fixedIcon: true,
                  list: 'no-icons',
                  options: [
                    'default_font',
                    'arial',
                    'arial_black',
                    'comic_sans',
                    'courier_new',
                    'impact',
                    'lucida_grande',
                    'times_new_roman',
                    'verdana'
                  ]
                }
              ]
            ]},model:{value:(_vm.editNoteCopy.note),callback:function ($$v) {_vm.$set(_vm.editNoteCopy, "note", $$v)},expression:"editNoteCopy.note"}}),_c('q-input',{attrs:{"filled":"","label":"Log Date","type":"date","outlined":"","square":""},model:{value:(_vm.noteDate),callback:function ($$v) {_vm.noteDate=$$v},expression:"noteDate"}}),_c('div',[_c('div',{staticClass:"row full-width"},[_c('div',{staticClass:"col spaced"},[_c('q-btn',{staticClass:"full-width",attrs:{"outline":"","label":"Cancel","color":"secondary"},on:{"click":_vm.cancelEdit}})],1),_c('div',{staticClass:"col spaced"},[_c('q-btn',{staticClass:"full-width",attrs:{"outline":"","label":"Submit","type":"submit","color":"primary"}})],1)])])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }