import Vue from 'vue';
import Vuex from 'vuex';
import networkPlug from './plugins/network';
import connector from '@/services/mqtt/connector';
import { countUnreadAll } from '@/lib/helpers';
import { listReminders, movePond } from '@/lib/helpers';

const getLastFarmList = function () {
  try {
    return JSON.parse(localStorage.lastFarmList);
  } catch (error) {
    return [];
  }
};

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    itemSearchId: null,
    quickAddComplete: true,
    createNewWorkLog: false,
    showLoadingCircle: true,
    OnLine: false,
    darkMode: localStorage.darkMode === 'true',
    pageHeight: 500,
    settings: {
      ASM_triggerA: 0,
      ASM_triggerB: 0
    },
    farm: {
      ponds: [],
      computers: [],
      repeaters: [],
      logs: [],
      notes: [],
      id: -1,
      loading: true
    },
    lastFarm: -1,
    lastFarmList: getLastFarmList(),
    farmOnMap: null,
    farmScrollTo: {
      id: '',
      goStatus: 'inactive'
    },
    emails: [],
    emailWatcher: false,
    emailScrollTo: {
      tab: 'inbox',
      emailKeyUnique: '',
      goStatus: 'inactive'
    },
    workOrderScrollTo: {
      workOrderId: '',
      filter: '',
      goStatus: 'inactive'
    },
    userList: [],
    farmList: [],
    todos: [],
    workLogs: [],
    workLogsCustom: [],
    boardLogs: [],
    boards: [],
    smsRecords: [],
    zebraPrinters: [],
    zebraSelection: localStorage.zebraSelection
      ? localStorage.zebraSelection
      : '',
    aerwareUnassignedPC: [],
    //newTodoComments: getNewTodoComments(),
    unreadWorkLogMsgs: {
      numUnread: 0,
      numStarred: 0,
      unreadArr: []
    },
    unreadTodoMsgs: {
      numUnread: 0,
      numStarred: 0,
      unreadArr: []
    },
    serviceList: [],
    parts: [],
    partsCategories: [],
    pondFilterId: null,
    screenshot: null,
    screenshotWorkLogId: null,
    screenshotBackToEmail: false,
    snoozeAllReminders: false,
    tags: [],
    tagsMaster: [],
    workItems: [],
    workCategories: [],
    workLogScrollToId: null,
    user: {
      aerworx_level: 'none',
      display_name: '',
      is_user_logged_in: false,
      user_email: '',
      user_id: 0,
      user_name: '',
      farms: [],
      userAccessSet: false
    },
    appVersion: process.env.VUE_APP_VERSION,
    newVersion: process.env.VUE_APP_VERSION,
    errorLogs: '',
    boardPage: ''
  },
  mutations: {
    SET_BOARD_PAGE(state, value) {
      state.boardPage = value;
    },
    SET_PAGE_HEIGHT(state, pageHeight) {
      state.pageHeight = pageHeight;
    },
    SET_DARK_MODE(state, value) {
      state.darkMode = value;
      localStorage.darkMode = value ? 'true' : 'false';
    },
    SET_ZEBRA_SELECTION(state, value) {
      state.zebraSelection = value;
      localStorage.zebraSelection = value;
    },
    CLEAR_FARM(state) {
      state.farm = {
        ponds: [],
        computers: [],
        repeaters: [],
        logs: [],
        notes: [],
        id: -1,
        loading: true
      };
    },
    SET_AERWARE_UNASSIGNED_PC(state, payload) {
      Vue.set(state, 'aerwareUnassignedPC', payload.item);
    },
    SET_USER_ACCESS(state, payload) {
      const user = state.userList.find((e) => e.user_id === payload.itemId);
      const self = state.userList.find(
        (e) =>
          e.user_id === payload.itemId && payload.itemId === state.user.user_id
      );
      if (user) {
        user.farms = payload.item;
      }
      if (self) {
        state.user.aerworx_level = self.user_role;
        state.user.display_name = self.display_name;
        state.user.farms = self.farms;
        state.user.userAccessSet = true;
      }
    },
    SET_USER_LIST(state, payload) {
      state.userList = payload.item;
      const self = state.userList.find((e) => e.user_id === state.user.user_id);
      if (self) {
        state.user.aerworx_level = self.user_role;
        state.user.display_name = self.display_name;
        state.user.farms = self.farms;
        state.user.userAccessSet = true;
      }
    },
    SET_SETTINGS(state, payload) {
      state.settings = payload.item;
    },
    SET_FARM(state, farmId) {
      state.farm.id = farmId;
      state.lastFarm = farmId;
      farmId = parseInt(farmId);
      const index = state.lastFarmList.findIndex((e) => e.id === farmId);
      if (index === -1) {
        // Delete if too many
        if (state.lastFarmList.length >= 3) {
          let track = Date.now() + 10;
          let delIndex = 0;
          for (let i = 0; i < state.lastFarmList.length; i++) {
            if (state.lastFarmList[i].date < track) {
              track = state.lastFarmList[i].date;
              delIndex = i;
            }
          }
          Vue.delete(state.lastFarmList, delIndex);
        }
        state.lastFarmList.push({
          id: farmId,
          date: Date.now()
        });
      } else {
        Vue.set(state.lastFarmList, index, {
          id: farmId,
          date: Date.now()
        });
      }
      localStorage.lastFarmList = JSON.stringify(state.lastFarmList);
    },
    SET_ONLINE(state, payload) {
      state.OnLine = payload.OnLine;
    },
    SET_API_VERSION(state, payload) {
      state.newVersion = payload;
    },
    SET_USER_DATA(state, userData) {
      if (userData === null) {
        state.user.aerworx_level = 'none';
        state.user.is_user_logged_in = false;
      } else {
        state.user = userData;
      }
      state.showLoadingCircle = false;
    },
    SET_FARM_ON_MAP(state, farmId) {
      state.farmMapId = farmId;
    },
    SET_ITEM_SEARCH_ID(state, searchId) {
      state.itemSearchId = searchId;
    },
    SET_EMAIL_SCROLL_TO(state, scrollTo) {
      state.emailScrollTo = scrollTo;
    },
    SET_WORK_LOG_SCROLL_TO(state, workLogId) {
      state.workLogScrollToId = workLogId;
    },
    SET_SCREENSHOT_WORKLOG_ID(state, workLogId) {
      state.screenshotWorkLogId = workLogId;
    },
    SET_SCREENSHOT_BACK_TO_EMAIL(state, goBack) {
      state.screenshotBackToEmail = goBack;
    },
    SET_FARM_SCROLL_TO(state, scrollTo) {
      state.farmScrollTo = scrollTo;
    },
    SET_POND_FILTER(state, pondId) {
      state.pondFilterId = pondId;
    },
    SET_WORK_ORDER_SCROLL_TO(state, scrollTo) {
      state.workOrderScrollTo = scrollTo;
    },
    SET_CREATE_NEW_WORKLOG(state, createNew) {
      state.createNewWorkLog = createNew;
    },
    SET_QUICK_ADD_COMPLETE(state, complete) {
      state.quickAddComplete = complete;
    },
    SET_TODOS_ALL(state, payload) {
      Vue.set(state, 'todos', payload.items);
    },
    SET_TODO(state, payload) {
      const index = state.todos.findIndex(
        (element) => element.id === payload.itemId
      );
      if (index !== -1) {
        Vue.set(state.todos, index, payload.item);
      } else {
        state.todos.push(payload.item);
      }
    },
    SET_TODO_MSGS(state, payload) {
      state.unreadTodoMsgs = countUnreadAll(state.todos, state.user.user_id);
    },
    SET_REPEATERS_ALL(state, payload) {
      Vue.set(state.farm, 'repeaters', payload.items);
    },
    SET_REPEATER(state, payload) {
      const index = state.farm.repeaters.findIndex(
        (element) => element.id === payload.itemId
      );
      if (index !== -1) {
        Vue.set(state.farm.repeaters, index, payload.item);
      } else {
        state.farm.repeaters.push(payload.item);
      }
    },
    SET_SCREENSHOT(state, screenshot) {
      state.screenshot = screenshot;
    },
    SNOOZE_ALL_REMINDERS(state, snooze) {
      state.snoozeAllReminders = snooze;
    },
    DEL_REPEATER(state, payload) {
      const index = state.farm.repeaters.findIndex(
        (element) => element.id === payload.itemId
      );
      if (index !== -1) {
        Vue.delete(state.farm.repeaters, index);
      }
    },
    SET_COMPUTERS_ALL(state, payload) {
      Vue.set(state.farm, 'computers', payload.items);
    },
    SET_COMPUTER(state, payload) {
      const index = state.farm.computers.findIndex(
        (element) => element.id === payload.itemId
      );
      if (index !== -1) {
        Vue.set(state.farm.computers, index, payload.item);
      } else {
        state.farm.computers.push(payload.item);
      }
    },
    DEL_COMPUTER(state, payload) {
      const index = state.farm.computers.findIndex(
        (element) => element.id === payload.itemId
      );
      if (index !== -1) {
        Vue.delete(state.farm.computers, index);
      }
    },
    SET_WORK_LOG_ALL(state, payload) {
      Vue.set(state.farm, 'logs', payload.items);
      state.farm.loading = false;
    },
    SET_WORK_LOG(state, payload) {
      // If for some reason farm unSubscribe failed
      if (parseInt(state.farm.id) !== payload.item.farm_id) {
        return;
      }

      const index = state.farm.logs.findIndex(
        (element) => element.id === payload.itemId
      );

      if (index !== -1) {
        Vue.set(state.farm.logs, index, payload.item);
      } else {
        state.farm.logs.push(payload.item);
      }
    },
    DEL_WORK_LOG(state, payload) {
      const index = state.farm.logs.findIndex(
        (element) => element.id === payload.itemId
      );
      if (index !== -1) {
        Vue.delete(state.farm.logs, index);
      }
    },
    SET_RECENT_WORK_CUSTOM_ALL(state, payload) {
      Vue.set(state, 'workLogsCustom', payload.item);
    },
    SET_RECENT_WORK_ALL(state, payload) {
      Vue.set(state, 'workLogs', payload.items);
    },
    SET_RECENT_WORK(state, payload) {
      const setWorkLog = (arr, addCreated) => {
        const index = arr.findIndex((element) => element.id === payload.itemId);
        if (index !== -1) {
          Vue.set(arr, index, payload.item);
        }
        if (index === -1 && addCreated) {
          arr.push(payload.item);
        }
        arr.sort((a, b) => a.date - b.date);
      };

      setWorkLog(state.workLogs, true);
      setWorkLog(state.workLogsCustom, false);
    },
    DEL_RECENT_WORK(state, payload) {
      const index = state.workLogs.findIndex(
        (element) => element.id === payload.itemId
      );
      if (index !== -1) {
        Vue.delete(state.workLogs, index);
      }
    },
    SET_RECENT_WORK_MSGS(state, payload) {
      state.unreadWorkLogMsgs = countUnreadAll(
        state.workLogs,
        state.user.user_id
      );
    },
    /* NOTES */
    SET_NOTE_ALL(state, payload) {
      Vue.set(state.farm, 'notes', payload.items);
    },
    SET_NOTE(state, payload) {
      const index = state.farm.notes.findIndex(
        (element) => element.id === payload.itemId
      );
      if (index !== -1) {
        Vue.set(state.farm.notes, index, payload.item);
      } else {
        state.farm.notes.push(payload.item);
      }
    },
    DEL_NOTE(state, payload) {
      const index = state.farm.notes.findIndex(
        (element) => element.id === payload.itemId
      );
      if (index !== -1) {
        Vue.delete(state.farm.notes, index);
      }
    },
    /* boards */
    SET_BOARDS_ALL(state, payload) {
      Vue.set(state, 'boards', payload.items);
    },
    SET_BOARD(state, payload) {
      const index = state.boards.findIndex(
        (element) => element.id === payload.itemId
      );
      if (index !== -1) {
        Vue.set(state.boards, index, payload.item);
      } else {
        state.boards.push(payload.item);
      }
    },
    DEL_BOARD(state, payload) {
      const index = state.boards.findIndex(
        (element) => element.id === payload.itemId
      );
      if (index !== -1) {
        Vue.delete(state.boards, index);
      }
    },
    /* smsRecords */
    SET_SMS_RECORD(state, payload) {
      const index = state.smsRecords.findIndex(
        (element) => element.id === payload.itemId
      );
      if (index !== -1) {
        Vue.set(state.smsRecords, index, payload.item);
      } else {
        state.smsRecords.push(payload.item);
      }
    },
    SET_SMS_RECORDS_ALL(state, payload) {
      Vue.set(state, 'smsRecords', payload.items);
    },
    /* zebraPrinters */
    SET_ZPL_PRINTER(state, payload) {
      const index = state.zebraPrinters.findIndex(
        (element) => element.id === payload.itemId
      );
      if (index !== -1) {
        Vue.set(state.zebraPrinters, index, payload.item);
      } else {
        state.zebraPrinters.push(payload.item);
      }
    },
    SET_ZPL_PRINTERS_ALL(state, payload) {
      Vue.set(state, 'zebraPrinters', payload.items);
    },
    /* REPAIR_ITEMS */
    SET_BOARD_LOGS_ALL(state, payload) {
      Vue.set(state, 'boardLogs', payload.items);
    },
    SET_BOARD_LOG(state, payload) {
      const index = state.boardLogs.findIndex(
        (element) => element.id === payload.itemId
      );
      if (index !== -1) {
        Vue.set(state.boardLogs, index, payload.item);
      } else {
        state.boardLogs.push(payload.item);
      }
    },
    DEL_BOARD_LOG(state, payload) {
      const index = state.boardLogs.findIndex(
        (element) => element.id === payload.itemId
      );
      if (index !== -1) {
        Vue.delete(state.boardLogs, index);
      }
    },
    /* PONDS */
    SET_PONDS_ALL(state, payload) {
      Vue.set(state.farm, 'ponds', payload.items);
    },
    SET_POND(state, payload) {
      const index = state.farm.ponds.findIndex(
        (element) => element.id === payload.itemId
      );
      if (index !== -1) {
        Vue.set(state.farm.ponds, index, payload.item);
      } else {
        state.farm.ponds.push(payload.item);
      }
    },
    DEL_POND(state, payload) {
      const index = state.farm.ponds.findIndex(
        (element) => element.id === payload.itemId
      );
      if (index !== -1) {
        Vue.delete(state.farm.ponds, index);
      }
    },
    SET_PART_ALL(state, payload) {
      Vue.set(state, 'parts', payload.items);
    },
    SET_PART(state, payload) {
      const index = state.parts.findIndex(
        (element) => element.id === payload.itemId
      );
      if (index !== -1) {
        Vue.set(state.parts, index, payload.item);
      } else {
        state.parts.push(payload.item);
      }
    },
    DEL_PART(state, payload) {
      const index = state.parts.findIndex(
        (element) => element.id === payload.itemId
      );
      if (index !== -1) {
        Vue.delete(state.parts, index);
      }
    },
    SET_PART_CAT_ALL(state, payload) {
      Vue.set(state, 'partsCategories', payload.items);
    },
    SET_PART_CAT(state, payload) {
      const index = state.partsCategories.findIndex(
        (element) => element.id === payload.itemId
      );
      if (index !== -1) {
        Vue.set(state.partsCategories, index, payload.item);
      } else {
        state.partsCategories.push(payload.item);
      }
    },
    DEL_PART_CAT(state, payload) {
      const index = state.partsCategories.findIndex(
        (element) => element.id === payload.itemId
      );
      if (index !== -1) {
        Vue.delete(state.partsCategories, index);
      }
    },
    SET_WORK_CAT_ALL(state, payload) {
      Vue.set(state, 'workCategories', payload.items);
    },
    SET_WORK_CAT(state, payload) {
      const index = state.workCategories.findIndex(
        (element) => element.id === payload.itemId
      );
      if (index !== -1) {
        Vue.set(state.workCategories, index, payload.item);
      } else {
        state.workCategories.push(payload.item);
      }
    },
    SET_WORK_ALL(state, payload) {
      Vue.set(state, 'workItems', payload.items);
    },
    SET_WORK(state, payload) {
      const index = state.workItems.findIndex(
        (element) => element.id === payload.itemId
      );
      if (index !== -1) {
        Vue.set(state.workItems, index, payload.item);
      } else {
        state.workItems.push(payload.item);
      }
    },
    SET_FARM_BILLING(state, payload) {
      const index = state.farmList.findIndex(
        (element) => element.id === payload.itemId
      );
      if (index !== -1) {
        Vue.set(state.farmList[index], 'billing', payload.item);
      }
    },
    SET_FARM_ASM(state, payload) {
      const index = state.farmList.findIndex(
        (element) => element.id === payload.itemId
      );
      if (index !== -1) {
        Vue.set(state.farmList[index], 'farmASM', payload.item);
      }
    },
    SET_FARM_GPS(state, payload) {
      const index = state.farmList.findIndex(
        (element) => element.id === payload.itemId
      );
      if (index !== -1) {
        Vue.set(state.farmList[index], 'gps', payload.item);
      }
    },
    SET_FARM_LIST_ALL(state, payload) {
      const farms = [];
      for (const farm of payload.items) {
        farms.push({
          name: farm.farm_name,
          id: farm.id
        });
      }
      localStorage.farms = JSON.stringify(farms);

      //set to local storage
      Vue.set(state, 'farmList', payload.items);
    },
    SET_FARM_LIST(state, payload) {
      const index = state.farmList.findIndex(
        (element) => element.id === payload.itemId
      );
      if (index !== -1) {
        Vue.set(state.farmList, index, payload.item);
      } else {
        state.farmList.push(payload.item);
      }
    },
    DEL_FARM_LIST(state, payload) {
      const index = state.farmList.findIndex(
        (element) => element.id === payload.itemId
      );
      if (index !== -1) {
        Vue.delete(state.farmList, index);
      }
    },
    SET_SERVICE_LIST_ALL(state, payload) {
      Vue.set(state, 'serviceList', payload.items);
    },
    SET_SERVICE_LIST(state, payload) {
      const index = state.serviceList.findIndex(
        (element) => element.id === payload.itemId
      );
      if (index !== -1) {
        Vue.set(state.serviceList, index, payload.item);
      } else {
        state.serviceList.push(payload.item);
      }
    },
    SET_SERVICE_LIST_POSITION(state, payload) {
      const index = state.serviceList.findIndex(
        (element) => element.id === payload.itemId
      );
      if (index !== -1) {
        Vue.set(state.serviceList[index], 'priority', payload.priority);
      }
    },
    SET_TAGS(state, payload) {
      Vue.set(state, 'tags', payload.item.tags);
      Vue.set(state, 'tagsMaster', payload.item.tagsMaster);
    },
    MOVE_POND(state, payload) {
      const moveObj = payload.item;
      movePond(Vue, state, moveObj);
    },
    SET_EMAILS(state, payload) {
      // When we call updateEmails the payload will return false because
      // it has already been processed and we don't want the slow service
      // double processing scenario to play out - causes ux issues
      // because the emails do take some time to process on the
      // front end
      if (!payload.item) {
        return;
      }

      state.emailWatcher = !state.emailWatcher;
      Vue.set(state, 'emails', payload.item);
    }
  },
  actions: {
    setBoardPage({ commit }, value) {
      commit('SET_BOARD_PAGE', value);
    },
    setPageHeight({ commit }, pageHeight) {
      commit('SET_PAGE_HEIGHT', pageHeight);
    },
    setDarkMode({ commit }, value) {
      commit('SET_DARK_MODE', value);
    },
    setZebraSelection({ commit }, value) {
      commit('SET_ZEBRA_SELECTION', value);
    },
    async subscribeInitial({ commit }, userLevel) {
      const subscriptions = [
        'farmList/#',
        'serviceItems/#',
        // 'partsCategories/#',
        // 'parts/#',
        'workCategories/#',
        'workItems/#',
        'userList/#',
        'todos/#',
        'tags/#',
        'movePond/',
        'aerwarePC/#',
        'settings/#',
        'emails/#'
      ];
      if (userLevel === 'super-admin') {
        subscriptions.push('partsCategories/#');
        subscriptions.push('parts/#');
        subscriptions.push('boards/#');
        subscriptions.push('boardLogs/#');
        subscriptions.push('smsRecords/#');
        subscriptions.push('zebraPrinters/#');
      }
      // We want this to be last
      subscriptions.push('workLogs/#');
      return connector.subscribe(subscriptions);
    },
    async subscribeFarm({ commit }, farmId) {
      commit('CLEAR_FARM');
      commit('SET_FARM', farmId);
      return connector.subscribe([
        `farm/${farmId}/ponds/#`,
        `farm/${farmId}/computers/#`,
        `farm/${farmId}/repeaters/#`,
        `farm/${farmId}/logs/#`,
        `farm/${farmId}/notes/#`
      ]);
    },
    async unSubscribeFarm({ commit }, farmId) {
      commit('CLEAR_FARM');
      return connector.unSubscribe([
        `farm/${farmId}/ponds/#`,
        `farm/${farmId}/computers/#`,
        `farm/${farmId}/repeaters/#`,
        `farm/${farmId}/logs/#`,
        `farm/${farmId}/notes/#`
      ]);
    },
    async setASMTriggers({ commit, getters }, triggers) {
      return connector.publish(
        'settings/asmTriggers/update',
        JSON.stringify(triggers)
      );
    },
    async movePond({ commit, getters }, moveObj) {
      return connector.publish('movePond/update', JSON.stringify(moveObj));
    },
    async setItemPosition({ commit, rootGetters }, { item, direction }) {
      return connector.publish(
        `serviceItems/${item.id}/priority/`,
        JSON.stringify(direction)
      );
    },
    async deleteItem({ commit, rootGetters }, item) {
      return connector.publish(
        `serviceItems/${item.id}/delete/`,
        JSON.stringify(item)
      );
    },
    async createItem({ commit, getters }, newItem) {
      return connector.publish(
        'serviceItems/new/create/',
        JSON.stringify(newItem)
      );
    },
    async updateItem({ commit, getters }, item) {
      return connector.publish(
        `serviceItems/${item.id}/update/`,
        JSON.stringify(item)
      );
    },
    /* Boards */
    async deleteBoard({ commit, rootGetters }, { board }) {
      return connector.publish(
        `boards/${board.id}/delete/`,
        JSON.stringify(board)
      );
    },
    async createBoard({ commit, getters }, { board }) {
      return connector.publish(`boards/new/create/`, JSON.stringify(board));
    },
    async updateBoard({ commit, getters }, { board }) {
      return connector.publish(
        `boards/${board.id}/update/`,
        JSON.stringify(board)
      );
    },
    /* Board Logs */
    async deleteBoardLog({ commit, rootGetters }, { log }) {
      return connector.publish(
        `boardLogs/${log.id}/delete/`,
        JSON.stringify(log)
      );
    },
    async createBoardLog({ commit, getters }, { log }) {
      return connector.publish(`boardLogs/new/create/`, JSON.stringify(log));
    },
    async updateBoardLog({ commit, getters }, { log }) {
      return connector.publish(
        `boardLogs/${log.id}/update/`,
        JSON.stringify(log)
      );
    },
    /* Parts */
    async deletePart({ commit, rootGetters }, part) {
      return connector.publish(
        `parts/${part.id}/delete/`,
        JSON.stringify(part)
      );
    },
    async createPart({ commit, getters }, newPart) {
      return connector.publish('parts/new/create/', JSON.stringify(newPart));
    },
    async updatePart({ commit, getters }, part) {
      return connector.publish(
        `parts/${part.id}/update/`,
        JSON.stringify(part)
      );
    },
    async deleteCategory({ commit, rootGetters }, category) {
      return connector.publish(
        `partsCategories/${category.id}/delete/`,
        JSON.stringify(category)
      );
    },
    async createCategory({ commit, getters }, newCategory) {
      return connector.publish(
        'partsCategories/new/create/',
        JSON.stringify(newCategory)
      );
    },
    async updateCategory({ commit, getters }, category) {
      return connector.publish(
        `partsCategories/${category.id}/update/`,
        JSON.stringify(category)
      );
    },
    async archiveWorkCategory({ commit, rootGetters }, category) {
      return connector.publish(
        `workCategories/${category.id}/archive/`,
        JSON.stringify(category)
      );
    },
    async createWorkCategory({ commit, getters }, newCategory) {
      return connector.publish(
        'workCategories/new/create/',
        JSON.stringify(newCategory)
      );
    },
    async updateWorkCategory({ commit, getters }, category) {
      return connector.publish(
        `workCategories/${category.id}/update/`,
        JSON.stringify(category)
      );
    },
    async archiveWorkItem({ commit, rootGetters }, item) {
      return connector.publish(
        `workItems/${item.id}/archive/`,
        JSON.stringify(item)
      );
    },
    async createWorkItem({ commit, getters }, newItem) {
      return connector.publish(
        'workItems/new/create/',
        JSON.stringify(newItem)
      );
    },
    async updateWorkItem({ commit, getters }, item) {
      return connector.publish(
        `workItems/${item.id}/update/`,
        JSON.stringify(item)
      );
    },
    async updateFarmMarkup({ commit, getters }, { farmId, value }) {
      return connector.publish(`farm/${farmId}/markup/`, JSON.stringify(value));
    },
    async setFarmPriority({ commit, getters }, { farmId, value }) {
      return connector.publish(
        `farm/${farmId}/priority_service/`,
        JSON.stringify(value)
      );
    },
    async setFarmAttachments({ commit, getters }, { farmId, value }) {
      return connector.publish(
        `farm/${farmId}/attachments/`,
        JSON.stringify(value)
      );
    },
    async attachmentRepairLog({ commit, getters }, { repair_id, log }) {
      return connector.publish(
        `boardLogs/${repair_id}/attachments/`,
        JSON.stringify(log)
      );
    },
    async setFarmWebEval({ commit, getters }, { farmId, value }) {
      return connector.publish(
        `farm/${farmId}/web_eval/`,
        JSON.stringify(value)
      );
    },
    async setFarmPCEval({ commit, getters }, { farmId, value }) {
      return connector.publish(
        `farm/${farmId}/pc_eval/`,
        JSON.stringify(value)
      );
    },
    async setFarmWinEval({ commit, getters }, { farmId, value }) {
      return connector.publish(
        `farm/${farmId}/win_eval/`,
        JSON.stringify(value)
      );
    },
    async setFarmDropboxUrl({ commit, getters }, { farmId, value }) {
      return connector.publish(
        `farm/${farmId}/dropbox_url/`,
        JSON.stringify(value)
      );
    },
    async deletePond({ commit, rootGetters }, { farmId, pond }) {
      return connector.publish(
        `farm/${farmId}/ponds/${pond.id}/delete/`,
        JSON.stringify(pond)
      );
    },
    async createPond({ commit, getters }, { farmId, pond }) {
      return connector.publish(
        `farm/${farmId}/ponds/new/create/`,
        JSON.stringify(pond)
      );
    },
    async updatePond({ commit, getters }, { farmId, pond }) {
      return connector.publish(
        `farm/${farmId}/ponds/${pond.id}/update/`,
        JSON.stringify(pond)
      );
    },
    async setASMSnooze({ commit, getters }, pond) {
      return connector.publish(
        `farm/${pond.farmId}/ponds/${pond.id}/setASMSnooze/`,
        JSON.stringify(pond)
      );
    },
    async deleteComputer({ commit, rootGetters }, { farmId, computer }) {
      return connector.publish(
        `farm/${farmId}/computers/${computer.id}/delete/`,
        JSON.stringify(computer)
      );
    },
    async createComputer({ commit, getters }, { farmId, computer }) {
      return connector.publish(
        `farm/${farmId}/computers/new/create/`,
        JSON.stringify(computer)
      );
    },
    async updateComputer({ commit, getters }, { farmId, computer }) {
      return connector.publish(
        `farm/${farmId}/computers/${computer.id}/update/`,
        JSON.stringify(computer)
      );
    },
    async deleteRepeater({ commit, rootGetters }, { farmId, repeater }) {
      return connector.publish(
        `farm/${farmId}/repeaters/${repeater.id}/delete/`,
        JSON.stringify(repeater)
      );
    },
    async createRepeater({ commit, getters }, { farmId, repeater }) {
      return connector.publish(
        `farm/${farmId}/repeaters/new/create/`,
        JSON.stringify(repeater)
      );
    },
    async updateRepeater({ commit, getters }, { farmId, repeater }) {
      return connector.publish(
        `farm/${farmId}/repeaters/${repeater.id}/update/`,
        JSON.stringify(repeater)
      );
    },
    async generateAerwareKey({ commit, getters }, { computer, farmId }) {
      return connector.publish(
        `farm/${farmId}/computers/any/generate/`,
        JSON.stringify(computer)
      );
    },
    async customWorkLogs({ commit }, { dates }) {
      return connector.publish(`workLogs/custom/dates`, JSON.stringify(dates));
    },
    async deleteWorkLog({ commit, rootGetters }, { farmId, log }) {
      return connector.publish(
        `farm/${farmId}/logs/${log.id}/delete/`,
        JSON.stringify(log)
      );
    },
    async createWorkLog({ commit, getters }, { farmId, log }) {
      return connector.publish(
        `farm/${farmId}/logs/new/create/`,
        JSON.stringify(log)
      );
    },
    async updateWorkLog({ commit, getters }, { farmId, log }) {
      return connector.publish(
        `farm/${farmId}/logs/${log.id}/update/`,
        JSON.stringify(log)
      );
    },
    async commentWorkLog({ commit, getters }, { farmId, log }) {
      return connector.publish(
        `farm/${farmId}/logs/${log.id}/comment/`,
        JSON.stringify(log)
      );
    },
    async officeCommentWorkLog({ commit, getters }, { farmId, log }) {
      return connector.publish(
        `farm/${farmId}/logs/${log.id}/officeComment/`,
        JSON.stringify(log)
      );
    },
    async attachmentWorkLog({ commit, getters }, { farmId, log }) {
      return connector.publish(
        `farm/${farmId}/logs/${log.id}/attachment/`,
        JSON.stringify(log)
      );
    },
    async chatWorkLog({ commit, getters }, { farmId, log }) {
      return connector.publish(
        `farm/${farmId}/logs/${log.id}/chat/`,
        JSON.stringify(log)
      );
    },
    async deleteNote({ commit, rootGetters }, { farmId, note }) {
      return connector.publish(
        `farm/${farmId}/notes/${note.id}/delete/`,
        JSON.stringify(note)
      );
    },
    async createNote({ commit, getters }, { farmId, note }) {
      return connector.publish(
        `farm/${farmId}/notes/new/create/`,
        JSON.stringify(note)
      );
    },
    async updateNote({ commit, getters }, { farmId, note }) {
      return connector.publish(
        `farm/${farmId}/notes/${note.id}/update/`,
        JSON.stringify(note)
      );
    },
    async deleteTodo({ commit, rootGetters }, { todo }) {
      return connector.publish(
        `todos/${todo.id}/delete/`,
        JSON.stringify(todo)
      );
    },
    async createTodo({ commit, getters }, { todo }) {
      return connector.publish('todos/new/create/', JSON.stringify(todo));
    },
    async updateTodo({ commit, getters }, { todo }) {
      return connector.publish(
        `todos/${todo.id}/update/`,
        JSON.stringify(todo)
      );
    },
    async commentTodo({ commit, getters }, { todo }) {
      return connector.publish(
        `todos/${todo.id}/comment/`,
        JSON.stringify(todo)
      );
    },
    async chatTodo({ commit, getters }, { todo }) {
      return connector.publish(`todos/${todo.id}/chat/`, JSON.stringify(todo));
    },
    async updateInstallerAccess(
      { commit, getters },
      { userId, farmId, access }
    ) {
      return connector.publish(
        `userList/${userId}/${farmId}/access/`,
        JSON.stringify(access)
      );
    },
    async updateChatUser({ commit, getters }, { userId, isUpdate }) {
      return connector.publish(
        `userList/${userId}/chat/`,
        JSON.stringify(isUpdate)
      );
    },
    async updateMasterTag({ commit, getters }, { tag }) {
      return connector.publish(
        `tags/master/${tag.action}/`,
        JSON.stringify(tag)
      );
    },
    async updateTag({ commit, getters }, { tag }) {
      return connector.publish(`tags/farm/${tag.action}/`, JSON.stringify(tag));
    },
    async updateFarmLabel({ commit, getters }, { farmId, loc }) {
      return connector.publish(
        `farm/${farmId}/farm_labels/`,
        JSON.stringify(loc)
      );
    },
    async updateFarmLabelColor({ commit, getters }, { farmId, color }) {
      return connector.publish(
        `farm/${farmId}/farm_label_color/`,
        JSON.stringify(color)
      );
    },
    async updateRepeaterLines({ commit, getters }, { farmId, lines }) {
      return connector.publish(
        `farm/${farmId}/repeater_lines/`,
        JSON.stringify(lines)
      );
    },
    async updateEmails({ commit, getters }, { email }) {
      return connector.publish(`emails/update/`, JSON.stringify(email));
    },
    async printToZebra({ commit, getters }, { zebraId, zpl }) {
      return connector.publish(`zebraPrinters/${zebraId}/print/`, zpl);
    },
    setItemSearchId({ commit }, { itemSearchId }) {
      commit('SET_ITEM_SEARCH_ID', itemSearchId);
    },
    setEmailScrollTo({ commit }, scrollTo) {
      commit('SET_EMAIL_SCROLL_TO', scrollTo);
    },
    setWorkLogScrollTo({ commit }, workLogId) {
      commit('SET_WORK_LOG_SCROLL_TO', workLogId);
    },
    setScreenshotWorkLogId({ commit }, workLogId) {
      commit('SET_SCREENSHOT_WORKLOG_ID', workLogId);
    },
    setScreenshotBackToEmail({ commit }, goBack) {
      commit('SET_SCREENSHOT_BACK_TO_EMAIL', goBack);
    },
    setFarmScrollTo({ commit }, scrollTo) {
      commit('SET_FARM_SCROLL_TO', scrollTo);
    },
    setPondFilter({ commit }, pondId) {
      commit('SET_POND_FILTER', pondId);
    },
    setWorkOrderScrollTo({ commit }, scrollTo) {
      commit('SET_WORK_ORDER_SCROLL_TO', scrollTo);
    },
    setCreateNewWorkLog({ commit }, createNew) {
      commit('SET_CREATE_NEW_WORKLOG', createNew);
    },
    setQuickAddComplete({ commit }, complete) {
      commit('SET_QUICK_ADD_COMPLETE', complete);
    },
    setScreenshot({ commit }, screenshot) {
      commit('SET_SCREENSHOT', screenshot);
    },
    snoozeAllReminders({ commit }, snooze) {
      commit('SNOOZE_ALL_REMINDERS', snooze);
    },
    goToFarmOnMap({ commit }, farmId) {
      commit('SET_FARM_ON_MAP', farmId);
    }
  },
  modules: {},
  getters: {
    newVersionAvailable(state) {
      return state.newVersion !== state.appVersion;
    },
    workCategory: (state) => (id) => {
      return state.workCategories.find((cat) => cat.id === id);
    },
    workItem: (state) => (id) => {
      return state.workItems.find((item) => item.id === id);
    },
    filteredFarms(state) {
      if (state.user.aerworx_level === 'super-admin') {
        return state.farmList;
      } else {
        return state.farmList.filter((e) => {
          return state.user.farms.find((f) => f === e.id);
        });
      }
    },
    isSuperAdmin(state) {
      return state.user.aerworx_level === 'super-admin';
    }
  },
  plugins: [networkPlug()]
});
