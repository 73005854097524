<template>
  <div>
    <q-card
      class="row justify-center items-center"
      :class="$q.screen.gt.xs ? 'q-pa-xl' : 'q-pa-md'"
    >
      <div :style="$q.screen.gt.xs ? 'width: 420px;' : 'width: 288px'">
        <div v-if="selectDateNotice" class="text-blue-14">
          Please Select Date Range
        </div>
        <div>
          <q-date
            flat
            bordered
            :minimal="$q.screen.xs"
            text-color="white"
            v-model="dateRange"
            color="blue-14"
            range
            :landscape="$q.screen.gt.xs"
            @input="dateChange"
          />
        </div>

        <q-card
          flat
          bordered
          class="row justify-center q-pa-sm q-mt-md"
          :style="$q.screen.gt.xs ? 'width: 420px;' : 'width: 288px'"
        >
          <div class="col-12 text-body1 text-bold text-center">
            Quick Select
            <q-separator class="q-mx-sm" />
          </div>
          <div class="row">
            <div class="row col-sm-6 col-xs-12">
              <q-radio
                dense
                v-model="dateRangeSelected"
                val="prev7"
                label="Previous 7 Days"
                color="blue-14"
                class="full-width q-pl-sm"
                :class="$q.screen.gt.xs ? 'q-mt-md' : 'q-mt-xs'"
                @input="setDateRanges"
              />
              <q-radio
                dense
                v-model="dateRangeSelected"
                val="prev30"
                label="Previous 30 Days"
                color="blue-14"
                class="full-width q-pl-sm"
                :class="$q.screen.gt.xs ? 'q-mt-md' : 'q-mt-xs'"
                @input="setDateRanges"
              />
              <q-radio
                dense
                v-model="dateRangeSelected"
                val="prev365"
                label="Previous 365 Days"
                color="blue-14"
                class="full-width q-pl-sm"
                :class="$q.screen.gt.xs ? 'q-mt-md' : 'q-mt-xs'"
                @input="setDateRanges"
              />
              <q-radio
                dense
                v-model="dateRangeSelected"
                val="currMo"
                label="Current Month"
                color="blue-14"
                class="full-width q-pl-sm"
                :class="$q.screen.gt.xs ? 'q-mt-md' : 'q-mt-xs'"
                @input="setDateRanges"
              />
            </div>
            <div class="row col-sm-6 col-xs-12">
              <q-radio
                dense
                v-model="dateRangeSelected"
                val="currYear"
                label="Current Year"
                color="blue-14"
                class="full-width q-pl-sm"
                :class="$q.screen.gt.xs ? 'q-mt-md' : 'q-mt-xs'"
                @input="setDateRanges"
              />
              <q-radio
                dense
                v-model="dateRangeSelected"
                val="LCM"
                label="Last Calendar Month"
                color="blue-14"
                class="full-width q-pl-sm"
                :class="$q.screen.gt.xs ? 'q-mt-md' : 'q-mt-xs'"
                @input="setDateRanges"
              />
              <q-radio
                dense
                v-model="dateRangeSelected"
                val="LCY"
                label="Last Calendar Year"
                color="blue-14"
                class="full-width q-pl-sm"
                :class="$q.screen.gt.xs ? 'q-mt-md' : 'q-mt-xs'"
                @input="setDateRanges"
              />
              <q-radio
                v-if="this.allowAllDates"
                dense
                v-model="dateRangeSelected"
                val="all"
                label="All Dates"
                color="blue-14"
                class="full-width q-pl-sm"
                :class="$q.screen.gt.xs ? 'q-mt-md' : 'q-mt-xs'"
                @input="setDateRanges"
              />
              <q-radio
                v-if="!this.allowAllDates"
                dense
                v-model="dateRangeSelected"
                val="default"
                label="Default (Previous 6 Mo)"
                color="blue-14"
                class="full-width q-pl-sm"
                :class="$q.screen.gt.xs ? 'q-mt-md' : 'q-mt-xs'"
                @input="setDateRanges"
              />
            </div>
          </div>
        </q-card>

        <div class="row full-width q-mt-md">
          <q-space />
          <q-btn
            flat
            icon="clear"
            label="Cancel"
            color="blue-14"
            class="q-mr-sm"
            v-close-popup
          />
          <q-btn
            icon="event_available"
            label="Set Dates"
            color="blue-14"
            @click="selectDates()"
          />
        </div>
      </div>
    </q-card>
  </div>
</template>

<script>
import {
  decoder,
  chatInfo,
  trimName,
  cloneObj,
  getReminderStatus
} from '@/lib/helpers';
import { parseTimeStamp } from '@/lib/date-utils';
import store from '@/store';
import { colors, date, exportFile } from 'quasar';
import xl from 'excel4node';
import image from '@/assets/pdf/AerWorx_logo_pdf.png';
import { todoOptions } from '@/lib/static-data';

export default {
  name: 'DatePicker',
  props: {
    allowAllDates: {
      type: Boolean,
      required: true
    },
    dateRangeSelection: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      dateRange: {
        from: '',
        to: ''
      },
      dateRangeSelected: this.dateRangeSelection,
      selectDateNotice: false
    };
  },
  mounted() {
    this.setDateRanges();
  },
  methods: {
    selectDates() {
      if (this.dateRangeSelected === 'all') {
        const dateRange = {
          to: '',
          from: '',
          dateRangeSelected: this.dateRangeSelected
        };

        this.$emit('datesSelected', dateRange);
        return;
      }

      if (!this.dateRange?.to || !this.dateRange?.from) {
        this.selectDateNotice = true;
        return;
      }

      const fromValid = this.isValidDate(this.dateRange.from);
      const toValid = this.isValidDate(this.dateRange.to);

      if (!(fromValid && toValid)) {
        this.selectDateNotice = true;
        return;
      }

      const dateRange = {
        to: this.dateRange.to,
        from: this.dateRange.from,
        dateRangeSelected: this.dateRangeSelected
      };

      this.$emit('datesSelected', dateRange);
    },
    setDateRanges() {
      if (this.dateRangeSelected === 'all') {
        this.dateRange = '';
      } else if (this.dateRangeSelected === 'default') {
        const to = date.formatDate(new Date(), 'YYYY/MM/DD');
        const from = date.formatDate(
          date.subtractFromDate(new Date(to), { days: 182 }),
          'YYYY/MM/DD'
        );
        this.dateRange = { from, to };
      } else if (this.dateRangeSelected === 'prev7') {
        const to = date.formatDate(new Date(), 'YYYY/MM/DD');
        const from = date.formatDate(
          date.subtractFromDate(new Date(to), { days: 6 }),
          'YYYY/MM/DD'
        );
        this.dateRange = { from, to };
      } else if (this.dateRangeSelected === 'prev30') {
        const to = date.formatDate(new Date(), 'YYYY/MM/DD');
        const from = date.formatDate(
          date.subtractFromDate(new Date(to), { days: 29 }),
          'YYYY/MM/DD'
        );
        this.dateRange = { from, to };
      } else if (this.dateRangeSelected === 'prev365') {
        const to = date.formatDate(new Date(), 'YYYY/MM/DD');
        const from = date.formatDate(
          date.subtractFromDate(new Date(to), { days: 364 }),
          'YYYY/MM/DD'
        );
        this.dateRange = { from, to };
      } else if (this.dateRangeSelected === 'currMo') {
        const thisMo = date.endOfDate(new Date(), 'month');
        const thisMoStart = date.startOfDate(thisMo, 'month');
        const to = date.formatDate(thisMo, 'YYYY/MM/DD');
        const from = date.formatDate(thisMoStart, 'YYYY/MM/DD');
        this.dateRange = { from, to };
      } else if (this.dateRangeSelected === 'currYear') {
        const thisYear = date.endOfDate(new Date(), 'year');
        const thisYearStart = date.startOfDate(thisYear, 'year');
        const to = date.formatDate(thisYear, 'YYYY/MM/DD');
        const from = date.formatDate(thisYearStart, 'YYYY/MM/DD');
        this.dateRange = { from, to };
      } else if (this.dateRangeSelected === 'LCM') {
        const lastMo = date.subtractFromDate(new Date(), { months: 1 });
        const lastMoStart = date.startOfDate(lastMo, 'month');
        const lastMoEnd = date.endOfDate(lastMo, 'month');
        const to = date.formatDate(lastMoEnd, 'YYYY/MM/DD');
        const from = date.formatDate(lastMoStart, 'YYYY/MM/DD');
        this.dateRange = { from, to };
      } else if (this.dateRangeSelected === 'LCY') {
        const lastYear = date.subtractFromDate(new Date(), { years: 1 });
        const lastYearStart = date.startOfDate(lastYear, 'year');
        const lastYearEnd = date.endOfDate(lastYear, 'year');
        const to = date.formatDate(lastYearEnd, 'YYYY/MM/DD');
        const from = date.formatDate(lastYearStart, 'YYYY/MM/DD');
        this.dateRange = { from, to };
      }

      this.selectDateNotice = false;
    },
    dateChange() {
      this.dateRangeSelected = '';
    },
    isValidDate(d) {
      let invalidDate = false;
      const dateArr = d.split('/');

      if (dateArr.length < 3) {
        return false;
      }

      if (dateArr[0].length !== 4) {
        invalidDate = true;
      }
      if (dateArr[1].length !== 2) {
        invalidDate = true;
      }
      if (dateArr[2].length !== 2) {
        invalidDate = true;
      }

      const valDate = date.isValid(new Date(d));
      if (!valDate) {
        invalidDate = true;
      }

      return !invalidDate;
    }
  }
};
</script>

<style scoped></style>
