import { render, staticRenderFns } from "./WorkOrders.vue?vue&type=template&id=d1dd59f2&scoped=true"
import script from "./WorkOrders.vue?vue&type=script&lang=js"
export * from "./WorkOrders.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d1dd59f2",
  null
  
)

export default component.exports
import QLayout from 'quasar/src/components/layout/QLayout.js';
import QHeader from 'quasar/src/components/header/QHeader.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QMenu from 'quasar/src/components/menu/QMenu.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QResizeObserver from 'quasar/src/components/resize-observer/QResizeObserver.js';
import QPageContainer from 'quasar/src/components/page/QPageContainer.js';
import QPage from 'quasar/src/components/page/QPage.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QLayout,QHeader,QBtn,QMenu,QCard,QSeparator,QResizeObserver,QPageContainer,QPage});
