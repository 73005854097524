<template>
  <q-card style="width: 600px">
    <div class="row items-center q-py-md q-px-lg">
      <q-space />
      <div class="text-h6 text-primary">Farm Search</div>
      <q-space />

      <q-btn icon="clear" color="primary" v-close-popup />
    </div>
    <q-separator color="primary" />

    <div class="q-py-lg q-px-lg">
      <q-input
        outlined
        autofocus
        v-model="searchStr"
        label="Enter Farm"
        color="primary"
        @input="filterFarms()"
      />
    </div>
    <q-separator color="primary" />

    <q-scroll-area
      visible
      :thumb-style="thumbStyle"
      :bar-style="barStyle"
      :style="`height: ${scrollHeight}px`"
      style="padding-right: 22px"
    >
      <div
        v-for="farm in farmsFiltered"
        :key="farm.name"
        @click="$emit('farmSelected', farm)"
        class="row"
      >
        <div class="col q-px-md q-py-sm">
          <div class="row items-center q-py-xs">
            <div
              class="row items-center"
              :class="$q.screen.xs && 'col-12 text-subtitle1 q-pb-sm'"
            >
              <q-avatar
                :size="$q.screen.xs ? 'md' : 'lg'"
                color="primary"
                text-color="white"
              >
                {{ farm.name.slice(0, 1) }}
              </q-avatar>

              <div class="q-pl-md">
                {{ farm.name }}
              </div>
            </div>

            <q-space v-if="$q.screen.gt.xs" />

            <div
              class="col-xs-12 col-sm-auto row"
              :class="$q.screen.xs && 'justify-end'"
            >
              <div>
                <q-btn
                  :color="$q.dark.isActive ? 'grey-9' : 'grey-4'"
                  text-color="$q.dark.isActive ? 'white' : 'black'"
                  label="worklogs"
                  class="col q-mr-xs"
                  @click="viewWorkLogs(farm)"
                />
                <q-btn
                  :color="$q.dark.isActive ? 'grey-9' : 'grey-4'"
                  text-color="$q.dark.isActive ? 'white' : 'black'"
                  icon="add"
                  label="new"
                  class="col q-ml-xs"
                  @click="createNewWorkLog(farm)"
                />
              </div>
            </div>
          </div>
        </div>
        <q-separator vertical />
        <q-separator class="full-width" />
      </div>
    </q-scroll-area>
  </q-card>
</template>

<script>
import { decoder } from '@/lib/helpers';
import store from '@/store';

export default {
  name: 'QuckAdd',
  data() {
    return {
      farms: [],
      farmsFiltered: [],
      searchStr: '',
      thumbStyle: {
        right: '4px',
        borderRadius: '5px',
        backgroundColor: '#bdbdbd',
        width: '14px',
        opacity: 0.75
      },
      barStyle: {
        right: '2px',
        borderRadius: '9px',
        backgroundColor: '#fff',
        width: '9px',
        opacity: 0.2
      }
    };
  },
  mounted() {
    this.setFarms();
  },
  methods: {
    createNewWorkLog(farm) {
      const currFarmId = this.$store.state.farm.id;

      if (currFarmId >= 0) {
        store.dispatch('unSubscribeFarm', currFarmId);
        store.dispatch('subscribeFarm', farm.id);
      }

      this.$emit('farmSelected');
      store.dispatch('setCreateNewWorkLog', true);
      store.dispatch('setQuickAddComplete', false);

      this.$router.push(`/farm/${farm.id}/work/`);
    },
    filterFarms() {
      this.farmsFiltered.splice(0);

      const needle = this.searchStr.toLowerCase();

      for (const farm of this.farms) {
        if (farm.name.toLowerCase().includes(needle)) {
          this.farmsFiltered.push(farm);
        }
      }
    },
    setFarms() {
      const farms = JSON.parse(localStorage.getItem('farms'));
      for (const farm of farms) {
        const farmName = decoder(farm.name);
        this.farms.push({
          name: farmName,
          id: farm.id
        });
        this.farmsFiltered.push({
          name: farmName,
          id: farm.id
        });
      }
    },
    viewWorkLogs(farm) {
      const currFarmId = this.$store.state.farm.id;

      if (currFarmId >= 0) {
        store.dispatch('unSubscribeFarm', currFarmId);
        store.dispatch('subscribeFarm', farm.id);
      }

      this.$emit('farmSelected');
      this.$router.push(`/farm/${farm.id}/work/`);
    }
  },
  computed: {
    scrollHeight() {
      const listHeight = this.$q.screen.xs
        ? this.farmsFiltered.length * 100
        : this.farmsFiltered.length * 63;
      const availHeight = window.innerHeight - 230;

      return listHeight < availHeight ? listHeight : availHeight;
    }
  }
};
</script>

<style scoped></style>
