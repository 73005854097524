<template>
  <q-card style="width: 400px">
    <div class="row items-center justify-between text-subtitle1 q-pa-md">
      <div style="width: 56px"></div>
      <div class="row flex-center">
        <div class="q-pr-md">
          <q-icon name="autorenew" size="md" color="green-5" />
        </div>
        <div class="text-h6">Windows Updates</div>
      </div>
      <q-btn unelevated icon="clear" color="primary" v-close-popup />
    </div>
    <q-separator />

    <div class="col text-center text-h6 ellipsis q-py-xs">
      {{ decoder(farm.farm_name) }}
    </div>
    <q-separator />

    <div>
      <div class="q-px-md">
        <div class="text-center text-subtitle1 q-py-sm">
          Evaluation Frequency (Days)
        </div>
        <div class="q-pt-lg">
          <q-slider
            v-model="evalFrequencyDays"
            :min="0"
            :max="62"
            :step="1"
            label
            label-always
            :label-value="getLabelValue(evalFrequencyDays)"
            track-size="7px"
            thumb-size="25px"
            :color="notifyOnExpiration ? 'red-14' : 'primary'"
          />
        </div>
      </div>
      <q-separator />

      <div class="row justify-center">
        <q-checkbox
          v-model="notifyOnExpiration"
          label="Red Icon on Expiration"
          color="red-14"
          @input="setRedIcon"
        />
      </div>
      <q-separator />

      <div v-if="farm.winEval.evalNote" class="q-py-xs q-px-md">
        <span class="text-bold text-primary"> Note: </span>
        {{ farm.winEval.evalNote }}
      </div>
      <q-separator v-if="farm.winEval.evalNote" />

      <div
        class="scroll q-px-md q-pb-md"
        :style="`max-height: ${store.state.pageHeight - 400}px`"
      >
        <div class="text-center text-subtitle1 q-py-sm">Evaluation History</div>
        <div
          v-for="winEval in farm.winEval?.evalHistoryReversed ?? []"
          :key="winEval.evalId"
        >
          <div class="row items-center justify-between">
            <div class="row items-center">
              <div>
                {{ winEval.user }}
              </div>
              <div v-if="winEval.workLog" class="q-pl-sm">
                <q-btn
                  unelevated
                  icon="assignment_turned_in"
                  color="primary"
                  size="sm"
                  padding="3px 5px"
                  @click="goToWL(winEval.workLog)"
                />
              </div>
            </div>
            <div class="row items-center q-py-xs">
              <div class="q-pr-sm">
                {{ date.formatDate(winEval.date, 'MM/DD/YY') }}
              </div>
              <div class="q-pr-xs">
                <q-btn
                  unelevated
                  icon="sticky_note_2"
                  color="grey-3"
                  text-color="primary"
                  size="sm"
                  padding="3px 5px"
                  @click="editWinEvalNote(winEval.evalId)"
                />
              </div>
              <div>
                <q-btn
                  unelevated
                  icon="delete"
                  color="grey-3"
                  text-color="red-14"
                  size="sm"
                  padding="3px 5px"
                  @click="winEvalDelete(winEval.evalId)"
                />
              </div>
            </div>
          </div>
          <q-separator />
        </div>
      </div>

      <q-separator />
      <div class="q-pa-md">
        <div class="row items-center justify-end">
          <q-btn
            unelevated
            label="New Worklog"
            color="grey-3"
            text-color="black"
            class="q-mr-sm"
            @click="addLog"
          />
          <q-btn
            unelevated
            label="Mark Evaluated"
            color="primary"
            @click="winEvalSet"
          />
        </div>
      </div>
    </div>

    <!-- Edit Worklog Dialog -->
    <q-dialog position="top" v-model="dialogEditWorklog" persistent>
      <WorkLogEdit
        :farmId="selectedWorkLog.farm_id"
        :workLog="selectedWorkLog"
        @cancel="dialogEditWorklog = false"
        @submitted="goToFarm"
      />
    </q-dialog>

    <!-- Eval Note Dialog -->
    <q-dialog position="top" v-model="dialogEvalNote" persistent>
      <q-card style="width: 400px">
        <div class="row items-center q-pa-md">
          <div class="col text-center text-h6">Windows Update Note</div>
          <div>
            <q-btn unelevated icon="clear" color="primary" v-close-popup />
          </div>
        </div>
        <q-separator />

        <div class="q-pa-md">
          <q-input outlined v-model="evalNote" label="Eval Note" autogrow />
        </div>
        <q-separator />

        <div class="row justify-end q-pa-md">
          <q-btn
            label="Cancel"
            color="grey-2"
            text-color="black"
            class="q-mr-sm"
            @click="dialogEvalNote = false"
          />
          <q-btn label="Submit" color="primary" @click="submitEvalNote" />
        </div>
      </q-card>
    </q-dialog>
  </q-card>
</template>

<script>
import WorkLogEdit from '@/components/workLogs/WorkLogEdit';

import store from '@/store';
import { date } from 'quasar';
import { cloneObj, decoder } from '@/lib/helpers';

export default {
  name: 'WinEval',
  components: {
    WorkLogEdit
  },
  props: {
    farm: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      date,
      decoder,
      dialogEditWorklog: false,
      dialogEvalNote: false,
      evalFrequencyDays: this.farm.win_eval?.evalFrequencyDays ?? 30,
      evalNote: '',
      evalNoteId: null,
      notifyOnExpiration: this.farm.win_eval?.notifyOnExpiration ?? false,
      selectedWorkLog: {},
      store
    };
  },
  mounted() {},
  methods: {
    addLog() {
      this.selectedWorkLog = {
        id: 'new',
        todo_flag: 0,
        todo_complete: null,
        todo_complete_by: null,
        attachments: [],
        comments: [],
        office_comments: '',
        chats: [],
        last_chat_date: null,
        reminders: [],
        farm_id: this.farm.id,
        location_id: null,
        work_item: 138,
        work_cat: 13,
        pond_id: null,
        computer_id: null,
        notes: '',
        office_notes: '',
        time: 0,
        assigned_to: null,
        date: date.formatDate(date.startOfDate(new Date(), 'day'), 'X'),
        field_values: {},
        billed: 0,
        billable: false,
        billed_changed_by: null,
        billed_changed_on: null
      };

      this.dialogEditWorklog = true;
    },
    editWinEvalNote(evalId) {
      this.evalNote = this.farm.win_eval?.evalHistory.find(
        (x) => x.evalId === evalId
      )?.note;

      this.evalNoteId = evalId;
      this.dialogEvalNote = true;
    },
    getLabelValue(val) {
      if (val === 0) {
        return '1 hr';
      }

      if (val <= 60) {
        return val;
      }

      return val === 61 ? 90 : 180;
    },
    goToWL(workLog) {
      const currentRoute = this.$router.currentRoute;
      if (currentRoute.name === 'Farm-Work') {
        this.$store.dispatch('setWorkLogScrollTo', workLog.workLogId);

        this.$emit('goToWL', workLog);
        this.$emit('goToFarm', this.farm);
        return;
      }

      this.$store.dispatch('setWorkLogScrollTo', workLog.workLogId);
      this.$emit('goToFarm', this.farm);
    },
    goToFarm() {
      this.dialogEditWorklog = false;
      this.$emit('goToFarm', this.farm);
    },
    openInNewTab(url) {
      window.open(url);
    },
    setRedIcon() {
      // this is in cases where the user marks evaluated,
      // but then changes the frequency to 0 and checks the notify box
      // this will properly set the date on the last eval
      // I wish there was a more elegant way to do this
      if (this.evalFrequencyDays === 0) {
        const lastEval =
          this.farm.win_eval?.evalHistory[
            this.farm.win_eval?.evalHistory?.length - 1 ?? 0
          ] ?? null;

        if (lastEval) {
          lastEval.date = lastEval.evalId;
        }
      }

      this.winEvalDispatch(this.farm.win_eval?.evalHistory ?? []);
    },
    winEvalDelete(evalId) {
      this.$q
        .dialog({
          title: 'Delete Evaluation',
          message: 'Are you sure you want to delete this evaluation?',
          ok: {
            color: 'red-14',
            icon: 'delete',
            label: 'Delete'
          },
          cancel: {
            color: 'grey-1',
            textColor: 'primary',
            label: 'Cancel'
          },
          focus: 'none'
        })
        .onOk(() => {
          this.winEvalDeleteConfirmed(evalId);
        });
    },
    winEvalDeleteConfirmed(evalId) {
      let evalHistory = this.farm.win_eval?.evalHistory ?? [];

      evalHistory = evalHistory.filter((x) => x.evalId !== evalId);

      this.winEvalDispatch(evalHistory);
    },
    winEvalDispatch(evalHistory) {
      const winEval = {
        evalFrequencyDays: this.evalFrequencyDays,
        notifyOnExpiration: this.notifyOnExpiration,
        evalHistory
      };

      store
        .dispatch('setFarmWinEval', {
          value: winEval,
          farmId: this.farm.id
        })
        .then((results) => {
          this.$finishResult.handleResultsAsync(results);
        });
    },
    winEvalSet() {
      let evalHistory = this.farm.win_eval?.evalHistory ?? [];

      const yearAgo = +new Date() - 31536000000;
      evalHistory = evalHistory.filter((x) => x.date > yearAgo);

      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const now = +new Date();

      const newEval = {
        user: this.$store.state.user.display_name,
        evalId: now,
        date: this.evalFrequencyDays === 0 ? now : +today
      };
      evalHistory.push(newEval);

      this.winEvalDispatch(evalHistory);
    },
    submitEvalNote() {
      const evalHistory = cloneObj(this.farm.win_eval?.evalHistory) ?? [];

      const evalIndex = evalHistory.findIndex(
        (x) => x.evalId === this.evalNoteId
      );
      if (evalIndex > -1) {
        evalHistory[evalIndex].note = this.evalNote;
      }

      this.winEvalDispatch(evalHistory);
      this.dialogEvalNote = false;
    }
  }
};
</script>

<style scoped></style>
