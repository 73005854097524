<template>
  <div style="position: relative; z-index: 999999">
    <q-page-sticky position="top-right" :offset="fabPos">
      <div
        style="width: 250px"
        class="row items-center rounded-borders pinned"
        :class="$q.screen.xs ? 'q-pa-sm' : 'q-pa-md'"
        :disable="draggingFab"
        v-touch-pan.prevent.mouse="moveFab"
      >
        <div style="width: 184px" class="ellipsis q-pr-sm">
          <span class="text-white">
            {{ farmName(reminder.farm_id) }}
          </span>
        </div>
        <q-btn
          outline
          icon="menu_open"
          text-color="white"
          size="sm"
          padding="xs sm"
          @click="$emit('reminderManage', reminder)"
        />
      </div>
    </q-page-sticky>
  </div>
</template>

<script>
import { decoder } from '@/lib/helpers';

export default {
  name: 'ReminderPin',
  props: {
    reminder: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      // offset stacked pins if more than one
      fabPos: [18 + this.index * 7, 18 + this.index * 7],
      draggingFab: false
    };
  },
  methods: {
    moveFab(ev) {
      this.draggingFab = ev.isFirst !== true && ev.isFinal !== true;

      this.fabPos = [this.fabPos[0] - ev.delta.x, this.fabPos[1] + ev.delta.y];
    },
    farmName(id) {
      if (id === null) {
        return '--';
      }
      const farm = this.$store.state.farmList.find(
        (element) => element.id === id
      );
      return farm ? decoder(farm.farm_name) : '---';
    },
    farmNameOnly(id) {
      const name = this.farmName(id);
      return name.startsWith('--') ? '' : name;
    },
    setFab() {
      this.fabPos = [18 + this.index * 7, 18 + this.index * 7];
    }
  },
  watch: {
    index() {
      // This seems to be necessary - with todos and workLogs coming in at different times
      this.setFab();
    }
  }
};
</script>

<style scoped>
.pinned {
  background: linear-gradient(-90deg, #4a148c, #6a1b9a, #311b92, #1a237e);
  background-size: 400% 400%;
  animation: gradient 30s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
</style>
