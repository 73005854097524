import axios from 'axios';

export default {
  doKeepAlive() {
    return axios.get('/api/keepalive?' + Date.now()).then((response) => {
      return response.data;
    });
    // handle errors elsewhere
  }
};
