import store from '@/store';
import { cloneObj } from '@/lib/helpers';

const searchHelper = (searchStr, worklogs, resultArr) => {
  // mutates resultArr

  for (let i = 0; i < worklogs.length; i++) {
    const workLog = cloneObj(worklogs[i]);
    if (workLog.notes.toLowerCase().includes(searchStr)) {
      resultArr.push({
        type: 'Work Log Note',
        date: workLog.date,
        farm: workLog.farm_id,
        data: workLog.notes,
        id: workLog.id,
        url: `farm/${workLog.farm_id}/work`
      });
    }

    for (let j = 0; j < workLog.comments.length; j++) {
      const comment = workLog.comments[j];
      if (comment.comment.toLowerCase().includes(searchStr)) {
        resultArr.push({
          type: 'Work Log Comment',
          date: workLog.date,
          farm: workLog.farm_id,
          data: comment.comment,
          id: workLog.id,
          url: `farm/${workLog.farm_id}/work`
        });
      }
    }

    if (workLog.office_notes.toLowerCase().includes(searchStr)) {
      resultArr.push({
        type: 'Work Log Office Note',
        date: workLog.date,
        farm: workLog.farm_id,
        data: workLog.office_notes,
        id: workLog.id,
        url: `farm/${workLog.farm_id}/work`
      });
    }

    for (let j = 0; j < workLog.office_comments.length; j++) {
      const comment = workLog.office_comments[j];
      if (comment.comment.toLowerCase().includes(searchStr)) {
        resultArr.push({
          type: 'Work Log Office Comment',
          date: workLog.date,
          farm: workLog.farm_id,
          data: comment.comment,
          id: workLog.id,
          url: `farm/${workLog.farm_id}/work`
        });
      }
    }

    for (let j = 0; j < workLog.chats.length; j++) {
      const chat = workLog.chats[j];
      if (chat.comment.toLowerCase().includes(searchStr)) {
        resultArr.push({
          type: 'Work Log Chat',
          date: workLog.date,
          farm: workLog.farm_id,
          data: chat.comment,
          id: workLog.id,
          url: `farm/${workLog.farm_id}/work`
        });
      }
    }

    for (const key of Object.keys(workLog.field_values)) {
      const fieldVal = workLog.field_values[key];

      if (fieldVal.toString().toLowerCase().includes(searchStr)) {
        const workItem = store.state.workItems.find(
          (x) => x.id === workLog.work_item
        );
        if (workItem.type === 'fields') {
          resultArr.push({
            type: 'Work Log Item',
            date: workLog.date,
            farm: workLog.farm_id,
            data: workItem.name + ': ' + fieldVal,
            id: workLog.id,
            url: `farm/${workLog.farm_id}/work`
          });
        }
      }
    }
  }
};

export { searchHelper };
