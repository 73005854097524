import { render, staticRenderFns } from "./UserFarms.vue?vue&type=template&id=4f490ed4&scoped=true"
import script from "./UserFarms.vue?vue&type=script&lang=js"
export * from "./UserFarms.vue?vue&type=script&lang=js"
import style0 from "./UserFarms.vue?vue&type=style&index=0&id=4f490ed4&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f490ed4",
  null
  
)

export default component.exports
import QTable from 'quasar/src/components/table/QTable.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QToggle from 'quasar/src/components/toggle/QToggle.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QTable,QInput,QIcon,QSpace,QTd,QToggle});
