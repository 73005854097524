<template>
  <div style="padding-top: 2px; padding-left: 2px; padding-right: 2px">
    <div v-if="scannerOpen" class="row justify-center full-width">
      <video id="readerVideo" style="width: 300px"></video>
      <div class="col-12 spaced">
        <q-btn
          class="full-width"
          label="Cancel QR"
          color="secondary"
          @click="cancelQR"
        />
      </div>
    </div>
    <q-tabs
      v-model="typeId"
      align="justify"
      dense
      no-caps
      mobile-arrows
      class="shadow-2"
    >
      <q-tab
        v-for="(type, index) in boardTypes"
        :key="index"
        class="custom-wrap"
        :name="type"
        :label="type"
      />
    </q-tabs>
    <q-table
      title="Boards"
      :data="Boards"
      :columns="columns"
      :pagination="pagination"
      row-key="name"
      dense
      no-data-label="No Boards"
    >
      <template v-slot:top>
        <div class="row justify-center full-width q-pa-md">
          <!-- Filtering -->
          <div class="q-gutter-md row">
            <template v-if="showPrintOpt">
              <div>
                <q-btn
                  :disable="!selectedZebraOnline"
                  outline
                  color="blue-14"
                  padding="8px 10px"
                  @click="printSelectedZebra()"
                >
                  <img
                    v-if="$q.dark.isActive"
                    width="24px"
                    height="24px"
                    src="/img/ZBRA.D.png"
                  />
                  <img
                    v-if="!$q.dark.isActive"
                    width="24px"
                    height="24px"
                    src="/img/ZBRA.png"
                  />
                </q-btn>
              </div>
              <div>
                <q-btn
                  outline
                  color="blue-14"
                  padding="8px 10px"
                  icon="print"
                  @click="printSelected()"
                />
              </div>
              <div style="width: 150px">
                <q-select
                  v-model="labelSelection"
                  dense
                  filled
                  :options="['Avery 27950', 'Avery 6427']"
                  emit-value
                  map-options
                  label="Label selection"
                />
              </div>
            </template>
            <div>
              <q-input
                v-model="quickJump"
                filled
                dense
                type="tel"
                style="width: 120px"
                @keydown.enter.prevent="jumpToBoard"
              >
                <template v-slot:append>
                  <q-btn
                    padding="3px 3px"
                    icon="search"
                    color="blue-14"
                    outline
                    @click="jumpToBoard"
                  />
                </template>
              </q-input>
            </div>
            <div>
              <q-btn
                padding="8px 8px"
                icon="qr_code"
                class="q-mx-sm"
                color="primary"
                @click="scanQR"
              />
            </div>
          </div>
        </div>
      </template>
      <template v-slot:header-cell-PrintOpt="props">
        <q-th :props="props">
          <q-icon name="print" size="1.5em" />
        </q-th>
      </template>
      <template v-slot:body-cell-PrintOpt="props">
        <q-td :props="props">
          <q-checkbox
            for="printing"
            size="lg"
            val="sm"
            :value="isPrinting(props.row.id)"
            @input="setPrinting(props.row.id)"
          />
        </q-td>
      </template>
      <template v-if="selectedZebraOnline" v-slot:body-cell-ZebraOpt="props">
        <q-td :props="props">
          <q-btn
            class="btn-table-primary"
            outline
            size="md"
            padding="xs"
            @click="printRowToZebra(props.row)"
          >
            <img
              v-if="$q.dark.isActive"
              width="24px"
              height="24px"
              src="/img/ZBRA.D.png"
            />
            <img
              v-if="!$q.dark.isActive"
              width="24px"
              height="24px"
              src="/img/ZBRA.png"
            />
          </q-btn>
        </q-td>
      </template>
      <template v-slot:body-cell-Link="props">
        <q-td :props="props">
          <q-btn
            class="btn-table-primary"
            outline
            icon="arrow_forward"
            size="md"
            padding="xs"
            @click="navigateTo(props.row)"
          >
          </q-btn>
        </q-td>
      </template>
      <template v-slot:body-cell-Edit="props">
        <q-td :props="props">
          <q-btn
            class="btn-table-primary"
            outline
            icon="edit"
            size="md"
            padding="xs"
            @click="editBoard(props.row)"
          >
          </q-btn>
        </q-td>
      </template>
      <template v-slot:body-cell-Delete="props">
        <q-td :props="props">
          <q-btn
            class="btn-table-negative"
            outline
            icon="delete"
            size="md"
            padding="xs"
            @click="deleteBoard(props.row)"
          >
          </q-btn>
        </q-td>
      </template>
    </q-table>
    <q-page-sticky position="bottom-right" :offset="[96, 35]">
      <q-btn fab icon="add" color="accent" @click="addBoard()" />
    </q-page-sticky>
    <!-- edit modal -->
    <q-dialog v-model="showEditModal" persistent class="">
      <q-card square bordered class="q-pa-lg card-size">
        <q-card-section class="">
          <q-form @submit="onSubmit" class="q-gutter-sm">
            <q-toolbar class="shadow-2">
              <q-toolbar-title class="row justify-center items-center">
                <q-icon name="handyman" size="sm" class="q-mr-sm" />
                {{ ModalHeader }}
              </q-toolbar-title>
            </q-toolbar>
            <q-input
              filled
              v-model.number="editBoardCopy.id"
              type="number"
              label="Board ID"
              outlined
              square
            />
            <q-select
              v-model="editBoardCopy.type"
              square
              filled
              :options="boardTypes"
              emit-value
              map-options
              label="Type"
            />
            <q-input
              filled
              v-model="editBoardCopy.rev"
              label="Rev"
              outlined
              square
            />
            <q-input
              filled
              v-model="editBoardCopy.note"
              label="Description"
              outlined
              square
            />
            <div>
              <div class="row full-width">
                <div class="col spaced">
                  <q-btn
                    outline
                    class="full-width"
                    label="Cancel"
                    color="secondary"
                    @click="cancelEdit"
                  />
                </div>
                <div class="col spaced">
                  <q-btn
                    outline
                    class="full-width"
                    label="Submit"
                    type="submit"
                    color="primary"
                  />
                </div>
              </div>
            </div>
          </q-form>
        </q-card-section>
      </q-card>
    </q-dialog>
    <!-- end edit modal -->
    <ZebraPreview
      :show="showZebraPreview"
      :printData="selectedPreview"
      @complete="closeZebraPreview"
    ></ZebraPreview>
  </div>
</template>

<script>
import { decoder } from '@/lib/helpers';
import store from '@/store';
import Vue from 'vue';
import { exportFile, Loading } from 'quasar';
import { BoardTypes } from '@/lib/board-types';
import axios from 'axios';
import { buildBoardLabel3x2 } from '@/lib/zebra-labels';
import ZebraPreview from '@/components/ZebraPreview';

export default {
  name: 'Boards',
  components: {
    ZebraPreview
  },
  data() {
    return {
      typeId: 'Main White',
      labelSelection: 'Avery 6427',
      quickJump: '',
      html5QrCode: null,
      printPreview: false,
      showEditModal: false,
      scannerOpen: false,
      showZebraPreview: false,
      selectedPreview: {},
      printText: '',
      printList: [],
      editBoardCopy: {},
      pagination: {
        descending: false,
        page: 1,
        rowsPerPage: 200,
        sortBy: 'ID'
      },
      columns: [
        {
          sortable: true,
          name: 'ID',
          label: 'ID',
          align: 'left',
          field: (row) => row.id
        },
        {
          sortable: true,
          name: 'Type',
          label: 'Type',
          align: 'left',
          field: (row) => decoder(row.type)
        },
        {
          sortable: false,
          name: 'Note',
          label: 'Description',
          align: 'left',
          field: (row) => decoder(row.note)
        },
        {
          sortable: false,
          name: 'Rev',
          label: 'Rev/Ver',
          align: 'left',
          field: (row) => decoder(row.rev)
        },
        {
          sortable: false,
          name: 'Location',
          label: 'Location',
          align: 'left',
          field: (row) => this.boardLocation(row.id)
        },
        {
          name: 'PrintOpt',
          label: '',
          align: 'center',
          classes: 'max-width'
        },
        {
          name: 'ZebraOpt',
          label: '',
          align: 'center',
          classes: 'max-width'
        },
        {
          name: 'Link',
          label: '',
          align: 'center',
          classes: 'max-width'
        },
        {
          name: 'Edit',
          label: '',
          align: 'center',
          classes: 'max-width'
        },
        {
          name: 'Delete',
          label: '',
          align: 'center',
          classes: 'max-width'
        }
      ],
      boardTypes: BoardTypes
    };
  },
  mounted() {
    const tab = sessionStorage.getItem('board-tab');
    if (tab && tab !== '') {
      this.typeId = tab;
    } else {
      this.typeId = 'Main White';
    }
    /* Check to see if other scanner passed in a new board */
    if (store.state.boardPage !== '') {
      const board_id = parseInt(store.state.boardPage);
      if (isNaN(board_id) || board_id < 10000 || board_id > 20000) {
        this.$q.notify({
          message: 'Scan value invalid',
          color: 'negative',
          textColor: 'black'
        });
      } else {
        this.addBoard(board_id);
      }
      store.dispatch('setBoardPage', '');
    }

    if (store.state.itemSearchId) {
      this.quickJump = store.state.itemSearchId;

      store.dispatch('setItemSearchId', { itemSearchId: null });
      this.jumpToBoard();
    }
  },
  beforeDestroy() {
    sessionStorage.setItem('board-tab', this.typeId);
  },
  methods: {
    lastLogData(board_id) {
      const logs = this.$store.state.boardLogs.filter(
        (log) => log.board_id === board_id
      );
      if (logs.length === 0) {
        return '';
      }
      const logCopy = JSON.parse(JSON.stringify(logs));
      logCopy.sort((a, b) => {
        return a.date === b.date ? b.id - a.id : b.date - a.date;
      });
      return logCopy[0].notes;
    },
    boardLocation(board_id) {
      const logs = this.$store.state.boardLogs.filter(
        (log) => log.board_id === board_id
      );
      if (logs.length === 0) {
        return '';
      }
      const logCopy = JSON.parse(JSON.stringify(logs));
      logCopy.sort((a, b) => {
        return a.date === b.date ? b.id - a.id : b.date - a.date;
      });
      const farm = this.$store.state.farmList.find(
        (e) => e.id === parseInt(logCopy[0].farm_id)
      );
      if (farm) {
        const pond = farm.farmASM.find(
          (pond) => pond.id === logCopy[0].pond_id
        );
        if (pond) {
          return `${decoder(farm.farm_name)} : ${decoder(pond.name)}`;
        } else {
          return `${decoder(farm.farm_name)}`;
        }
      }
      return '';
    },
    jumpToBoard() {
      const board = this.$store.state.boards.find(
        (brd) => brd.id === parseInt(this.quickJump)
      );

      this.quickJump = '';
      if (board) {
        this.$router.push(`/boards/${board.id}`);
      } else {
        this.$q.notify({
          message: 'Board not found',
          color: 'negative',
          textColor: 'black'
        });
      }
    },
    cancelQR() {
      this.scannerOpen = false;
      if (this.html5QrCode !== null) {
        this.html5QrCode.stop();
        this.html5QrCode.destroy();
        this.html5QrCode = null;
      }
    },
    async scanQR() {
      if (this.scannerOpen) {
        return;
      }
      const that = this;
      const QrScanner = await import('qr-scanner');
      this.scannerOpen = true;
      await this.$nextTick();
      this.html5QrCode = new QrScanner.default(
        document.getElementById('readerVideo'),
        (result) => {
          const decodedText = result.data;
          that.scannerOpen = false;
          that.html5QrCode.stop();
          this.html5QrCode.destroy();
          let foundValue = null;
          if (isNaN(parseInt(decodedText))) {
            const tail = decodedText.split('/boards/');
            foundValue = tail[1];
          } else {
            foundValue = decodedText;
          }
          if (!foundValue || isNaN(parseInt(foundValue))) {
            that.$q.notify({
              message: 'Invalid Code',
              color: 'negative',
              textColor: 'black'
            });
          } else {
            const board_id = parseInt(foundValue);
            if (that.$store.state.boards.find((brd) => brd.id === board_id)) {
              that.$router.push(`/boards/${foundValue}`);
            } else {
              if (board_id < 10000 || board_id > 20000) {
                that.$q.notify({
                  message: 'Board not found',
                  color: 'negative',
                  textColor: 'black'
                });
              } else {
                this.addBoard(parseInt(foundValue));
              }
            }
            that.html5QrCode = null;
          }
        },
        {
          returnDetailedScanResult: true,
          highlightScanRegion: true,
          highlightCodeOutline: true,
          calculateScanRegion: function (video) {
            const smallestDimension = Math.min(
              video.videoWidth,
              video.videoHeight
            );
            const scanRegionSize = Math.round((1 / 2) * smallestDimension);
            return {
              x: Math.round((video.videoWidth - scanRegionSize) / 2),
              y: Math.round((video.videoHeight - scanRegionSize) / 2),
              width: scanRegionSize,
              height: scanRegionSize,
              downScaledWidth: 300,
              downScaledHeight: 300
            };
          }
        }
      );
      try {
        await this.html5QrCode.start();
      } catch (error) {
        that.$q.notify({
          message: error,
          color: 'negative',
          textColor: 'black'
        });
        that.scannerOpen = false;
        that.html5QrCode.stop();
        this.html5QrCode.destroy();
        that.html5QrCode = null;
      }
    },
    cancelPrint() {
      this.printPreview = false;
    },
    async printToDOCX(urlList, labels) {
      const {
        Document,
        HeadingLevel,
        ImageRun,
        Packer,
        Paragraph,
        Table,
        TableCell,
        TableRow,
        TextRun,
        TextDirection,
        VerticalAlign,
        TableRowHeight,
        convertInchesToTwip,
        HeightRule,
        WidthType,
        BorderStyle
      } = await import('docx');
      let tableRow = {
        height: {
          value: convertInchesToTwip(5.0),
          rule: HeightRule.EXACT
        },
        children: []
      };
      const tableRows = [];
      let tableChildren = [];
      const borderStyle = {
        style: BorderStyle.NONE,
        size: 0,
        color: '000000'
      };
      const noBorders = {
        top: borderStyle,
        bottom: borderStyle,
        left: borderStyle,
        right: borderStyle
      };
      const labelOrientation =
        labels.orientation === 'rotate'
          ? TextDirection.BOTTOM_TO_TOP_LEFT_TO_RIGHT
          : TextDirection.LEFT_TO_RIGHT_TOP_TO_BOTTOM;
      let rowIndex = 0;
      let columnIndex = 0;
      let pageIndex = 0;
      let row = {};
      let column = {};
      for (let i = 0; i < urlList.length; i++) {
        const urlItem = urlList[i];
        /* row column selection */

        row = labels.rows[rowIndex];
        column = labels.columns[columnIndex];
        if (row.type === 'space') {
          // Push space
          tableChildren.push(
            new TableCell({
              children: [
                new Paragraph({
                  alignment: 'center',
                  children: [
                    new TextRun({
                      text: labels.name,
                      size: 12
                    })
                  ]
                }),
                new Paragraph({})
              ],
              verticalAlign: VerticalAlign.CENTER,
              textDirection: TextDirection.BOTTOM_TO_TOP_LEFT_TO_RIGHT,
              width: {
                size: convertInchesToTwip(row.width),
                type: WidthType.DXA
              },
              borders: noBorders
            })
          );
          row = labels.rows[++rowIndex];
        }
        if (column.type === 'space') {
          for (let j = 0; j < labels.rows.length; j++) {
            tableChildren.push(
              new TableCell({
                children: [
                  new Paragraph({
                    alignment: 'center',
                    text: 'space'
                  }),
                  new Paragraph({})
                ],
                verticalAlign: VerticalAlign.CENTER,
                width: {
                  size: convertInchesToTwip(labels.rows[j].width),
                  type: WidthType.DXA
                },
                borders: noBorders
              })
            );
          }
          tableRows.push(
            new TableRow({
              height: {
                value: convertInchesToTwip(column.height),
                rule: HeightRule.EXACT
              },
              children: tableChildren
            })
          );
          tableChildren = [];
          rowIndex = 0;
          column = labels.columns[++columnIndex];
        }
        /* Core Label */
        tableChildren.push(
          new TableCell({
            children: [
              new Paragraph({
                alignment: 'center',
                children: [
                  new TextRun({
                    text: urlItem.type,
                    size: labels.font
                  })
                ]
              }),
              new Paragraph({
                alignment: 'center',
                children: [
                  new TextRun({
                    text: urlItem.note,
                    size: labels.noteFont
                  })
                ]
              }),
              new Paragraph({
                alignment: 'center',
                children: [
                  new TextRun({
                    text: urlItem.rev,
                    size: labels.noteFont
                  })
                ]
              }),
              new Paragraph({
                alignment: 'center',
                children: [
                  new ImageRun({
                    data: urlItem.qrcode,
                    transformation: {
                      width: labels.size,
                      height: labels.size
                    }
                  })
                ]
              }),
              new Paragraph({
                alignment: 'center',
                children: [
                  new TextRun({
                    text: urlItem.id.toString(),
                    size: labels.font
                  })
                ]
              })
            ],
            verticalAlign: VerticalAlign.CENTER,
            textDirection: labelOrientation,
            width: {
              size: convertInchesToTwip(row.width),
              type: WidthType.DXA
            },
            borders: noBorders
          })
        );
        /* End Core Label */
        rowIndex++;
        if (rowIndex >= labels.rows.length) {
          /* Draw queue */
          tableRows.push(
            new TableRow({
              height: {
                value: convertInchesToTwip(column.height),
                rule: HeightRule.EXACT
              },
              children: tableChildren
            })
          );
          tableChildren = [];
          rowIndex = 0;
          columnIndex++;
        }
        if (columnIndex >= labels.columns.length) {
          columnIndex = 0;
          pageIndex++;
        }
      }
      /* Finalize */
      if (tableChildren.length > 0) {
        tableRows.push(
          new TableRow({
            height: {
              value: convertInchesToTwip(column.height),
              rule: HeightRule.EXACT
            },
            children: tableChildren
          })
        );
      }

      const doc = new Document({
        sections: [
          {
            properties: {
              page: {
                margin: {
                  top: convertInchesToTwip(labels.margins.top),
                  bottom: convertInchesToTwip(labels.margins.bottom),
                  right: convertInchesToTwip(labels.margins.right),
                  left: convertInchesToTwip(labels.margins.left)
                },
                size: {
                  width: convertInchesToTwip(labels.page.width),
                  height: convertInchesToTwip(labels.page.height),
                  orientation: labels.page.orientation
                }
              }
            },
            children: [
              new Table({
                rows: tableRows
              })
            ]
          }
        ]
      });

      Packer.toBuffer(doc).then((buffer) => {
        exportFile(
          `Selected-labels.docx`,
          buffer,
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        );
      });
    },
    closeZebraPreview() {
      this.showZebraPreview = false;
    },
    printRowToZebra(row) {
      const zebraId = this.$store.state.zebraSelection;
      this.selectedPreview = {
        type: row.type,
        id: row.id,
        rev: row.rev,
        note: row.note,
        logData: this.lastLogData(row.id)
      };
      this.showZebraPreview = true;
    },
    async printSelectedZebra() {
      const zebraId = this.$store.state.zebraSelection;
      const list = this.Boards.filter((brd) => this.printList[brd.id] === true);
      if (list.length === 0) {
        this.$q.notify({
          message: 'No print selections',
          color: 'primary',
          textColor: 'black'
        });
        return;
      }

      let zpl = '';
      for (let i = 0; i < list.length; i++) {
        zpl += buildBoardLabel3x2(
          list[i].type,
          list[i].id,
          list[i].rev,
          list[i].note,
          this.lastLogData(list[i].id)
        );
      }

      store
        .dispatch('printToZebra', {
          zebraId,
          zpl
        })
        .then((results) => {
          if (results.sent === false || results.reasonCode !== 0) {
            this.$q.notify({
              message: `${results.error}`,
              color: 'red-14',
              textColor: this.$q.dark.isActive ? 'black' : 'white'
            });
          } else {
            this.$q.notify({
              message: `sent to ${zebraId}`,
              color: 'primary',
              textColor: this.$q.dark.isActive ? 'black' : 'white'
            });
          }
        });
    },
    async printSelected() {
      const Avery6427 = {
        name: 'Avery 6427',
        orientation: 'rotate',
        size: 175,
        font: 40,
        noteFont: 30,
        rows: [
          {
            width: 4.0,
            type: 'print'
          },
          {
            width: 0.166,
            type: 'space'
          },
          {
            width: 4.0,
            type: 'print'
          }
        ],
        columns: [
          {
            height: 2.0,
            type: 'print'
          },
          {
            height: 2.0,
            type: 'print'
          },
          {
            height: 2.0,
            type: 'print'
          },
          {
            height: 2.0,
            type: 'print'
          },
          {
            height: 2.0,
            type: 'print'
          }
        ],
        margins: {
          top: 0.5,
          bottom: 0.3,
          left: 0.166,
          right: 0.166
        },
        page: {
          width: 8.5,
          height: 11.0,
          orientation: 'portrait'
        }
      };

      const Avery27950 = {
        name: 'Avery 27950',
        labelOrientation: 'normal',
        size: 200,
        font: 50,
        noteFont: 38,
        rows: [
          {
            width: 3.75,
            type: 'print'
          },
          {
            width: 0.5,
            type: 'space'
          },
          {
            width: 3.75,
            type: 'print'
          }
        ],
        columns: [
          {
            height: 5.0,
            type: 'print'
          },
          {
            height: 5.0,
            type: 'print'
          }
        ],
        margins: {
          top: 0.5,
          bottom: 0.3,
          left: 0.5,
          right: 0.5
        },
        page: {
          width: 8.5,
          height: 11.0,
          orientation: 'portrait'
        }
      };
      const list = this.Boards.filter((brd) => this.printList[brd.id] === true);
      if (list.length === 0) {
        this.$q.notify({
          message: 'No print selections',
          color: 'primary',
          textColor: 'black'
        });
        return;
      }
      this.printList = [];
      const QRCode = await import('qrcode');

      const urlList = [];
      for (let i = 0; i < list.length; i++) {
        urlList.push({
          type: list[i].type,
          id: list[i].id,
          note: list[i].note,
          rev: list[i].rev,
          qrcode: await QRCode.toDataURL(
            `https://${window.location.host}/boards/${list[i].id}`
          )
        });
      }
      urlList.sort((a, b) => a.id - b.id);
      let label = {};
      switch (this.labelSelection) {
        case 'Avery 27950':
          label = Avery27950;
          break;
        case 'Avery 6427':
          label = Avery6427;
          break;
        default:
          this.$q.notify({
            message: 'Invalid label selection',
            color: 'negative',
            textColor: 'black'
          });
          break;
      }
      await this.printToDOCX(urlList, label);
    },
    setPrinting(board_id) {
      if (this.printList[board_id]) {
        Vue.set(this.printList, board_id, false);
      } else {
        Vue.set(this.printList, board_id, true);
      }
    },
    isPrinting(board_id) {
      const board = this.$store.state.boards.find((brd) => brd.id === board_id);
      return board && this.printList[board_id] === true;
    },
    navigateTo(board) {
      this.$router.push(`boards/${board.id}`);
    },
    editBoard(board) {
      this.editBoardCopy = JSON.parse(JSON.stringify(board));
      this.showEditModal = true;
    },
    deleteBoard(board) {
      this.$deleteResult
        .getResultsAsync(
          'handyman',
          `${board.type} - ${board.id}`,
          'Delete board?'
        )
        .then((result) => {
          if (result === 'delete') {
            this.dispatchDelete(board);
          }
        });
    },
    dispatchDelete(board) {
      store
        .dispatch('deleteBoard', {
          board
        })
        .then((results) => {
          this.$finishResult.handleResultsAsync(results).then((response) => {
            if (response === 'retry') {
              this.dispatchDelete(board);
            }
          });
        });
    },
    addBoard(board_id) {
      if (!board_id) {
        //Find lowest available
        board_id = 10001;
        for (;;) {
          if (
            this.$store.state.boards.find((b) => {
              return b.id === board_id;
            })
          ) {
            board_id++;
          } else {
            break;
          }
        }
      }
      this.editBoardCopy = {
        newBoard: true,
        id: board_id,
        rev: '',
        note: '',
        type: this.typeId
      };
      this.showEditModal = true;
    },
    onSubmit() {
      let dispatch;
      if (this.editBoardCopy.newBoard === true) {
        dispatch = 'createBoard';
      } else {
        dispatch = 'updateBoard';
      }
      store
        .dispatch(dispatch, {
          board: this.editBoardCopy
        })
        .then((results) => {
          this.$finishResult.handleResultsAsync(results).then((response) => {
            if (response === 'retry') {
              this.onSubmit();
            } else {
              this.showEditModal = false;
              if (this.editBoardCopy.newBoard === true) {
                this.$router.push(`/boards/${this.editBoardCopy.id}`);
              }
            }
          });
        });
    },
    cancelEdit() {
      this.showEditModal = false;
    }
  },
  computed: {
    selectedZebraOnline() {
      if (this.$store.state.zebraSelection === '') {
        return false;
      }
      return this.$store.state.zebraPrinters.find((p) => {
        return p.zebraId === this.$store.state.zebraSelection;
      });
    },
    Boards() {
      let boards = this.$store.state.boards.slice();
      boards = boards.filter((brd) => {
        return brd.type === this.typeId;
      });
      return boards;
    },
    ModalHeader() {
      return this.editBoardCopy.newBoard === true ? 'New Board' : 'Edit Board';
    },
    typeOptions() {
      const list = [];
      this.boardTypes.forEach((board) => {
        list.push({
          label: board.name,
          value: board.id
        });
      });
      return list;
    },
    showPrintOpt() {
      return this.printList.find((e) => {
        return e === true;
      });
    }
  }
};
</script>

<style scoped>
@media only screen and (min-width: 750px) {
  .spaced {
    margin-left: 2px;
    margin-right: 2px;
  }

  table {
    width: 80%;
  }

  .card-size {
    min-width: 500px;
  }

  .print-size {
    min-width: 250px;
  }
}

.max-width {
  max-width: 100px;
}
</style>
