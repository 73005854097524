<template>
  <div>
    <q-tabs
      v-model="categoryId"
      align="justify"
      dense
      no-caps
      mobile-arrows
      class="shadow-2"
    >
      <q-tab
        v-for="(category, index) in PartsCategories"
        :key="index"
        class="custom-wrap"
        :name="category.id"
        :label="category.item"
      />
      <q-btn outline @click="addCategory">
        <q-icon name="add" size="md"></q-icon>
      </q-btn>
    </q-tabs>

    <div style="padding-top: 2px; padding-left: 2px; padding-right: 2px">
      <q-table
        :title="activeCategoryName"
        :data="Parts"
        :columns="columns"
        :pagination.sync="pagination"
        row-key="id"
        no-data-label="No parts in this category"
      >
        <template v-slot:body-cell-Link="props">
          <q-td :props="props">
            <a
              class="link-color"
              :href="props.row.link"
              rel="noopener noreferrer"
              target="_blank"
              ><q-icon name="link" size="sm"
            /></a>
          </q-td>
        </template>
        <template v-slot:body-cell-Edit="props">
          <q-td :props="props">
            <q-btn
              class="btn-table-primary"
              outline
              icon="edit"
              size="md"
              padding="xs"
              @click="editItem(props.row)"
            >
            </q-btn>
          </q-td>
        </template>
        <template v-slot:body-cell-Delete="props">
          <q-td :props="props">
            <q-btn
              class="btn-table-negative"
              outline
              icon="delete"
              size="md"
              padding="xs"
              @click="deleteItem(props.row)"
            >
            </q-btn>
          </q-td>
        </template>
      </q-table>
    </div>

    <q-page-sticky
      v-if="Parts.length < 1"
      position="bottom-right"
      :offset="[100, 28]"
    >
      <q-btn fab icon="delete" color="negative" @click="deleteCategory" />
    </q-page-sticky>
    <q-page-sticky
      v-if="Parts.length > 0"
      position="bottom-right"
      :offset="[100, 28]"
    >
      <q-btn fab icon="edit" color="primary" @click="editCategory" />
    </q-page-sticky>
    <q-page-sticky position="bottom-right" :offset="[28, 28]">
      <q-btn fab icon="add" color="accent" @click="addItem" />
    </q-page-sticky>
    <!-- edit modal -->
    <q-dialog v-model="showEditModal" persistent class="">
      <q-card square bordered class="q-pa-lg card-size">
        <q-card-section class="">
          <q-form @submit="onSubmit" class="q-gutter-sm">
            <q-toolbar class="shadow-2">
              <q-toolbar-title class="row justify-center items-center">
                <q-icon name="build" size="sm" class="q-mr-sm" />
                Part
              </q-toolbar-title>
            </q-toolbar>
            <q-input
              filled
              v-model="editCopy.name"
              label="Name"
              maxlength="64"
              outlined
              square
            />
            <q-input
              filled
              v-model="editCopy.mfgpart"
              label="Part No."
              maxlength="64"
              outlined
              square
            />
            <q-input
              filled
              v-model="editCopy.vendor"
              label="Vendor"
              maxlength="64"
              outlined
              square
            />
            <q-input
              filled
              v-model="editCopy.link"
              label="Link"
              maxlength="256"
              outlined
              square
            />
            <q-select
              v-model="editCopy.category"
              square
              filled
              :options="categoryOptions(false)"
              emit-value
              map-options
              label="Category"
            />
            <q-select
              v-model="editCopy.categoryOpt2"
              square
              filled
              :options="categoryOptions(true)"
              emit-value
              map-options
              label="Category 2"
            />
            <q-select
              v-model="editCopy.categoryOpt3"
              square
              filled
              :options="categoryOptions(true)"
              emit-value
              map-options
              label="Category 3"
            />
            <div align="right">
              <div class="row full-width">
                <div class="col spaced">
                  <q-btn
                    outline
                    class="full-width"
                    label="Cancel"
                    color="secondary"
                    @click="cancelEdit"
                  />
                </div>
                <div class="col spaced">
                  <q-btn
                    outline
                    class="full-width"
                    label="Submit"
                    type="submit"
                    color="primary"
                  />
                </div>
              </div>
            </div>
          </q-form>
        </q-card-section>
      </q-card>
    </q-dialog>
    <!-- end edit modal -->
    <!-- edit category modal -->
    <q-dialog v-model="showEditCategory" persistent class="">
      <q-card square bordered class="q-pa-lg">
        <q-card-section class="">
          <q-form @submit="onSubmitCategory" class="q-gutter-sm">
            <q-toolbar class="shadow-2">
              <q-toolbar-title class="row justify-center items-center">
                <q-icon name="build" size="sm" class="q-mr-sm" />
                {{
                  editCategoryCopy.id === 'new'
                    ? 'New Category'
                    : 'Edit Category'
                }}
              </q-toolbar-title>
            </q-toolbar>
            <q-input
              filled
              v-model="editCategoryCopy.item"
              label="Name"
              maxlength="32"
              outlined
              square
            />
            <div align="right">
              <div class="row full-width">
                <div class="col spaced">
                  <q-btn
                    outline
                    class="full-width"
                    label="Cancel"
                    color="secondary"
                    @click="cancelEdit"
                  />
                </div>
                <div class="col spaced">
                  <q-btn
                    outline
                    class="full-width"
                    label="Submit"
                    type="submit"
                    color="primary"
                  />
                </div>
              </div>
            </div>
          </q-form>
        </q-card-section>
      </q-card>
    </q-dialog>
    <!-- end edit category modal -->
  </div>
</template>

<script>
import store from '@/store';
// import { parseTimeStamp } from "@/lib/date-utils";

export default {
  name: 'Farms',
  data() {
    return {
      categoryId:
        this.$store.state.partsCategories.length > 0
          ? this.$store.state.partsCategories[0].id
          : -1,
      showEditModal: false,
      showEditCategory: false,
      editCopy: {},
      editCategoryCopy: {},
      pagination: {
        descending: false,
        page: 1,
        rowsPerPage: 0
      },
      columns: [
        {
          sortable: true,
          name: 'Item',
          label: 'Item',
          align: 'left',
          classes: 'table-highlight-column',
          field: (row) => this.decoder(row.name)
        },
        {
          sortable: true,
          name: 'MfgPart',
          label: 'Part No.',
          align: 'left',
          field: (row) => this.decoder(row.mfgpart)
        },
        {
          name: 'Link',
          label: 'Link',
          align: 'center',
          field: (row) => row.link
        },
        {
          sortable: true,
          name: 'Vendor',
          label: 'Vendor',
          align: 'left',
          field: (row) => this.decoder(row.vendor)
        },
        {
          name: 'Edit',
          label: '',
          align: 'center'
        },
        {
          name: 'Delete',
          label: '',
          align: 'center'
        }
      ]
    };
  },
  methods: {
    decoder(str) {
      const textArea = document.createElement('textarea');
      textArea.innerHTML = str;
      return textArea.value;
    },
    addCategory() {
      this.editCategoryCopy = {
        id: 'new',
        item: ''
      };
      this.showEditCategory = true;
    },
    editCategory() {
      const category = this.$store.state.partsCategories.find(
        (item) => item.id === this.categoryId
      );
      this.editCategoryCopy = JSON.parse(JSON.stringify(category));
      this.showEditCategory = true;
    },
    deleteCategory() {
      const category = this.$store.state.partsCategories.find(
        (item) => item.id === this.categoryId
      );
      this.$deleteResult
        .getResultsAsync('setting', category.item, 'Delete category?')
        .then((result) => {
          if (result === 'delete') {
            this.dispatchDeleteCategory(category);
          }
        });
    },
    onSubmitCategory() {
      let dispatch;
      if (this.editCategoryCopy.id === 'new') {
        dispatch = 'createCategory';
      } else {
        dispatch = 'updateCategory';
      }
      store.dispatch(dispatch, this.editCategoryCopy).then((results) => {
        this.$finishResult.handleResultsAsync(results).then((response) => {
          if (response === 'retry') {
            this.onSubmitCategory();
          } else {
            this.showEditCategory = false;
          }
        });
      });
    },
    dispatchDeleteCategory(category) {
      store.dispatch('deleteCategory', category).then((results) => {
        this.$finishResult.handleResultsAsync(results).then((response) => {
          if (response === 'retry') {
            this.dispatchDeleteCategory(category);
          }
        });
      });
    },
    onSubmit() {
      let dispatch;
      if (this.editCopy.id === 'new') {
        dispatch = 'createPart';
      } else {
        dispatch = 'updatePart';
      }
      store.dispatch(dispatch, this.editCopy).then((results) => {
        this.$finishResult.handleResultsAsync(results).then((response) => {
          if (response === 'retry') {
            this.onSubmit();
          } else {
            this.showEditModal = false;
          }
        });
      });
    },
    addItem() {
      this.editCopy = {
        id: 'new',
        item: '',
        category: this.categoryId,
        categoryOpt2: null,
        categoryOpt3: null,
        link: '',
        mfgpart: '',
        vendor: ''
      };
      this.showEditModal = true;
    },
    editItem(part) {
      this.editCopy = JSON.parse(JSON.stringify(part));
      this.showEditModal = true;
    },
    deleteItem(part) {
      this.$deleteResult
        .getResultsAsync('build', part.name, 'Delete part?')
        .then((result) => {
          if (result === 'delete') {
            this.dispatchDelete(part);
          }
        });
    },
    dispatchDelete(part) {
      store.dispatch('deletePart', part).then((results) => {
        this.$finishResult.handleResultsAsync(results).then((response) => {
          if (response === 'retry') {
            this.dispatchDelete(part);
          }
        });
      });
    },
    cancelEdit() {
      this.showEditModal = false;
      this.showEditCategory = false;
    },
    categoryOptions(allowNone) {
      const list = [];
      for (let i = 0; i < this.$store.state.partsCategories.length; i++) {
        list.push({
          label: this.$store.state.partsCategories[i].item,
          value: this.$store.state.partsCategories[i].id
        });
      }
      if (allowNone) {
        list.push({
          label: 'None selected',
          value: null
        });
      }
      return list;
    }
  },
  computed: {
    Parts() {
      return this.$store.state.parts
        .filter(
          (part) =>
            part.category === this.categoryId ||
            part.categoryOpt2 === this.categoryId ||
            part.categoryOpt3 === this.categoryId
        )
        .sort(function (a, b) {
          return a.name.localeCompare(b.name, undefined, {
            numeric: true,
            sensitivity: 'base'
          });
        });
    },
    PartsCategories() {
      const cats = JSON.parse(
        JSON.stringify(this.$store.state.partsCategories)
      );
      cats.sort(function (a, b) {
        return a.item.localeCompare(b.item, undefined, {
          numeric: true,
          sensitivity: 'base'
        });
      });
      return cats;
    },
    activeCategoryName() {
      const category = this.$store.state.partsCategories.find(
        (item) => item.id === this.categoryId
      );
      return category ? category.item : '--';
    }
  },
  watch: {
    '$store.state.partsCategories'() {
      if (
        this.$store.state.partsCategories.length > 0 &&
        this.$store.state.partsCategories.findIndex(
          (item) => item.id === this.categoryId
        ) === -1
      ) {
        this.categoryId = -1;
      }
      if (
        this.$store.state.partsCategories.length > 0 &&
        this.categoryId === -1
      ) {
        this.categoryId = this.$store.state.partsCategories[0].id;
      }
    }
  }
};
</script>

<style scoped lang="scss">
/* Allow multi row q-categoryId */
@media (min-width: 700px) {
  .q-tab {
    &::v-deep {
      .q-tab__content {
        flex-wrap: wrap !important;
      }
    }
  }

  .q-tabs {
    &::v-deep {
      .q-tabs__content {
        flex-wrap: wrap !important;
      }
    }
  }
}
</style>

<style scoped>
@media only screen and (min-width: 1000px) {
  table {
    width: 80%;
  }

  .card-size {
    min-width: 500px;
  }
}

.spaced {
  margin-left: 2px;
  margin-right: 2px;
}

.name-header {
  color: #d5d9dd;
  background-color: #424242;
  padding: 10px 10px 10px 0px;
  width: 35px;
}

.contact-td {
  padding-left: 15px;
  text-align: left;
  font-weight: bold;
  font-size: 14px;
}

.item-td {
  padding-left: 10px;
  text-align: left;
  font-weight: bold;
}

.col-td {
  padding-left: 10px;
  text-align: left;
}

.col-th {
  padding-left: 15px;
  text-align: left;
}

table {
  margin: 0px auto 0px auto;
  font-size: 12px;
  border-collapse: collapse;
  border-left: none;
  border-right: none;
}

td {
  text-align: center;
  border: 1px solid #212121;
  border-left: none;
  border-right: none;
}

th {
  padding: 10px 0px;
  border: 1px solid #212121;
  border-left: none;
  border-right: none;
}
</style>
