import { render, staticRenderFns } from "./farm-home.vue?vue&type=template&id=34752d63&scoped=true"
import script from "./farm-home.vue?vue&type=script&lang=js"
export * from "./farm-home.vue?vue&type=script&lang=js"
import style0 from "./farm-home.vue?vue&type=style&index=0&id=34752d63&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34752d63",
  null
  
)

export default component.exports
import QCard from 'quasar/src/components/card/QCard.js';
import QToggle from 'quasar/src/components/toggle/QToggle.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QFab from 'quasar/src/components/fab/QFab.js';
import QFabAction from 'quasar/src/components/fab/QFabAction.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QCard,QToggle,QSeparator,QCardActions,QBtn,QFab,QFabAction,QDialog});
