// we need our modal component
import DeleteConfirm from './DeleteConfirm.vue';

const DeleteModal = {
  install(Vue, options) {
    this.EventBus = new Vue();
    Vue.component('delete-confirm', DeleteConfirm);
    Vue.prototype.$deleteResult = {
      /**
       *
       * @param {string} icon
       * @param {string} title
       * @param {string} message
       * @returns {Promise<string>}
       */
      async getResultsAsync(icon, title, message) {
        return new Promise((resolve, reject) => {
          DeleteModal.EventBus.$emit(
            'getResultsAsync',
            icon,
            title,
            message,
            resolve,
            reject
          );
        });
      }
    };
  }
};

export default DeleteModal;
