import Vue from 'vue';
import VueRouter from 'vue-router';
import Farms from '../views/Farms.vue';
import Parts from '../views/Parts.vue';
import Setup from '../views/Setup.vue';
import Todos from '../views/Todos.vue';
import Map from '../views/Map.vue';
import Users from '../views/Users.vue';
import UserFarms from '../views/UserFarms.vue';
import RecentWork from '../views/Recent.vue';
import Chats from '../views/Chats.vue';
import Emails from '../views/Emails.vue';
import Tags from '../views/Tags.vue';
import WorkOrders from '../views/WorkOrders.vue';
import Boards from '../views/Boards.vue';
import BoardLogs from '../views/BoardLogs.vue';
import SmsRecords from '../views/SmsRecords.vue';
// Farms
import Farm_root from '../views/Farm/farm-root.vue';
import Farm_Tabs from '../views/Farm/farm-tabs.vue';
import Farm_home from '../views/Farm/farm-home.vue';
import Farm_ponds from '../views/Farm/farm-ponds.vue';
import Farm_computers from '../views/Farm/farm-computers.vue';
import Farm_repeaters from '../views/Farm/farm-repeaters.vue';
import Farm_notes from '../views/Farm/farm-notes.vue';
import Farm_work from '../views/Farm/farm-work.vue';
import Farm_todos from '../views/Farm/farm-todos.vue';
import Farm_attachments from '../views/Farm/farm-attachments.vue';
import Farm_emails from '../views/Farm/farm-emails.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Farms',
    component: Farms
  },
  {
    path: '/work-orders',
    name: 'WorkOrders',
    component: WorkOrders
  },
  {
    path: '/emails',
    name: 'Emails',
    component: Emails
  },
  {
    path: '/chats',
    name: 'Chats',
    component: Chats
  },
  {
    path: '/tags',
    name: 'Tags',
    component: Tags
  },
  {
    path: '/parts',
    name: 'Parts',
    component: Parts
  },
  {
    path: '/setup',
    name: 'Setup',
    component: Setup
  },
  {
    path: '/recent',
    name: 'Recent',
    component: RecentWork
  },
  {
    path: '/sms-records',
    name: 'SmsRecords',
    component: SmsRecords
  },
  {
    path: '/boards',
    name: 'Boards',
    component: Boards
  },
  {
    path: '/boards/:boardId/',
    name: 'BoardLogs',
    component: BoardLogs,
    props: true
  },
  {
    path: '/todos',
    name: 'Todos',
    component: Todos
  },
  {
    path: '/map',
    name: 'Map',
    component: Map
  },
  {
    path: '/users',
    name: 'Users',
    component: Users
  },
  {
    path: '/users/:userId/',
    name: 'UsersFarms',
    component: UserFarms,
    props: true
  },
  {
    path: '/farm/:farmId/',
    components: {
      default: Farm_root,
      tabs: Farm_Tabs
    },
    props: {
      default: true,
      tabs: true
    },
    children: [
      {
        path: '',
        name: 'Farm-Home',
        component: Farm_home,
        props: {
          default: true
        }
      },
      {
        path: 'work',
        name: 'Farm-Work',
        component: Farm_work
      },
      {
        path: 'emails',
        name: 'Farm-Emails',
        component: Farm_emails
      },
      {
        path: 'todos',
        name: 'Farm-Todos',
        component: Farm_todos
      },
      {
        path: 'ponds',
        name: 'Farm-Ponds',
        component: Farm_ponds
      },
      {
        path: 'computers',
        name: 'Farm-Computers',
        component: Farm_computers
      },
      {
        path: 'repeaters',
        name: 'Farm-Repeaters',
        component: Farm_repeaters
      },
      {
        path: 'notes',
        name: 'Farm-Notes',
        component: Farm_notes
      },
      {
        path: 'attachments',
        name: 'Farm-Attachments',
        component: Farm_attachments
      }
    ]
  }
];

const router = new VueRouter({
  mode: 'history',
  routes
});

export default router;
