import { render, staticRenderFns } from "./QuickAdd.vue?vue&type=template&id=d5c23c3e&scoped=true"
import script from "./QuickAdd.vue?vue&type=script&lang=js"
export * from "./QuickAdd.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d5c23c3e",
  null
  
)

export default component.exports
import QCard from 'quasar/src/components/card/QCard.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QCard,QSpace,QBtn,QSeparator,QInput,QScrollArea,QAvatar});qInstall(component, 'directives', {ClosePopup});
