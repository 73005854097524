import Vue from 'vue';

import './styles/quasar.sass';
import '@quasar/extras/material-icons/material-icons.css';
import '@quasar/extras/fontawesome-v5/fontawesome-v5.css';
import Quasar from 'quasar/src/vue-plugin.js';import Dialog from 'quasar/src/plugins/Dialog.js';import Loading from 'quasar/src/plugins/Loading.js';import Notify from 'quasar/src/plugins/Notify.js';;

Vue.use(Quasar, {
  config: {
    brand: {
      // primary: '#027BE3',
      // primary: '#2962FF',
      primary: '#245AD3',
      secondary: '#26A69A',
      // accent: '#9C27B0',
      accent: '#A41E39',

      dark: '#1d1d1d',

      positive: '#21BA45',
      // negative: '#C10015',
      negative: '#A41E39',
      info: '#31CCEC',
      warning: '#F2C037'
    }
  },
  plugins: {
    Dialog,
    Loading,
    Notify
  }
});
