<template>
  <div class="q-pt-xl">
    <Attachments :parentObj="parentObj" :parentType="'farm'" />
  </div>
</template>

<script>
import store from '@/store';
import Attachments from '@/components/Attachments';

export default {
  name: 'FarmAttachments',
  components: {
    Attachments
  },
  props: {
    farmId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      parentObj: {
        attachments: [],
        farmId: this.farmId
      }
    };
  },
  mounted() {
    this.setAttachments();
  },
  methods: {
    setAttachments() {
      const farm = this.$store.state.farmList.find(
        (e) => e.id === parseInt(this.farmId)
      );
      if (farm) {
        this.parentObj.attachments = JSON.parse(
          JSON.stringify(farm.attachments)
        );
      }
      this.parentObj.farmId = this.farmId;
    }
  },
  watch: {
    '$store.state.farmList': {
      deep: true,
      handler() {
        this.setAttachments();
      }
    }
  }
};
</script>

<style scoped></style>
