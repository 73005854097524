<template>
  <div>
    <div class="row">
      <div v-if="showList" class="col-xs-12 col-sm-5 row">
        <div class="col q-pa-sm">
          <q-btn-dropdown flat auto-close label="sort" class="full-width">
            <q-card class="q-pa-md">
              <div class="q-pb-md">
                <q-btn
                  label-position="left"
                  color="grey-1"
                  text-color="accent"
                  icon="star"
                  label="Sort By Starred"
                  class="full-width"
                  @click="sortBy = 'starred'"
                />
              </div>
              <div class="q-pb-md">
                <q-btn
                  label-position="left"
                  color="grey-1"
                  text-color="accent"
                  icon="chat"
                  label="Sort By Unread"
                  class="full-width"
                  @click="sortBy = 'unread'"
                />
              </div>
              <div>
                <q-btn
                  label-position="left"
                  color="grey-1"
                  text-color="primary"
                  icon="date_range"
                  label="Sort By Date/Time"
                  class="full-width"
                  @click="sortBy = 'date'"
                />
              </div>
            </q-card>
          </q-btn-dropdown>
        </div>
        <q-separator vertical />

        <div class="col-12">
          <q-list
            bordered
            :style="`height: ${usableHeight - 52}px; overflow: auto`"
          >
            <q-resize-observer @resize="onResize" />

            <q-item
              v-for="chat in ChatsSorted"
              clickable
              :key="chat.id + chat.type"
              class="q-my-sm"
              manual-focus
              :active="selectedItem && chat.uniqueId === selectedItem.uniqueId"
              active-class="bg-grey-4"
              style="margin-bottom: 0; margin-top: 0; padding: 10px"
              @click="viewMessages(chat)"
            >
              <q-item-section avatar>
                <q-avatar color="primary" text-color="white">
                  {{ farmName(chat.farm_id).charAt(0) }}
                </q-avatar>
              </q-item-section>

              <q-item-section>
                <q-item-label
                  lines="1"
                  :class="
                    selectedItem && chat.uniqueId === selectedItem.uniqueId
                      ? 'text-black'
                      : 'text-primary'
                  "
                >
                  {{ farmName(chat.farm_id) }}
                </q-item-label>
                <q-item-label
                  v-if="chat.type === 'workLog'"
                  caption
                  lines="1"
                  :class="
                    selectedItem && chat.uniqueId === selectedItem.uniqueId
                      ? 'text-black'
                      : 'text-grey-7'
                  "
                >
                  <div class="row items-center">
                    {{ chat.work_item_name }}
                  </div>
                </q-item-label>
                <q-item-label
                  v-if="chat.type === 'todo'"
                  caption
                  lines="1"
                  :class="
                    selectedItem && chat.id === selectedItem.id
                      ? 'text-black'
                      : 'text-grey-7'
                  "
                >
                  <q-badge
                    rounded
                    :color="mapStatusColor(chat.status)"
                    class="q-mr-sm"
                    style="min-width: 95px"
                    text-color="black"
                  >
                    <div class="row justify-center full-width">
                      S - {{ chat.status }}
                    </div>
                  </q-badge>
                  <q-badge
                    rounded
                    :color="mapPriorityColor(chat.priority)"
                    style="min-width: 80px"
                    text-color="black"
                  >
                    <div class="row justify-center full-width">
                      P - {{ chat.priority }}
                    </div>
                  </q-badge>
                </q-item-label>
              </q-item-section>

              <q-item-section side>
                <div class="q-pb-xs">
                  {{ parseListTime(chat.latestMsgTs) }}
                </div>
                <div class="row">
                  <q-badge
                    v-if="chat.numUnread > 0"
                    rounded
                    color="accent"
                    text-color="white"
                  >
                    {{ chat.numUnread }}
                  </q-badge>
                  <q-badge
                    v-if="chat.numStarred > 0"
                    rounded
                    color="accent"
                    text-color="white"
                    class="q-ml-sm"
                  >
                    <q-icon name="star" class="q-mr-xs" />
                    {{ chat.numStarred }}
                  </q-badge>
                </div>
              </q-item-section>
            </q-item>
            <div v-if="showMoreBtn" class="row justify-center q-my-lg">
              <q-btn
                rounded
                outline
                label="More"
                icon="expand_more"
                color="primary"
                padding="xs 70px"
                @click="showMore"
              />
            </div>
          </q-list>
        </div>
      </div>

      <!--Chats-->
      <div
        v-if="displayMode === 'chat' || $q.screen.gt.xs"
        class="col-xs-12 col-sm-7"
      >
        <div
          v-if="!selectedItem"
          style="height: 57px"
          class="row items-center justify-center"
        >
          <div class="q-pt-xs">
            <q-chip color="grey-2" text-color="primary">
              Select Work Log
            </q-chip>
          </div>
          <q-separator class="full-width" />
        </div>

        <!--Selected Work Log Info-->
        <div v-if="selectedItem && selectedItem.type === 'workLog'">
          <div
            style="margin-bottom: 0; margin-top: 0; padding: 10px"
            class="row"
          >
            <div class="gt-xs row items-center q-pr-sm">
              <q-avatar color="primary" text-color="white" size="40px">
                {{ farmName(selectedItem.farm_id).charAt(0) }}
              </q-avatar>
            </div>

            <div class="lt-sm row items-center q-pr-sm">
              <q-btn
                round
                icon="arrow_back"
                color="primary"
                @click="displayMode = 'list'"
              />
            </div>

            <div class="col">
              <div class="row items-center text-primary no-wrap">
                <div class="q-pr-md ellipsis">
                  {{ farmName(selectedItem.farm_id) }}
                </div>
                <div class="text-grey-7 ellipsis">
                  {{ parseTimeStamp(selectedItem.date) }}
                </div>
              </div>

              <div class="row text-grey-7 no-wrap">
                <div class="ellipsis">{{ selectedItem.work_cat_name }} -</div>
                <div class="q-ml-xs ellipsis">
                  {{ selectedItem.work_item_name }}
                </div>
              </div>
            </div>

            <div class="row items-center">
              <q-btn
                rounded
                label="View"
                color="white"
                text-color="primary"
                @click="navigateToFarm"
              />
            </div>
          </div>
          <q-separator />
        </div>

        <!--Selected Todo Info-->
        <div v-if="selectedItem && selectedItem.type === 'todo'">
          <q-item style="margin-bottom: 0; margin-top: 0; padding: 10px">
            <q-item-section v-if="$q.screen.gt.md" avatar>
              <q-avatar color="primary" text-color="white" class="gt-md">
                {{ farmName(selectedItem.farm_id).charAt(0) }}
              </q-avatar>
            </q-item-section>

            <q-item-section>
              <q-item-label
                lines="1"
                class="row items-center no-wrap ellipsis"
                style="padding-top: 2px"
              >
                <div class="row no-wrap items-center text-primary ellipsis">
                  <q-btn
                    icon="arrow_back"
                    padding="0px sm"
                    class="lt-sm q-mr-sm"
                    color="primary"
                    @click="displayMode = 'list'"
                  />
                  <div class="ellipsis q-mr-sm">
                    {{ farmName(selectedItem.farm_id) }}
                  </div>
                  <div class="text-grey-7">
                    {{ parseTimeStamp(selectedItem.date) }}
                  </div>
                </div>
              </q-item-label>
              <q-item-label lines="1" class="row items-center no-wrap q-py-sm">
                <q-badge
                  rounded
                  :color="mapStatusColor(selectedItem.status)"
                  class="q-mr-sm"
                  style="min-width: 90px"
                  text-color="black"
                >
                  <div class="row justify-center full-width">
                    S - {{ selectedItem.status }}
                  </div>
                </q-badge>
                <q-badge
                  rounded
                  :color="mapPriorityColor(selectedItem.priority)"
                  style="min-width: 90px"
                  text-color="black"
                  class="q-mr-sm"
                >
                  <div class="row justify-center full-width">
                    P - {{ selectedItem.priority }}
                  </div>
                </q-badge>
                <q-space />
                <div
                  class="row items-center no-wrap text-green-8 ellipsis q-mr-md"
                ></div>
              </q-item-label>
            </q-item-section>

            <q-item-section avatar>
              <q-btn
                label="Notes"
                color="accent"
                icon="sticky_note_2"
                font-size="14px"
                class="q-mr-sm"
                size="10px"
                @click="showNote = true"
              />
            </q-item-section>
          </q-item>
          <q-separator />
        </div>

        <div>
          <div
            :style="`height: ${usableHeight - 62}px; width 100%`"
            class="scroll"
          >
            <div class="q-pt-md q-pb-sm q-px-md">
              <WorkLogChats
                v-if="selectedItem && selectedItem.type === 'workLog'"
                ref="WorkLogChats"
                :selectedLog="selectedItem"
                @typedMsg="(msg) => (chatTypedMsg = msg)"
              />
              <TodoChats
                v-if="selectedItem && selectedItem.type === 'todo'"
                ref="TodoChats"
                :selectedTodo="selectedItem"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- note display-->
    <q-dialog v-model="showNote">
      <q-card v-if="selectedItem" style="width: 700px; max-width: 80vw">
        <q-card-section>
          <div class="text-h6">{{ parseTimeStamp(selectedItem.date) }}</div>
          <div
            v-if="selectedItem.type === 'workLog'"
            :style="buildBackColor(selectedItem.color, 0.3)"
            class="text"
          >
            {{ selectedItem.work_cat_name }} : {{ selectedItem.work_item_name }}
          </div>
        </q-card-section>
        <q-card-section class="q-pt-none">
          <div
            v-if="selectedItem.type === 'workLog'"
            style="white-space: pre-line"
          >
            {{ selectedItem.notes }}
          </div>
          <div
            v-if="selectedItem.type === 'todo'"
            style="white-space: pre-line"
          >
            {{ selectedItem.note }}
          </div>
        </q-card-section>
        <q-separator />

        <q-card-section class="q-pt-lg">
          <Comments
            :parentObj="selectedItem"
            :commentType="selectedItem.type"
          />
        </q-card-section>
        <q-separator />

        <q-card-actions align="right">
          <q-btn flat label="OK" color="primary" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import store from '@/store';
import WorkLogChats from '@/components/workLogs/WorkLogChats';
import TodoChats from '@/components/TodoChats';
import Comments from '@/components/workLogs/Comments.vue';
import { parseTimeStamp } from '@/lib/date-utils';
import {
  chatInfo,
  countUnread,
  trimName,
  decoder,
  cloneObj
} from '@/lib/helpers';
import { v4 } from 'uuid';
import { colors, date } from 'quasar';

export default {
  name: 'ChatPage',
  components: {
    Comments,
    WorkLogChats,
    TodoChats
  },
  data() {
    return {
      chatTypedMsg: '',
      displayMode: 'list',
      numInList: 50,
      selectedItem: null,
      showNote: false,
      sortBy: 'date',
      usableHeight: 1000
    };
  },
  mounted() {
    this.onResize();
  },
  methods: {
    buildBackColor(color, opacity) {
      if (color) {
        return 'background-color: ' + colors.changeAlpha(color, opacity || 0.5);
      } else {
        return '';
      }
    },
    displayNote() {
      this.$q.dialog({
        title: 'Note',
        message: this.selectedItem.notes
      });
    },
    farmName(farmId) {
      const farm = this.$store.state.farmList.find(
        (e) => e.id === parseInt(farmId)
      );
      return farm ? decoder(farm.farm_name) : '---';
    },
    fieldDecoder(fieldDef, fields) {
      const item = fields[fieldDef.id];
      if (item) {
        switch (fieldDef.type) {
          case 'checkbox':
            return item || false;
          case 'date':
            return this.dateHelper(item);
          case 'number':
          case 'text':
          case 'fields':
            return item;
          default:
            return '!!';
        }
      } else {
        switch (fieldDef.type) {
          case 'checkbox':
            return false;
          default:
            return '--';
        }
      }
    },
    fieldList(itemId) {
      const workItem = this.$store.state.workItems.find(
        (element) => element.id === itemId
      );
      return workItem ? workItem.fields : [];
    },
    mapStatusColor(status) {
      return status === 'None'
        ? 'blue-grey'
        : status === 'Not started'
        ? 'deep-orange'
        : status === 'Working'
        ? 'blue'
        : status === 'Complete'
        ? 'green'
        : '';
    },
    mapPriorityColor(priority) {
      return priority === 'None'
        ? 'blue-grey'
        : priority === 'Low'
        ? 'blue'
        : priority === 'Medium'
        ? 'yellow-14'
        : priority === 'High'
        ? 'orange-10'
        : priority === 'Critical'
        ? 'red-14'
        : '';
    },
    navigateToFarm() {
      this.$store.dispatch('setWorkLogScrollTo', this.selectedItem.id);
      this.$router.push(`/farm/${this.selectedItem.farm_id}/work`);
    },
    onResize() {
      const height =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight;

      this.usableHeight = height - 52;
    },
    parseListTime(time) {
      if (!(time && !isNaN(time))) return '--';

      const today = new Date().setHours(0, 0, 0, 0);
      const msgDay = new Date(time).setHours(0, 0, 0, 0);
      if (today === msgDay) {
        return date.formatDate(time, 'h:mm A');
      } else {
        return date.formatDate(time, 'M/D/YY');
      }
    },
    parseTimeStamp(value) {
      return parseTimeStamp(value, 'short');
    },
    showMore() {
      this.numInList += 50;
    },
    viewList() {
      this.selectedItem = null;
      this.displayMode = 'list';
    },
    viewMessages(selectedItem) {
      if (this.chatTypedMsg !== '') {
        this.$q
          .dialog({
            title: 'Unsaved Text',
            message:
              'Are you sure you want to close chat? Unsaved text will be discarded.',
            ok: {
              label: 'Close',
              color: 'primary'
            },
            cancel: {
              label: 'Cancel',
              color: 'grey-2',
              textColor: 'primary'
            },
            focus: 'none',
            persistent: true
          })
          .onOk(() => {
            this.viewMessagesConfirmed(selectedItem);
          });
        return;
      }

      this.viewMessagesConfirmed(selectedItem);
    },
    viewMessagesConfirmed(selectedItem) {
      this.chatTypedMsg = '';

      this.selectedItem = null;

      this.$nextTick(() => {
        this.selectedItem = cloneObj(selectedItem);
        this.displayMode = 'chat';
      });
    }
  },
  computed: {
    currUserId() {
      return this.$store.state.user.user_id;
    },
    isSuperAdmin() {
      return this.$store.state.user.aerworx_level === 'super-admin';
    },
    Chats() {
      if (
        this.$store.state.workLogs.length === 0 &&
        this.$store.state.todos.length === 0
      ) {
        return [];
      }
      const logs = JSON.parse(JSON.stringify(this.$store.state.workLogs));
      const todos = JSON.parse(JSON.stringify(this.$store.state.todos));

      const chats = [];

      for (let i = 0; i < logs.length; i++) {
        if (logs[i].chats.length) {
          const item = this.$store.getters.workItem(logs[i].work_item);
          const cat = this.$store.getters.workCategory(logs[i].work_cat);
          if (cat && item) {
            logs[i].work_cat_name = cat.item;
            logs[i].color = cat.def_color;
            logs[i].work_item_name = item.name;
            logs[i].master_billable = item.billable;
          }

          const chatInfoObj = chatInfo(logs[i].chats, this.currUserId);

          logs[i].numUnread = chatInfoObj.numUnread;
          logs[i].numStarred = chatInfoObj.numStarred;
          logs[i].latestMsgTs = chatInfoObj.latestMsgTs;
          logs[i].hasChat = chatInfoObj.isPartOfChat || this.isSuperAdmin;
          logs[i].isUser = logs[i].user_id === this.currUserId;
          logs[i].type = 'workLog';
          logs[i].uniqueId = logs[i].id + 'workLog';

          if (logs[i].hasChat) {
            chats.push(logs[i]);
          }
        }
      }

      for (let i = 0; i < todos.length; i++) {
        if (todos[i].chats.length) {
          const chatInfoObj = chatInfo(todos[i].chats, this.currUserId);

          todos[i].latestMsgTs = chatInfoObj.latestMsgTs;
          todos[i].numUnread = chatInfoObj.numUnread;
          todos[i].numStarred = chatInfoObj.numStarred;
          todos[i].numImportant =
            chatInfoObj.numUnread + chatInfoObj.numStarred;
          todos[i].hasChat =
            chatInfoObj.isPartOfChat ||
            (todos[i].chats.length > 0 && this.isSuperAdmin);
          todos[i].isUser = todos[i].user_id === this.currUserId;
          todos[i].type = 'todo';
          todos[i].uniqueId = todos[i].id + 'todo';

          if (todos[i].hasChat) {
            chats.push(todos[i]);
          }
        }
      }

      return chats;
    },
    ChatsSorted() {
      const chats = this.Chats.slice();

      if (this.sortBy === 'starred') {
        chats.sort((a, b) => {
          if (a.numStarred < 1 && b.numStarred < 1) {
            return b.latestMsgTs - a.latestMsgTs;
          }
          return b.numStarred - a.numStarred;
        });
      } else if (this.sortBy === 'unread') {
        chats.sort((a, b) => {
          if (a.numUnread < 1 && b.numUnread < 1) {
            return b.latestMsgTs - a.latestMsgTs;
          }
          return b.numUnread - a.numUnread;
        });
      } else {
        chats.sort((a, b) => b.latestMsgTs - a.latestMsgTs);
      }

      if (chats.length > this.numInList) {
        return chats.slice(0, this.numInList);
      }

      return chats;
    },
    showChat() {
      return this.$q.screen.gt.xs || this.displayMode === 'chat';
    },
    showList() {
      return this.$q.screen.gt.xs || this.displayMode === 'list';
    },
    showMoreBtn() {
      return this.Chats.length >= this.numInList;
    }
  },
  watch: {
    '$store.state.workLogs'() {
      if (this.selectedItem) {
        const log = this.$store.state.workLogs.find((e) => {
          return e.id === this.selectedItem.id;
        });

        if (!log) {
          return;
        }

        const chatInfoObj = chatInfo(log.chats, this.currUserId);

        const item = this.$store.getters.workItem(log.work_item);
        const cat = this.$store.getters.workCategory(log.work_cat);
        if (cat && item) {
          log.work_cat_name = cat.item;
          log.color = cat.def_color;
          log.work_item_name = item.name;
          log.master_billable = item.billable;
          log.numUnread = chatInfoObj.numUnread;
          log.numStarred = chatInfoObj.numStarred;
          log.latestMsgTs = chatInfoObj.latestMsgTs;
          log.isPartOfChat =
            chatInfoObj.isPartOfChat || (log.chats.length && this.isSuperAdmin);
          log.isUser = log.user_id === this.currUserId;
          log.type = 'workLog';
          log.uniqueId = log.id + 'workLog';
        }
        this.selectedItem = JSON.parse(JSON.stringify(log));
      }
    },
    '$store.state.todos'() {
      if (this.selectedItem) {
        const selectedItem = this.$store.state.todos.find(
          (e) => e.id === this.selectedItem.id
        );

        if (!selectedItem) {
          return;
        }

        const chatInfoObj = chatInfo(selectedItem.chats, this.currUserId);

        selectedItem.numUnread = chatInfoObj.numUnread;
        selectedItem.numStarred = chatInfoObj.numStarred;
        selectedItem.numImportant =
          chatInfoObj.numUnread + chatInfoObj.numStarred;
        selectedItem.latestMsgTs = chatInfoObj.latestMsgTs;
        selectedItem.isPartOfChat =
          chatInfoObj.isPartOfChat || (log.chats.length && this.isSuperAdmin);
        selectedItem.isUser = selectedItem.user_id === this.currUserId;
        selectedItem.type = 'todo';
        selectedItem.uniqueId = selectedItem.id + 'workLog';

        this.selectedItem = JSON.parse(JSON.stringify(selectedItem));
      }
    }
  }
};
</script>

<style scoped></style>
