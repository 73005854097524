<template>
  <div style="padding-top: 2px; padding-left: 2px; padding-right: 2px">
    <q-table
      title="Sms Records"
      :data="Records"
      :columns="columns"
      :pagination="pagination"
      row-key="name"
      dense
      no-data-label="No Records"
    >
      <template v-slot:top>
        <div class="row justify-center full-width q-pa-md">
          <!-- Filtering -->
          <div class="q-gutter-md row">
            <div>
              <q-btn-dropdown
                color="blue-14"
                outline
                padding="8px 10px"
                label="Filters"
              >
                <div class="row no-wrap q-pa-md">
                  <div class="column">
                    <div class="text-h6 q-mb-sm">Filters</div>
                    <div v-for="(item, index) in statusFilter" :key="index">
                      <q-toggle v-model="item.show" :label="item.name" />
                    </div>
                  </div>
                </div>
              </q-btn-dropdown>
            </div>
          </div>
        </div>
      </template>
    </q-table>
  </div>
</template>
<script>
import { decoder } from '@/lib/helpers';
import store from '@/store';
import { parseTimeStamp } from '@/lib/date-utils';

export default {
  name: 'SmsRecords',
  components: {},
  data() {
    return {
      twilio: [
        {
          code: 21610,
          description: 'Unsubscribed recipient'
        },
        {
          code: 30001,
          description: 'Queue overflow'
        },
        {
          code: 30002,
          description: 'Account suspended'
        },
        {
          code: 30003,
          description: 'Unreachable handset'
        },
        {
          code: 30004,
          description: 'Message blocked'
        },
        {
          code: 30005,
          description: 'Unknown handset'
        },
        {
          code: 30006,
          description: 'Landline or unreachable carrier'
        },
        {
          code: 30007,
          description: 'Message filtered'
        }
      ],
      pagination: {
        descending: false,
        page: 1,
        rowsPerPage: 200,
        sortBy: 'ID'
      },
      columns: [
        {
          sortable: true,
          name: 'Phone',
          label: 'Phone',
          align: 'left',
          field: (row) => this.phoneParser(row.phone)
        },
        {
          sortable: true,
          name: 'Status',
          label: 'Status',
          align: 'left',
          field: (row) => decoder(row.status)
        },
        {
          sortable: true,
          name: 'Error',
          label: 'Error',
          align: 'left',
          field: (row) => this.errorMessageDecoder(row.ErrorMessage)
        },
        {
          sortable: true,
          name: 'Send time',
          label: 'Send time',
          align: 'left',
          field: (row) => parseTimeStamp(row.sendTime)
        },
        {
          sortable: true,
          name: 'Message',
          label: 'Message',
          align: 'left',
          field: (row) => decoder(row.message)
        }
      ],
      statusFilter: [
        { name: 'accepted', show: false },
        { name: 'scheduled', show: false },
        { name: 'queued', show: true },
        { name: 'sending', show: true },
        { name: 'sent', show: false },
        { name: 'delivery_unknown', show: true },
        { name: 'delivered', show: false },
        { name: 'undelivered', show: true },
        { name: 'failed', show: true }
      ]
    };
  },
  methods: {
    phoneParser(phone) {
      try {
        const prefix = phone.substring(0, 2);
        const area = phone.substring(2, 5);
        const local = phone.substring(5, 8);
        const num = phone.substring(8, 12);
        return `${area}.${local}.${num}`;
      } catch {
        return phone;
      }
    },
    errorMessageDecoder(error) {
      if (isNaN(parseInt(error))) {
        return error;
      }
      const err = this.twilio.find((val) => {
        return val.code === parseInt(error);
      });
      if (err) {
        return err.description;
      }
      return error;
    }
  },
  computed: {
    Records() {
      let records = JSON.parse(JSON.stringify(this.$store.state.smsRecords));
      records = records.filter((rcd) => {
        return (
          this.statusFilter.findIndex(
            (flt) => flt.name === rcd.status && flt.show
          ) !== -1
        );
      });
      return records;
    }
  }
};
</script>
