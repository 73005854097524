<template>
  <q-card style="width: 450px">
    <div class="row items-center q-py-md q-mx-md">
      <div class="col text-center">
        <div class="text-weight-medium q-px-sm" style="font-size: 18px">
          {{ decoder(farm.farm_name) }}
        </div>
        <div class="row flex-center">
          <div v-if="farm.serviceDate > 0" class="q-pr-sm">
            {{ formatDate(farm.serviceDate) }}
          </div>
          <div v-else class="q-pr-md">---</div>
          <div>
            <q-icon
              v-if="farm.asmLevel > 0"
              :color="
                farm.asmLevel === 1
                  ? 'yellow-7'
                  : farm.asmLevel === 2
                  ? 'orange-14'
                  : 'red-14'
              "
              :name="farm.asmLevel === 4 ? 'warning' : 'error'"
              size="sm"
            />
            <q-icon
              v-if="farm.asmLevel === 0 && farm.isSnoozed"
              color="primary"
              name="alarm"
              size="sm"
            />
          </div>
        </div>
      </div>
      <q-btn
        unelevated
        icon="swap_vert"
        color="grey-3"
        text-color="primary"
        class="q-mr-sm"
        @click="sortByASM = !sortByASM"
      />
      <q-btn unelevated icon="clear" color="primary" v-close-popup />
    </div>
    <q-separator />

    <div class="scroll" style="max-height: calc(93vh - 88px)">
      <div v-for="(pond, i) in FarmASM" :key="pond.id">
        <div class="row justify-between q-py-sm q-px-md">
          <div class="cursor-pointer">
            <span
              :style="
                'border-bottom: solid 1px ' +
                ($q.dark.isActive ? 'white' : 'black')
              "
              @click="goToPond(pond.id)"
            >
              {{ decodeURIComponent(pond.name) }}
            </span>
          </div>
          <div class="row items-center">
            <div v-if="pond.latestService > 0" class="q-pr-sm">
              {{ formatDate(pond.latestService) }}
            </div>
            <div v-else class="q-pr-md">---</div>
            <div>
              <q-icon
                v-if="pond.asmLevel === 0 && pond.asmSnoozed"
                color="primary"
                name="alarm"
                size="sm"
                class="cursor-pointer"
                @click="setASMSnooze(pond, false)"
              />
              <q-icon
                v-if="pond.asmLevel > 0"
                :color="
                  pond.asmLevel === 1
                    ? 'yellow-7'
                    : pond.asmLevel === 2
                    ? 'orange-14'
                    : 'red-14'
                "
                :name="pond.asmLevel === 4 ? 'warning' : 'error'"
                size="sm"
                class="cursor-pointer"
                @click="setASMSnooze(pond, true)"
              />
              <div
                v-if="pond.asmLevel === 0 && !pond.asmSnoozed"
                style="width: 24px"
              ></div>
            </div>
          </div>
        </div>
        <q-separator v-if="i !== farm.farmASM.length - 1" />
      </div>
    </div>
  </q-card>
</template>

<script>
import store from '@/store';
import { date } from 'quasar';
import { cloneObj, decoder } from '@/lib/helpers';

export default {
  name: 'ASMDetail',
  props: {
    farm: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      decoder,
      sortByASM: false
    };
  },
  mounted() {},
  methods: {
    formatDate(ts) {
      return date.formatDate(ts * 1000, 'MM/DD/YY');
    },
    goToPond(pondId) {
      this.$store.dispatch('setFarmScrollTo', {
        id: this.farm.id,
        goStatus: 'active'
      });

      const route = this.$route.name;
      if (route !== 'Farm-Work') {
        this.$store.dispatch('setPondFilter', pondId);

        this.$router.push(`farm/${this.farm.id}/work`);
      } else {
        this.$emit('setPondFilter', pondId);
      }
    },
    setASMSnooze(pond, snooze) {
      this.$q
        .dialog({
          title: snooze ? 'Snooze ASM' : 'Unsnooze ASM',
          message: snooze
            ? 'Are you sure you want to snooze this ASM for 1 month?'
            : 'Are you sure you want to unsnooze this ASM?',
          ok: {
            label: snooze ? 'Snooze' : 'Unsnooze',
            color: 'primary'
          },
          cancel: true,
          focus: 'none',
          persistent: true
        })
        .onOk(() => {
          this.setASMSnoozeConfirmed(pond, snooze);
        });
    },
    setASMSnoozeConfirmed(pond, snooze) {
      const pondObj = cloneObj(pond);
      const oneMo = date.addToDate(new Date(), { month: 1 });
      pondObj.snoozeDate = snooze ? parseInt(+oneMo / 1000) : null;
      pondObj.farmId = this.farm.id;
      pondObj.farm_id = this.farm.id;

      store.dispatch('setASMSnooze', pondObj);
      this.$emit('setASM', true);
    }
  },
  computed: {
    FarmASM() {
      const ASM = cloneObj(this.farm.farmASM);

      return this.sortByASM
        ? ASM.sort((a, b) => {
            if (a.asmLevel === b.asmLevel && a.asmSnoozed === b.asmSnoozed) {
              return a.latestService - b.latestService;
            }

            if (a.asmLevel === 0 && b.asmLevel === 0) {
              return b.asmSnoozed - a.asmSnoozed;
            }

            return b.asmLevel - a.asmLevel;
          })
        : ASM.sort((a, b) => {
            const aName = a.name.toLowerCase();
            const bName = b.name.toLowerCase();

            const aIsHB = aName.includes('hb') || aName.includes('harvest');
            const bIsHB = bName.includes('hb') || bName.includes('harvest');

            if (aIsHB && !bIsHB) {
              return 1;
            }

            if (!aIsHB && bIsHB) {
              return -1;
            }

            return a.name.localeCompare(b.name, undefined, {
              numeric: true,
              sensitivity: 'base'
            });
          });
    }
  }
};
</script>

<style scoped></style>
