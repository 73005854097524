<template>
  <div>
    <!-- Larger Screens -->
    <div
      v-if="$q.screen.gt.md"
      :id="workLog.id"
      :style="scrollToId === workLog.id && getBackColor('#bdbdbd', 0.3)"
      style="font-size: 13px"
      class="row items-center full-width q-py-xs q-px-xs"
    >
      <div class="col-auto q-px-xs">
        {{ parseTimeStamp(workLog.date, 'short') }}
      </div>

      <div class="col-auto q-px-sm">
        <q-spinner
          v-if="todoClickLoading"
          color="primary"
          size="16px"
          :thickness="7"
        />
        <q-btn
          v-if="!todoClickLoading"
          unelevated
          :loading="todoClickLoading"
          :color="getToDoColor(workLog.todo_flag)"
          style="width: 16px; height: 10px"
          @click="(evt) => todoClick(evt, workLog)"
        />
      </div>

      <div
        class="col-auto text-center ellipsis q-px-xs"
        style="width: 130px; font-size: 14px"
        :style="workLog.location_name && 'padding-top: 4px'"
      >
        <span class="cursor-pointer" @click="goToFarm(workLog)">
          {{ decoder(farmNames[workLog.farm_id]) || '' }}
        </span>
        <div v-if="workLog.location_name" class="text-primary">
          {{ workLog.location_name }}
        </div>
      </div>

      <div class="col-auto q-px-xs">
        <div
          class="col-auto rounded-borders text-center text-caption q-px-xs"
          :style="getBackColor(workLog.color, 0.2)"
          style="width: 140px"
        >
          <div class="ellipsis">{{ workLog.work_cat_name }}</div>
          <div class="ellipsis">
            {{ workLog.work_item_name }}
          </div>
        </div>
      </div>

      <div class="col-auto q-px-xs">
        <div class="ellipsis text-center" style="width: 80px">
          {{ pondOrPcName(workLog.pond_id, workLog.computer_id) }}
          <q-tooltip :delay="700">
            {{ pondOrPcName(workLog.pond_id, workLog.computer_id) }}
          </q-tooltip>
        </div>
      </div>

      <div class="col-auto text-center q-pr-sm" style="width: 34px">
        <span v-if="valueType(workLog.work_item) !== 'fields'">
          {{ parseFloat(workLog.time) !== 0 ? parseFloat(workLog.time) : '' }}
        </span>
        <span v-else>
          <q-icon
            name="checklist_rtl"
            class="cursor-pointer"
            size="26px"
            color="primary"
            @click="dialogFieldValues = true"
          >
            <q-tooltip
              :content-class="
                $q.dark.isActive
                  ? 'bg-dark text-white q-pa-none'
                  : 'bg-white text-black q-pa-none'
              "
              content-style="font-size: 14px; margin: 0 30px"
              :offset="[10, 10]"
              max-width="500px"
              :delay="400"
            >
              <div
                :style="`border: 2px solid ${colorPallete.primary}; border-radius: 5px`"
              >
                <q-card>
                  <q-card-section>
                    <div class="text-h6">
                      {{ parseTimeStamp(workLog.date) }}
                    </div>
                    <div
                      :style="buildBackColor(workLog.color, 0.3)"
                      class="text"
                    >
                      {{ workLog.work_cat_name }} : {{ workLog.work_item_name }}
                    </div>
                  </q-card-section>

                  <q-card-section class="q-pt-none">
                    <div
                      v-for="(field, index) in fieldList(workLog.work_item)"
                      :key="index"
                      class="row"
                    >
                      <div class="col-8" style="min-width: 250px">
                        {{ decoder(field.name) }}
                      </div>
                      <div class="col">
                        <template v-if="field.type === 'checkbox'">
                          <q-icon
                            class="check-style check-color"
                            v-if="fieldDecoder(field, workLog.field_values)"
                            name="check"
                          ></q-icon>
                          <q-icon v-else color="negative" name="clear"></q-icon>
                        </template>
                        <template v-else>
                          {{ fieldDecoder(field, workLog.field_values) }}
                        </template>
                      </div>
                    </div>
                  </q-card-section>
                </q-card>
              </div>
            </q-tooltip>
          </q-icon>
        </span>
      </div>

      <div
        class="col-auto q-px-xs cursor-pointer"
        @click="dialogAttachments = true"
      >
        <q-icon
          name="image"
          size="sm"
          :color="workLog.attachments.length ? 'primary' : 'grey-icon'"
        />
      </div>

      <div class="col-auto q-px-xs cursor-pointer" @click="dialogChats = true">
        <q-icon
          name="forum"
          size="sm"
          :color="workLog.chats.length ? 'primary' : 'grey-icon'"
        />
      </div>

      <div
        class="col-auto q-px-xs cursor-pointer"
        @click="dialogReminders = true"
      >
        <q-icon
          name="alarm"
          size="sm"
          :color="workLog.reminderStatus.hasReminder ? 'primary' : 'grey-icon'"
        />
      </div>

      <div class="col q-px-xs">
        <div
          class="ellipsis-3-lines cursor-pointer"
          style="height: 58.5px"
          @click="dialogOfficeNotes = true"
        >
          <span
            v-if="workLog.office_comments.length && isSuperAdmin"
            @click="dialogOfficeComments = true"
          >
            <q-icon
              name="speaker_notes"
              color="primary"
              size="20px"
              class="q-mr-sm"
            />
          </span>
          <span v-html="createHyperLink(workLog.officeNotes)"></span>
          <span v-if="workLog.emailMsg.length > 0" class="text-weight-light">
            {{ workLog.emailMsg }}
          </span>

          <q-tooltip
            :content-class="
              $q.dark.isActive
                ? 'bg-dark text-white q-pa-none'
                : 'bg-white text-black q-pa-none'
            "
            content-style="font-size: 14px; margin: 0 30px"
            :offset="[10, 10]"
            max-width="800px"
            :delay="400"
          >
            <div
              class="q-px-lg q-pt-lg"
              :class="!workLog.office_comments.length ? 'q-pb-lg' : 'q-pb-md'"
              :style="`border: 2px solid ${colorPallete.primary}; border-radius: 5px;`"
            >
              <span
                style="white-space: pre-wrap"
                v-html="createHyperLink(workLog.office_notes)"
              >
              </span>
              <span
                v-if="workLog.emailMsg.length > 0"
                class="text-weight-light"
              >
                {{ workLog.emailMsg }}
              </span>

              <OfficeComments
                v-if="workLog.office_comments.length"
                :parentObj="workLog"
                :commentType="'workLog'"
                :tooltip="true"
              />
            </div>
          </q-tooltip>
        </div>
      </div>

      <div class="col q-px-xs">
        <div
          class="ellipsis-3-lines cursor-pointer"
          style="height: 58.5px"
          @click="dialogNotes = true"
        >
          <span v-if="workLog.comments.length">
            <q-icon
              name="chat_bubble_outline"
              color="primary"
              size="20px"
              class="q-mr-sm"
            />
          </span>
          <span v-html="createHyperLink(workLog.notes)"> </span>
          <q-tooltip
            :content-class="
              $q.dark.isActive
                ? 'bg-dark text-white q-pa-none'
                : 'bg-white text-black q-pa-none'
            "
            content-style="font-size: 14px; margin: 0 30px"
            :offset="[10, 10]"
            max-width="800px"
            :delay="400"
          >
            <div
              class="q-px-lg q-pt-lg"
              :class="!workLog.comments.length ? 'q-pb-lg' : 'q-pb-md'"
              :style="`border: 2px solid ${colorPallete.primary}; border-radius: 5px`"
            >
              <span
                style="white-space: pre-wrap"
                v-html="createHyperLink(workLog.notes)"
              >
              </span>

              <Comments
                v-if="workLog.comments.length"
                :parentObj="workLog"
                :commentType="'workLog'"
                :tooltip="true"
              />
            </div>
          </q-tooltip>
        </div>
      </div>

      <div class="col-auto q-px-xs">
        <div>
          <q-btn-toggle
            size="sm"
            padding="1px"
            glossy
            :text-color="
              workLog.billable
                ? $q.dark.isActive
                  ? 'white'
                  : 'black'
                : 'grey-5'
            "
            :value="workLog.billed"
            :toggle-color="getBilledColor(workLog.billed)"
            :options="getBilledOptions(workLog)"
          >
            <template v-slot:unBilled>
              <div class="q-pa-xs" @click="(evt) => setBilled(evt, 0, workLog)">
                <q-icon name="money_off" size="xs">
                  <q-tooltip
                    v-if="workLog.billed === 0 && workLog.billed_changed_by"
                    content-class="bg-grey-3"
                  >
                    <div class="text-black">
                      Changed By:
                      <br />
                      {{ userNames[workLog.billed_changed_by] }}
                      <br />
                      <br />
                      Changed On:
                      <br />
                      {{ parseTimeStamp(workLog.billed_changed_on) }}
                    </div>
                  </q-tooltip>
                </q-icon>
              </div>
            </template>
            <template v-slot:ready>
              <div class="q-pa-xs" @click="(evt) => setBilled(evt, 1, workLog)">
                <q-icon name="attach_money" size="xs">
                  <q-tooltip
                    v-if="workLog.billed === 1"
                    content-class="bg-grey-3"
                  >
                    <div class="text-black">
                      Changed By:
                      <br />
                      {{ userNames[workLog.billed_changed_by] }}
                      <br />
                      <br />
                      Changed On:
                      <br />
                      {{ parseTimeStamp(workLog.billed_changed_on) }}
                    </div>
                  </q-tooltip>
                </q-icon>
              </div>
            </template>
            <template v-slot:billed>
              <div class="q-pa-xs" @click="(evt) => setBilled(evt, 2, workLog)">
                <q-icon name="receipt_long" size="xs">
                  <q-tooltip
                    v-if="workLog.billed === 2"
                    content-class="bg-grey-3"
                  >
                    <div class="text-black">
                      Changed By:
                      <br />
                      {{ userNames[workLog.billed_changed_by] }}
                      <br />
                      <br />
                      Changed On:
                      <br />
                      {{ parseTimeStamp(workLog.billed_changed_on) }}
                    </div>
                  </q-tooltip>
                </q-icon>
              </div>
            </template>
          </q-btn-toggle>
        </div>
      </div>

      <div class="col-auto ellipsis text-center q-px-xs" style="width: 75px">
        {{ userNames[workLog.assigned_to] || '- - -' }}
      </div>

      <div class="col-auto q-px-xs">
        <q-btn
          outline
          icon="edit"
          size="md"
          padding="xs"
          color="primary"
          @click="editLog(workLog)"
        />
      </div>
    </div>

    <!-- Smaller Screens -->
    <div v-if="$q.screen.lt.lg" :id="workLog.id">
      <div
        class="row q-pa-md"
        :style="scrollToId === workLog.id && getBackColor('#bdbdbd', 0.2)"
      >
        <div class="col-12 row items-start">
          <div class="col-12 text-center text-subtitle1 cursor-pointer q-pb-md">
            <span
              :style="
                'border-bottom: solid 1px ' +
                ($q.dark.isActive ? 'white' : 'black')
              "
              @click="goToFarm(workLog)"
            >
              {{ decoder(farmNames[workLog.farm_id]) || '' }}
            </span>
            <div v-if="workLog.location_name" class="text-primary">
              {{ workLog.location_name }}
            </div>

            <div class="col-12">
              {{ pondOrPcName(workLog.pond_id, workLog.computer_id) }}
            </div>
          </div>

          <div class="col-12 row items-center">
            <div class="row items-center">
              <q-btn
                unelevated
                :color="getToDoColor(workLog.todo_flag)"
                style="width: 16px; height: 12px"
                @click="(evt) => todoClick(evt, workLog)"
              />
              <div class="q-pl-sm">
                {{ new Date(workLog.date * 1000).toLocaleDateString() }}
              </div>
            </div>
            <q-space />
          </div>

          <div class="col-12 row q-pt-sm">
            <div
              class="col-12 row rounded-borders text-caption text-center q-px-xs"
              :style="getBackColor(workLog.color, 0.2)"
            >
              <div style="width: 120px">
                {{ workLog.work_cat_name }}
              </div>
              <q-separator vertical />
              <div class="col ellipsis q-pl-xs">
                {{ workLog.work_item_name }}
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 row justify-between q-pt-sm">
          <div>
            <q-btn-toggle
              size="sm"
              padding="1px"
              glossy
              :text-color="
                workLog.billable
                  ? $q.dark.isActive
                    ? 'white'
                    : 'black'
                  : 'grey-5'
              "
              :value="workLog.billed"
              :toggle-color="getBilledColor(workLog.billed)"
              :options="getBilledOptions(workLog)"
            >
              <template v-slot:unBilled>
                <div
                  class="q-pa-xs"
                  @click="(evt) => setBilled(evt, 0, workLog)"
                >
                  <q-icon name="money_off" size="xs" />
                </div>
              </template>
              <template v-slot:ready>
                <div
                  class="q-pa-xs"
                  @click="(evt) => setBilled(evt, 1, workLog)"
                >
                  <q-icon name="attach_money" size="xs" />
                </div>
              </template>
              <template v-slot:billed>
                <div
                  class="q-pa-xs"
                  @click="(evt) => setBilled(evt, 2, workLog)"
                >
                  <q-icon name="receipt_long" size="xs" />
                </div>
              </template>
            </q-btn-toggle>
            <q-btn
              glossy
              color="white"
              text-color="blue-grey"
              icon="info"
              size="sm"
              padding="5.5px 6px"
              class="q-ml-sm"
              @click="displayBilledInfo(workLog)"
            />
          </div>

          <div class="row items-center justify-end">
            <div
              v-if="valueType(workLog.work_item) == 'fields'"
              class="q-pr-sm"
            >
              <q-btn
                icon="checklist_rtl"
                size="16px"
                color="grey-1"
                text-color="primary"
                padding="none 1px"
                @click="dialogFieldValues = true"
              >
              </q-btn>
            </div>

            <div
              class="row flex-center rounded-borders bg-grey-3 q-px-xs"
              :class="
                workLog.assigned_to === currUserId
                  ? 'text-primary text-bold'
                  : 'text-black'
              "
              style="width: 80px; height: 28px"
            >
              {{ userNames[workLog.assigned_to] || '- - -' }}
            </div>
          </div>
        </div>

        <div
          v-if="valueType(workLog.work_item) !== 'fields' && time !== ''"
          class="col-12 q-pt-sm"
        >
          <span class="text-bold text-primary q-pr-xs"> Time: </span>
          <span>
            {{ parseFloat(workLog.time) !== 0 ? parseFloat(workLog.time) : '' }}
          </span>
        </div>

        <div v-if="workLog.office_notes !== ''" class="col-12 q-pt-sm">
          <div>
            <span class="text-bold text-primary q-pr-sm">Office Note:</span>
            <span
              style="white-space: pre-wrap"
              v-html="createHyperLink(workLog.officeNotes)"
            ></span>
            <span v-if="workLog.emailMsg.length > 0" class="text-grey-7">
              {{ workLog.emailMsg }}
            </span>
          </div>
        </div>

        <div v-if="workLog.notes !== ''" class="col-12 q-pt-sm">
          <div>
            <span class="text-bold text-primary q-pr-sm">Note:</span>
            <span
              style="white-space: pre-wrap"
              v-html="createHyperLink(workLog.notes)"
            ></span>
          </div>
        </div>

        <div class="col-12 row justify-end q-pt-md">
          <q-btn
            rounded
            color="grey-1"
            :text-color="
              workLog.reminderStatus.hasReminder ? 'accent' : 'primary'
            "
            icon="alarm"
            size="md"
            padding="1px 8px"
            class="q-mr-sm"
            @click="dialogReminders = true"
          />
          <q-btn
            rounded
            color="grey-1"
            :text-color="workLog.attachments.length ? 'accent' : 'primary'"
            icon="image"
            size="md"
            padding="1px 8px"
            class="q-mr-sm"
            @click="dialogAttachments = true"
          />
          <q-btn
            rounded
            icon="forum"
            color="grey-1"
            :text-color="workLog.chats.length ? 'accent' : 'primary'"
            size="md"
            padding="1px 8px"
            class="q-mr-sm"
            @click="dialogChats = true"
          />
          <q-btn
            v-if="isSuperAdmin"
            rounded
            icon="speaker_notes"
            color="grey-1"
            :text-color="workLog.office_comments.length ? 'accent' : 'primary'"
            size="md"
            padding="1px 8px"
            class="q-mr-sm"
            @click="dialogOfficeNotes = true"
          />
          <q-btn
            rounded
            icon="chat_bubble_outline"
            color="grey-1"
            :text-color="workLog.comments.length ? 'accent' : 'primary'"
            size="md"
            padding="1px 8px"
            class="q-mr-sm"
            @click="dialogNotes = true"
          />
          <q-btn
            rounded
            icon="edit"
            size="md"
            color="grey-1"
            text-color="primary"
            padding="1px 8px"
            @click="editLog(workLog)"
          />
        </div>
      </div>
      <q-separator size="4px" color="grey-5" />
    </div>

    <!-- edit dialog -->
    <q-dialog position="top" v-model="dialogEditWorklog" persistent>
      <WorkLogEdit
        :farmId="selectedWorkLog.farm_id"
        :workLog="selectedWorkLog"
        @cancel="dialogEditWorklog = false"
      />
    </q-dialog>

    <!-- field values dialog -->
    <q-dialog position="top" v-model="dialogFieldValues">
      <q-card>
        <q-card-section>
          <div class="text-h6">{{ parseTimeStamp(workLog.date) }}</div>
          <div :style="buildBackColor(workLog.color, 0.3)" class="text">
            {{ workLog.work_cat_name }} : {{ workLog.work_item_name }}
          </div>
        </q-card-section>

        <q-card-section class="q-pt-none">
          <div
            v-for="(field, index) in fieldList(workLog.work_item)"
            :key="index"
            class="row"
          >
            <div class="col-8" style="min-width: 250px">
              {{ decoder(field.name) }}
            </div>
            <div class="col">
              <template v-if="field.type === 'checkbox'">
                <q-icon
                  class="check-style check-color"
                  v-if="fieldDecoder(field, workLog.field_values)"
                  name="check"
                ></q-icon>
                <q-icon v-else color="negative" name="clear"></q-icon>
              </template>
              <template v-else>
                {{ fieldDecoder(field, workLog.field_values) }}
              </template>
            </div>
          </div>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn flat label="OK" color="primary" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>

    <!-- note dialog -->
    <q-dialog persistent position="top" v-model="dialogNotes">
      <q-card style="width: 800px">
        <div class="row items-center q-ma-md">
          <div class="col text-center text-h5 text-primary">Notes</div>
          <q-btn unelevated icon="clear" color="primary" v-close-popup />
        </div>
        <q-separator color="primary" />

        <div class="scroll q-pa-md" style="max-height: calc(100vh - 200px)">
          <span
            style="white-space: pre-wrap"
            v-html="createHyperLink(workLog.officeNotes)"
          />
        </div>
        <q-separator color="primary" />

        <div class="scroll q-pa-md" style="max-height: calc(100vh - 200px)">
          <Comments
            :parentObj="workLog"
            :commentType="'workLog'"
            :tooltip="false"
          />
        </div>
      </q-card>
    </q-dialog>

    <!-- officeNote dialog -->
    <q-dialog persistent position="top" v-model="dialogOfficeNotes">
      <q-card style="width: 800px">
        <div class="row items-center q-ma-md">
          <div class="col text-center text-h5 text-primary">Office Notes</div>
          <q-btn unelevated icon="clear" color="primary" v-close-popup />
        </div>
        <q-separator color="primary" />

        <div class="scroll q-pa-md" style="max-height: calc(100vh - 200px)">
          <span
            style="white-space: pre-wrap"
            v-html="createHyperLink(workLog.officeNotes)"
          >
          </span>
          <span v-if="workLog.emailMsg.length > 0" class="text-weight-light">
            {{ workLog.emailMsg }}
          </span>
        </div>

        <div v-if="isSuperAdmin">
          <q-separator color="primary" />

          <div class="scroll q-pa-md" style="max-height: calc(100vh - 200px)">
            <OfficeComments
              :parentObj="workLog"
              :commentType="'workLog'"
              :tooltip="false"
            />
          </div>
        </div>
      </q-card>
    </q-dialog>

    <!-- chat dialog -->
    <q-dialog persistent position="top" v-model="dialogChats">
      <q-card style="width: 800px">
        <div class="row items-center q-ma-md">
          <div class="col text-center text-h5 text-primary">Chats</div>
          <q-btn unelevated icon="clear" color="primary" @click="closeChat" />
        </div>
        <q-separator color="primary" style="padding-top 1px" />

        <div class="scroll q-pa-md" style="max-height: calc(100vh - 200px)">
          <WorkLogChats
            :selectedLog="workLog"
            ref="WorkLogChats"
            @typedMsg="(msg) => (chatTypedMsg = msg)"
          />
        </div>
      </q-card>
    </q-dialog>

    <!-- attachment dialog -->
    <q-dialog
      v-model="dialogAttachments"
      persistent
      :maximized="true"
      transition-show="slide-up"
      transition-hide="slide-down"
    >
      <q-card>
        <q-card-actions>
          <q-btn
            outline
            label="Back"
            color="primary"
            icon="arrow_back"
            padding="xs sm"
            v-close-popup
          />
        </q-card-actions>
        <q-separator />

        <q-card-section
          style="height: calc(100vh - 60px)"
          class="scroll q-pt-lg"
        >
          <Attachments :parentObj="workLog" :parentType="'workLog'" />
        </q-card-section>
      </q-card>
    </q-dialog>

    <!-- reminder dialog-->
    <q-dialog persistent v-model="dialogReminders">
      <WorkLogReminders :workLog="workLog" :farmId="workLog.farm_id" />
    </q-dialog>

    <!-- billed info dialog -->
    <q-dialog persistent v-model="dialogBilledInfo">
      <q-card class="q-pa-sm" style="width: 200px">
        <div class="text-black">
          Changed By:
          <br />
          {{ userNames[billedInfo.billed_changed_by] }}
          <br />
          <br />
          Changed On:
          <br />
          {{ parseTimeStamp(billedInfo.billed_changed_on) }}
        </div>
        <div class="row justify-end q-pt-md">
          <q-btn label="OK" color="blue-grey" v-close-popup />
        </div>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import Attachments from '@/components/Attachments';
import Comments from '@/components/workLogs/Comments.vue';
import OfficeComments from '@/components/workLogs/OfficeComments.vue';
import WorkLogChats from '@/components/workLogs/WorkLogChats';
import WorkLogEdit from '@/components/workLogs/WorkLogEdit';
import WorkLogReminders from '@/components/workLogs/WorkLogReminders';

import {
  decoder,
  chatInfo,
  trimName,
  cloneObj,
  getReminderStatus,
  createHyperLink
} from '@/lib/helpers';
import { parseTimeStamp } from '@/lib/date-utils';
import { colors, date } from 'quasar';
import image from '@/assets/pdf/AerWorx_logo_pdf.png';
import { todoOptions } from '@/lib/static-data';
import store from '@/store';
import { colorPallete } from '@/styles/colors.js';

export default {
  name: 'WorkOrderRow',
  components: {
    Attachments,
    Comments,
    OfficeComments,
    WorkLogChats,
    WorkLogEdit,
    WorkLogReminders
  },
  data() {
    return {
      billedInfo: {
        billed_changed_by: '',
        billed_changed_on: ''
      },
      chatTypedMsg: '',
      colorPallete,
      createHyperLink,
      dialogAttachments: false,
      dialogBilledInfo: false,
      dialogEditWorklog: false,
      dialogFieldValues: false,
      dialogChats: false,
      dialogNotes: false,
      dialogOfficeNotes: false,
      dialogReminders: false,
      parseTimeStamp,
      selectedWorkLog: {},
      todoClickLoading: false
    };
  },
  props: {
    farmNames: {
      type: Object,
      required: true
    },
    userNames: {
      type: Object,
      required: true
    },
    workLog: {
      type: Object,
      required: true
    },
    sort: {
      type: String,
      required: true
    },
    scrollToId: {
      type: Number,
      required: true
    }
  },
  methods: {
    closeChat() {
      if (this.chatTypedMsg !== '') {
        this.$q
          .dialog({
            title: 'Unsaved Text',
            message:
              'Are you sure you want to close chat? Unsaved text will be discarded.',
            ok: {
              label: 'Close',
              color: 'primary'
            },
            cancel: {
              label: 'Cancel',
              color: 'grey-2',
              textColor: 'primary'
            },
            focus: 'none',
            persistent: true
          })
          .onOk(() => {
            this.closeChatConfirmed();
          });
        return;
      }

      this.closeChatConfirmed();
    },
    closeChatConfirmed() {
      this.chatTypedMsg = '';
      this.dialogChats = false;
    },
    buildBackColor(color, opacity) {
      if (color) {
        return 'background-color: ' + colors.changeAlpha(color, opacity || 0.5);
      } else {
        return '';
      }
    },
    displayBilledInfo(workLog) {
      this.billedInfo = {
        billed_changed_by: workLog.billed_changed_by,
        billed_changed_on: workLog.billed_changed_on
      };

      this.dialogBilledInfo = true;
    },
    editLog(workLog) {
      this.selectedWorkLog = cloneObj(workLog);
      this.dialogEditWorklog = true;
    },
    decoder(val) {
      return decoder(val);
    },
    fieldDecoder(fieldDef, fields) {
      const item = fields[fieldDef.id];
      if (item) {
        switch (fieldDef.type) {
          case 'checkbox':
            return item || false;
          case 'date':
            return this.dateHelper(item);
          case 'number':
          case 'text':
          case 'fields':
            return item;
          default:
            return '!!';
        }
      } else {
        switch (fieldDef.type) {
          case 'checkbox':
            return false;
          default:
            return '--';
        }
      }
    },
    fieldList(itemId) {
      const workItem = this.$store.state.workItems.find(
        (element) => element.id === itemId
      );
      return workItem ? workItem.fields : [];
    },
    getBackColor(color, opacity) {
      if (color) {
        return 'background-color: ' + colors.changeAlpha(color, opacity || 0.5);
      } else {
        return '';
      }
    },
    getBilledColor(billed) {
      return billed === 0
        ? 'negative'
        : billed === 1
        ? 'green'
        : billed === 2
        ? 'blue-grey'
        : 'cyan-8';
    },
    getBilledOptions(workLog) {
      if (workLog.billable) {
        return [
          { slot: 'unBilled', value: 0 },
          { slot: 'ready', value: 1 },
          { slot: 'billed', value: 2 }
        ];
      }

      // make sure val does not match any - 20 is arbitrary
      return [
        { slot: 'unBilled', value: 20 },
        { slot: 'ready', value: 20 },
        { slot: 'billed', value: 20 }
      ];
    },
    getToDoColor(todoFlag) {
      return todoOptions.find((x) => x.value === todoFlag)?.color ?? '';
    },
    getTodoType(todoFlag) {
      return todoOptions.find((x) => x.value === todoFlag)?.label ?? '';
    },
    goToFarm(workLog) {
      const scrollObj = {
        workLogId: workLog.id,
        sort: this.sort,
        goStatus: 'active'
      };
      this.$store.dispatch('setWorkOrderScrollTo', scrollObj);
      this.$router.push(`/farm/${workLog.farm_id}/work/`);
    },
    isAuthForChat(chat) {
      if (this.isSuperAdmin) {
        return true;
      }
      return chat.chatGroup.some((x) => x.value === this.currUserId);
    },
    markComplete(log) {
      const workLog = cloneObj(log);
      if (workLog.todo_flag !== 2) {
        workLog.todo_complete = Math.floor(new Date().getTime() / 1000);
        workLog.todo_complete_by = this.currUserId;
      }

      workLog.todo_flag = 2;
      const notifyObj = {
        msg: `${this.farmNames[workLog.farm_id]} Marked Complete`,
        color: 'primary'
      };
      this.onSubmit(workLog, notifyObj);
    },
    markReviewed(log) {
      const workLog = cloneObj(log);
      workLog.todo_flag = 1;
      const notifyObj = {
        msg: `${this.farmNames[workLog.farm_id]} Marked Reviewed`,
        color: 'primary'
      };
      this.onSubmit(workLog, notifyObj);
    },
    onSubmit(workLog, notifyObj) {
      const dispatch = 'updateWorkLog';

      store
        .dispatch(dispatch, {
          farmId: workLog.farm_id,
          log: workLog
        })
        .then((results) => {
          results.disableCheck = true;
          this.$finishResult.handleResultsAsync(results).then((response) => {
            if (response === 'retry') {
              this.onSubmit(workLog, notifyObj);
            }
          });
          this.$q.notify({
            message: notifyObj.msg,
            color: notifyObj.color,
            textColor: this.$q.dark.isActive ? 'black' : 'white'
          });
        });
    },
    pondOrPcName(pondId, computerId) {
      if (pondId === null && computerId === null) {
        return '--';
      }

      const farm = this.$store.state.farmList.find(
        (x) => x.id === this.workLog.farm_id
      );

      if (!farm) {
        return '--';
      }

      let obj = farm.gps.ponds.find((x) => x.id === pondId);

      if (!obj) {
        obj = farm.gps.computers.find((x) => x.id === computerId);
      }

      return obj ? obj.name : '---';
    },
    setBilled(evt, value, log) {
      // changed to allow all users to set billed
      // if (!this.isSuperAdmin) {
      //   return;
      // }

      if (!evt.altKey && !this.$q.platform.is.mobile) {
        this.$q.notify({
          message: 'Alt + Click to set Billing',
          position: 'right',
          color: 'primary',
          textColor: this.$q.dark.isActive ? 'black' : 'white'
        });
        return;
      }

      const setBilledFn = () => {
        const workLog = cloneObj(log);
        workLog.billable = !workLog.billable
          ? true
          : workLog.billed === 0 && value === 0
          ? false
          : true;
        workLog.billed = value;
        workLog.billed_changed_by = this.currUserId;
        workLog.billed_changed_on = date.formatDate(new Date(), 'X');

        const notifyObj = {
          msg: `${this.farmNames[workLog.farm_id]} billing updated`,
          color: 'primary'
        };

        this.onSubmit(workLog, notifyObj);
      };

      if (this.$q.platform.is.mobile) {
        this.$q
          .dialog({
            title: 'Change Billing?',
            ok: {
              color: 'blue-14',
              label: 'Confirm'
            },
            cancel: {
              color: 'blue-14',
              flat: true,
              label: 'Cancel'
            },
            persistent: true
          })
          .onOk(() => {
            setBilledFn();
          });
        return;
      }

      setBilledFn();
    },
    todoClick(evt, log) {
      if (!this.isSuperAdmin) {
        return;
      }

      if (log.todo_flag === 1) {
        return;
      }

      const setTodoFn = () => {
        if (log.todo_flag === 2) {
          this.markReviewed(log);
        } else {
          this.markComplete(log);
        }
      };

      if (!evt.altKey && !this.$q.platform.is.mobile) {
        this.$q.notify({
          message: `Alt + Click to mark ${
            log.todo_flag === 2 ? 'reviewed' : 'complete'
          }`,
          position: 'left',
          color: 'primary',
          textColor: this.$q.dark.isActive ? 'black' : 'white'
        });
        return;
      }

      if (this.$q.platform.is.mobile) {
        this.$q
          .dialog({
            title: log.todo_flag === 2 ? 'Mark Reviewed?' : 'Mark Complete?',
            ok: {
              color: 'primary',
              label: 'Confirm'
            },
            cancel: {
              color: 'primary',
              flat: true,
              label: 'Cancel'
            },
            persistent: true
          })
          .onOk(() => {
            setTodoFn();
          });
        return;
      }

      this.todoClickLoading = true;
      setTodoFn();
    },
    valueType(itemId) {
      const workItem = this.$store.state.workItems.find(
        (element) => element.id === itemId
      );
      return workItem ? workItem.type : '';
    }
  },
  computed: {
    currUserId() {
      return this.$store.state.user.user_id;
    },
    isSuperAdmin() {
      return this.$store.state.user.aerworx_level === 'super-admin';
    },
    time() {
      return parseFloat(this.workLog.time) !== 0
        ? parseFloat(this.workLog.time)
        : '';
    }
  }
};
</script>

<style scoped></style>
