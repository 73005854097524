<template>
  <div>
    <q-table
      :title="userName() + ' - access'"
      :data="Farms"
      :columns="columns"
      :pagination.sync="pagination"
      no-data-label="Data loading"
      row-key="name"
    >
      <template v-slot:top>
        <q-input v-model="farmSearch" filled type="search">
          <template v-slot:append>
            <q-icon
              v-if="farmSearch !== ''"
              name="cancel"
              @click="farmSearch = ''"
              clickable
            />
            <q-icon v-else name="search" />
          </template>
        </q-input>
        <q-space></q-space>
        <div style="font-size: 1.2rem">{{ userName() + ' - access' }}</div>
        <q-space></q-space>
      </template>
      <template v-slot:body-cell-Access="props">
        <q-td :props="props">
          <q-toggle
            :value="access(props.row.id)"
            color="primary"
            @input="toggleAccess($event, props.row.id)"
          />
        </q-td>
      </template>
    </q-table>
  </div>
</template>

<script>
import { decoder } from '@/lib/helpers';
import { parseTimeStamp } from '@/lib/date-utils';
import store from '@/store';

export default {
  name: 'Users',
  props: {
    userId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      farmSearch: '',
      pagination: {
        descending: false,
        page: 1,
        rowsPerPage: 0
      },
      columns: [
        {
          sortable: true,
          name: 'Name',
          label: 'Name',
          align: 'left',
          classes: 'table-highlight-column',
          field: (row) => this.decoder(row.farm_name)
        },
        {
          sortable: true,
          name: 'Access',
          label: 'Access',
          align: 'center',
          field: (row) => row.farms
        }
      ]
    };
  },
  methods: {
    dispatchAccess(access, farmId) {
      store
        .dispatch('updateInstallerAccess', {
          farmId: farmId,
          userId: this.userId,
          access
        })
        .then((results) => {
          this.$finishResult.handleResultsAsync(results).then((response) => {
            if (response === 'retry') {
              this.dispatchAccess(access);
            }
          });
        });
    },
    toggleAccess(event, farmId) {
      this.dispatchAccess(event, farmId);
    },
    access(farmId) {
      const user = this.$store.state.userList.find(
        (e) => e.user_id === parseInt(this.userId)
      );
      if (!user) {
        return false;
      }
      return !!user.farms.find((e) => e === farmId);
    },
    farmName(id) {
      if (id === null) {
        return '--';
      }
      const farm = this.$store.state.farmList.find(
        (element) => element.id === id
      );
      return farm ? farm.farm_name : '---';
    },
    userName() {
      if (this.userId === null) {
        return '--';
      }
      const user = this.$store.state.userList.find(
        (element) => element.user_id === parseInt(this.userId)
      );
      return user ? user.display_name : '- - - -';
    },
    parseTimeStamp(value) {
      return parseTimeStamp(value, 'short');
    },
    decoder(val) {
      return decoder(val);
    }
  },
  computed: {
    Farms() {
      if (this.farmSearch !== '') {
        const search = this.farmSearch.toLowerCase();
        return this.$store.state.farmList.filter((e) => {
          return e.farm_name.toLowerCase().includes(search);
        });
      }
      return this.$store.state.farmList;
    },
    Users() {
      const list = JSON.parse(JSON.stringify(this.$store.state.userList));
      list.sort(function (a, b) {
        return a.display_name.localeCompare(b.display_name, undefined, {
          numeric: true,
          sensitivity: 'base'
        });
      });
      return list;
    },
    userList() {
      const list = [];
      for (let i = 0; i < this.$store.state.userList.length; i++) {
        list.push({
          label: this.$store.state.userList[i].display_name,
          value: this.$store.state.userList[i].user_id
        });
      }
      list.sort(function (a, b) {
        return a.label.localeCompare(b.label, undefined, {
          numeric: true,
          sensitivity: 'base'
        });
      });
      list.push({
        label: '---',
        value: null
      });
      return list;
    }
  }
};
</script>

<style scoped>
@media only screen and (min-width: 750px) {
  .spaced {
    margin-left: 2px;
    margin-right: 2px;
  }

  table {
    width: 80%;
  }

  .card-size {
    min-width: 500px;
  }
}

.max-width {
  max-width: 100px;
}
</style>
