<template>
  <div>
    <q-table
      title="Logs"
      dense
      :data="Logs"
      :columns="columns"
      :pagination.sync="pagination"
      :loading="farmLoading"
      color="blue-14"
      no-data-label="No logs"
      row-key="name"
      class="sticky-header-table"
      style="height: calc(100vh - 95px)"
    >
      <!-- Larger Screens -->
      <template v-slot:top>
        <template v-if="$q.screen.gt.md">
          <div class="row justify-center full-width q-pa-md">
            <!-- Filtering -->
            <div class="q-gutter-md row">
              <div>
                <q-btn
                  outline
                  color="blue-14"
                  padding="8px 10px"
                  icon="add"
                  @click="addLog(false)"
                />
              </div>
              <div>
                <q-btn
                  outline
                  color="blue-14"
                  label="ASM"
                  padding="8px 10px"
                  @click="displayASM"
                />
              </div>
              <div>
                <q-btn
                  outline
                  color="blue-14"
                  label="W-Eval"
                  padding="8px 10px"
                  @click="displayWebEval"
                />
              </div>
              <div>
                <q-btn
                  outline
                  color="blue-14"
                  label="PC-Eval"
                  padding="8px 10px"
                  @click="displayPCEval"
                />
              </div>
              <div style="border: 1px solid #2c62ff; border-radius: 5px">
                <q-btn-toggle
                  size="sm"
                  padding="7px 5px"
                  unelevated
                  v-model="filters.billed"
                  toggle-color="blue-14"
                  text-color="blue-14"
                  :options="[
                    { slot: 'all', value: -1 },
                    { slot: 'unBilled', value: 0 },
                    { slot: 'ready', value: 1 },
                    { slot: 'billed', value: 2 }
                  ]"
                >
                  <template v-slot:all>
                    <q-icon name="list" size="sm">
                      <q-tooltip content-class="bg-blue-14"
                        >Un filtered</q-tooltip
                      >
                    </q-icon>
                  </template>
                  <template v-slot:unBilled>
                    <q-icon name="money_off" size="sm">
                      <q-tooltip content-class="bg-blue-14"
                        >Un billed</q-tooltip
                      >
                    </q-icon>
                  </template>
                  <template v-slot:ready>
                    <q-icon name="attach_money" size="sm">
                      <q-tooltip content-class="bg-blue-14"
                        >Ready to bill</q-tooltip
                      >
                    </q-icon>
                  </template>
                  <template v-slot:billed>
                    <q-icon name="receipt_long" size="sm">
                      <q-tooltip content-class="bg-blue-14">Billed</q-tooltip>
                    </q-icon>
                  </template>
                </q-btn-toggle>
              </div>

              <!-- Pond Filter -->
              <div>
                <q-btn-dropdown
                  color="blue-14"
                  outline
                  padding="8px 10px"
                  label="Pond / PC"
                  auto-close
                >
                  <q-item dense clickable v-ripple @click="filters.pond = null">
                    <q-item-section> No Filter</q-item-section>
                  </q-item>
                  <q-separator />

                  <div :style="`height: ${PondFilterHeight}px`" class="scroll">
                    <q-list dense class="rounded-borders">
                      <q-item
                        clickable
                        v-ripple
                        v-for="pond in PondFilterOptions"
                        :key="pond.value"
                        @click="filters.pond = pond.value"
                      >
                        <q-item-section> {{ pond.label }} </q-item-section>
                      </q-item>
                    </q-list>
                  </div>
                </q-btn-dropdown>
              </div>

              <div>
                <q-btn-dropdown
                  color="blue-14"
                  outline
                  padding="8px 10px"
                  label="Categories"
                >
                  <div class="row no-wrap q-pa-md">
                    <div class="column">
                      <div class="text-h6 q-mb-sm">Filters</div>
                      <q-toggle
                        v-model="catSelectAll"
                        label="Select All"
                        class="q-mb-md"
                        @input="catSelectAllInput"
                      />
                      <div
                        v-for="(category, index) in filters.categories"
                        :key="index"
                        :style="buildBackColor(category.color, 0.2)"
                      >
                        <q-toggle
                          v-model="category.showItem"
                          :label="category.name"
                          @input="catFilterInput"
                        />
                      </div>
                    </div>
                  </div>
                </q-btn-dropdown>
              </div>
              <div>
                <q-btn
                  @click="dialogDatePicker = true"
                  outline
                  color="blue-14"
                  padding="8px 10px"
                  icon="event"
                />
              </div>
              <div v-if="isSuperAdmin">
                <q-btn
                  outline
                  @click="downloadXLSX"
                  color="blue-14"
                  padding="8px 10px"
                  icon="fas fa-file-excel"
                />
              </div>
              <div v-if="isSuperAdmin">
                <q-btn
                  outline
                  @click="downloadCSV"
                  color="blue-14"
                  padding="8px 10px"
                  icon="fas fa-file-csv"
                />
              </div>
            </div>
          </div>
        </template>

        <!-- Smaller Screens -->
        <template v-if="$q.screen.lt.lg">
          <div class="row justify-center q-py-md full-width">
            <!-- Filtering -->
            <div class="q-gutter-md row">
              <div>
                <q-btn
                  outline
                  color="blue-14"
                  padding="8px 10px"
                  icon="add"
                  @click="addLog(false)"
                />
              </div>
              <div>
                <q-btn
                  @click="dialogDatePicker = true"
                  outline
                  color="blue-14"
                  padding="8px 10px"
                  icon="event"
                />
              </div>
              <q-btn-dropdown
                v-model="mobileDropdown"
                outline
                color="blue-14"
                label="More"
                size="md"
                padding="8px 10px"
              >
                <q-card class="q-pa-md">
                  <div class="q-mb-md">
                    <q-btn
                      outline
                      color="blue-14"
                      label="ASM"
                      class="full-width"
                      padding="8px 10px"
                      @click="displayASM"
                      v-close-popup
                    />
                  </div>
                  <div class="q-mb-md">
                    <q-btn
                      outline
                      color="blue-14"
                      label="W-Eval"
                      class="full-width"
                      padding="8px 10px"
                      @click="displayWebEval"
                      v-close-popup
                    />
                  </div>
                  <div class="q-mb-md">
                    <q-btn
                      outline
                      color="blue-14"
                      label="PC-Eval"
                      class="full-width"
                      padding="8px 10px"
                      @click="displayPCEval"
                      v-close-popup
                    />
                  </div>
                  <div
                    style="border: 1px solid #2c62ff; border-radius: 5px"
                    class="q-mb-md"
                  >
                    <q-btn-toggle
                      size="sm"
                      padding="7px 5px"
                      unelevated
                      v-model="filters.billed"
                      toggle-color="blue-14"
                      text-color="blue-14"
                      :options="[
                        { slot: 'all', value: -1 },
                        { slot: 'unBilled', value: 0 },
                        { slot: 'ready', value: 1 },
                        { slot: 'billed', value: 2 }
                      ]"
                    >
                      <template v-slot:all>
                        <q-icon name="list" size="sm">
                          <q-tooltip content-class="bg-blue-14"
                            >Un filtered</q-tooltip
                          >
                        </q-icon>
                      </template>
                      <template v-slot:unBilled>
                        <q-icon name="money_off" size="sm">
                          <q-tooltip content-class="bg-blue-14"
                            >Un billed</q-tooltip
                          >
                        </q-icon>
                      </template>
                      <template v-slot:ready>
                        <q-icon name="attach_money" size="sm">
                          <q-tooltip content-class="bg-blue-14"
                            >Ready to bill</q-tooltip
                          >
                        </q-icon>
                      </template>
                      <template v-slot:billed>
                        <q-icon name="receipt_long" size="sm">
                          <q-tooltip content-class="bg-blue-14"
                            >Billed</q-tooltip
                          >
                        </q-icon>
                      </template>
                    </q-btn-toggle>
                  </div>

                  <!-- Pond Filter -->
                  <div class="q-mb-md">
                    <q-btn-dropdown
                      color="blue-14"
                      outline
                      padding="8px 18px"
                      label="Pond / PC"
                      auto-close
                    >
                      <q-item
                        dense
                        clickable
                        v-ripple
                        @click="(filters.pond = null), (mobileDropdown = false)"
                      >
                        <q-item-section> No Filter</q-item-section>
                      </q-item>
                      <q-separator />

                      <div
                        :style="`height: ${PondFilterHeight}px`"
                        class="scroll"
                      >
                        <q-list dense class="rounded-borders">
                          <q-item
                            clickable
                            v-ripple
                            v-for="pond in PondFilterOptions"
                            :key="pond.value"
                            @click="
                              (filters.pond = pond.value),
                                (mobileDropdown = false)
                            "
                          >
                            <q-item-section> {{ pond.label }} </q-item-section>
                          </q-item>
                        </q-list>
                      </div>
                    </q-btn-dropdown>
                  </div>

                  <div class="q-pb-md">
                    <q-btn-dropdown
                      color="blue-14"
                      outline
                      padding="8px 10px"
                      label="Categories"
                    >
                      <div class="row no-wrap q-pa-md">
                        <div class="column">
                          <div class="text-h6 q-mb-sm">Filters</div>
                          <q-toggle
                            v-model="catSelectAll"
                            label="Select All"
                            class="q-mb-md"
                            @input="catSelectAllInput"
                          />
                          <div
                            v-for="(category, index) in filters.categories"
                            :key="index"
                            :style="buildBackColor(category.color, 0.2)"
                          >
                            <q-toggle
                              v-model="category.showItem"
                              :label="category.name"
                              @input="catFilterInput"
                            />
                          </div>
                        </div>
                      </div>
                    </q-btn-dropdown>
                  </div>

                  <div v-if="isSuperAdmin" class="row full-width">
                    <div class="col-6 q-pr-xs">
                      <q-btn
                        outline
                        @click="downloadCSV"
                        color="blue-14"
                        padding="8px 10px"
                        class="full-width"
                        icon="fas fa-file-csv"
                      />
                    </div>
                    <div class="col-6 q-pl-xs">
                      <q-btn
                        outline
                        @click="downloadXLSX"
                        color="blue-14"
                        padding="8px 10px"
                        class="full-width"
                        icon="fas fa-file-excel"
                      />
                    </div>
                  </div>
                </q-card>
              </q-btn-dropdown>
            </div>
          </div>
        </template>
      </template>

      <template v-slot:header-cell="props">
        <q-th :props="props" style="padding: 0; text-align: center">
          <div>
            <div>
              {{ props.col.label }}
            </div>
            <div>
              {{ props.col.label2 }}
            </div>
          </div>
        </q-th>
      </template>

      <!--Table Slots-->
      <template v-slot:body-cell-Date="props">
        <q-td
          :id="props.row.id"
          :props="props"
          class="cursor-pointer"
          :class="props.row.id === scrollToId && 'bg-grey-4'"
        >
          <div>
            {{ parseTimeStamp(props.row.date, 'short') }}
            <q-tooltip v-if="isSuperAdmin">
              Created At:
              <br />
              {{ parseDateTime(props.row.created_date) }}
              <br /><br />
              Completed At:
              <br />
              {{ parseDateTime(props.row.todo_complete) }}
              <br /><br />
              Completed By:
              <br />
              {{ userName(props.row.todo_complete_by) }}
            </q-tooltip>
          </div>
        </q-td>
      </template>

      <template v-slot:body-cell-ToDo="props">
        <q-td
          :props="props"
          :class="props.row.id === scrollToId && 'bg-grey-4'"
        >
          <q-btn
            v-if="props.row.todo_flag !== 0"
            unelevated
            class="rounded-borders text-black text-caption q-pa-xs"
            :color="buildToDoColor(props.row.todo_flag)"
            style="width: 16px; height: 10px"
            @click="(evt) => todoClick(evt, props.row)"
          />
        </q-td>
      </template>

      <template v-slot:body-cell-Category="props">
        <q-td
          :props="props"
          :class="props.row.id === scrollToId && 'bg-grey-4'"
        >
          <div
            class="rounded-borders q-pa-xs"
            :class="props.row.hasItemSearchId && 'cursor-pointer'"
            :style="buildBackColor(props.row.color)"
            @click="searchForItem(props.row)"
          >
            {{ serviceType(props.row.work_item) }}
          </div>
        </q-td>
      </template>

      <template v-slot:body-cell-Item="props">
        <q-td
          :props="props"
          class="ellipsis"
          :class="props.row.id === scrollToId && 'bg-grey-4'"
        >
          {{ workItem(props.row.work_item) }}
          <q-tooltip :delay="700">
            {{ workItem(props.row.work_item) }}
          </q-tooltip>
        </q-td>
      </template>

      <template v-slot:body-cell-Pond="props">
        <q-td
          :props="props"
          class="ellipsis cursor-pointer"
          :class="props.row.id === scrollToId && 'bg-grey-4'"
          @click="filters.pond = props.row.pond_id || props.row.computer_id"
        >
          {{ pondOrPcName(props.row.pond_id, props.row.computer_id) }}
          <q-tooltip :delay="700">
            {{ pondOrPcName(props.row.pond_id, props.row.computer_id) }}
          </q-tooltip>
        </q-td>
      </template>

      <template v-slot:body-cell-Time="props">
        <q-td
          :props="props"
          class="ellipsis"
          :class="props.row.id === scrollToId && 'bg-grey-4'"
        >
          <template v-if="valueType(props.row.work_item) !== 'fields'">
            {{
              parseFloat(props.row.time) !== 0 ? parseFloat(props.row.time) : ''
            }}
          </template>
          <template v-else>
            <div class="col-auto text-center q-pr-sm" style="width: 34px">
              <span v-if="valueType(props.row.work_item) !== 'fields'">
                {{
                  parseFloat(props.row.time) !== 0
                    ? parseFloat(props.row.time)
                    : ''
                }}
              </span>
              <span v-else>
                <q-icon
                  name="checklist_rtl"
                  class="cursor-pointer"
                  size="26px"
                  color="primary"
                  @click="showWorkField(props.row)"
                >
                  <q-tooltip
                    :content-class="
                      $q.dark.isActive
                        ? 'bg-dark text-white q-pa-none'
                        : 'bg-white text-black q-pa-none'
                    "
                    content-style="font-size: 14px; margin: 0 30px"
                    :offset="[10, 10]"
                    max-width="500px"
                    :delay="400"
                  >
                    <div
                      :style="`border: 2px solid ${colorPallete.primary}; border-radius: 5px`"
                    >
                      <q-card>
                        <q-card-section>
                          <div class="text-h6">
                            {{ parseTimeStamp(props.row.date) }}
                          </div>
                          <div
                            :style="buildBackColor(props.row.color, 0.3)"
                            class="text"
                          >
                            {{ props.row.work_cat_name }} :
                            {{ props.row.work_item_name }}
                          </div>
                        </q-card-section>

                        <q-card-section class="q-pt-none">
                          <div
                            v-for="(field, index) in fieldList(
                              props.row.work_item
                            )"
                            :key="index"
                            class="row"
                          >
                            <div class="col-8" style="min-width: 250px">
                              {{ decoder(field.name) }}
                            </div>
                            <div class="col">
                              <template v-if="field.type === 'checkbox'">
                                <q-icon
                                  class="check-style check-color"
                                  v-if="
                                    fieldDecoder(field, props.row.field_values)
                                  "
                                  name="check"
                                ></q-icon>
                                <q-icon
                                  v-else
                                  color="negative"
                                  name="clear"
                                ></q-icon>
                              </template>
                              <template v-else>
                                {{
                                  fieldDecoder(field, props.row.field_values)
                                }}
                              </template>
                            </div>
                          </div>
                        </q-card-section>
                      </q-card>
                    </div>
                  </q-tooltip>
                </q-icon>
              </span>
            </div>
          </template>
        </q-td>
      </template>

      <template v-slot:body-cell-Attachments="props">
        <q-td
          :props="props"
          :class="props.row.id === scrollToId && 'bg-grey-4'"
        >
          <q-btn
            v-if="props.row.attachments.length"
            outline
            color="primary"
            size="sm"
            padding="6px 8px"
            @click="showAttachmentClick(props.row)"
          >
            <q-icon name="photo_library" color="primary" />
          </q-btn>
          <q-btn
            v-if="!props.row.attachments.length"
            outline
            color="grey-5"
            size="sm"
            padding="6px 8px"
            @click="showAttachmentClick(props.row)"
          >
            <q-icon name="photo_library" color="grey-5" />
          </q-btn>
        </q-td>
      </template>

      <template v-slot:body-cell-Chat="props">
        <q-td
          :props="props"
          :class="props.row.id === scrollToId && 'bg-grey-4'"
        >
          <q-btn
            v-if="props.row.numUnread > 0"
            outline
            color="red-14"
            size="sm"
            padding="6px 8px"
            @click="showChatClick(props.row)"
          >
            <q-icon name="forum" color="red-14" />
          </q-btn>
          <q-btn
            v-if="props.row.numStarred > 0 && props.row.numUnread === 0"
            outline
            color="red-14"
            size="sm"
            padding="6px 8px"
            @click="showChatClick(props.row)"
          >
            <q-icon name="star" color="red-14" />
          </q-btn>
          <q-btn
            v-if="
              props.row.hasChat &&
              (isAuthForChat || isSuperAdmin) &&
              props.row.numImportant === 0
            "
            outline
            color="primary"
            size="sm"
            padding="6px 8px"
            @click="showChatClick(props.row)"
          >
            <q-icon name="forum" color="primary" />
          </q-btn>
          <q-btn
            v-if="!props.row.hasChat && (isAuthForChat || isSuperAdmin)"
            outline
            color="grey-5"
            size="sm"
            padding="6px 8px"
            @click="showChatClick(props.row)"
          >
            <q-icon name="forum" color="grey-5" />
          </q-btn>
        </q-td>
      </template>

      <template v-slot:body-cell-Reminders="props">
        <q-td
          :props="props"
          :class="props.row.id === scrollToId && 'bg-grey-4'"
        >
          <q-btn
            v-if="props.row.reminderStatus.remindNow"
            round
            outline
            color="red-14"
            size="sm"
            icon="alarm"
            padding="7px"
            @click="remindersDisplay(props.row)"
          />
          <q-btn
            v-if="
              props.row.reminderStatus.hasReminder &&
              !props.row.reminderStatus.remindNow
            "
            round
            :outline="!props.row.reminderStatus.remindNow"
            color="deep-purple-14"
            size="sm"
            padding="7px"
            @click="remindersDisplay(props.row)"
          >
            <q-icon
              name="alarm"
              :color="
                props.row.reminderStatus.userReminder
                  ? 'red-14'
                  : 'deep-purple-14'
              "
            />
          </q-btn>
          <q-btn
            v-if="!props.row.reminderStatus.hasReminder"
            round
            outline
            color="grey-5"
            size="sm"
            icon="alarm"
            padding="7px"
            @click="remindersDisplay(props.row)"
          />
        </q-td>
      </template>

      <template v-slot:body-cell-OfficeNotes="props">
        <q-td
          :props="props"
          :class="props.row.id === scrollToId && 'bg-grey-4'"
        >
          <div class="row items-center">
            <q-item
              class="row col cursor-pointer q-pa-none"
              style="white-space: pre-wrap"
            >
              <div
                class="row col ellipsis-3-lines"
                style="height: 58.5px"
                @click="showOfficeNoteClick(props.row)"
              >
                <span v-if="props.row.office_comments.length && isSuperAdmin">
                  <q-icon
                    name="speaker_notes"
                    color="primary"
                    size="20px"
                    class="q-mr-sm"
                  />
                </span>
                <span v-html="createHyperLink(props.row.officeNotes)"></span>
                <span
                  v-if="props.row.emailMsg.length > 0"
                  class="text-weight-light"
                >
                  {{ props.row.emailMsg }}
                </span>
              </div>

              <q-tooltip
                :content-class="
                  $q.dark.isActive
                    ? 'bg-dark text-white q-pa-none'
                    : 'bg-white text-black q-pa-none'
                "
                content-style="font-size: 14px; margin: 0 30px"
                :offset="[10, 10]"
                max-width="800px"
                :delay="400"
              >
                <div
                  class="q-px-lg q-pt-lg"
                  :class="
                    !props.row.office_comments.length ? 'q-pb-lg' : 'q-pb-md'
                  "
                  :style="`border: 2px solid ${colorPallete.primary}; border-radius: 5px;`"
                >
                  <span
                    style="white-space: pre-wrap"
                    v-html="createHyperLink(props.row.officeNotes)"
                  >
                  </span>
                  <span
                    v-if="props.row.emailMsg.length > 0"
                    class="text-weight-light"
                  >
                    {{ props.row.emailMsg }}
                  </span>

                  <OfficeComments
                    v-if="props.row.office_comments.length"
                    :parentObj="props.row"
                    :commentType="'workLog'"
                    :tooltip="true"
                  />
                </div>
              </q-tooltip>
            </q-item>
          </div>
        </q-td>
      </template>

      <template v-slot:body-cell-Notes="props">
        <q-td
          :props="props"
          :class="props.row.id === scrollToId && 'bg-grey-4'"
        >
          <div class="row items-center">
            <q-item
              class="row col cursor-pointer q-pa-none"
              style="white-space: pre-wrap"
            >
              <div
                class="row col ellipsis-3-lines"
                style="height: 58.5px"
                @click="showNoteClick(props.row)"
              >
                <span v-if="props.row.comments.length && isSuperAdmin">
                  <q-icon
                    name="chat_bubble_outline"
                    color="primary"
                    size="20px"
                    class="q-mr-sm"
                  />
                </span>
                <span v-html="createHyperLink(props.row.notes)"></span>
              </div>

              <q-tooltip
                :content-class="
                  $q.dark.isActive
                    ? 'bg-dark text-white q-pa-none'
                    : 'bg-white text-black q-pa-none'
                "
                content-style="font-size: 14px; margin: 0 30px"
                :offset="[10, 10]"
                max-width="800px"
                :delay="400"
              >
                <div
                  class="q-px-lg q-pt-lg"
                  :class="!props.row.comments.length ? 'q-pb-lg' : 'q-pb-md'"
                  :style="`border: 2px solid ${colorPallete.primary}; border-radius: 5px;`"
                >
                  <span
                    style="white-space: pre-wrap"
                    v-html="createHyperLink(props.row.notes)"
                  >
                  </span>

                  <Comments
                    v-if="props.row.comments.length"
                    :parentObj="props.row"
                    :commentType="'workLog'"
                    :tooltip="true"
                  />
                </div>
              </q-tooltip>
            </q-item>
          </div>
        </q-td>
      </template>

      <template v-slot:body-cell-Billed="props">
        <q-td
          :props="props"
          class="cursor-pointer"
          :class="props.row.id === scrollToId && 'bg-grey-4'"
        >
          <q-btn-toggle
            size="sm"
            padding="1px"
            glossy
            :text-color="
              props.row.billable
                ? $q.dark.isActive
                  ? 'white'
                  : 'black'
                : 'grey-5'
            "
            :value="props.row.billed"
            :toggle-color="getBilledColor(props.row.billed)"
            :options="getBilledOptions(props.row)"
          >
            <template v-slot:unBilled>
              <div
                class="q-pa-xs"
                @click="(evt) => setBilled(evt, 0, props.row)"
              >
                <q-icon name="money_off" size="xs">
                  <q-tooltip
                    v-if="
                      props.row.billed === 0 &&
                      props.row.billed_changed_by &&
                      $q.screen.gt.sm
                    "
                    content-class="bg-grey-3"
                  >
                    <div class="text-black">
                      Changed By:
                      <br />
                      {{ userName(props.row.billed_changed_by) }}
                      <br />
                      <br />
                      Changed On:
                      <br />
                      {{ parseDateTime(props.row.billed_changed_on) }}
                    </div>
                  </q-tooltip>
                </q-icon>
              </div>
            </template>
            <template v-slot:ready>
              <div
                class="q-pa-xs"
                @click="(evt) => setBilled(evt, 1, props.row)"
              >
                <q-icon name="attach_money" size="xs">
                  <q-tooltip
                    v-if="props.row.billed === 1 && $q.screen.gt.sm"
                    content-class="bg-grey-3"
                  >
                    <div class="text-black">
                      Changed By:
                      <br />
                      {{ userName(props.row.billed_changed_by) }}
                      <br />
                      <br />
                      Changed On:
                      <br />
                      {{ parseDateTime(props.row.billed_changed_on) }}
                    </div>
                  </q-tooltip>
                </q-icon>
              </div>
            </template>
            <template v-slot:billed>
              <div
                class="q-pa-xs"
                @click="(evt) => setBilled(evt, 2, props.row)"
              >
                <q-icon name="receipt_long" size="xs">
                  <q-tooltip
                    v-if="props.row.billed === 2 && $q.screen.gt.sm"
                    content-class="bg-grey-3"
                  >
                    <div class="text-black">
                      Changed By:
                      <br />
                      {{ userName(props.row.billed_changed_by) }}
                      <br />
                      <br />
                      Changed On:
                      <br />
                      {{ parseDateTime(props.row.billed_changed_on) }}
                    </div>
                  </q-tooltip>
                </q-icon>
              </div>
            </template>
          </q-btn-toggle>
          <q-btn
            glossy
            color="white"
            text-color="blue-grey"
            icon="info"
            size="sm"
            padding="5.5px 6px"
            class="lt-md q-ml-sm"
            @click="displayBilledInfo(props.row)"
          />
        </q-td>
      </template>

      <template v-slot:body-cell-Assigned="props">
        <q-td
          :props="props"
          :class="props.row.id === scrollToId && 'bg-grey-4'"
        >
          {{ userName(props.row.assigned_to) }}
        </q-td>
      </template>

      <template v-slot:body-cell-Edit="props">
        <q-td
          :props="props"
          :class="props.row.id === scrollToId && 'bg-grey-4'"
        >
          <q-btn
            class="btn-table-primary"
            outline
            icon="edit"
            size="md"
            padding="xs"
            @click="editLog(props.row)"
          >
          </q-btn>
        </q-td>
      </template>
    </q-table>

    <!-- edit modal -->
    <q-dialog position="top" v-model="showEditModal" persistent>
      <WorkLogEdit
        :farmId="parseInt(farmId)"
        :workLog="editLogCopy"
        @addScreenshot="addScreenshot"
        @cancel="showEditModal = false"
      />
    </q-dialog>

    <!-- ASM Dialog -->
    <q-dialog position="top" full-height v-model="dialogASM">
      <ASMDetail
        :farm="farm"
        @setASM="(val) => (setASM = val)"
        @setPondFilter="setPondFilter"
      />
    </q-dialog>

    <!-- Web Eval Dialog -->
    <q-dialog position="top" full-height v-model="dialogWebEval">
      <WebEval
        :farm="farm"
        @goToFarm="dialogWebEval = false"
        @goToWL="scrollTo"
      />
    </q-dialog>

    <!-- PC Eval Dialog -->
    <q-dialog position="top" full-height v-model="dialogPCEval">
      <PCEval
        :farm="farm"
        @goToFarm="dialogPcEval = false"
        @goToWL="scrollTo"
      />
    </q-dialog>

    <!-- chat display-->
    <q-dialog persistent position="top" v-model="showChat">
      <q-card style="width: 800px">
        <div class="row items-center q-ma-md">
          <div class="col text-center text-h5 text-primary">Chats</div>
          <q-btn unelevated icon="clear" color="primary" @click="closeChat" />
        </div>
        <q-separator color="primary" style="padding-top 1px" />

        <div class="scroll q-pa-md" style="max-height: calc(100vh - 200px)">
          <WorkLogChats
            :selectedLog="selectedLog"
            ref="WorkLogChats"
            @typedMsg="(msg) => (chatTypedMsg = msg)"
          />
        </div>
      </q-card>
    </q-dialog>

    <!-- attachment display-->
    <q-dialog
      v-model="showAttachment"
      persistent
      :maximized="true"
      transition-show="slide-up"
      transition-hide="slide-down"
    >
      <q-card v-if="selectedLog">
        <q-card-actions>
          <q-btn
            outline
            label="Back"
            color="primary"
            icon="arrow_back"
            padding="xs sm"
            @click="closeAttachments"
          />
        </q-card-actions>
        <q-separator />

        <q-card-section
          style="height: calc(100vh - 60px)"
          class="scroll q-pt-lg"
        >
          <Attachments :parentObj="selectedLog" :parentType="'farm-work'" />
        </q-card-section>
      </q-card>
    </q-dialog>

    <!-- note display-->
    <q-dialog persistent position="top" v-model="showNote">
      <q-card v-if="selectedLog" style="width: 800px">
        <div class="row items-center q-ma-md">
          <div class="col text-center text-h5 text-primary">Notes</div>
          <q-btn unelevated icon="clear" color="primary" v-close-popup />
        </div>
        <q-separator color="primary" />

        <div class="scroll q-pa-md" style="max-height: calc(100vh - 200px)">
          <span
            style="white-space: pre-wrap"
            v-html="createHyperLink(selectedLog.notes)"
          />
        </div>
        <q-separator color="primary" />

        <div class="scroll q-pa-md" style="max-height: calc(100vh - 200px)">
          <Comments
            :parentObj="selectedLog"
            :commentType="'workLog'"
            :tooltip="false"
          />
        </div>
      </q-card>
    </q-dialog>

    <!-- officeNote display-->
    <q-dialog persistent position="top" v-model="showOfficeNote">
      <q-card v-if="selectedLog && showOfficeNote" style="width: 800px">
        <div class="row items-center q-ma-md">
          <div class="col text-center text-h5 text-primary">Office Notes</div>
          <q-btn unelevated icon="clear" color="primary" v-close-popup />
        </div>
        <q-separator color="primary" />

        <div class="scroll q-pa-md" style="max-height: calc(100vh - 200px)">
          <span
            style="white-space: pre-wrap"
            v-html="createHyperLink(selectedLog.officeNotes)"
          />
          <span
            v-if="selectedLog.emailMsg.length > 0"
            class="text-weight-light"
          >
            {{ selectedLog.emailMsg }}
          </span>
        </div>

        <div v-if="isSuperAdmin">
          <q-separator color="primary" />

          <div class="scroll q-pa-md" style="max-height: calc(100vh - 200px)">
            <OfficeComments :parentObj="selectedLog" :tooltip="false" />
          </div>
        </div>
      </q-card>
    </q-dialog>

    <!-- field display -->
    <q-dialog position="top" v-model="showFields">
      <q-card>
        <q-card-section>
          <div class="text-h6">{{ parseTimeStamp(shownFields.date) }}</div>
          <div :style="buildBackColor(shownFields.color, 0.3)" class="text">
            {{ shownFields.work_cat_name }} : {{ shownFields.work_item_name }}
          </div>
        </q-card-section>

        <q-card-section class="q-pt-none">
          <div
            v-for="(field, index) in fieldList(shownFields.work_item)"
            :key="index"
            class="row"
          >
            <div class="col-8" style="min-width: 250px">
              {{ decoder(field.name) }}
            </div>
            <div class="col">
              <template v-if="field.type === 'checkbox'">
                <q-icon
                  class="check-style check-color"
                  v-if="fieldDecoder(field, shownFields.field_values)"
                  name="check"
                ></q-icon>
                <q-icon v-else color="negative" name="clear"></q-icon>
              </template>
              <template v-else>
                {{ fieldDecoder(field, shownFields.field_values) }}
              </template>
            </div>
          </div>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn flat label="OK" color="primary" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>

    <!--Calendar / Date Picker-->
    <q-dialog
      v-model="dialogDatePicker"
      persistent
      :maximized="$q.screen.gt.xs ? false : true"
    >
      <DatePicker
        :allowAllDates="true"
        :dateRangeSelection="dateRange.dateRangeSelected"
        @datesSelected="datesSelected"
      />
    </q-dialog>

    <!-- reminder dialog-->
    <q-dialog persistent v-model="dialogReminders">
      <WorkLogReminders :workLog="editLogCopy" :farmId="editLogCopy.farm_id" />
    </q-dialog>

    <!-- billed info dialog -->
    <q-dialog persistent v-model="dialogBilledInfo">
      <q-card class="q-pa-sm" style="width: 200px">
        <div class="text-black">
          Changed By:
          <br />
          {{ userName(billedInfo.billed_changed_by) }}
          <br />
          <br />
          Changed On:
          <br />
          {{ parseTimeStamp(billedInfo.billed_changed_on) }}
        </div>
        <div class="row justify-end q-pt-md">
          <q-btn label="OK" color="blue-grey" v-close-popup />
        </div>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import ASMDetail from '@/components/ASMDetail.vue';
import WebEval from '@/components/WebEval.vue';
import PCEval from '@/components/PCEval.vue';
import Attachments from '@/components/Attachments';
import Comments from '@/components/workLogs/Comments.vue';
import DatePicker from '@/components/DatePicker';
import OfficeComments from '@/components/workLogs/OfficeComments.vue';
import WorkLogChats from '@/components/workLogs/WorkLogChats';
import WorkLogEdit from '@/components/workLogs/WorkLogEdit';
import WorkLogReminders from '@/components/workLogs/WorkLogReminders';

import {
  decoder,
  chatInfo,
  trimName,
  cloneObj,
  getReminderStatus,
  createHyperLink,
  smartCompare,
  checkPondNotInUse
} from '@/lib/helpers';
import { parseTimeStamp } from '@/lib/date-utils';
import store from '@/store';
import { colors, date, exportFile } from 'quasar';
import xl from 'excel4node';
import image from '@/assets/pdf/AerWorx_logo_pdf.png';
import { todoOptions } from '@/lib/static-data';
import { colorPallete } from '@/styles/colors.js';
import { getFarmEval, getWebFolders } from '@/lib/farmHelpers';

export default {
  name: 'Tickets',
  components: {
    ASMDetail,
    Attachments,
    Comments,
    OfficeComments,
    WorkLogChats,
    WorkLogEdit,
    WorkLogReminders,
    DatePicker,
    WebEval,
    PCEval
  },
  props: {
    farmId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      billedInfo: {
        billed_changed_by: '',
        billed_changed_on: ''
      },
      keyword: '',
      catSelectAll: true,
      chatTypedMsg: '',
      colorPallete,
      createHyperLink,
      dialogASM: false,
      dialogBilledInfo: false,
      dialogReminders: false,
      dialogDatePicker: false,
      dialogOfficeNotes: false,
      dialogWebEval: false,
      dialogPCEval: false,
      dateRange: {
        dateRangeSelected: 'all',
        to: '',
        from: ''
      },
      emailScreenshot: false,
      farm: {},
      filters: {
        billed: -1,
        categories: [],
        pond: null
      },
      lockedBilling: true,
      logsDefault: this.$store.state.workLogs,
      logsCustom: [],
      mobileDropdown: false,
      screenshotLog: null,
      setASM: false,
      showFields: false,
      showAttachment: false,
      showChat: false,
      selectedLog: null,
      showNote: false,
      showOfficeNote: false,
      shownFields: {},
      showEditModal: false,
      todoFlag: 0,
      editLogCopy: {
        reminders: []
      },
      // editWorkCategory: null,
      pagination: {
        sortBy: 'desc',
        descending: false,
        page: 1,
        rowsPerPage: 100
      },
      columns: [
        {
          sortable: true,
          name: 'Date',
          label: 'Date',
          align: 'center',
          field: (row) => parseTimeStamp(row.date, 'short'),
          sort: (a, b, rowA, rowB) => rowB.date - rowA.date
        },
        {
          sortable: true,
          name: 'ToDo',
          label: 'ToDo',
          align: 'center',
          field: (row) => this.todoType(row.todo_flag),
          sort: (a, b, rowA, rowB) => rowB.todo_flag - rowA.todo_flag
        },
        {
          sortable: true,
          name: 'Category',
          label: 'Category',
          align: 'center',
          field: (row) => this.serviceType(row.work_item)
        },
        {
          sortable: true,
          name: 'Item',
          label: 'Item',
          align: 'center',
          style: 'max-width: 140px',
          field: (row) => this.workItem(row.work_item)
        },
        {
          sortable: true,
          name: 'Pond',
          label: 'Pond / PC',
          align: 'center',
          style: 'max-width: 120px',
          field: (row) => this.pondOrPcName(row.pond_id, row.computer_id),
          sort: (a, b, rowA, rowB) => smartCompare(a, b)
        },
        {
          sortable: true,
          name: 'Time',
          label: 'Time',
          align: 'center',
          style: 'max-width: 80px',
          field: (row) => this.valueDecoder(row)
        },
        {
          sortable: true,
          name: 'Attachments',
          label: 'Attach',
          align: 'center',
          style: 'max-width: 45px',
          field: (row) => row.attachments,
          sort: (a, b, rowA, rowB) => b.length - a.length
        },
        {
          sortable: true,
          name: 'Chat',
          label: 'Chat',
          align: 'center',
          style: 'max-width: 45px',
          field: (row) => row.numImportant,
          sort: (a, b, rowA, rowB) => {
            if (rowA.numImportant === 0 && rowB.numImportant === 0) {
              return rowA.hasChat === rowB.hasChat ? 0 : rowA.hasChat ? -1 : 1;
            }
            return rowB.numImportant - rowA.numImportant;
          }
        },
        {
          required: true,
          sortable: true,
          name: 'Reminders',
          label: 'Remind',
          align: 'center',
          style: 'max-width: 45px',
          field: (row) => row.reminders,
          sort: (a, b, rowA, rowB) => {
            // Trick to make math.min work correctly
            const rowATSArr = [999999999999999];
            let rowARem = false;
            let rowATS = 0;
            rowA.reminders.forEach((x) => {
              if (x.reminderRecipient === this.currUserId) {
                rowARem = true;
                rowATS = x.reminderDate;
              }
              rowATSArr.push(x.reminderDate);
            });

            const rowBTSArr = [999999999999999];
            let rowBRem = false;
            let rowBTS = 0;
            rowB.reminders.forEach((x) => {
              if (x.reminderRecipient === this.currUserId) {
                rowBRem = true;
                rowBTS = x.reminderDate;
              }
              rowBTSArr.push(x.reminderDate);
            });

            if (rowARem && rowBRem) {
              return rowATS - rowBTS;
            }

            if (rowARem && !rowBRem) {
              return -1;
            }

            if (!rowARem && rowBRem) {
              return 1;
            }

            return Math.min(...rowATSArr) - Math.min(...rowBTSArr);
          }
        },
        {
          sortable: true,
          name: 'OfficeNotes',
          label: 'Office Notes',
          align: 'center',
          class: 'ellipsis-2-lines',
          style: 'max-width: 250px; min-width: 200px',
          field: (row) => decoder(row.office_notes)
        },
        {
          sortable: true,
          name: 'Notes',
          label: 'Field Notes',
          align: 'center',
          class: 'ellipsis-2-lines',
          style: 'max-width: 250px; min-width: 200px',
          field: (row) => decoder(row.notes),
          sort: (a, b, rowA, rowB) => {
            if (rowA.comments.length === 0 && rowB.comments.length === 0) {
              return 0;
            }
            if (rowA.comments.length > 0 && rowB.comments.length === 0) {
              return -1;
            }
            if (rowA.comments.length === 0 && rowB.comments.length > 0) {
              return 1;
            }
            return rowB.lastCommentTime - rowA.lastCommentTime;
          }
        },
        {
          sortable: true,
          name: 'Billed',
          label: 'Billing',
          align: 'center',
          field: (row) => row,
          sort: (a, b) => {
            const level = function (item) {
              if (!item.billable) {
                return 3;
              }
              return item.billed;
            };
            return level(a) - level(b);
          }
        },
        {
          sortable: true,
          name: 'Assigned',
          label: 'Assigned To',
          align: 'center',
          field: (row) => this.userName(row.assigned_to)
        },
        {
          name: 'Edit',
          label: '',
          align: 'center',
          classes: 'max-width'
        }
      ],
      scrollToIndex: 0,
      scrollToId: ''
    };
  },
  mounted() {
    this.setCategoryFilters();
    this.scrollTo();
    this.checkPondFilter();
    this.checkAddEmailScreenshot();
    this.checkAddScreenshot();
    this.checkCreateNew();

    if (store.state.screenshot && !this.emailScreenshot) {
      this.addLog(false);
    }
  },
  methods: {
    searchForItem(workLog) {
      if (!workLog.hasItemSearchId) {
        return;
      }

      store.dispatch('setItemSearchId', { itemSearchId: workLog.itemSearchId });
      console.log(workLog.itemSearchId);

      console.log(workLog);

      this.$router.push('/boards');
    },
    addScreenshot(screenshotLog) {
      this.screenshotLog = screenshotLog;
    },
    checkAddScreenshot() {
      if (this.screenshotLog) {
        for (const log of this.Logs) {
          if (log.created_date === this.screenshotLog.created_date) {
            this.showAttachmentClick(cloneObj(log));
            this.screenshotLog = null;
            break;
          }
        }
      }
    },
    checkAddEmailScreenshot() {
      const workLogId = this.$store.state.screenshotWorkLogId;
      if (workLogId) {
        this.emailScreenshot = true;

        for (const log of this.Logs) {
          if (log.id === workLogId) {
            this.screenshotLog = cloneObj(log);
            this.$store.dispatch('setScreenshotWorkLogId', null);
            break;
          }
        }
      }
    },
    checkCreateNew() {
      const createNewWorkLog = this.$store.state.createNewWorkLog;

      if (createNewWorkLog && !this.emailScreenshot) {
        this.addLog(true);
        store.dispatch('setCreateNewWorkLog', false);
      }
    },
    checkPondFilter() {
      if (this.$store.state.pondFilterId) {
        this.filters.pond = this.$store.state.pondFilterId;

        this.$store.dispatch('setPondFilter', null);
      }
    },
    setPondFilter(pondId) {
      this.filters.pond = pondId;
      this.dialogASM = false;
    },
    closeAttachments() {
      this.showAttachment = false;
      const backToEmail = this.$store.state.screenshotBackToEmail;

      if (backToEmail) {
        const scrollObj = this.$store.state.emailScrollTo;
        scrollObj.goStatus = 'go';
        this.$store.dispatch('setEmailScrollTo', scrollObj);
        this.$store.dispatch('setScreenshotBackToEmail', false);
        this.$router.push('/emails');
      }
    },
    displayASM() {
      const farm = this.$store.state.farmList.find(
        (farm) => farm.id === parseInt(this.farmId)
      );

      this.setASM = false;

      // create timestamps for ASM level
      const now = +new Date();
      const sixWeeksPast = +new Date() - 3628800000;
      const threeWeeksUpcoming = +new Date() + 1814400000;
      const sixWeeksUpcoming = +new Date() + 3628800000;
      const nowASM = parseInt(+new Date() / 1000);

      let farmServiceDate = 9999999999999;
      let farmASMLevel = 0;
      for (const pond of farm.farmASM) {
        const serviceDateAdjusted = +date.addToDate(
          new Date(pond.latestService * 1000),
          {
            year: 1
          }
        );

        const isSnoozed = pond.asm_snooze !== null && pond.asm_snooze > nowASM;
        pond.asmSnoozed = isSnoozed;

        const isRebuilding = checkPondNotInUse(pond.name);

        pond.asmLevel =
          isRebuilding || isSnoozed
            ? 0
            : serviceDateAdjusted < sixWeeksPast
            ? 4
            : serviceDateAdjusted < now
            ? 3
            : serviceDateAdjusted < threeWeeksUpcoming
            ? 2
            : serviceDateAdjusted < sixWeeksUpcoming
            ? 1
            : 0;

        if (!isRebuilding && !isSnoozed) {
          if (pond.latestService < farmServiceDate) {
            farmServiceDate = pond.latestService;
          }

          if (pond.asmLevel > farmASMLevel) {
            farmASMLevel = pond.asmLevel;
          }
        }
      }

      farm.farmASM.sort((a, b) => smartCompare(a.name, b.name));
      farm.serviceDate = farmServiceDate;
      farm.asmLevel = farmASMLevel;

      this.farm = farm;
      this.dialogASM = true;
    },
    displayBilledInfo(workLog) {
      this.billedInfo = {
        billed_changed_by: workLog.billed_changed_by,
        billed_changed_on: workLog.billed_changed_on
      };

      this.dialogBilledInfo = true;
    },
    displayWebEval() {
      let farm = this.$store.state.farmList.find(
        (farm) => farm.id === parseInt(this.farmId)
      );
      farm = cloneObj(farm);

      const webEevalWorkItems = [135];
      farm.webEval = getFarmEval(farm, 'web_eval', webEevalWorkItems);

      farm.numLocations = farm.webfolders.length;
      farm.webAxessUrls = getWebFolders(farm);

      this.farm = farm;
      this.dialogWebEval = true;
    },
    displayPCEval() {
      let farm = this.$store.state.farmList.find(
        (farm) => farm.id === parseInt(this.farmId)
      );
      farm = cloneObj(farm);

      const PCEevalWorkItems = [136];
      farm.PCEval = getFarmEval(farm, 'pc_eval', PCEevalWorkItems);

      this.farm = farm;
      this.dialogPCEval = true;
    },
    editLog(workLog) {
      this.editLogCopy = cloneObj(workLog);
      this.showEditModal = true;
    },
    async scrollTo() {
      const workLogId = this.$store.state.workLogScrollToId;

      if (workLogId) {
        const logIndex = this.Logs.findIndex((x) => x.id === workLogId);
        this.pagination.page =
          Math.floor(logIndex / this.pagination.rowsPerPage) + 1;
        this.scrollToId = workLogId;

        this.$nextTick(async () => {
          let el = document.getElementById(workLogId);

          if (!el) {
            // try again
            await new Promise((resolve) => setTimeout(resolve, 500));
            el = document.getElementById(workLogId);
          }

          if (!el) {
            if (this.scrollToIndex > 10) {
              return;
            }
            this.scrollToIndex++;

            await new Promise((resolve) => setTimeout(resolve, 200));
            this.scrollTo();
            return;
          }

          el.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          });

          this.$store.dispatch('setWorkLogScrollTo', null);

          await new Promise((resolve) => setTimeout(resolve, 2500));
          this.scrollToId = '';
        });
      }
    },
    remindersDisplay(row) {
      this.editLogCopy = cloneObj(row);
      this.dialogReminders = true;
    },
    catSelectAllInput() {
      this.filters.categories.forEach(
        (cat) => (cat.showItem = this.catSelectAll)
      );
    },
    catFilterInput() {
      let allTrue = true;
      this.filters.categories.forEach((cat) => {
        allTrue = cat.showItem ? allTrue : false;
      });
      this.catSelectAll = allTrue;
    },
    datesSelected(dateRange) {
      this.dateRange = dateRange;
      this.dialogDatePicker = false;
    },
    closeChat() {
      if (this.chatTypedMsg !== '') {
        this.$q
          .dialog({
            title: 'Unsaved Text',
            message:
              'Are you sure you want to close chat? Unsaved text will be discarded.',
            ok: {
              label: 'Close',
              color: 'primary'
            },
            cancel: {
              label: 'Cancel',
              color: 'grey-2',
              textColor: 'primary'
            },
            focus: 'none',
            persistent: true
          })
          .onOk(() => {
            this.closeChatConfirmed();
          });
        return;
      }

      this.closeChatConfirmed();
    },
    closeChatConfirmed() {
      this.chatTypedMsg = '';
      this.showChat = false;
    },
    chatHeight() {
      return window.innerHeight - 180;
    },
    reportData() {
      const farm = this.$store.state.farmList.find(
        (farm) => farm.id === parseInt(this.farmId)
      );
      const logs = this.Logs;
      const sumTracker = {};
      const MainData = [];
      const fieldPages = {};
      const Pages = [];
      for (let i = 0; i < logs.length; i++) {
        const log = logs[i];
        let value = '';
        let sum;
        let row = null;
        if (this.valueType(log.work_item) === 'fields') {
          row = [];
          const page = [
            parseTimeStamp(log.date, 'shortExcel'),
            this.pondOrPcName(log.pond_id, log.computer_id)
          ];
          sum = '';
          const fieldList = this.fieldList(log.work_item);
          const fieldKey = `${log.work_item}:${log.work_cat}`;
          // Secondary pages
          if (!fieldPages[fieldKey]) {
            fieldPages[fieldKey] = {
              cat: log.work_cat,
              title: log.work_cat_name,
              subTitle: log.work_item_name,
              types: [],
              columns: [],
              totals: [],
              isNum: []
            };
            fieldPages[fieldKey].data = [['Date', 'Pond']];
            fieldPages[fieldKey].types = ['date', 'string'];
            for (let j = 0; j < fieldList.length; j++) {
              fieldPages[fieldKey].data[0].push(fieldList[j].name);
              fieldPages[fieldKey].types.push(fieldList[j].type);
              if (fieldList[j].report) {
                fieldPages[fieldKey].columns.push(fieldList[j].name);
              }
            }
          }
          let index = 0;
          for (let j = 0; j < fieldList.length; j++) {
            let value;
            if (fieldList[j].report) {
              value = this.fieldDecoder(fieldList[j], log.field_values);
              if (fieldList[j].type === 'checkbox') {
                value = value ? 'Y' : 'N';
                sum = '';
              } else if (!isNaN(Number(value))) {
                value = Number(value);
                if (sumTracker[fieldList[j].id]) {
                  sumTracker[fieldList[j].id] += value;
                } else {
                  sumTracker[fieldList[j].id] = value;
                }
                sum = sumTracker[fieldList[j].id];
              } else {
                sum = '';
              }
              row.push({
                name: fieldList[j].name,
                value,
                sum
              });
              fieldPages[fieldKey].isNum[index] =
                fieldList[j].type === 'number';
              fieldPages[fieldKey].totals[index++] = sum;
            } else {
              value = this.fieldDecoder(fieldList[j], log.field_values);
              if (fieldList[j].type === 'checkbox') {
                value = value ? 'Y' : 'N';
              }
            }
            page.push(value);
          }
          fieldPages[fieldKey].data.push(page);
        } else {
          value = this.valueDecoder(log);
          if (this.valueType(log.work_item) === 'number') {
            if (!isNaN(Number(value))) {
              value = Number(value);
            }
          }
        }
        const outBill = ['U', 'R', 'B'];
        MainData.push({
          date: parseTimeStamp(log.date, 'shortExcel'),
          cat: log.work_cat,
          cat_name: log.work_cat_name,
          item_name: log.work_item_name,
          pond: this.pondOrPcName(log.pond_id, log.computer_id),
          value,
          billable: log.billable ? outBill[log.billed] : '-',
          user: this.userName(log.assigned_to),
          notes: log.notes,
          office_notes: log.office_notes,
          chats: log.chats,
          comments: log.comments,
          fields: row
        });
      }

      Object.keys(fieldPages).forEach((key) => {
        Pages.push({
          title: fieldPages[key].title,
          subTitle: fieldPages[key].subTitle,
          tab: fieldPages[key].subTitle.replace(
            /(-|\/|\?|\*|\||:|,|\[|\])/gm,
            '_'
          ),
          data: fieldPages[key].data,
          totals: fieldPages[key].totals,
          isNum: fieldPages[key].isNum,
          columns: fieldPages[key].columns,
          cat: fieldPages[key].cat,
          types: fieldPages[key].types
        });
      });

      return {
        farm: farm.farm_name,
        main: MainData,
        fields: Pages
      };
    },
    downloadXLSX() {
      const cols = {
        A: 1,
        B: 2,
        C: 3,
        D: 4,
        E: 5,
        F: 6,
        G: 7,
        H: 8,
        I: 9,
        J: 10
      };
      const report = this.reportData();

      report.main.sort((a, b) => {
        return new Date(a.date) - new Date(b.date);
      });

      const wb = new xl.Workbook({
        author: 'AerWorx ' + process.env.VUE_APP_VERSION
      });

      const mainColumns = [
        {
          name: 'Date',
          width: 10.25
        },
        {
          name: 'Category',
          width: 15.25
        },
        {
          name: 'Item',
          width: 25.25
        },
        {
          name: 'Pond / PC',
          width: 12.25
        },
        {
          name: 'Value',
          width: 12.25
        },
        {
          name: 'Bill',
          width: 2.9
        },
        {
          name: 'User',
          width: 14.25
        },
        {
          name: 'Office Notes',
          width: 45,
          style: wb.createStyle({
            alignment: {
              wrapText: true
            }
          })
        },
        {
          name: 'Field Notes',
          width: 45,
          style: wb.createStyle({
            alignment: {
              wrapText: true
            }
          })
        },
        {
          name: 'Sum',
          width: 10.25
        }
      ];

      const options = {
        pageSetup: {
          orientation: 'landscape',
          fitToWidth: 1,
          fitToHeight: 0
        },
        margins: {
          header: 0.0,
          footer: 0.0,
          top: 0.25,
          bottom: 0.25,
          left: 0.25,
          right: 0.25
        }
      };

      const ws = wb.addWorksheet('Main billing', options);
      const pic = ws.addImage({
        image: Buffer.from(image.substr(22), 'base64'),
        type: 'picture',
        position: {
          type: 'absoluteAnchor',
          x: '0.15in',
          y: '0.3in'
        }
      });
      pic._pxWidth /= 5;
      pic._pxHeight /= 5;
      // Title
      ws.row(2).setHeight(30);

      const titleStyle = wb.createStyle({
        font: {
          name: 'Calibri',
          size: 28
        }
      });
      const columnStyle = wb.createStyle({
        font: {
          name: 'Calibri',
          size: 12,
          bold: true
        },
        fill: {
          type: 'pattern',
          patternType: 'solid',
          fgColor: 'CCCCCC'
        }
      });
      const altRowStyle = wb.createStyle({
        fill: {
          type: 'pattern',
          patternType: 'solid',
          fgColor: 'CCCCCC'
        }
      });
      const verticalTop = wb.createStyle({
        alignment: {
          vertical: 'top'
        }
      });
      const centerStyle = wb.createStyle({
        alignment: {
          horizontal: 'center',
          vertical: 'top'
        }
      });
      const billColors = {
        U: '#C10015',
        R: '#4CAF50',
        B: '#607D8B',
        '-': '#EEEEEE'
      };

      ws.cell(4, cols.A).string('Aerworx ' + process.env.VUE_APP_VERSION);
      ws.cell(4, cols.C).string(
        report.main[0].date + ' - ' + report.main[report.main.length - 1].date
      );
      ws.cell(2, 8).string(report.farm).style(titleStyle);

      const fillColor = {
        fill: {
          type: 'pattern',
          patternType: 'solid',
          fgColor: billColors.U
        }
      };

      ws.cell(3, 6)
        .string('U')
        .style({ ...centerStyle, ...fillColor });
      ws.cell(3, 7).string('Unbilled');
      fillColor.fill.fgColor = billColors.R;
      ws.cell(4, 6)
        .string('R')
        .style({ ...centerStyle, ...fillColor });
      ws.cell(4, 7).string('Ready To Bill');
      fillColor.fill.fgColor = billColors.B;
      ws.cell(5, 6)
        .string('B')
        .style({ ...centerStyle, ...fillColor });
      ws.cell(5, 7).string('Billed Out');
      ws.row(6).freeze();

      for (let i = 0; i < mainColumns.length; i++) {
        ws.cell(6, i + 1)
          .string(mainColumns[i].name)
          .style(columnStyle);
        ws.column(i + 1).setWidth(mainColumns[i].width);
      }

      let c = 7;
      const userHours = [];
      for (let y = 0; y < report.main.length; y++) {
        const item = report.main[y];
        const typeColor = {
          fill: {
            type: 'pattern',
            patternType: 'solid',
            fgColor: this.buildExcelColor(item.cat, 65)
          },
          alignment: {
            vertical: 'top'
          }
        };
        if (y % 2 === 1) {
          ws.cell(c, 1, c, 9).style(altRowStyle);
        }
        ws.cell(c, 1).string(item.date).style(verticalTop);
        ws.cell(c, 2).string(item.cat_name).style(typeColor);
        ws.cell(c, 3).string(item.item_name).style(typeColor);
        ws.cell(c, 4).string(item.pond).style(verticalTop);
        if (typeof item.value === 'number') {
          ws.cell(c, 5).number(item.value).style(verticalTop);
        } else {
          ws.cell(c, 5).string(item.value).style(verticalTop);
        }
        if (item.billable === '-') {
          ws.cell(c, 6).string(item.billable).style(centerStyle);
        } else {
          ws.cell(c, 6)
            .string(item.billable)
            .style({
              alignment: {
                horizontal: 'center',
                vertical: 'top'
              },
              fill: {
                type: 'pattern',
                patternType: 'solid',
                fgColor: billColors[item.billable]
              }
            });
        }

        ws.cell(c, 7).string(item.user).style(verticalTop);

        ws.cell(c, 8)
          .string(item.office_notes)
          .style({
            alignment: {
              wrapText: true,
              vertical: 'top'
            }
          });
        ws.cell(c, 9)
          .string(item.notes)
          .style({
            alignment: {
              wrapText: true,
              vertical: 'top'
            }
          });
        c++;
        // Collapsible fields
        if (item.fields) {
          for (let f = 0; f < item.fields.length; f++) {
            ws.cell(c, cols.C).string(item.fields[f].name).style(typeColor);
            if (typeof item.fields[f].value === 'number') {
              ws.cell(c, cols.E).number(item.fields[f].value);
            } else {
              ws.cell(c, cols.E).string(item.fields[f].value);
            }
            if (typeof item.fields[f].sum === 'number') {
              ws.cell(c, cols.I).number(item.fields[f].sum);
            }
            ws.row(c).group(1, true);
            c++;
          }
        }

        for (let i = 0; i < item.comments.length; i++) {
          const com = item.comments[i];
          const comDate = new Date(com.time).toLocaleString('en-US', {
            timeStyle: 'short',
            dateStyle: 'short'
          });
          if (i === 0) {
            ws.cell(c, 1)
              .string('Comments')
              .style({
                font: {
                  bold: true
                }
              });
          }
          ws.cell(c, 2).string(this.userName(com.user));
          ws.cell(c, 3)
            .string(comDate)
            .style({
              font: {
                color: '#8c8c8c',
                size: 10
              }
            });
          ws.cell(c, 4, c, 10, true)
            .string(com.comment)
            .style({
              alignment: {
                wrapText: true
              },
              font: {
                color: '#333333',
                size: 10
              }
            });
          ws.row(c).group(1, false);
          c++;
        }

        const chatGroups = [];
        for (let i = 0; i < item.chats.length; i++) {
          const chat = item.chats[i];

          const chatIds = chat.chatGroup
            .map((x) => x.userId)
            .sort((a, b) => a - b);
          const chatIdsStr = JSON.stringify(chatIds);

          const groupStr = chat.chatGroup
            .map((x) => {
              const user = this.userList.find((u) => u.value === x.userId);
              return user ? trimName(user.label) : '~~~';
            })
            .join(' - ');

          const chatIndex = chatGroups.findIndex(
            (x) => x.chatIdsStr === chatIdsStr
          );

          if (chatIndex < 0) {
            chatGroups.push({
              chatIdsStr,
              groupStr,
              messages: [
                {
                  sentBy: chat.sentBy,
                  msg: chat.comment,
                  time: chat.time
                }
              ]
            });
          } else {
            chatGroups[chatIndex].messages.push({
              sentBy: chat.sentBy,
              msg: chat.comment,
              time: chat.time
            });
          }
        }

        for (let i = 0; i < chatGroups.length; i++) {
          const chat = chatGroups[i];
          if (i === 0) {
            ws.cell(c, 1)
              .string('Chats')
              .style({
                font: {
                  bold: true
                }
              });
          }
          ws.cell(c, 2, c, 10, true)
            .string(chat.groupStr)
            .style({
              font: {
                bold: true
              }
            });
          ws.row(c).group(1, false);
          c++;
          for (let j = 0; j < chat.messages.length; j++) {
            const msg = chat.messages[j];
            const msgDate = new Date(msg.time).toLocaleString('en-US', {
              timeStyle: 'short',
              dateStyle: 'short'
            });

            ws.cell(c, 2).string(this.userName(msg.sentBy));
            ws.cell(c, 3)
              .string(msgDate)
              .style({
                font: {
                  color: '#8c8c8c',
                  size: 10
                }
              });
            ws.cell(c, 4, c, 10, true)
              .string(msg.msg)
              .style({
                alignment: {
                  wrapText: true
                },
                font: {
                  color: '#333333',
                  size: 10
                }
              });
            ws.row(c).group(1, false);
            c++;
          }
        }

        // Need to add up hours here - this may be a string with words!!
        if (+item.value) {
          const userIndex = userHours.findIndex((user) => {
            return user.name === item.user;
          });

          if (userIndex >= 0) {
            userHours[userIndex].hours += +item.value;
          } else {
            userHours.push({
              name: item.user,
              hours: +item.value
            });
          }
        }
      }

      // write totals on bottom
      c += 1;
      ws.row(c).setHeight(32);
      ws.cell(c, cols.B)
        .string('Totals')
        .style({
          font: {
            name: 'Calibri',
            size: 28,
            bold: true
          }
        });
      c += 2;

      for (let i = 0; i < userHours.length; i++) {
        ws.cell(c, cols.A)
          .string('Hours')
          .style({
            font: {
              name: 'Calibri',
              size: 12,
              bold: true
            }
          });
        ws.cell(c, cols.B).string(userHours[i].name);
        ws.cell(c, cols.F).number(userHours[i].hours);

        ws.cell(c, cols.A, c, cols.F).style(altRowStyle);
        c += 2;
      }

      // ID and color
      for (let y = 0; y < report.fields.length; y++) {
        const item = report.fields[y];
        const typeColor = {
          fill: {
            type: 'pattern',
            patternType: 'solid',
            fgColor: this.buildExcelColor(item.cat, 65)
          }
        };
        ws.cell(c, cols.B).string(item.title).style(typeColor);
        ws.cell(c, cols.C).string(item.subTitle).style(typeColor);
        ws.cell(c, cols.E)
          .number(item.data.length - 1)
          .style({
            fill: {
              type: 'pattern',
              patternType: 'solid',
              fgColor: 'B4DE86'
            },
            alignment: {
              horizontal: 'center'
            },
            font: {
              bold: true
            }
          });
        let pondList = '';
        for (let i = 1; i < item.data.length; i++) {
          pondList += item.data[i][1];
          if (i + 1 < item.data.length) {
            pondList += ', ';
          }
        }
        ws.cell(c, cols.H)
          .string(pondList)
          .style({
            fill: {
              type: 'pattern',
              patternType: 'solid',
              fgColor: 'B4DE86'
            },
            alignment: {
              wrapText: true
            }
          });
        c++;
        for (let i = 0; i < item.columns.length; i++) {
          const total = item.totals[i];
          if (item.isNum[i] && typeof total === 'number') {
            ws.cell(c, cols.C).string(item.columns[i]);
            ws.cell(c, cols.E)
              .number(item.totals[i])
              .style({
                fill: {
                  type: 'pattern',
                  patternType: 'solid',
                  fgColor: '92D050'
                },
                alignment: {
                  horizontal: 'center'
                }
              });
            c++;
          }
        }
        c += 2;
      }

      // build report pages
      for (let y = 0; y < report.fields.length; y++) {
        const fieldSet = report.fields[y];
        const wsR = wb.addWorksheet(fieldSet.tab, options);
        wsR.cell(2, 1).string(report.farm).style(titleStyle);

        const pic1 = wsR.addImage({
          image: Buffer.from(image.substr(22), 'base64'),
          type: 'picture',
          position: {
            type: 'absoluteAnchor',
            x: '8.5in',
            y: '0.3in'
          }
        });
        pic1._pxWidth /= 5;
        pic1._pxHeight /= 5;

        wsR.cell(2, 5, 3, 7).style({
          fill: {
            type: 'pattern',
            patternType: 'solid',
            fgColor: this.buildExcelColor(fieldSet.cat, 65)
          },
          font: {
            name: 'Calibri',
            size: 16
          }
        });

        wsR.cell(2, 5).string(fieldSet.title);
        wsR.cell(3, 5).string(fieldSet.subTitle);
        wsR.row(2).setHeight(30);
        wsR.row(3).setHeight(18);

        if (
          fieldSet.tab === 'Buoy Service _Annual_Initial' ||
          fieldSet.tab === 'NEW System Install _ Typical'
        ) {
          const firstEl = fieldSet.data.shift();

          fieldSet.data.sort((a, b) => {
            return (
              a[1] - b[1] ||
              a[1].localeCompare(b[1], undefined, {
                numeric: true,
                sensitivity: 'base'
              })
            );
          });

          fieldSet.data.unshift(firstEl);
        }

        let xLen = 0;
        for (let i = 0; i < fieldSet.data.length; i++) {
          const set = fieldSet.data[i];
          xLen = set.length;
          for (let s = 0; s < set.length; s++) {
            if (typeof set[s] === 'number') {
              wsR
                .cell(6 + i, 1 + s)
                .number(set[s])
                .style({
                  alignment: {
                    horizontal: 'center',
                    wrapText: true
                  }
                });
            } else {
              wsR
                .cell(6 + i, 1 + s)
                .string(set[s])
                .style({
                  alignment: {
                    horizontal: 'center',
                    wrapText: true
                  }
                });
            }
          }
        }
        for (let i = 0; i < fieldSet.types.length; i++) {
          if (fieldSet.types[i] === 'number') {
            // Insert SUM()
            const start = xl.getExcelCellRef(7, 1 + i);
            const end = xl.getExcelCellRef(6 + fieldSet.data.length, 1 + i);
            wsR
              .cell(6 + fieldSet.data.length + 1, i + 1)
              .formula(`SUM(${start}:${end})`)
              .style({
                fill: {
                  type: 'pattern',
                  patternType: 'solid',
                  fgColor: '92D050'
                },
                alignment: {
                  horizontal: 'center'
                }
              });
          }
        }
        wsR
          .cell(6 + fieldSet.data.length + 1, 2)
          .number(fieldSet.data.length - 1)
          .style({
            fill: {
              type: 'pattern',
              patternType: 'solid',
              fgColor: '92D050'
            },
            alignment: {
              horizontal: 'center'
            }
          });
        wsR.cell(6, 1, 6 + fieldSet.data.length, 1 + xLen).style({
          font: {
            name: 'Calibri',
            size: 13
          },
          width: 17
        });
        wsR.column(1).setWidth(16);
      }

      wb.writeToBuffer().then((buffer) => {
        exportFile(
          `${report.farm}-${Math.floor(Date.now() / 1000)}.xlsx`,
          buffer
        );
      });
    },
    downloadCSV() {
      const logs = this.Logs;
      const sumTracker = {};
      let csv = 'Date,Category,Item,Pond,Value,Notes,Billed,User,Sum\r\n';
      for (let i = 0; i < logs.length; i++) {
        const log = logs[i];
        let value = '';
        let row, sum;
        if (this.valueType(log.work_item) === 'fields') {
          row = '';
          sum = '';
          const fieldList = this.fieldList(log.work_item);
          for (let j = 0; j < fieldList.length; j++) {
            if (fieldList[j].report) {
              let value = this.fieldDecoder(fieldList[j], log.field_values);
              if (fieldList[j].type === 'checkbox') {
                value = value ? 'Y' : 'N';
                sum = '';
              } else if (!isNaN(Number(value))) {
                if (sumTracker[fieldList[j].id]) {
                  sumTracker[fieldList[j].id] += Number(value);
                } else {
                  sumTracker[fieldList[j].id] = Number(value);
                }
                sum = sumTracker[fieldList[j].id];
              }
              row += `,,${fieldList[j].name},,${value},,,,${sum},\r\n`;
            }
          }
        } else {
          value = this.valueDecoder(log);
        }
        csv += `${parseTimeStamp(log.date, 'short')},${log.work_cat_name},${
          log.work_item_name
        },`;
        csv += `${this.pondOrPcName(log.pond_id)},${value},"${log.notes}",`;
        const billable = log.billable ? (log.billed ? 'B' : 'U') : '-';
        csv += `${billable},${this.userName(log.assigned_to)},\r\n`;
        if (row) {
          csv += row;
        }
      }
      const farm = this.$store.state.farmList.find(
        (farm) => farm.id === parseInt(this.farmId)
      );
      exportFile(`${farm.farm_name}-${Math.floor(Date.now() / 1000)}.csv`, csv);
    },
    getBilledColor(billed) {
      return billed === 0
        ? 'negative'
        : billed === 1
        ? 'green'
        : billed === 2
        ? 'blue-grey'
        : 'cyan-8';
    },
    getBilledOptions(workLog) {
      if (workLog.billable) {
        return [
          { slot: 'unBilled', value: 0 },
          { slot: 'ready', value: 1 },
          { slot: 'billed', value: 2 }
        ];
      }

      // make sure val does not match any - 20 is arbitrary
      return [
        { slot: 'unBilled', value: 20 },
        { slot: 'ready', value: 20 },
        { slot: 'billed', value: 20 }
      ];
    },
    parseTimeStamp(value) {
      return parseTimeStamp(value, 'short');
    },
    showWorkField(fields) {
      this.shownFields = fields;
      this.showFields = true;
    },
    dateHelper(quasarDate) {
      const d = new Date(quasarDate);
      const time = d.getTime() / 1000 + (d.getTimezoneOffset() * 60 + 3600);
      return parseTimeStamp(time, 'short');
    },
    fieldDecoder(fieldDef, fields) {
      const item = fields[fieldDef.id];
      if (item) {
        switch (fieldDef.type) {
          case 'checkbox':
            return item || false;
          case 'date':
            return this.dateHelper(item);
          case 'number':
          case 'text':
          case 'fields':
            return item;
          default:
            return '!!';
        }
      } else {
        switch (fieldDef.type) {
          case 'checkbox':
            return false;
          default:
            return '--';
        }
      }
    },
    valueMask(itemId) {
      const workItem = this.$store.state.workItems.find(
        (element) => element.id === itemId
      );
      return workItem ? workItem.mask : [];
    },
    fieldList(itemId) {
      const workItem = this.$store.state.workItems.find(
        (element) => element.id === itemId
      );
      return workItem ? workItem.fields : [];
    },
    valueType(itemId) {
      const workItem = this.$store.state.workItems.find(
        (element) => element.id === itemId
      );
      return workItem ? workItem.type : '';
    },
    decoder(val) {
      return decoder(val);
    },
    buildBackColor(color, opacity) {
      if (color) {
        return 'background-color: ' + colors.changeAlpha(color, opacity || 0.5);
      } else {
        return '';
      }
    },
    buildSelColor(id, opacity) {
      const category = this.$store.state.workCategories.find(
        (element) => element.id === id
      );
      return category
        ? 'background-color: ' +
            colors.changeAlpha(category.def_color, opacity || 0.5)
        : '';
    },
    buildToDoColor(todoFlag) {
      return todoOptions.find((x) => x.value === todoFlag)?.color ?? '';
    },
    todoType(todoFlag) {
      return todoOptions.find((x) => x.value === todoFlag)?.label ?? '';
    },
    buildExcelColor(id, lighten) {
      const category = this.$store.state.workCategories.find(
        (element) => element.id === id
      );
      return category
        ? // ? category.def_color
          colors.lighten(category.def_color, lighten || 1.0)
        : '000000';
    },
    markComplete(log) {
      const workLog = cloneObj(log);
      if (workLog.todo_flag !== 2) {
        workLog.todo_complete = Math.floor(new Date().getTime() / 1000);
        workLog.todo_complete_by = this.currUserId;
      }

      workLog.todo_flag = 2;
      const notifyObj = {
        msg: `${this.farmName(workLog.farm_id)} Marked Complete`,
        color: 'primary'
      };
      this.onSubmit(workLog, notifyObj);
    },
    markReviewed(log) {
      const workLog = cloneObj(log);
      workLog.todo_flag = 1;
      const notifyObj = {
        msg: `${this.farmName(workLog.farm_id)} Marked Reviewed`,
        color: 'primary'
      };
      this.onSubmit(workLog, notifyObj);
    },
    onSubmit(workLog, notifyObj) {
      const dispatch = 'updateWorkLog';

      store
        .dispatch(dispatch, {
          farmId: workLog.farm_id,
          log: workLog
        })
        .then((results) => {
          results.disableCheck = true;
          this.$finishResult.handleResultsAsync(results).then((response) => {
            if (response === 'retry') {
              this.onSubmit(workLog, notifyObj);
            }
          });
          this.$q.notify({
            message: notifyObj.msg,
            color: notifyObj.color,
            textColor: this.$q.dark.isActive ? 'black' : 'white',
            position: 'top'
          });
        });
    },
    todoClick(evt, log) {
      if (!this.isSuperAdmin) {
        return;
      }

      if (log.todo_flag === 1) {
        return;
      }

      const setTodoFn = () => {
        if (log.todo_flag === 2) {
          this.markReviewed(log);
        } else {
          this.markComplete(log);
        }
      };

      if (!evt.altKey && !this.$q.platform.is.mobile) {
        this.$q.notify({
          message: `Alt + Click to mark ${
            log.todo_flag === 2 ? 'reviewed' : 'complete'
          }`,
          position: 'left',
          color: 'primary',
          textColor: this.$q.dark.isActive ? 'black' : 'white'
        });
        return;
      }

      if (this.$q.platform.is.mobile) {
        this.$q
          .dialog({
            title: log.todo_flag === 2 ? 'Mark Verified?' : 'Mark Complete?',
            ok: {
              color: 'primary',
              label: 'Confirm'
            },
            cancel: {
              color: 'primary',
              flat: true,
              label: 'Cancel'
            },
            persistent: true
          })
          .onOk(() => {
            setTodoFn();
          });
        return;
      }

      setTodoFn();
    },
    setBilled(evt, value, log) {
      // Changed to allow all users to set billed
      // if (!this.isSuperAdmin) {
      //   return;
      // }

      if (!evt.altKey && !this.$q.platform.is.mobile) {
        this.$q.notify({
          message: 'Alt + Click to set Billing',
          position: 'right',
          color: 'primary',
          textColor: this.$q.dark.isActive ? 'black' : 'white'
        });
        return;
      }

      const setBilledFn = () => {
        const workLog = cloneObj(log);
        workLog.billable = !workLog.billable
          ? true
          : workLog.billed === 0 && value === 0
          ? false
          : true;
        workLog.billed = value;
        workLog.billed_changed_by = this.currUserId;
        workLog.billed_changed_on = date.formatDate(new Date(), 'X');

        this.$q.notify({
          message: 'Billing Updated',
          color: 'primary'
        });

        store
          .dispatch('updateWorkLog', {
            farmId: this.farmId,
            log: workLog
          })
          .then((results) => {
            this.$finishResult.handleResultsAsync(results).then((response) => {
              if (response === 'retry') {
                this.setBilled(evt, value, log);
              }
            });
          });
      };

      if (this.$q.platform.is.mobile) {
        this.$q
          .dialog({
            title: 'Change Billing?',
            ok: {
              color: 'blue-14',
              label: 'Confirm'
            },
            cancel: {
              color: 'blue-14',
              flat: true,
              label: 'Cancel'
            },
            persistent: true
          })
          .onOk(() => {
            setBilledFn();
          });
        return;
      }

      setBilledFn();
    },
    showChatClick(row) {
      this.selectedLog = row;
      this.showChat = true;
    },
    showAttachmentClick(row) {
      this.selectedLog = row;
      this.showAttachment = true;
    },
    showNoteClick(row) {
      this.selectedLog = row;
      this.showNote = true;
    },
    showOfficeNoteClick(row) {
      this.selectedLog = row;
      this.showOfficeNote = true;
    },
    serviceType(id) {
      const item = this.$store.state.workItems.find(
        (element) => element.id === id
      );
      if (!item) {
        return 'Not found';
      }
      const service = this.$store.state.workCategories.find(
        (element) => element.id === item.work_cat
      );
      return service ? service.item : 'Not found';
    },
    workItem(id) {
      const item = this.$store.state.workItems.find(
        (element) => element.id === id
      );
      return item ? item.name : 'Not found';
    },
    parseDateTime(dateTime) {
      if (!dateTime) {
        return '- - -';
      }
      return new Date(dateTime * 1000).toLocaleString('en-US');
    },
    farmName(id) {
      const farm = this.$store.state.farmList.find(
        (e) => e.id === parseInt(id)
      );
      return farm ? farm.farm_name : '';
    },
    pondOrPcName(pondId, computerId) {
      if (pondId === null && computerId === null) {
        return '--';
      }
      let obj = this.$store.state.farm.ponds.find(
        (element) => element.id === pondId
      );

      if (!obj) {
        obj = this.$store.state.farm.computers.find((x) => x.id === computerId);
      }

      return obj ? obj.name : '---';
    },
    userName(id) {
      if (id === null) {
        return '- - -';
      }
      const user = this.$store.state.userList.find(
        (element) => element.user_id === id
      );
      return user ? user.display_name : '- - - -';
    },
    valueDecoder(log) {
      const workItem = this.$store.state.workItems.find(
        (element) => element.id === log.work_item
      );
      if (workItem) {
        switch (workItem.type) {
          case 'fields':
            return '**';
          case 'boolean':
          case 'note':
            return '';
          case 'date':
            return parseTimeStamp(log.value, 'short');
          case 'number':
          case 'text':
            return log.value;
          default:
            return '';
        }
      }
    },
    addLog(loading) {
      let workCat = sessionStorage.getItem('lastCategory');
      if (loading) {
        workCat = 12;
      } else if (workCat === null) {
        workCat = this.topCategory.id;
        sessionStorage.setItem('lastCategory', workCat);
      } else {
        workCat = parseInt(workCat);
        const validCategory = this.$store.state.workCategories.find(
          (e) => e.id === workCat && !e.archived
        );
        if (!validCategory) {
          workCat = this.topCategory.id;
          sessionStorage.setItem('lastCategory', workCat);
        }
      }

      this.todoFlag = 0;
      this.keyword = '';

      this.editLogCopy = {
        id: 'new',
        todo_flag: 0,
        todo_complete: null,
        todo_complete_by: null,
        attachments: [],
        comments: [],
        office_comments: '',
        chats: [],
        last_chat_date: null,
        reminders: [],
        farm_id: parseInt(this.farmId),
        location_id: null,
        work_item: null,
        work_cat: workCat,
        pond_id: null,
        computer_id: null,
        notes: '',
        office_notes: '',
        time: 0,
        assigned_to: this.$store.state.user.user_id,
        date: date.formatDate(date.startOfDate(new Date(), 'day'), 'X'),
        field_values: {},
        billed: 0,
        billable: false,
        billed_changed_by: null,
        billed_changed_on: null
      };

      this.showEditModal = true;
    },
    cancelEdit() {
      if (this.editLogCopy.id === 'new') {
        if (this.editLogCopy.notes !== '') {
          this.$q
            .dialog({
              title: 'Unsaved Text',
              message:
                'Are you sure you want to exit? You have unsaved text that will be lost.',
              cancel: true,
              persistent: true
            })
            .onOk(() => {
              this.showEditModal = false;
            });
        } else {
          this.showEditModal = false;
        }
      } else {
        this.showEditModal = false;
      }
    },
    setCategoryFilters() {
      const list = [];
      for (let i = 0; i < this.$store.state.workCategories.length; i++) {
        list.push({
          name: this.$store.state.workCategories[i].item,
          color: this.$store.state.workCategories[i].def_color,
          id: this.$store.state.workCategories[i].id,
          showItem: true
        });
      }
      this.filters.categories = list;
    }
  },
  computed: {
    farmLoading() {
      return this.$store.state.farm.loading;
    },
    currUserId() {
      return this.$store.state.user.user_id;
    },
    isSuperAdmin() {
      return this.$store.state.user.aerworx_level === 'super-admin';
    },
    chatGroupAll() {
      const userList = JSON.parse(JSON.stringify(this.userList));
      const all = userList
        .filter((x) => x.chatAuth)
        .map((x) => {
          x.label = trimName(x.label);
          return x;
        });
      return all;
    },
    isAuthForChat() {
      return this.chatGroupAll.some((x) => x.value === this.currUserId);
    },
    PondFilterHeight() {
      const allItemHeight = this.PondFilterOptions.length * 32;

      return allItemHeight > 500 ? 500 : allItemHeight;
    },
    PondFilterOptions() {
      const list = [];
      for (let i = 0; i < this.$store.state.farm.ponds.length; i++) {
        list.push({
          label: this.$store.state.farm.ponds[i].name,
          value: this.$store.state.farm.ponds[i].id,
          type: 'pond'
        });
      }

      list.sort((a, b) => {
        const aName = a.label.toLowerCase();
        const bName = b.label.toLowerCase();

        const aIsHB = aName.includes('hb') || aName.includes('harvest');
        const bIsHB = bName.includes('hb') || bName.includes('harvest');

        if (aIsHB && !bIsHB) {
          return 1;
        }

        if (!aIsHB && bIsHB) {
          return -1;
        }

        return a.label.localeCompare(b.label, undefined, {
          numeric: true,
          sensitivity: 'base'
        });
      });

      for (let i = 0; i < this.$store.state.farm.computers.length; i++) {
        list.push({
          label: this.$store.state.farm.computers[i].name,
          value: this.$store.state.farm.computers[i].id,
          type: 'computer'
        });
      }

      return list;
    },
    topCategory() {
      const cats = JSON.parse(
        JSON.stringify(
          this.$store.state.workCategories.filter((e) => !e.archived)
        )
      );
      cats.sort(function (a, b) {
        return a.view_order - b.view_order;
      });
      return cats.length > 0 ? cats[0] : null;
    },
    userList() {
      const list = [];
      for (let i = 0; i < this.$store.state.userList.length; i++) {
        list.push({
          label: this.$store.state.userList[i].display_name,
          value: this.$store.state.userList[i].user_id,
          chatAuth: this.$store.state.userList[i].chatAuth,
          userRole: this.$store.state.userList[i].user_role
        });
      }
      list.sort((a, b) => a.label.localeCompare(b.label));
      return list;
    },
    reminderUserList() {
      const reminderRecipients = this.editLogCopy.reminders.map(
        (x) => x.reminderRecipient
      );
      return this.userList.filter((x) => !reminderRecipients.includes(x.value));
    },
    remindersFiltered() {
      return this.editLogCopy.reminders.filter(
        (x) => x.reminderRecipient === this.currUserId || this.isSuperAdmin
      );
    },
    Logs() {
      if (
        this.$store.state.farm.logs.length === 0 ||
        this.$store.getters.workCategory.length === 0 ||
        this.$store.getters.workItem.length === 0
      ) {
        return [];
      }
      let filtered = cloneObj(this.$store.state.farm.logs);

      if (this.dateRange.dateRangeSelected !== 'all') {
        const from = Math.floor(+new Date(this.dateRange.from) / 1000);

        const endOfTo = date.endOfDate(this.dateRange.to, 'day');
        const to = Math.floor(+new Date(endOfTo) / 1000);

        filtered = filtered.filter((x) => x.date <= to && x.date >= from);
      }

      for (let i = 0; i < filtered.length; i++) {
        // Set last comment time for sorting
        let lastCommentTime = 0;
        if (filtered[i].comments.length) {
          lastCommentTime =
            filtered[i].comments[filtered[i].comments.length - 1].time;
        }
        filtered[i].lastCommentTime = lastCommentTime;

        const item = this.$store.getters.workItem(filtered[i].work_item);
        const cat = this.$store.getters.workCategory(filtered[i].work_cat);
        if (cat && item) {
          filtered[i].work_cat_name = cat.item;
          filtered[i].color = cat.def_color;
          filtered[i].work_item_name = item.name;
          filtered[i].master_billable = item.billable;
        }

        const chatInfoObj = chatInfo(filtered[i].chats, this.currUserId);

        filtered[i].latestMsgTs = chatInfoObj.latestMsgTs;
        filtered[i].numUnread = chatInfoObj.numUnread;
        filtered[i].numStarred = chatInfoObj.numStarred;
        filtered[i].numImportant =
          chatInfoObj.numUnread + chatInfoObj.numStarred;
        filtered[i].hasChat =
          chatInfoObj.isPartOfChat ||
          (filtered[i].chats.length > 0 && this.isSuperAdmin);
        filtered[i].isUser = filtered[i].assigned_to === this.currUserId;
        filtered[i].reminderStatus = getReminderStatus(
          filtered[i].reminders,
          this.$store.state
        );

        const officeNotesSplit = filtered[i].office_notes.split('[ Email: ');
        filtered[i].officeNotes = officeNotesSplit[0].trim();
        filtered[i].emailMsg =
          officeNotesSplit.length > 1
            ? '[ Email: ' + officeNotesSplit.pop()
            : '';

        filtered[i].hasItemSearchId = filtered[i].officeNotes.startsWith('ID ');
        filtered[i].itemSearchId = '';

        if (filtered[i].hasItemSearchId) {
          filtered[i].itemSearchId = filtered[i].officeNotes.split(' ')[1];
        }
      }

      if (this.filters.pond !== null) {
        filtered = filtered.filter((log) => {
          return (
            log.pond_id === this.filters.pond ||
            log.computer_id === this.filters.pond
          );
        });
      }

      if (this.filters.billed !== -1) {
        filtered = filtered.filter((log) => log.billed === this.filters.billed);
      }

      filtered = filtered.filter((log) => {
        return (
          this.filters.categories.findIndex(
            (cat) => cat.id === log.work_cat && cat.showItem
          ) !== -1
        );
      });

      filtered.sort((a, b) => {
        if (
          a.todo_flag === b.todo_flag ||
          (a.todo_flag <= 2 && b.todo_flag <= 2)
        ) {
          return b.date - a.date;
        }

        return b.todo_flag - a.todo_flag;
      });

      return filtered;
    },
    valueDate: {
      get() {
        return date.formatDate(this.editLogCopy.value * 1000, 'YYYY-MM-DD');
      },
      set(value) {
        const d = new Date(value);
        this.editLogCopy.value =
          d.getTime() / 1000 + (d.getTimezoneOffset() * 60 + 3600);
      }
    },
    itemDate: {
      get() {
        return date.formatDate(this.editLogCopy.date * 1000, 'YYYY-MM-DD');
      },
      set(value) {
        const d = new Date(value);
        this.editLogCopy.date =
          d.getTime() / 1000 + (d.getTimezoneOffset() * 60 + 3600);
      }
    }
  },
  watch: {
    '$store.state.workCategories'() {
      this.setCategoryFilters();
    },
    '$store.getters.filteredFarms'() {
      if (this.dialogASM) {
        this.displayASM();
      }

      if (this.dialogWebEval) {
        this.displayWebEval();
      }

      if (this.dialogPCEval) {
        this.displayPCEval();
      }
    },
    '$store.state.farm.logs'() {
      if (
        this.showChat ||
        this.showAttachment ||
        this.showNote ||
        this.showOfficeNote
      ) {
        const log = this.$store.state.farm.logs.find((e) => {
          return e.id === this.selectedLog.id;
        });

        const chatInfoObj = chatInfo(log.chats, this.currUserId);

        const item = this.$store.getters.workItem(log.work_item);
        const cat = this.$store.getters.workCategory(log.work_cat);
        if (cat && item) {
          log.work_cat_name = cat.item;
          log.color = cat.def_color;
          log.work_item_name = item.name;
          log.master_billable = item.billable;
          log.numUnread = chatInfoObj.numUnread;
          log.numStarred = chatInfoObj.numStarred;
          log.numImportant = chatInfoObj.numUnread + chatInfoObj.numStarred;
          log.latestMsgTs = chatInfoObj.latestMsgTs;
          log.hasChat =
            chatInfoObj.hasChat || (log.chats.length && this.isSuperAdmin);
          log.isUser = log.assigned_to === this.currUserId;
        }

        const officeNotesSplit = log.office_notes.split('[ Email: ');
        log.officeNotes = officeNotesSplit[0].trim();
        log.emailMsg =
          officeNotesSplit.length > 1
            ? '[ Email: ' + officeNotesSplit.pop()
            : '';
        this.selectedLog = cloneObj(log);
      }

      this.checkAddEmailScreenshot();
      this.checkAddScreenshot();
      this.checkCreateNew();
    },
    '$store.state.screenshot'() {
      if (
        store.state.screenshot &&
        !this.showAttachment &&
        !this.emailScreenshot
      ) {
        this.addLog(false);
      }
    }
  }
};
</script>

<style scoped></style>
