const qColors = [
  '#000000', //'black',
  '#d50000', //'red-14',
  '#e91e63', //'pink',
  '#880e4f', //'pink-10',
  '#4a148c', //'purple-10',
  '#d500f9', //'purple-13',
  '#673ab7', //'deep-purple-6',
  '#1a237e', //'indigo-10',
  '#0d47a1', //'blue-10',
  '#64ffda', //'teal-12',
  '#00695c', //'teal-9',
  '#004d40', //'teal-10',
  '#33691e', //'light-green-10',
  '#76ff03', //'light-green-13',
  '#cddc39', //'lime',
  '#827717', //'lime-10',
  '#aeea00', //'lime-14',
  '#ffeb3b', //'yellow',
  '#ff9800', //'orange',
  '#ffb74d', //'orange-4',
  '#bf360c', //'deep-orange-10',
  '#795548', //'brown',
  '#3e2723', //'brown-10',
  '#9e9e9e', //'grey',
  '#424242', //'grey-9',
  '#455a64', //'blue-grey-8',
  '#e0e0e0', //'grey-4',
  '#263238' //'blue-grey-10'
];

const todoOptions = [
  { label: 'Critical', value: 10, color: 'red-14' },
  { label: 'High', value: 9, color: 'orange-14' },
  { label: 'Medium', value: 8, color: 'yellow-14' },
  { label: 'Low', value: 7, color: 'indigo-14' },
  { label: 'Complete', value: 2, color: 'green-14' },
  { label: 'Reviewed', value: 1, color: 'grey-4' },
  { label: 'None', value: 0, color: 'white' }
];

module.exports = {
  qColors,
  todoOptions
};
