<template>
  <div>
    <!--Reminder Info dialog-->
    <q-card style="width: 300px" :class="$q.screen.xs ? 'q-pa-sm' : 'q-pa-md'">
      <div class="row items-center q-mb-sm">
        {{ farmName(workLog.farm_id) }}
        <q-space />
        <q-btn
          icon="clear"
          color="deep-purple-14"
          size="sm"
          padding="2px sm"
          v-close-popup
        />
      </div>
      <div
        class="row items-center ful-width q-pa-sm"
        style="border: 1px solid #6200ea; border-radius: 4px"
      >
        <div class="text-deep-purple-14 text-subtitle2">
          <q-icon name="alarm" size="28px" class="q-mr-sm" />
          Reminders
        </div>
        <q-space />
        <div class="q-mr-sm">
          <q-btn
            v-if="
              (isSuperAdmin && reminderUserList.length) ||
              !remindersFiltered.length
            "
            round
            icon="add"
            size="sm"
            color="red-14"
            @click="reminderAdd"
          />
        </div>
        <q-separator
          v-if="remindersFiltered.length"
          class="full-width q-mt-sm"
        />

        <div v-if="remindersFiltered.length" class="row full-width">
          <div class="text-subtitle1 text-red-14 q-py-xs">Recipients</div>
          <div
            v-for="(reminder, i) in remindersFiltered"
            :key="reminder.id"
            class="row items-center full-width q-py-xs"
          >
            <div>
              <div>
                {{ userName(reminder.reminderRecipient) }}
              </div>
              <div>
                <span class="q-mr-md">
                  {{ reminderDateF(reminder.reminderDate) }}
                </span>
                <span>
                  {{ reminderTime(reminder.reminderDate) }}
                </span>
              </div>
            </div>
            <q-space />

            <div>
              <q-btn
                outline
                icon="edit"
                color="deep-purple-14"
                size="sm"
                padding="3px xs"
                class="q-mr-sm"
                @click="reminderEdit(reminder)"
              />
            </div>
            <q-separator
              v-if="i !== remindersFiltered.length - 1"
              class="full-width q-mt-sm"
            />
          </div>
        </div>
      </div>
    </q-card>

    <!--Reminder dialog-->
    <q-dialog position="top" v-model="reminderDialog" persistent>
      <q-card
        square
        style="width: 450px"
        :class="$q.screen.xs ? 'q-pa-md' : 'q-pa-lg'"
      >
        <div>
          <div v-if="reminderMode === 'add' && !isSuperAdmin">
            {{ userName(reminderRecipient) }}
            <q-separator />
          </div>
          <div v-if="reminderMode === 'add' && isSuperAdmin" class="q-mb-md">
            <q-select
              v-model="reminderRecipient"
              square
              filled
              color="red-14"
              :options="reminderUserList"
              emit-value
              map-options
              label="Select Recipient"
            />
            <div v-if="recipErr" class="text-caption text-red-14">
              Please Select Recipient
            </div>
          </div>
          <div v-if="reminderMode === 'edit'" class="text-subtitle2">
            {{ userName(reminderObj.reminderRecipient) }}
            <q-separator />
          </div>

          <div class="row items-center q-py-sm">
            <div class="q-mr-md">
              <q-btn
                icon="event"
                color="deep-purple-14"
                class="q-mr-xs"
                size="sm"
                padding="xs sm"
              >
                <q-popup-proxy transition-show="scale" transition-hide="scale">
                  <q-date
                    v-model="reminderDate"
                    no-unset
                    mask="YYYY-MM-DD h:mm A"
                    color="deep-purple-14"
                  >
                    <div class="row items-center justify-end q-gutter-sm">
                      <q-btn
                        label="Cancel"
                        color="deep-purple-14"
                        flat
                        v-close-popup
                      />
                      <q-btn
                        label="OK"
                        color="deep-purple-14"
                        flat
                        v-close-popup
                      />
                    </div>
                  </q-date>
                </q-popup-proxy>
              </q-btn>
              <q-btn
                icon="access_time"
                color="deep-purple-14"
                class="q-mx-xs"
                size="sm"
                padding="xs sm"
              >
                <q-popup-proxy transition-show="scale" transition-hide="scale">
                  <q-time
                    v-model="reminderDate"
                    mask="YYYY-MM-DD h:mm A"
                    color="deep-purple-14"
                  >
                    <div class="row items-center justify-end q-gutter-sm">
                      <q-btn
                        label="Cancel"
                        color="deep-purple-14"
                        flat
                        v-close-popup
                      />
                      <q-btn
                        label="OK"
                        color="deep-purple-14"
                        flat
                        v-close-popup
                      />
                    </div>
                  </q-time>
                </q-popup-proxy>
              </q-btn>
              <q-btn
                v-if="reminderMode === 'edit'"
                icon="delete"
                color="red-14"
                size="sm"
                padding="xs sm"
                class="q-ml-xs"
                @click="reminderDelete"
              />
            </div>
            <q-space />
            <span class="q-mr-md">
              {{ reminderDateF(reminderDate) }}
            </span>
            <span>
              {{ reminderTime(reminderDate) }}
            </span>
          </div>
          <div v-if="backDateErr" class="text-caption text-red-14">
            Reminder Date/Time Has Already Passed
          </div>
          <q-separator />

          <div class="text-center text-green-14 q-mt-sm">Remind In:</div>
          <div class="row q-py-sm">
            <div class="q-pr-xs" style="width: 20%">
              <q-btn
                outline
                label="1 Hr"
                color="green-14"
                size="sm"
                padding="xs sm"
                class="full-width q-mx-xs"
                @click="reminderAddTime(3600000)"
              />
            </div>
            <div class="q-pr-xs" style="width: 20%">
              <q-btn
                outline
                label="1 Day"
                color="green-14"
                size="sm"
                padding="xs sm"
                class="full-width q-mx-xs"
                @click="reminderAddTime(86400000)"
              />
            </div>
            <div class="q-pr-xs" style="width: 20%">
              <q-btn
                outline
                label="1 Wk"
                color="green-14"
                size="sm"
                padding="xs sm"
                class="full-width q-mx-xs"
                @click="reminderAddTime(604800000)"
              />
            </div>
            <div class="q-pr-xs" style="width: 20%">
              <q-btn
                outline
                label="1 Mo"
                color="green-14"
                size="sm"
                padding="xs sm"
                class="full-width q-mx-xs"
                @click="reminderAddMonth()"
              />
            </div>
            <div class="q-pr-xs" style="width: 20%">
              <q-btn
                outline
                label="1 Yr"
                color="green-14"
                size="sm"
                padding="xs sm"
                class="full-width q-mx-xs"
                @click="reminderAddYear()"
              />
            </div>
          </div>
          <q-separator />

          <div class="row full-width q-mt-md">
            <q-space />
            <q-btn
              flat
              label="Cancel"
              color="red-14"
              class="q-mr-sm"
              size="sm"
              @click="reminderDialog = false"
            />
            <q-btn
              label="Set Reminder"
              color="red-14"
              size="sm"
              @click="reminderSet"
            />
          </div>
        </div>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { date } from 'quasar';
import store from '@/store';
import { v4 } from 'uuid';

export default {
  name: 'WorkLogReminders',
  data() {
    return {
      backDateErr: false,
      recipErr: false,
      reminderDate: date.formatDate(new Date(), 'YYYY-MM-DD h:mm A'),
      reminderDialog: false,
      reminderInfoDialog: false,
      reminderMode: 'add',
      reminderObj: null,
      reminderRecipient: null,
      reminderRecipConfirm: false
    };
  },
  mounted() {},
  props: {
    farmId: {
      type: Number,
      required: true
    },
    workLog: {
      type: Object,
      required: true
    }
  },
  methods: {
    farmName(id) {
      const farm = this.$store.state.farmList.find(
        (e) => e.id === parseInt(id)
      );
      return farm ? farm.farm_name : '';
    },
    reminderAdd() {
      // set date to tommorrow
      this.reminderDate = date.formatDate(
        +new Date() + 86400000,
        'YYYY-MM-DD h:mm A'
      );
      this.reminderRecipient = null;
      this.recipErr = false;
      this.backDateErr = false;
      this.reminderRecipConfirm = false;
      this.reminderMode = 'add';

      const hasReminder = this.workLog.reminders.find(
        (x) => x.reminderRecipient === this.currUserId
      );

      this.reminderRecipient = hasReminder
        ? this.reminderUserList[0].value
        : this.currUserId;
      this.reminderDialog = true;
    },
    reminderAddTime(time) {
      let reminderTS = +new Date();
      reminderTS += time;
      this.reminderDate = date.formatDate(reminderTS, 'YYYY-MM-DD h:mm A');
    },
    reminderAddMonth() {
      const plusMo = date.addToDate(new Date(), { months: 1 });
      this.reminderDate = date.formatDate(plusMo, 'YYYY-MM-DD h:mm A');
    },
    reminderAddYear() {
      const plusYr = date.addToDate(new Date(), { years: 1 });
      this.reminderDate = date.formatDate(plusYr, 'YYYY-MM-DD h:mm A');
    },
    reminderDateF(TS) {
      return date.formatDate(TS, 'ddd, MMM D YYYY');
    },
    reminderDelete() {
      const index = this.workLog.reminders.findIndex(
        (x) => x.id === this.reminderObj.id
      );

      this.$q
        .dialog({
          ok: {
            label: 'Delete Reminder',
            color: 'red-14'
          },
          cancel: {
            flat: true,
            color: 'deep-purple-10'
          },
          title: 'Confirm',
          focus: 'none',
          message: `Delete Reminder?`,
          persistent: true
        })
        .onOk(() => {
          this.workLog.reminders.splice(index, 1);

          this.$q.notify({
            message: 'Reminder Deleted',
            icon: 'alarm',
            color: 'red-14'
          });
          this.reminderSetDispatch();
          this.reminderDialog = false;
        });
    },
    reminderFutureCheck(TS) {
      const twoYrFuture = +date.addToDate(this.workLog.date * 1000, {
        years: 2
      });
      return TS < twoYrFuture;
    },
    reminderFutureErr() {
      this.$q.dialog({
        title: 'Alert',
        ok: {
          flat: true,
          color: 'deep-purple-14'
        },
        message: 'Cannot set Reminder Date more than two years after ToDo date!'
      });
    },
    reminderEdit(reminder) {
      this.recipErr = false;
      this.backDateErr = false;
      this.reminderRecipConfirm = false;
      this.reminderObj = reminder;
      this.reminderMode = 'edit';
      this.reminderDate = date.formatDate(
        reminder.reminderDate,
        'YYYY-MM-DD h:mm A'
      );
      this.reminderRecipient = reminder.reminderRecipient;
      this.reminderDialog = true;
    },
    remindersDisplay(reminderWorkLog) {
      this.workLog = reminderWorkLog;
      this.reminderInfoDialog = true;
    },
    reminderSet() {
      if (!this.reminderRecipient) {
        this.recipErr = true;
        return;
      }

      const now = +new Date();
      const reminderTS = +new Date(
        date.extractDate(this.reminderDate, 'YYYY-MM-DD h:mm A')
      );
      if (now > reminderTS) {
        this.backDateErr = true;
        return;
      }

      // Reminder cannot be set more than two years into future
      if (!this.reminderFutureCheck(reminderTS)) {
        this.reminderFutureErr();
        return;
      }

      // If reminder goes to user other than creator we need to confirm
      if (
        this.reminderRecipient !== this.currUserId &&
        !this.reminderRecipConfirm
      ) {
        let recip = this.userName(this.reminderRecipient);

        this.$q
          .dialog({
            ok: {
              flat: true,
              label: 'Set Reminder',
              color: 'red-14'
            },
            cancel: {
              flat: true,
              color: 'deep-purple-10'
            },
            title: 'Confirm Recipient',
            message: `Set reminder for ${recip}?`,
            persistent: true
          })
          .onOk(() => {
            this.reminderRecipConfirm = true;
            this.reminderSet();
          });

        return;
      }

      if (this.reminderMode === 'add') {
        this.workLog.reminders.push({
          id: v4(),
          creator: this.currUserId,
          reminderDate: reminderTS,
          reminderRecipient: this.reminderRecipient,
          pinned: false
        });

        this.$q.notify({
          message: 'Reminder Added',
          icon: 'alarm',
          color: 'deep-purple-14'
        });
      }

      if (this.reminderMode === 'edit') {
        const reminder = this.workLog.reminders.find(
          (x) => x.id === this.reminderObj.id
        );
        reminder.reminderDate = reminderTS;
        reminder.pinned = false;

        this.$q.notify({
          message: 'Reminder Updated',
          icon: 'alarm',
          color: 'deep-purple-14'
        });
      }

      this.reminderSetDispatch();
      this.reminderDialog = false;
    },
    reminderSetDispatch() {
      store
        .dispatch('updateWorkLog', {
          farmId: this.farmId,
          log: this.workLog
        })
        .then((results) => {
          results.disableCheck = true;
          this.$finishResult.handleResultsAsync(results).then((response) => {
            if (response === 'retry') {
              this.reminderSetDispatch();
            }
          });
        });
    },
    reminderTime(TS) {
      return date.formatDate(TS, 'h:mm A');
    },
    userName(id) {
      if (id === null) {
        return '- - -';
      }
      const user = this.$store.state.userList.find(
        (element) => element.user_id === id
      );
      return user ? user.display_name : '- - - -';
    }
  },
  computed: {
    currUserId() {
      return this.$store.state.user.user_id;
    },
    isSuperAdmin() {
      return this.$store.state.user.aerworx_level === 'super-admin';
    },
    remindersFiltered() {
      return this.workLog.reminders.filter(
        (x) => x.reminderRecipient === this.currUserId || this.isSuperAdmin
      );
    },
    reminderUserList() {
      const reminderRecipients = this.workLog.reminders.map(
        (x) => x.reminderRecipient
      );
      return this.userList.filter((x) => !reminderRecipients.includes(x.value));
    },
    userList() {
      const list = [];
      for (let i = 0; i < this.$store.state.userList.length; i++) {
        list.push({
          label: this.$store.state.userList[i].display_name,
          value: this.$store.state.userList[i].user_id,
          chatAuth: this.$store.state.userList[i].chatAuth,
          userRole: this.$store.state.userList[i].user_role
        });
      }
      list.sort((a, b) => a.label.localeCompare(b.label));
      return list;
    }
  }
};
</script>

<style scoped></style>
