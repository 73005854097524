<template>
  <div>
    <div v-if="scannerOpen" class="row justify-center full-width">
      <video id="readerVideo" style="width: 300px"></video>
      <div class="col-12 spaced">
        <q-btn
          class="full-width"
          label="Cancel QR"
          color="secondary"
          @click="cancelQR"
        />
      </div>
    </div>
    <q-table
      dense
      :title="TableTitle"
      :data="BoardLogs"
      :columns="columns"
      :pagination="pagination"
      row-key="name"
      wrap-cells
      no-data-label="No Board Logs"
    >
      <template v-slot:top>
        <!-- Filtering -->
        <div class="q-gutter-xs row justify-center full-width">
          <q-btn
            padding="8px 10px"
            icon="arrow_back"
            color="blue-14"
            class="q-mx-sm"
            @click="backToBoards"
          />
          <q-btn
            outline
            color="blue-14"
            padding="8px 8px"
            icon="edit"
            class="q-mx-sm"
            @click="editBoard"
          />
          <q-input
            v-model="quickJump"
            filled
            dense
            type="tel"
            style="width: 120px"
            @keydown.enter.prevent="jumpToBoard"
          >
            <template v-slot:append>
              <q-btn
                padding="3px 3px"
                icon="search"
                color="blue-14"
                outline
                @click="jumpToBoard"
              />
            </template>
          </q-input>
          <q-btn
            padding="8px 8px"
            icon="qr_code"
            class="q-mx-sm"
            color="primary"
            @click="scanQR"
          />
          <div>
            <q-btn
              :disable="!selectedZebraOnline"
              outline
              color="blue-14"
              padding="8px 10px"
              @click="printSelectedZebra()"
            >
              <img
                v-if="$q.dark.isActive"
                width="24px"
                height="24px"
                src="/img/ZBRA.D.png"
              />
              <img
                v-if="!$q.dark.isActive"
                width="24px"
                height="24px"
                src="/img/ZBRA.png"
              />
            </q-btn>
          </div>
        </div>
        <q-space />
        <div
          v-if="$q.screen.lt.md"
          class="q-gutter-xs row text-center full-width"
        >
          <header class="full-width">{{ TableName }}</header>
        </div>
      </template>
      <template v-slot:body-cell-Edit="props">
        <q-td :props="props">
          <q-btn
            class="btn-table-primary"
            outline
            icon="edit"
            size="md"
            padding="xs"
            @click="editLog(props.row)"
          >
          </q-btn>
        </q-td>
      </template>
      <template v-slot:body-cell-Delete="props">
        <q-td :props="props">
          <q-btn
            class="btn-table-negative"
            outline
            icon="delete"
            size="md"
            padding="xs"
            @click="deleteLog(props.row)"
          >
          </q-btn>
        </q-td>
      </template>
      <template v-slot:body-cell-Attachments="props">
        <q-td :props="props">
          <q-btn
            v-if="props.row.attachments.length"
            outline
            color="primary"
            size="sm"
            padding="6px 8px"
            @click="showAttachmentClick(props.row)"
          >
            <q-icon name="photo_library" color="primary" />
          </q-btn>
          <q-btn
            v-if="!props.row.attachments.length"
            outline
            color="grey-5"
            size="sm"
            padding="6px 8px"
            @click="showAttachmentClick(props.row)"
          >
            <q-icon name="photo_library" color="grey-5" />
          </q-btn>
        </q-td>
      </template>
    </q-table>
    <q-page-sticky position="bottom-right" :offset="[28, 28]">
      <q-btn fab icon="add" color="accent" @click="addLog" />
    </q-page-sticky>
    <!-- edit modal -->
    <q-dialog v-model="showEditModal" persistent class="">
      <q-card square bordered class="q-pa-lg card-size">
        <q-card-section class="">
          <q-form @submit="onSubmit" class="q-gutter-sm">
            <q-toolbar class="shadow-2">
              <q-toolbar-title class="row justify-center items-center">
                <q-icon name="handyman" size="sm" class="q-mr-sm" />
                {{ ModalHeader }}
              </q-toolbar-title>
            </q-toolbar>
            <div class="q-mb-sm">
              <q-input
                filled
                dense
                v-model="editLogCopy.notes"
                type="textarea"
                label="Repair Notes"
                maxlength="1024"
                outlined
                square
                autogrow
              />
            </div>
            <div class="q-mb-sm">
              <q-select
                v-model="editLogCopy.farm_id"
                square
                dense
                filled
                :options="FarmOptions"
                emit-value
                map-options
                label="Farm"
              />
            </div>
            <div class="q-mb-sm">
              <q-select
                v-model="editLogCopy.pond_id"
                square
                dense
                filled
                :options="PondOptions"
                emit-value
                map-options
                label="Pond"
              />
            </div>
            <div class="q-mb-sm">
              <q-input
                filled
                square
                dense
                label="Date"
                v-model="logDate"
                outlined
                hide-bottom-space
              >
                <template v-slot:append>
                  <q-icon name="event" class="cursor-pointer" color="primary">
                    <q-popup-proxy
                      ref="qDateProxy"
                      transition-show="scale"
                      transition-hide="scale"
                    >
                      <q-date v-model="logDate" mask="MM/DD/YYYY">
                        <div class="row items-center justify-end">
                          <q-btn
                            v-close-popup
                            label="Close"
                            color="primary"
                            flat
                          />
                        </div>
                      </q-date>
                    </q-popup-proxy>
                  </q-icon>
                </template>
              </q-input>
            </div>
            <div class="col">
              <q-input dense v-model="farmSearch" filled type="search">
                <template v-slot:append>
                  <q-icon name="search" />
                </template>
              </q-input>
            </div>
            <div>
              <div class="row full-width">
                <div class="col spaced">
                  <q-btn
                    outline
                    class="full-width"
                    label="Cancel"
                    color="secondary"
                    @click="cancelEdit"
                  />
                </div>
                <div class="col spaced">
                  <q-btn
                    outline
                    class="full-width"
                    label="Submit"
                    type="submit"
                    color="primary"
                  />
                </div>
              </div>
            </div>
          </q-form>
        </q-card-section>
      </q-card>
    </q-dialog>
    <!-- end edit modal -->
    <!-- attachment display-->
    <q-dialog
      v-model="showAttachment"
      persistent
      :maximized="true"
      transition-show="slide-up"
      transition-hide="slide-down"
    >
      <q-card v-if="selectedLog">
        <q-card-actions>
          <q-btn
            label="Back"
            color="primary"
            icon="arrow_back"
            padding="xs sm"
            v-close-popup
          />
        </q-card-actions>
        <q-separator />

        <q-card-section
          style="height: calc(100vh - 60px)"
          class="scroll q-pt-lg"
        >
          <Attachments :parentObj="selectedLog" :parentType="'repair-log'" />
        </q-card-section>
      </q-card>
    </q-dialog>
    <!-- edit modal -->
    <q-dialog v-model="showBoardEditModal" persistent class="">
      <q-card square bordered class="q-pa-lg card-size">
        <q-card-section class="">
          <q-form @submit="onSubmit" class="q-gutter-sm">
            <q-toolbar class="shadow-2">
              <q-toolbar-title class="row justify-center items-center">
                <q-icon name="handyman" size="sm" class="q-mr-sm" />
                {{ BoardModalHeader }}
              </q-toolbar-title>
            </q-toolbar>
            <div class="text-center">ID: {{ editBoardCopy.id }}</div>
            <q-select
              v-model="editBoardCopy.type"
              square
              filled
              :options="boardTypes"
              emit-value
              map-options
              label="Type"
            />
            <q-input
              filled
              v-model="editBoardCopy.rev"
              label="Rev"
              outlined
              square
            />
            <q-input
              filled
              v-model="editBoardCopy.note"
              label="Description"
              outlined
              square
            />
            <div>
              <div class="row full-width">
                <div class="col spaced">
                  <q-btn
                    outline
                    class="full-width"
                    label="Cancel"
                    color="secondary"
                    @click="cancelBoardEdit"
                  />
                </div>
                <div class="col spaced">
                  <q-btn
                    outline
                    class="full-width"
                    label="Submit"
                    color="primary"
                    @click="onSubmitBoardEdit"
                  />
                </div>
              </div>
            </div>
          </q-form>
        </q-card-section>
      </q-card>
    </q-dialog>
    <!-- end edit modal -->
    <ZebraPreview
      :show="showZebraPreview"
      :printData="selectedPreview"
      @complete="closeZebraPreview"
    ></ZebraPreview>
  </div>
</template>

<script>
import Attachments from '@/components/Attachments';
import ZebraPreview from '@/components/ZebraPreview';
import { parseTimeStamp } from '@/lib/date-utils';
import { decoder } from '@/lib/helpers';
import store from '@/store';
import { date } from 'quasar';
import { BoardTypes } from '@/lib/board-types';
import { buildBoardLabel3x2 } from '@/lib/zebra-labels';

export default {
  name: 'Tickets',
  components: {
    Attachments,
    ZebraPreview
  },
  props: {
    boardId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      quickJump: '',
      html5QrCode: null,
      editLogCopy: {},
      editBoardCopy: {},
      scannerOpen: false,
      showAttachment: false,
      selectedLog: null,
      logDate: null,
      showEditModal: false,
      showBoardEditModal: false,
      farmSearch: '',
      showZebraPreview: false,
      selectedPreview: {},
      pagination: {
        sortBy: 'desc',
        descending: false,
        page: 1,
        rowsPerPage: 50
      },
      columns: [
        {
          sortable: true,
          name: 'Date',
          label: 'Date',
          align: 'center',
          field: (row) => parseTimeStamp(row.date, 'short'),
          sort: (a, b, rowA, rowB) => rowB.date - rowA.date
        },
        {
          sortable: true,
          name: 'Notes',
          label: 'Repair Notes',
          align: 'center',
          class: 'ellipsis-2-lines',
          style: 'max-width: 250px; min-width: 200px',
          field: (row) => decoder(row.notes)
        },
        {
          sortable: true,
          name: 'In service',
          label: 'In service',
          align: 'center',
          field: (row) => this.inServiceName(row.farm_id, row.pond_id)
        },
        {
          sortable: true,
          name: 'Attachments',
          label: 'Attach',
          align: 'center',
          style: 'max-width: 45px',
          field: (row) => row.attachments,
          sort: (a, b, rowA, rowB) => b.length - a.length
        },
        {
          name: 'Edit',
          label: '',
          align: 'center',
          classes: 'max-width'
        },
        {
          name: 'Delete',
          label: '',
          align: 'center',
          classes: 'max-width'
        }
      ],
      boardTypes: BoardTypes
    };
  },
  methods: {
    editBoard() {
      this.showBoardEditModal = true;
      const board = this.$store.state.boards.find(
        (brd) => brd.id === parseInt(this.boardId)
      );
      if (!board) {
        this.showBoardEditModal = true;
        return;
      }
      this.editBoardCopy = JSON.parse(JSON.stringify(board));
    },
    cancelBoardEdit() {
      this.showBoardEditModal = false;
    },
    onSubmitBoardEdit() {
      store
        .dispatch('updateBoard', {
          board: this.editBoardCopy
        })
        .then((results) => {
          this.$finishResult.handleResultsAsync(results).then((response) => {
            if (response === 'retry') {
              this.onSubmitBoardEdit();
            } else {
              this.showBoardEditModal = false;
            }
          });
        });
    },
    backToBoards() {
      this.$router.push('/boards');
    },
    jumpToBoard() {
      const board = this.$store.state.boards.find(
        (brd) => brd.id === parseInt(this.quickJump)
      );
      // this.quickJump = '';
      if (board) {
        this.$router.push(`/boards/${board.id}`);
      } else {
        this.$q.notify({
          message: 'Board not found',
          color: 'negative',
          textColor: 'black'
        });
      }
    },
    cancelQR() {
      this.scannerOpen = false;
      if (this.html5QrCode !== null) {
        this.html5QrCode.stop();
        this.html5QrCode.destroy();
        this.html5QrCode = null;
      }
    },
    async scanQR() {
      if (this.scannerOpen) {
        return;
      }
      const that = this;
      const QrScanner = await import('qr-scanner');
      this.scannerOpen = true;
      await this.$nextTick();
      this.html5QrCode = new QrScanner.default(
        document.getElementById('readerVideo'),
        (result) => {
          const decodedText = result.data;
          that.scannerOpen = false;
          that.html5QrCode.stop();
          this.html5QrCode.destroy();
          let foundValue = null;
          if (isNaN(parseInt(decodedText))) {
            const tail = decodedText.split('/boards/');
            foundValue = tail[1];
          } else {
            foundValue = decodedText;
          }
          if (!foundValue) {
            that.$q.notify({
              message: 'Invalid Code',
              color: 'negative',
              textColor: 'black'
            });
          } else {
            if (that.Boards.find((brd) => brd.id === parseInt(foundValue))) {
              that.$router.push(`/boards/${foundValue}`);
            } else {
              /* that.$q.notify({
                message: 'Board not found',
                color: 'negative',
                textColor: 'black'
              });*/
              store.dispatch('setBoardPage', foundValue);
              this.$router.push(`/boards`);
            }
            that.html5QrCode = null;
          }
        },
        {
          returnDetailedScanResult: true,
          highlightScanRegion: true,
          highlightCodeOutline: true,
          calculateScanRegion: function (video) {
            const smallestDimension = Math.min(
              video.videoWidth,
              video.videoHeight
            );
            const scanRegionSize = Math.round((1 / 2) * smallestDimension);
            return {
              x: Math.round((video.videoWidth - scanRegionSize) / 2),
              y: Math.round((video.videoHeight - scanRegionSize) / 2),
              width: scanRegionSize,
              height: scanRegionSize,
              downScaledWidth: 300,
              downScaledHeight: 300
            };
          }
        }
      );
      try {
        await this.html5QrCode.start();
      } catch (error) {
        that.$q.notify({
          message: error,
          color: 'negative',
          textColor: 'black'
        });
        that.scannerOpen = false;
        that.html5QrCode.stop();
        this.html5QrCode.destroy();
        that.html5QrCode = null;
      }
    },
    inServiceName(farm_id, pond_id) {
      if (farm_id) {
        const farm = this.$store.state.farmList.find(
          (farm) => farm.id === farm_id
        );
        if (farm) {
          const pond = farm.farmASM.find((pond) => pond.id === pond_id);
          if (pond) {
            return `${decoder(farm.farm_name)} : ${decoder(pond.name)}`;
          } else {
            return `${decoder(farm.farm_name)}`;
          }
        }
      }
      return '---';
    },
    editLog(log) {
      this.farmSearch = '';
      this.editLogCopy = JSON.parse(JSON.stringify(log));
      this.logDate = date.formatDate(
        this.editLogCopy.date * 1000,
        'MM/DD/YYYY'
      );
      this.showEditModal = true;
    },
    deleteLog(log) {
      this.$deleteResult
        .getResultsAsync('handyman', `${this.boardId}`, 'Delete log?')
        .then((result) => {
          if (result === 'delete') {
            this.dispatchDelete(log);
          }
        });
    },
    dispatchDelete(log) {
      store
        .dispatch('deleteBoardLog', {
          log
        })
        .then((results) => {
          this.$finishResult.handleResultsAsync(results).then((response) => {
            if (response === 'retry') {
              this.dispatchDelete(log);
            }
          });
        });
    },
    addLog() {
      this.farmSearch = '';
      this.editLogCopy = {
        id: 'new',
        board_id: parseInt(this.boardId),
        notes: '',
        farm_id: null,
        pond_id: null,
        attachments: []
      };
      this.logDate = date.formatDate(Date.now(), 'MM/DD/YYYY');
      this.showEditModal = true;
    },
    onSubmit() {
      let dispatch;
      if (this.editLogCopy.id === 'new') {
        dispatch = 'createBoardLog';
      } else {
        dispatch = 'updateBoardLog';
      }
      this.editLogCopy.date = date.formatDate(new Date(this.logDate), 'X');
      store
        .dispatch(dispatch, {
          log: this.editLogCopy
        })
        .then((results) => {
          this.$finishResult.handleResultsAsync(results).then((response) => {
            if (response === 'retry') {
              this.onSubmit();
            } else {
              this.showEditModal = false;
            }
          });
        });
    },
    cancelEdit() {
      this.showEditModal = false;
    },
    showAttachmentClick(row) {
      this.selectedLog = row;
      this.showAttachment = true;
    },
    async printSelectedZebra() {
      const zebraId = this.$store.state.zebraSelection;
      const board = this.$store.state.boards.find(
        (brd) => brd.id === parseInt(this.boardId)
      );
      this.selectedPreview = {
        type: board.type,
        id: board.id,
        rev: board.rev,
        note: board.note,
        logData: this.lastLogData
      };
      this.showZebraPreview = true;
    },
    closeZebraPreview() {
      this.showZebraPreview = false;
    }
  },
  computed: {
    lastLogData() {
      const logs = this.BoardLogs;
      if (logs.length === 0) {
        return '';
      }
      const logCopy = JSON.parse(JSON.stringify(logs));
      logCopy.sort((a, b) => {
        return a.date === b.date ? b.id - a.id : b.date - a.date;
      });
      return logCopy[0].notes;
    },
    selectedZebraOnline() {
      if (this.$store.state.zebraSelection === '') {
        return false;
      }
      return this.$store.state.zebraPrinters.find((p) => {
        return p.zebraId === this.$store.state.zebraSelection;
      });
    },
    Boards() {
      return this.$store.state.boards.slice();
    },
    BoardLogs() {
      const logs = this.$store.state.boardLogs.filter(
        (log) => log.board_id === parseInt(this.boardId)
      );
      logs.sort((a, b) => {
        return a.date - b.date;
      });
      return logs;
    },
    TableTitle() {
      return `Board Logs - ${this.boardId}`;
    },
    ModalHeader() {
      return this.editLogCopy.id === 'new' ? 'New Log' : 'Edit Log';
    },
    BoardModalHeader() {
      const board = this.$store.state.boards.find(
        (brd) => brd.id === parseInt(this.boardId)
      );
      if (!board && this.$store.state.boards.length > 0) {
        return 'Please create board';
      }
      return board ? `Edit: ${this.TableName}` : 'Loading boards';
    },
    TableName() {
      const board = this.$store.state.boards.find(
        (brd) => brd.id === parseInt(this.boardId)
      );
      return board
        ? `${board.type} ${board.id} (${board.rev})`
        : 'Loading boards';
    },
    FarmOptions() {
      const ret = [];
      ret.push({
        label: '---',
        value: null,
        name: '---'
      });
      let farmList;
      if (this.$store.getters.isSuperAdmin) {
        farmList = this.$store.state.farmList;
      } else {
        farmList = this.$store.state.farmList.filter((e) => {
          return this.$store.state.user.farms.find((f) => f === e.id);
        });
      }
      for (let i = 0; i < farmList.length; i++) {
        ret.push({
          label: decoder(farmList[i].farm_name),
          value: farmList[i].id,
          name: farmList[i].farm_name
        });
      }

      if (this.farmSearch !== '') {
        const search = this.farmSearch.toLowerCase();
        return ret.filter((e) => {
          return e.name.toLowerCase().includes(search) || e.value === null;
        });
      }

      return ret;
    },
    PondOptions() {
      const ret = [];
      ret.push({
        label: '---',
        value: null,
        name: '---'
      });
      const farm = this.$store.state.farmList.find(
        (farm) => farm.id === this.editLogCopy.farm_id
      );
      if (farm) {
        for (let i = 0; i < farm.farmASM.length; i++) {
          ret.push({
            label: decoder(farm.farmASM[i].name),
            value: farm.farmASM[i].id,
            name: farm.farmASM[i].name
          });
        }
      }
      return ret;
    }
  },
  watch: {
    '$store.state.boardLogs'() {
      if (this.showAttachment === true) {
        const log = this.$store.state.boardLogs.find(
          (log) => log.id === this.selectedLog.id
        );
        if (log) {
          this.selectedLog = log;
        }
      }
    }
  }
};
</script>

<style scoped>
.spaced {
  margin-left: 2px;
  margin-right: 2px;
}

@media only screen and (min-width: 750px) {
  table {
    width: 80%;
  }

  .card-size {
    min-width: 500px;
  }

  .print-size {
    min-width: 250px;
  }
}

.max-width {
  max-width: 100px;
}
</style>
