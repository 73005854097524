<template>
  <div>
    <q-tabs
      v-model="categoryId"
      align="justify"
      dense
      no-caps
      mobile-arrows
      class="shadow-2"
    >
      <q-tab
        v-for="(category, index) in WorkCategories"
        :key="index"
        :style="buildBackColor(category.def_color)"
        class="custom-wrap"
        :name="category.id"
        :label="category.item"
      />
      <q-btn outline @click="addCategory">
        <q-icon name="add" size="md"></q-icon>
      </q-btn>
    </q-tabs>

    <div style="padding-top: 2px; padding-left: 2px; padding-right: 2px">
      <q-table
        :title="activeCategoryName"
        :data="Works"
        :columns="columns"
        :pagination.sync="pagination"
        row-key="id"
        no-data-label="No work items in this category"
      >
        <template v-slot:body-cell-Billable="props">
          <q-td :props="props">
            <q-icon
              class="check-style check-color"
              v-if="props.row.billable"
              name="check"
            ></q-icon>
            <q-icon
              color="negative"
              v-if="!props.row.billable"
              name="clear"
            ></q-icon>
          </q-td>
        </template>
        <template v-slot:body-cell-Edit="props">
          <q-td :props="props">
            <q-btn
              class="btn-table-primary"
              outline
              icon="edit"
              size="md"
              padding="xs"
              @click="editItem(props.row)"
            >
            </q-btn>
          </q-td>
        </template>
        <template v-slot:body-cell-Delete="props">
          <q-td :props="props">
            <q-btn
              class="btn-table-negative"
              outline
              icon="delete"
              size="md"
              padding="xs"
              @click="deleteItem(props.row)"
            >
            </q-btn>
          </q-td>
        </template>
      </q-table>
    </div>

    <div class="q-ma-md">
      <q-btn
        label="Move Pond"
        color="red-14"
        padding="sm"
        class="q-ml-sm"
        :size="$q.screen.xs ? 'sm' : 'md'"
        style="margin-bottom: 10px"
        @click="movePondStart"
      />
      <q-btn
        label="ASM Trip Dates"
        color="primary"
        icon="date_range"
        :size="$q.screen.xs ? 'sm' : 'md'"
        class="q-ml-sm"
        padding="sm"
        style="margin-bottom: 10px"
        @click="asmTDialog = true"
      />
      <q-btn
        label="Farm IDs"
        color="green-14"
        :size="$q.screen.xs ? 'sm' : 'md'"
        class="q-ml-sm"
        padding="sm"
        style="margin-bottom: 10px"
        @click="idDialog = true"
      />
      <q-btn
        label="Zebra Printers"
        color="green-14"
        :size="$q.screen.xs ? 'sm' : 'md'"
        padding="sm"
        class="q-ml-sm"
        style="margin-bottom: 10px"
        @click="editZebraSerial()"
      />
    </div>

    <q-page-sticky
      v-if="Works.length < 1"
      position="bottom-right"
      :offset="[100, 28]"
    >
      <q-btn fab icon="delete" color="negative" @click="deleteCategory" />
    </q-page-sticky>
    <q-page-sticky
      v-if="Works.length > 0"
      position="bottom-right"
      :offset="[100, 28]"
    >
      <q-btn fab icon="edit" color="primary" @click="editCategory" />
    </q-page-sticky>
    <q-page-sticky position="bottom-right" :offset="[28, 28]">
      <q-btn fab icon="add" color="accent" @click="addItem" />
    </q-page-sticky>

    <!-- edit modal -->
    <q-dialog v-model="showEditModal" persistent class="">
      <q-card square bordered class="q-pa-sm card-size">
        <q-card-section class="">
          <q-form @submit="onSubmit" class="q-gutter-xs">
            <q-toolbar class="shadow-2">
              <q-toolbar-title class="row justify-center items-center">
                <q-icon name="build" size="sm" class="q-mr-sm" />
                Work item
              </q-toolbar-title>
            </q-toolbar>
            <q-input
              filled
              v-model="editCopy.name"
              label="Name"
              maxlength="64"
              outlined
              square
            />
            <q-toggle
              label="Billable"
              v-model="editCopy.billable"
              color="primary"
            />
            <!-- <q-toggle
              label="Ready to Bill flag"
              v-model="editCopy.rtb_flag"
              color="primary"/> -->
            <q-select
              v-model="editCopy.type"
              square
              filled
              :options="[
                { label: 'Fields', value: 'fields' },
                { label: 'Time', value: 'time' },
                { label: 'No settable value', value: 'none' }
              ]"
              emit-value
              map-options
              label="Type"
            />
            <template v-if="editCopy.type === 'text'">
              <q-input
                filled
                v-model="editCopy.mask"
                label="Mask"
                maxlength="32"
                outlined
                square
              />
            </template>
            <template v-if="editCopy.type === 'fields'">
              <div
                v-for="(field, index) in editCopy.fields"
                :key="index"
                class="row"
              >
                <div
                  class="q-mr-xs"
                  :class="
                    field.type === 'text' || field.type === 'fields'
                      ? 'col-12 col-md-3'
                      : 'col-12 col-md-5'
                  "
                >
                  <q-input
                    filled
                    dense
                    v-model="field.name"
                    label="Field name"
                    maxlength="64"
                    outlined
                    square
                  />
                </div>
                <div
                  class="q-mr-xs"
                  :class="
                    field.type === 'text' || field.type === 'fields'
                      ? 'col-4 col-md-3'
                      : 'col-8 col-md-4'
                  "
                >
                  <q-select
                    v-model="field.type"
                    square
                    filled
                    :options="[
                      { label: 'Date', value: 'date' },
                      { label: 'Number', value: 'number' },
                      { label: 'Checkbox', value: 'checkbox' },
                      { label: 'Text', value: 'text' },
                      { label: 'Fields', value: 'fields' }
                    ]"
                    dense
                    emit-value
                    map-options
                    label="Type"
                  />
                </div>
                <div
                  class="col-4 col-md-3 q-mr-xs"
                  v-if="field.type === 'text'"
                >
                  <q-input
                    filled
                    dense
                    v-model="field.mask"
                    label="Mask"
                    maxlength="32"
                    outlined
                    square
                  />
                </div>
                <div
                  class="col-4 col-md-3 q-mr-xs"
                  v-if="field.type === 'fields'"
                >
                  <q-input
                    filled
                    dense
                    v-model="field.mask"
                    label="Fields csv"
                    maxlength="64"
                    outlined
                    square
                  />
                </div>
                <!-- <div class="col col-xs-auto"> -->
                <!--   <q-toggle -->
                <!--     style="margin: 2px" -->
                <!--     v-model="field.report" -->
                <!--     color="secondary" -->
                <!--     icon="fas fa-file-csv" -->
                <!--     size="xs" -->
                <!--     label="" -->
                <!--   /> -->
                <!-- </div> -->
                <q-space />
                <div class="col-auto">
                  <q-btn
                    v-if="index > 0"
                    style="margin: 3px"
                    class="btn-table-primary"
                    icon="arrow_upward"
                    size="md"
                    padding="xs"
                    @click="shiftField(index)"
                  >
                  </q-btn>
                </div>
                <div class="col-auto">
                  <q-btn
                    style="margin: 3px"
                    class="btn-table-negative"
                    icon="delete"
                    size="md"
                    padding="xs"
                    @click="deleteField(index)"
                  >
                  </q-btn>
                </div>
              </div>
              <div class="row justify-end">
                <div>
                  <q-btn
                    style="margin: 3px"
                    class="btn-table-primary"
                    icon="add"
                    size="md"
                    padding="xs"
                    @click="addField()"
                  >
                  </q-btn>
                </div>
              </div>
            </template>
            <div align="right">
              <div class="row full-width">
                <div class="col spaced">
                  <q-btn
                    outline
                    class="full-width"
                    label="Cancel"
                    color="secondary"
                    @click="cancelEdit"
                  />
                </div>
                <div class="col spaced">
                  <q-btn
                    outline
                    class="full-width"
                    label="Submit"
                    type="submit"
                    color="primary"
                  />
                </div>
              </div>
            </div>
          </q-form>
        </q-card-section>
      </q-card>
    </q-dialog>
    <!-- end edit modal -->

    <!-- edit category modal -->
    <q-dialog v-model="showEditCategory" persistent class="">
      <q-card square bordered class="q-pa-lg">
        <q-card-section class="">
          <q-form @submit="onSubmitCategory" class="q-gutter-sm">
            <q-toolbar class="shadow-2">
              <q-toolbar-title class="row justify-center items-center">
                <q-icon name="build" size="sm" class="q-mr-sm" />
                {{
                  editCategoryCopy.id === 'new'
                    ? 'New Category'
                    : 'Edit Category'
                }}
              </q-toolbar-title>
            </q-toolbar>
            <q-input
              filled
              v-model="editCategoryCopy.item"
              label="Name"
              maxlength="32"
              outlined
              square
            />
            <q-toggle
              label="Farm only"
              color="primary"
              v-model="editCategoryCopy.farm_cat"
            ></q-toggle>
            <q-input
              filled
              v-model.number="editCategoryCopy.view_order"
              type="number"
              min="0"
              label="View order (0 = First)"
              outlined
              square
            />
            <q-input
              filled
              v-model="editCategoryCopy.def_color"
              label="Default color"
              outlined
              square
            >
              <template v-slot:append>
                <q-icon name="colorize" class="cursor-pointer">
                  <q-popup-proxy
                    transition-show="scale"
                    transition-hide="scale"
                  >
                    <q-color v-model="editCategoryCopy.def_color" />
                  </q-popup-proxy>
                </q-icon>
              </template>
            </q-input>
            <div align="right">
              <div class="row full-width">
                <div class="col spaced">
                  <q-btn
                    outline
                    class="full-width"
                    label="Cancel"
                    color="secondary"
                    @click="cancelEdit"
                  />
                </div>
                <div class="col spaced">
                  <q-btn
                    outline
                    class="full-width"
                    label="Submit"
                    type="submit"
                    color="primary"
                  />
                </div>
              </div>
            </div>
          </q-form>
        </q-card-section>
      </q-card>
    </q-dialog>
    <!-- end edit category modal -->

    <!--Move Pond Dialog-->
    <q-dialog position="top" v-model="movePondDialog">
      <q-card style="width: 400px">
        <div class="text-h6 text-primary text-center q-pt-md">Move From:</div>
        <div class="q-px-md">
          <q-select
            v-model="moveFarmModel"
            :options="moveFarmList"
            label="Select Farm"
          />
        </div>
        <div class="q-pa-md">
          <q-select
            v-model="movePondModel"
            :options="movePondList"
            label="Select Pond"
          />
        </div>
        <q-separator color="primary" style="padding-top 1px" />
        <div class="text-h6 text-red-14 text-center q-pt-md">Move To:</div>
        <div class="q-px-md q-pb-md">
          <q-select
            v-model="moveToFarmModel"
            :options="moveFarmList"
            label="Select Farm"
          />
        </div>
        <q-separator color="primary" style="padding-top 1px" />
        <div align="right" class="q-ma-md">
          <q-btn
            flat
            label="cancel"
            color="primary"
            class="q-mr-sm"
            v-close-popup
          />
          <q-btn
            label="Move Pond"
            color="red-14"
            :disable="
              moveFarmModel === null ||
              movePondModel === null ||
              moveToFarmModel === null ||
              moveFarmModel.value === moveToFarmModel.value
            "
            @click="movePond"
          />
        </div>
      </q-card>
    </q-dialog>

    <!--ASM Dialog-->
    <q-dialog v-model="asmTDialog" persistent>
      <q-card :class="$q.screen.xs ? 'q-pa-sm' : 'q-pa-lg'">
        <div class="q-pa-sm">
          <div class="full-width">
            <q-input
              v-model="asmTriggers.triggerA"
              outlined
              type="number"
              min="0"
              label="Days Before ASM ( Yellow )"
              label-color="yellow"
              color="primary"
              class="q-mb-md"
            />
            <q-input
              v-model="asmTriggers.triggerB"
              outlined
              type="number"
              min="0"
              label="Days Before ASM ( Orange )"
              label-color="orange"
              color="primary"
            />
          </div>
          <div v-if="asmError.negative" class="text-red-14">
            Numbers cannot be negative
          </div>
          <div v-if="asmError.swapped" class="text-red-14">
            Days Before ASM ( Yellow ) must be greater than orange
          </div>
          <div class="row q-mt-lg">
            <q-space />
            <q-btn
              flat
              icon="clear"
              label="Cancel"
              color="primary"
              class="q-mr-sm"
              padding="xs sm"
              :size="$q.screen.xs ? 'sm' : 'md'"
              @click="(asmTDialog = false), initASMTriggers()"
            />
            <q-btn
              icon="date_range"
              label="Set Trip Dates"
              color="primary"
              padding="xs md"
              :size="$q.screen.xs ? 'sm' : 'md'"
              @click="setTriggers()"
            />
          </div>
        </div>
      </q-card>
    </q-dialog>

    <!--Farm Id Dialog-->
    <q-dialog persistent position="top" v-model="idDialog">
      <q-card style="width: 400px">
        <div align="right" class="q-ma-md">
          <q-btn
            icon="clear"
            color="grey-8"
            text-color="black"
            class="q-mr-sm"
            v-close-popup
          />
        </div>
        <q-separator color="primary" style="padding-top 1px" />

        <div class="scroll q-pb-md" style="max-height: calc(100vh - 200px)">
          <div class="row text-subtitle1 q-px-md q-pb-md">
            Farm
            <q-space />
            ID
          </div>
          <div
            v-for="farm in moveFarmList"
            :key="farm.value"
            class="row q-px-md q-pt-xs"
          >
            {{ farm.label }}
            <q-space />
            {{ farm.value }}
            <q-separator class="full-width" />
          </div>
        </div>
      </q-card>
    </q-dialog>
    <!--Zebra Dialog-->
    <q-dialog v-model="zebraDialog" persistent>
      <q-card :class="$q.screen.xs ? 'q-pa-sm' : 'q-pa-lg'">
        <div class="q-pa-sm">
          <div class="full-width">
            <q-card class="my-card" flat bordered>
              <div style="text-align: center">Online status</div>
              <q-markup-table>
                <thead>
                  <tr>
                    <th class="text-left">Name</th>
                    <th class="text-left">Serial</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="printer in zebraPrinters" :key="printer.id">
                    <th class="text-left">
                      {{ printer.zebraFriendlyName }}
                    </th>
                    <th class="text-left">
                      {{ printer.zebraId }}
                    </th>
                  </tr>
                </tbody>
              </q-markup-table>
            </q-card>
            <div style="margin-bottom: 10px"></div>
            <q-input
              v-model="zebraSelection"
              outlined
              type="text"
              label="Zebra Serial No"
              color="primary"
              class="q-mb-md"
            />
          </div>
          <div class="row q-mt-lg">
            <q-space />
            <q-btn
              flat
              icon="clear"
              label="Cancel"
              color="primary"
              class="q-mr-sm"
              padding="xs sm"
              :size="$q.screen.xs ? 'sm' : 'md'"
              @click="zebraDialog = false"
            />
            <q-btn
              label="Update Serial"
              color="primary"
              padding="xs md"
              :size="$q.screen.xs ? 'sm' : 'md'"
              @click="setZebraSerial()"
            />
          </div>
        </div>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import store from '@/store';
import { colors } from 'quasar';
import { v4 } from 'uuid';

export default {
  name: 'Setup',
  data() {
    return {
      idDialog: false,
      zebraDialog: false,
      zebraSelection: '',
      asmError: {
        negative: false,
        swapped: false
      },
      asmTDialog: false,
      asmTriggers: {
        triggerA: 0,
        triggerB: 0
      },
      movePondDialog: false,
      moveFarmModel: null,
      movePondModel: null,
      moveToFarmModel: null,
      categoryId: this.getInitialCategory(),
      showEditModal: false,
      showEditCategory: false,
      editCopy: {},
      editCategoryCopy: {},
      pagination: {
        descending: false,
        page: 1,
        rowsPerPage: 0
      },
      columns: [
        {
          sortable: true,
          name: 'Item',
          label: 'Item',
          align: 'left',
          classes: 'table-highlight-column',
          field: (row) => this.decoder(row.name)
        },
        {
          sortable: true,
          name: 'Type',
          label: 'Type',
          align: 'left',
          field: (row) =>
            row.type === 'fields'
              ? 'Fields'
              : row.type === 'time'
              ? 'Time'
              : '- - -'
        },
        {
          sortable: true,
          name: 'Billable',
          label: 'Billable',
          align: 'center',
          field: (row) => row.billable
        },
        {
          name: 'Edit',
          label: '',
          align: 'center'
        },
        {
          name: 'Delete',
          label: '',
          align: 'center'
        }
      ]
    };
  },
  mounted() {
    this.initASMTriggers();
  },
  methods: {
    movePond() {
      this.$q
        .dialog({
          title: 'Confirm',
          message: `Move ${this.movePondModel.label}
            from ${this.moveFarmModel.label} to
            ${this.moveToFarmModel.label}?`,
          ok: {
            flat: true,
            label: 'Move',
            color: 'red-14'
          },
          cancel: {
            flat: true,
            label: 'cancel',
            color: 'primary'
          },
          persistent: true
        })
        .onOk(() => {
          const moveObj = {
            farmFrom: this.moveFarmModel.value,
            farmTo: this.moveToFarmModel.value,
            pondId: this.movePondModel.value
          };

          store.dispatch('movePond', moveObj).then((results) => {
            results.disableCheck = true;
            this.$finishResult.handleResultsAsync(results).then((response) => {
              if (response === 'retry') {
                this.movePond();
              }
            });
          });
          this.movePondDialog = false;
          this.$q.notify({
            message: 'Pond Moved',
            icon: 'waves',
            color: 'red-14'
          });
        });
    },
    movePondStart() {
      this.movePondModel = null;
      this.moveFarmModel = null;
      this.moveToFarmModel = null;

      this.movePondDialog = true;
    },
    getInitialCategory() {
      if (this.$store.state.workCategories.length === 0) {
        return -1;
      }
      const firstCat = this.$store.state.workCategories.find(
        (e) => e.archived === false
      );
      return firstCat ? firstCat.id : -1;
    },
    buildBackColor(color) {
      return 'background-color: ' + colors.changeAlpha(color, 0.5);
    },
    decoder(str) {
      const textArea = document.createElement('textarea');
      textArea.innerHTML = str;
      return textArea.value;
    },
    addCategory() {
      this.editCategoryCopy = {
        id: 'new',
        item: '',
        view_order: 0,
        farm_cat: false,
        def_color: '#888'
      };
      this.showEditCategory = true;
    },
    editCategory() {
      const category = this.$store.state.workCategories.find(
        (item) => item.id === this.categoryId
      );
      this.editCategoryCopy = JSON.parse(JSON.stringify(category));
      this.showEditCategory = true;
    },
    deleteCategory() {
      const category = this.$store.state.workCategories.find(
        (item) => item.id === this.categoryId
      );
      this.$deleteResult
        .getResultsAsync('setting', category.item, 'Archive category?')
        .then((result) => {
          if (result === 'delete') {
            this.dispatchArchiveCategory(category);
          }
        });
    },
    onSubmitCategory() {
      let dispatch;
      if (this.editCategoryCopy.id === 'new') {
        dispatch = 'createWorkCategory';
      } else {
        dispatch = 'updateWorkCategory';
      }
      store.dispatch(dispatch, this.editCategoryCopy).then((results) => {
        this.$finishResult.handleResultsAsync(results).then((response) => {
          if (response === 'retry') {
            this.onSubmitCategory();
          } else {
            this.showEditCategory = false;
          }
        });
      });
    },
    dispatchArchiveCategory(category) {
      store.dispatch('archiveWorkCategory', category).then((results) => {
        this.$finishResult.handleResultsAsync(results).then((response) => {
          if (response === 'retry') {
            this.dispatchArchiveCategory(category);
          }
        });
      });
    },
    onSubmit() {
      let dispatch;
      if (this.editCopy.type === 'fields') {
        for (let i = 0; i < this.editCopy.fields.length; i++) {
          if (this.editCopy.fields[i].name === '') {
            this.$q.notify({
              message: 'Field must not be blank',
              color: 'primary'
            });
            return;
          }
        }
      }
      if (this.editCopy.id === 'new') {
        dispatch = 'createWorkItem';
      } else {
        dispatch = 'updateWorkItem';
      }
      store.dispatch(dispatch, this.editCopy).then((results) => {
        this.$finishResult.handleResultsAsync(results).then((response) => {
          if (response === 'retry') {
            this.onSubmit();
          } else {
            this.showEditModal = false;
          }
        });
      });
    },
    addItem() {
      this.editCopy = {
        id: 'new',
        name: '',
        mask: '',
        work_cat: this.categoryId,
        type: 'none',
        fields: [],
        billable: true,
        rtb_flag: false,
        archived: false
      };
      this.showEditModal = true;
    },
    editItem(item) {
      this.editCopy = JSON.parse(JSON.stringify(item));
      this.manageEmptyFields();
      this.showEditModal = true;
    },
    deleteItem(item) {
      this.$deleteResult
        .getResultsAsync('build', item.name, 'Delete item?')
        .then((result) => {
          if (result === 'delete') {
            this.dispatchDelete(item);
          }
        });
    },
    dispatchDelete(item) {
      store.dispatch('archiveWorkItem', item).then((results) => {
        this.$finishResult.handleResultsAsync(results).then((response) => {
          if (response === 'retry') {
            this.dispatchDelete(item);
          }
        });
      });
    },
    cancelEdit() {
      this.showEditModal = false;
      this.showEditCategory = false;
    },
    categoryOptions(allowNone) {
      const list = [];
      for (let i = 0; i < this.$store.state.workCategories.length; i++) {
        list.push({
          label: this.$store.state.workCategories[i].item,
          value: this.$store.state.workCategories[i].id
        });
      }
      if (allowNone) {
        list.push({
          label: 'None selected',
          value: null
        });
      }
      return list;
    },
    manageEmptyFields() {
      for (let i = 0; i < this.editCopy.fields.length; i++) {
        if (typeof this.editCopy.fields[i].report === 'undefined') {
          this.editCopy.fields[i].report = false;
        }
      }
    },
    shiftField(index) {
      this.editCopy.fields.splice(
        index - 1,
        0,
        this.editCopy.fields.splice(index, 1)[0]
      );
    },
    deleteField(index) {
      this.editCopy.fields.splice(index, 1);
    },
    addField() {
      this.editCopy.fields.push({
        id: v4(),
        name: '',
        mask: '',
        report: false,
        type: 'checkbox'
      });
    },
    initASMTriggers() {
      const settings = this.settings;

      this.asmTriggers = {
        triggerA: settings.ASM_triggerA,
        triggerB: settings.ASM_triggerB
      };

      // For cancel
      this.asmError.negative = false;
      this.asmError.swapped = false;
    },
    setTriggers() {
      if (this.asmTriggers.triggerA <= 0 || this.asmTriggers.triggerB <= 0) {
        this.asmError.negative = true;
        return;
      }
      if (this.asmTriggers.triggerA <= this.asmTriggers.triggerB) {
        this.asmError.swapped = true;
        return;
      }

      this.asmTDialog = false;
      store
        .dispatch('setASMTriggers', {
          value: {
            triggerA: this.asmTriggers.triggerA,
            triggerB: this.asmTriggers.triggerB
          }
        })
        .then((results) => {
          this.$finishResult.handleResultsAsync(results).then((response) => {
            if (response === 'retry') {
              this.setTriggers();
            }
          });
        });
    },
    setZebraSerial() {
      store.dispatch('setZebraSelection', this.zebraSelection);
      this.zebraDialog = false;
    },
    editZebraSerial() {
      this.zebraSelection = this.$store.state.zebraSelection;
      this.zebraDialog = true;
    }
  },
  computed: {
    zebraPrinters() {
      return this.$store.state.zebraPrinters;
    },
    moveFarmList() {
      const farmArr = [];
      for (let i = 0; i < this.$store.state.farmList.length; i++) {
        const farm = this.$store.state.farmList[i];
        farmArr.push({
          label: farm.farm_name,
          value: farm.id,
          farm: farm
        });
      }
      return farmArr;
    },
    movePondList() {
      const pondArr = [];
      if (this.moveFarmModel) {
        for (let i = 0; i < this.moveFarmModel.farm.gps.ponds.length; i++) {
          const pond = this.moveFarmModel.farm.gps.ponds[i];
          pondArr.push({
            pond,
            label: pond.name,
            value: pond.id
          });
        }
      }

      return pondArr;
    },
    settings() {
      return this.$store.state.settings;
    },
    Works() {
      return this.$store.state.workItems
        .filter((work) => work.work_cat === this.categoryId && !work.archived)
        .sort(function (a, b) {
          return a.name.localeCompare(b.name, undefined, {
            numeric: true,
            sensitivity: 'base'
          });
        });
    },
    WorkCategories() {
      const cats = JSON.parse(JSON.stringify(this.$store.state.workCategories));
      cats.sort(function (a, b) {
        return a.item.localeCompare(b.item, undefined, {
          numeric: true,
          sensitivity: 'base'
        });
      });
      cats.sort(function (a, b) {
        return a.view_order - b.view_order;
      });
      return cats.filter((e) => !e.archived);
    },
    activeCategoryName() {
      const category = this.$store.state.workCategories.find(
        (item) => item.id === this.categoryId
      );
      return category ? category.item : '--';
    }
  },
  watch: {
    '$store.state.workCategories'() {
      if (
        this.$store.state.workCategories.length > 0 &&
        this.$store.state.workCategories.findIndex(
          (item) => item.id === this.categoryId
        ) === -1
      ) {
        this.categoryId = -1;
      }
      if (
        this.$store.state.workCategories.length > 0 &&
        this.categoryId === -1
      ) {
        //this.categoryId = this.$store.state.workCategories[0].id;
        this.categoryId = this.getInitialCategory();
      }
    }
  }
};
</script>

<style scoped lang="scss">
/* Allow multi row q-categoryId */
@media (min-width: 700px) {
  .q-tab {
    &::v-deep {
      .q-tab__content {
        flex-wrap: wrap !important;
      }
    }
  }

  .q-tabs {
    &::v-deep {
      .q-tabs__content {
        flex-wrap: wrap !important;
      }
    }
  }
}
</style>

<style scoped>
.check-style {
  font-size: 1.4em;
  text-align: center;
}

@media only screen and (min-width: 1000px) {
  table {
    width: 80%;
  }

  .card-size {
    min-width: 500px;
  }
}

@media only screen and (min-width: 1200px) {
  table {
    width: 80%;
  }

  .card-size {
    min-width: 600px;
  }
}

.spaced {
  margin-left: 2px;
  margin-right: 2px;
}

.name-header {
  color: #d5d9dd;
  background-color: #424242;
  padding: 10px 10px 10px 0px;
  width: 35px;
}

.contact-td {
  padding-left: 15px;
  text-align: left;
  font-weight: bold;
  font-size: 14px;
}

.item-td {
  padding-left: 10px;
  text-align: left;
  font-weight: bold;
}

.col-td {
  padding-left: 10px;
  text-align: left;
}

.col-th {
  padding-left: 15px;
  text-align: left;
}

table {
  margin: 0px auto 0px auto;
  font-size: 12px;
  border-collapse: collapse;
  border-left: none;
  border-right: none;
}

td {
  text-align: center;
  border: 1px solid #212121;
  border-left: none;
  border-right: none;
}

th {
  padding: 10px 0px;
  border: 1px solid #212121;
  border-left: none;
  border-right: none;
}
</style>
