import { render, staticRenderFns } from "./Attachments.vue?vue&type=template&id=7251385e&scoped=true"
import script from "./Attachments.vue?vue&type=script&lang=js"
export * from "./Attachments.vue?vue&type=script&lang=js"
import style0 from "./Attachments.vue?vue&type=style&index=0&id=7251385e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7251385e",
  null
  
)

export default component.exports
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QMenu from 'quasar/src/components/menu/QMenu.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QToggle from 'quasar/src/components/toggle/QToggle.js';
import QLinearProgress from 'quasar/src/components/linear-progress/QLinearProgress.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import QFile from 'quasar/src/components/file/QFile.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QToolbar,QSpace,QBtn,QMenu,QItem,QItemSection,QIcon,QSeparator,QToggle,QLinearProgress,QCheckbox,QFile,QInput,QBadge,QImg,QDialog,QCard});qInstall(component, 'directives', {ClosePopup});
