<template>
  <!-- IOSpwaDialog Dialog -->
  <q-dialog persistent position="top" v-model="dialogIOSpwa">
    <q-card class="q-pa-md" style="width: 400px">
      <div>
        <div>To Install this webapp on your iPhone or iPad:</div>
        <div class="q-py-sm">
          Tap <q-icon name="ios_share" color="light-blue" size="22px" /> and
          then Add to Home Screen
        </div>
        <q-separator />
        <div align="right" class="q-pt-sm">
          <q-btn
            flat
            label="Ok, Got It"
            color="primary"
            v-close-popup
            @click="$emit('IOSpwaConfirm')"
          />
        </div>
      </div>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  name: 'IOSpwaDialog',
  data() {
    return {
      dialogIOSpwa: true
    };
  }
};
</script>

<style scoped></style>
