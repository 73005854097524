import { render, staticRenderFns } from "./Boards.vue?vue&type=template&id=02093826&scoped=true"
import script from "./Boards.vue?vue&type=script&lang=js"
export * from "./Boards.vue?vue&type=script&lang=js"
import style0 from "./Boards.vue?vue&type=style&index=0&id=02093826&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02093826",
  null
  
)

export default component.exports
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QTh from 'quasar/src/components/table/QTh.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import QPageSticky from 'quasar/src/components/page-sticky/QPageSticky.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QForm from 'quasar/src/components/form/QForm.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QBtn,QTabs,QTab,QTable,QSelect,QInput,QTh,QIcon,QTd,QCheckbox,QPageSticky,QDialog,QCard,QCardSection,QForm,QToolbar,QToolbarTitle});
