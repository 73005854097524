import { render, staticRenderFns } from "./farm-tabs.vue?vue&type=template&id=443e0ed5"
import script from "./farm-tabs.vue?vue&type=script&lang=js"
export * from "./farm-tabs.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QRouteTab from 'quasar/src/components/tabs/QRouteTab.js';
import QBtnDropdown from 'quasar/src/components/btn-dropdown/QBtnDropdown.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QTabs,QSpace,QBtn,QRouteTab,QBtnDropdown,QList,QItem,QItemSection});
