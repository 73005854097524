<template>
  <TodoPlugin farm-id="all" :farm-only="false" />
</template>

<script>
import TodoPlugin from '@/components/TodoPlugin';

export default {
  name: 'Todos',
  components: {
    TodoPlugin
  }
};
</script>
